<template>
	<div class="grid grid-cols-5 gap-5">
		<div v-for="(color, index) in colors" :key="index" class="shadow color rounded-md hover:bg-gray-100 p-2">
			<button class="w-full h-40 focus:outline-none" @click="selectColor(color)">
				<div v-if="color === 'reset'" class="w-full h-full bg-white flex justify-center items-center text-xl">
					Reset
					<br />
					Color
				</div>
				<div v-if="color !== 'reset'" class="w-full h-full" :class="'bg' + color"></div>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	emits: ['selectColor'],
	setup() {},
	data() {
		return {
			colors: ['reset', '-sc-blue', '-sc-green', '-sc-gold', '-sc-light-blue', '-sc-orange', '-sc-purple'],
		}
	},
	methods: {
		selectColor(color) {
			this.$emit('selectColor', color)
		},
	},
}
</script>

<style>
.color {
	min-width: 72px;
}
</style>
