<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M32.05,18.21V24.3a7.21,7.21,0,0,1-1.78,4.42c-1.78,2-3.87,5.23-.68,7s5.41-.1,7-.67a4.42,4.42,0,0,1,3.32,0c1,.3,5,2.08,4.54-1.05s-2.27-2.77-4.18-4.61-3.56-4.55-2.39-11.19Z"
		/>
		<path
			:fill="fillComputed"
			d="M32.17,53.85s1.14-3.77-2-8.69-.55-7.9,1.63-7.56,3.16,1.78,4.94,1.66,2.58-2,4.37-1.07,1.5,4.73-.77,5.77-4,4.21-3.2,9.89Z"
		/>
		<path
			:fill="fillComputed"
			d="M40.1,53.85h1.6s-.83-5.71,1.72-8.13,3.29-8-.89-7.88c0,0,2.61,2.31.09,5.54S40.41,46.63,40.35,48,40.1,53.85,40.1,53.85Z"
		/>
		<path
			:fill="fillComputed"
			d="M51.7,63h-30a2.68,2.68,0,0,1-2.67-2.67V11.74a2.68,2.68,0,0,1,2.67-2.67h30a2.67,2.67,0,0,1,2.67,2.67V60.33A2.67,2.67,0,0,1,51.7,63Zm-30-52.47a1.22,1.22,0,0,0-1.22,1.21V60.33a1.22,1.22,0,0,0,1.22,1.21h30a1.22,1.22,0,0,0,1.22-1.21V11.74a1.22,1.22,0,0,0-1.22-1.21Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Bones',
	mixins: [iconMixin],
}
</script>
