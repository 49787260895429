<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M22.81,55.09c-2.85-.24-4-2.13-4.29-4.58-.54-5.58-.39-11.15,1.65-16.46,2.23-5.81,7-8.34,12.79-9.46.44-.08,1,.53,1.52.81-.32.53-.54,1.22-1,1.55a5.51,5.51,0,0,1-1.83.54c-5.42,1.33-9,4.68-9.87,10.19a94.57,94.57,0,0,0-.71,12.38c0,.77.75,2.13,1.26,2.19A3.55,3.55,0,0,0,24.75,51a33.33,33.33,0,0,0,3.32-4.09,10.7,10.7,0,0,1,5.67-4.21c.92-.29,1.64-.76,1.27-1.9a1.66,1.66,0,0,0-2.32-1.19,11.16,11.16,0,0,0-6,4c-.57.74-1.15,1.51-2.14.77s-.45-1.55.11-2.28a13,13,0,0,1,8.15-5.1,4,4,0,0,1,4.67,3,3.66,3.66,0,0,1-2.37,4.8,10.39,10.39,0,0,0-5.35,4,14.63,14.63,0,0,1-2.54,3.13c-2.38,2-2,4.26-1.1,6.67a11.77,11.77,0,0,0,13,7.48,16.17,16.17,0,0,1-.47-3.2,3.92,3.92,0,0,1,3-3.89C46.78,57.85,48,55,48,49.66c0-.94,0-1.87,0-2.46L45.8,45.76a10.53,10.53,0,0,1,1.28-1.9c1.56-1.5,1.8-2.5.68-4.33-.61-1-1.42-1.86-2-2.87a6.64,6.64,0,0,1-.92-2.4c0-.37.53-1,1-1.2s1,.25,1.27.6c1,1.38,1.82,2.85,2.72,4.27a5.92,5.92,0,0,1,.86,5.49,8.15,8.15,0,0,0-.15,2.43c-.07,2.45.06,4.93-.21,7.36a8.53,8.53,0,0,1-6.05,7.67c-.55.19-1.12.33-1.66.55a2,2,0,0,0-1.32,2.67,2,2,0,0,0,2.7,1.25C48.55,64.16,51.43,61,53,56.8a30.8,30.8,0,0,0,1.72-7.67,55,55,0,0,0,0-8.42c-.32-6.37-4.38-11.24-10.92-13.35-1-.33-2.76-.3-2.17-2s2-.68,3-.34c7,2.36,12.91,7.84,12.61,17-.13,4.09.09,8.3-.85,12.21C53.91,65,47.34,69,36,68.9,29.24,68.86,23.27,62.71,22.81,55.09Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M47.75,14c0,5.33-3.14,9.47-7.87,10.44A10,10,0,0,1,28.62,18.7,5.44,5.44,0,0,1,28.08,17a12,12,0,0,0-2.18-6.54c-1.28-1.77-.68-4,.86-5.65a5.31,5.31,0,0,1,5.63-1.45A5.41,5.41,0,0,0,35,3.46C41.77,1.67,47.76,6.59,47.75,14ZM39.48,12.8l-8.95,3.13a7.15,7.15,0,0,0,8,6.14c3.56-.31,6.57-3.42,6.41-6.35Zm-9,.09c2.74-.22,5.3-.82,7.37-2.76,1.61-1.51,1.65-1.42,3.13.09a32.31,32.31,0,0,0,4,3,7.26,7.26,0,1,0-14.52-.29Zm.18-7.06c-2.36.25-3.32,1.86-2.28,3.48Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M44.88,52.29a10.89,10.89,0,0,1-1.26,2.81,53.13,53.13,0,0,1-4.88,5,2,2,0,0,1-2,0,44.59,44.59,0,0,1-5-5.1,4.19,4.19,0,0,1,0-5.5C33,48,34.78,47.49,37,48a4.4,4.4,0,0,0,2-.11A4.84,4.84,0,0,1,44.88,52.29ZM37.74,58c1.47-1.65,2.71-3,3.86-4.35a1.85,1.85,0,0,0-.08-2.69c-.82-.83-1.69-.67-2.72-.19a2.86,2.86,0,0,1-2.15,0c-1-.47-2.08-.73-2.65.25a3,3,0,0,0,0,2.63A42.08,42.08,0,0,0,37.74,58Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PregnancyChildbirth',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1 {
	fill: none !important;
	stroke-miterlimit: 10;
	stroke-width: 2px;
}
</style>
