<template>
	<div :class="[hasColSpan ? field.colSpan : '', field.label.length > 0 ? 'sm:pt-3' : '']">
		<label v-if="field.label.length > 0" :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0 relative">
			<div
				v-if="!meta.valid"
				class="absolute inset-y-0 right-5 pr-3 pt-2.5 flex items-top pointer-events-none z-10"
			>
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>

			<Multiselect
				v-model="value"
				:class="[!meta.valid ? 'errors' : '']"
				:mode="field.mode"
				:searchable="true"
				:create-tag="createTag"
				:append-new-tag="false"
				:options="field.options"
				:resolve-on-load="false"
				:object="true"
				:max="field.maxItems"
				:disabled="!field.options"
				@tag="storeTag"
			/>
			<IconButtonLoading :loading="!field.options" class="text-black absolute top-2 right-0" />

			<div class="mt-2 text-red-500 font-semibold">{{ errorMessage }}</div>
		</div>
	</div>
</template>

<script>
import { computed, ref } from 'vue'
import { useForm, useField } from 'vee-validate'
import * as yup from 'yup'

import Multiselect from '@vueform/multiselect'
import { ExclamationCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		Multiselect,
		ExclamationCircleIcon,
	},
	props: {
		modelValue: {
			type: Array,
			default: function() {
				return []
			},
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
		createTag: Boolean,
		disabled: Boolean,
	},
	emits: ['update:modelValue', 'storeTag', 'invalidField', 'validField'],
	setup(props, { emit }) {
		const required = ref(props.field.required)
		const min = ref(props.field.min || 0)
		const max = ref(props.field.max || 255)

		// Define a validation schema
		let schemaData = {
			inputVal: yup
				.array()
				.min(min.value)
				.max(max.value)
				.nullable(true)
				.label(props.field.label),
		}
		if (required.value) {
			// console.log('required')
			schemaData = {
				inputVal: yup
					.array()
					.min(min.value)
					.max(max.value)
					.required()
					.nullable(true)
					.label(props.field.label),
			}
		}

		const schema = computed(() => {
			return yup.object(schemaData)
		})

		// Create a form context with the validation schema
		useForm({
			validationSchema: schema,
		})

		const { errorMessage, value, meta, validate } = useField('inputVal', undefined, {
			initialValue: props.modelValue,
		})

		// trigger validation
		validate()
		setTimeout(() => {
			if (!meta.valid) {
				emit('invalidField', props.field.model)
			}
		}, 50)

		return {
			errorMessage,
			value,
			meta,
		}
	},
	data() {
		return {}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log("INPUT:", val);
				this.$emit('update:modelValue', val)
			},
		},
	},
	watch: {
		value: function(val) {
			this.inputVal = this.value
			// console.log(val)
			setTimeout(() => {
				console.log('ERROR:', this.meta.valid)
				if (!this.meta.valid) this.$emit('invalidField', this.field.model)
				else this.$emit('validField', this.field.model)
			}, 50)
		},
		'field.options': {
			// Silly fix for the option value
			deep: true,
			handler(val) {
				// this.inputVal = this.value
				// console.log('field.options', this.value, val)

				this.value.forEach((selected, index) => {
					let fieldOptionIndex = val.findIndex(x => x.label === selected.value)

					if (fieldOptionIndex >= 0) {
						this.value[index] = val[fieldOptionIndex]
					}
				})
			},
		},
	},
	mounted() {
		// console.log("INPUT:", this.modelValue);
	},
	methods: {
		storeTag(tag) {
			this.$emit('storeTag', tag)
		},
	},
}
</script>

<style>
.multiselect {
	position: relative;
	margin: 0 auto;
	font-size: 0;
}

.multiselect > * {
	font-size: medium;
}

.multiselect.is-searchable {
	cursor: auto;
}

.multiselect-input {
	width: 100%;
	display: flex;
	align-items: center;
	min-height: 40px;
	box-sizing: border-box;
	cursor: pointer;
	position: relative;
	outline: none;
	background-color: #fff;

	/* padding: 0.5rem; */
	border-width: 1px;
	border-radius: 0.375rem;
	--tw-border-opacity: 1;
	border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.errors .multiselect-input {
	--tw-border-opacity: 1;
	border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.multiselect-caret {
	position: absolute;
	right: 12px;
	top: 50%;
	color: #999;
	border-color: #999 transparent transparent;
	border-style: solid;
	border-width: 5px 5px 0;
	content: '';
	transform: translateY(-50%);
	transition: transform 0.3s;
}

.is-disabled .multiselect-input {
	background: #f9f9f9;
}

.is-open .multiselect-input {
	border-radius: 0.375rem 0.375rem 0 0;
}

.is-open .multiselect-caret {
	transform: translateY(-50%) rotate(180deg);
}

.multiselect-multiple-label,
.multiselect-placeholder,
.multiselect-single-label {
	display: flex;
	align-items: center;
	height: 100%;
	padding-left: 14px;
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	background: transparent;
}

.multiselect-placeholder {
	color: #777;
}

.is-multiple .multiselect-search,
.is-single .multiselect-search {
	display: flex;
	height: 100%;
	width: 100%;
	background: transparent;
}

.is-multiple .multiselect-search input,
.is-single .multiselect-search input {
	width: 100%;
	border: 0;
	padding: 8px 35px 8px 14px;
	outline: none;
	background: transparent;
	font-size: 16px;
	font-family: inherit;
}

.is-multiple.no-caret .multiselect-search input,
.is-single.no-caret .multiselect-search input {
	padding: 8px 14px;
}

.is-tags .multiselect-search {
	flex-grow: 1;
}

.is-tags .multiselect-search input {
	outline: none;
	border: 0;
	margin: 0 0 5px 3px;
	flex-grow: 1;
	min-width: 100%;
	font-size: 16px;
	font-family: inherit;
}

.multiselect-clear {
	position: absolute;
	right: 0;
	top: 1px;
	width: 36px;
	height: 36px;
	background: #fff;
}

.multiselect-clear:after,
.multiselect-clear:before {
	position: absolute;
	top: 12px;
	left: 16px;
	content: ' ';
	height: 12px;
	width: 2px;
	background-color: #999;
}

.multiselect-clear:before {
	transform: rotate(45deg);
}

.multiselect-clear:after {
	transform: rotate(-45deg);
}

.multiselect-clear:hover:after,
.multiselect-clear:hover:before {
	background-color: #555;
}

.multiselect-tags {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	padding-left: 9px;
	margin-top: 5px;
	flex-wrap: wrap;
	padding-right: 36px;
}

.no-caret .multiselect-tags {
	padding-right: 9px;
}

.multiselect-tag {
	background: #41b883;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	padding: 0 0 0 8px;
	border-radius: 0.375rem;
	margin-right: 5px;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	cursor: text;
	white-space: nowrap;
}

.multiselect-tag i {
	cursor: pointer;
}

.multiselect-tag i:before {
	content: '\D7';
	color: #266d4d;
	font-size: 14px;
	font-weight: 700;
	padding: 1px 5px;
	margin-left: 3px;
	display: flex;
	font-style: normal;
}

.multiselect-tag i:hover:before {
	color: #fff;
	background: hsla(0, 0%, 100%, 0.2);
}

.is-disabled .multiselect-tag {
	background: #a0a0a0;
	padding: 1px 8px;
}

.multiselect-fake-input {
	background: transparent;
	width: 100%;
	height: 1px;
	border: 0;
	padding: 0;
	font-size: 0;
	margin-top: -1px;
	outline: none;
}

.multiselect-fake-input:active,
.multiselect-fake-input:focus {
	outline: none;
}

.multiselect-options {
	position: absolute;
	left: 0;
	right: 0;
	border: 1px solid #e8e8e8;
	margin-top: -1px;
	max-height: 160px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	z-index: 100;
	background: #fff;
}

.multiselect-option {
	display: flex;
	min-height: 40px;
	padding: 9px 12px;
	box-sizing: border-box;
	color: #222;
	text-decoration: none;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
}

.multiselect-option.is-pointed {
	background: #e6e6e6;
}

.multiselect-option.is-disabled {
	background: #f9f9f9;
	color: #a6a6a6;
	cursor: not-allowed;
}

.multiselect-option.is-selected {
	background: #41b883;
	color: #fff;
}

.multiselect-option.is-selected.is-pointed {
	background: #4fbd8c;
}

.is-multiple .multiselect-option.is-selected,
.is-tags .multiselect-option.is-selected {
	color: #999;
	background: transparent;
}

.is-multiple .multiselect-option.is-selected.is-pointed,
.is-tags .multiselect-option.is-selected.is-pointed {
	background: #f1f1f1;
}

.multiselect-no-options,
.multiselect-no-results {
	display: flex;
	padding: 10px 12px;
	color: #777;
}

.multiselect-spinner {
	position: absolute;
	right: 12px;
	top: 0;
	width: 16px;
	height: 16px;
	background: #fff;
	display: block;
	transform: translateY(50%);
}

.multiselect-spinner:after,
.multiselect-spinner:before {
	position: absolute;
	content: '';
	top: 50%;
	left: 50%;
	margin: -8px 0 0 -8px;
	width: 16px;
	height: 16px;
	border-radius: 100%;
	border: 2px solid transparent;
	border-top-color: #41b883;
	box-shadow: 0 0 0 1px transparent;
}

.is-disabled .multiselect-spinner {
	background: #f9f9f9;
}

.is-disabled .multiselect-spinner:after,
.is-disabled .multiselect-spinner:before {
	border-color: #999 transparent transparent;
}

.multiselect-spinner:before {
	-webkit-animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
	animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.multiselect-spinner:after {
	-webkit-animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
	animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.multiselect-enter-active {
	transition: all 0.15s ease;
}

.multiselect-leave-active {
	transition: all 0s;
}

.multiselect-enter,
.multiselect-leave-active {
	opacity: 0;
}

.multiselect-loading-enter-active,
.multiselect-loading-leave-active {
	transition: opacity 0.4s ease-in-out;
	opacity: 1;
}

.multiselect-loading-enter,
.multiselect-loading-leave-active {
	opacity: 0;
}

@-webkit-keyframes spinning {
	0% {
		transform: rotate(0);
	}

	to {
		transform: rotate(2turn);
	}
}

@keyframes spinning {
	0% {
		transform: rotate(0);
	}

	to {
		transform: rotate(2turn);
	}
}
</style>
