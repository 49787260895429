<template>
	<div :class="[hasColSpan ? field.colSpan : '', field.label.length > 0 ? 'sm:pt-3' : '']">
		<label v-if="field.label.length > 0" :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>

		<div class="mt-1 relative rounded-md shadow-sm flex">
			<div v-if="!meta.valid" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>

			<div
				class="flex-initial "
				:style="field.restrictUrlPrefix ? 'width:' + field.prefix.length * 10 + 'px;min-width: 30px;' : ''"
			>
				<input
					v-if="field.restrictUrlPrefix"
					:value="field.prefix"
					type="text"
					class="h-full w-full tracking-wide py-0 px-2 bg-gray-50 border-gray-300 text-center rounded-l-md sm:text-sm focus:outline-none focus:ring-0"
					readonly
					disabled
				/>
				<select
					v-if="!field.restrictUrlPrefix"
					:value="field.prefix"
					name="url_prefix"
					class="h-full w-full tracking-wide py-0 px-2 pr-7 bg-gray-50 border-gray-300 rounded-l-md sm:text-sm focus:outline-none focus:ring-0"
					@change="updateUrlPrefix"
				>
					<option value="/">/</option>
					<option value="/careers/">/careers/</option>
					<option value="/patient-tools/">/patient-tools/</option>
				</select>
			</div>
			<div class="flex-1">
				<input
					v-model="value"
					:type="field.inputType"
					class="-ml-px block w-full pr-12 sm:text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500 disabled:opacity-50"
					:class="[
						hasPrefix && hasCopy ? 'rounded-none' : hasPrefix ? 'rounded-none rounded-r-md' : 'rounded-md',
						!meta.valid ? 'border-red-300 text-red-900' : '',
					]"
					:name="field.model"
					:autocomplete="field.model"
					:disabled="disabled"
					@blur="$emit('blur')"
				/>
			</div>

			<div class="flex-initial">
				<button
					v-if="hasCopy"
					class="-ml-2 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none disabled:opacity-50"
					:disabled="disabled"
					@click="copy"
				>
					<ClipboardCopyIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
					<span>Copy</span>
				</button>
			</div>
		</div>

		<div class="mt-2 text-red-500 font-semibold">{{ errorMessage }}</div>
	</div>
</template>

<script>
import { computed, ref } from 'vue'
import { useToast } from 'vue-toastification'
import useClipboard from 'vue-clipboard3'
import { ExclamationCircleIcon, ClipboardCopyIcon } from '@heroicons/vue/solid'

import { useForm, useField } from 'vee-validate'
import * as yup from 'yup'

const { toClipboard } = useClipboard()

export default {
	components: {
		ExclamationCircleIcon,
		ClipboardCopyIcon,
	},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue', 'blur', 'updateUrlPrefix', 'invalidField', 'validField'],
	setup(props, { emit }) {
		const toast = useToast()

		const required = ref(props.field.required)
		const min = ref(props.field.min || 0)
		const max = ref(props.field.max || 255)

		// Define a validation schema
		let schemaData = {
			inputVal: yup
				.string()
				.min(min.value)
				.max(max.value)
				.nullable(true)
				.label(props.field.label),
		}
		if (required.value) {
			// console.log('required')
			schemaData = {
				inputVal: yup
					.string()
					.required()
					.min(min.value)
					.max(max.value)
					.nullable(true)
					.label(props.field.label),
			}
		}

		const schema = computed(() => {
			return yup.object(schemaData)
		})

		// Create a form context with the validation schema
		useForm({
			validationSchema: schema,
		})

		const { errorMessage, value, meta, validate } = useField('inputVal', undefined, {
			initialValue: props.modelValue,
		})

		// trigger validation
		validate()
		setTimeout(() => {
			if (!meta.valid) {
				emit('invalidField', props.field.model)
			}
		}, 50)

		return {
			toast,
			errorMessage,
			value,
			meta,
		}
	},
	data() {
		return {}
	},
	computed: {
		wordCount: function() {
			if (this.inputVal === null || this.inputVal.length === 0) {
				return 0
			}
			return this.inputVal.split(' ').length
		},
		characterCount: function() {
			if (this.inputVal === null || this.inputVal.length === 0) {
				return 0
			}
			return this.inputVal.length
		},
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasPrefix: function() {
			return typeof this.field.prefix !== 'undefined'
		},
		hasCopy: function() {
			return typeof this.field.enableCopy !== 'undefined' && this.field.enableCopy
		},
		copyWithPrefix: function() {
			return typeof this.field.copyWithPrefix !== 'undefined' && this.field.copyWithPrefix
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log('INPUT:', val)
				this.$emit('update:modelValue', this.processField(val))
			},
		},
	},
	watch: {
		value: function(val) {
			this.value = this.processField(this.value)
			this.inputVal = this.value
			setTimeout(() => {
				// console.log('inputVal:', this.inputVal)
				// console.log('ERROR:', this.meta.valid)
				if (!this.meta.valid) this.$emit('invalidField', this.field.model)
				else this.$emit('validField', this.field.model)
			}, 50)
		},
	},
	mounted() {
		console.log('INPUT:', this.modelValue)
	},
	methods: {
		async copy() {
			try {
				let fullUrl = (this.copyWithPrefix ? this.field.prefix : '') + this.inputVal
				console.log(fullUrl)
				await toClipboard(fullUrl)
				this.toast.success('Copied "' + fullUrl + '" to clipboard')
			} catch (e) {
				console.error(e)
			}
		},

		processField(val) {
			// console.log(val)
			// return
			if (typeof this.field.kebabCase !== 'undefined' && this.field.kebabCase && typeof val == 'string') {
				let value = val
					.toLowerCase()
					.replaceAll(' ', '-')
					.replaceAll('--', '-')
					.replace(/[^-0-9a-z_/]/gi, '')

				while (value.charAt(0) === '-') {
					value = value.substring(1)
				}
				// while (value.charAt(value.length - 1) === '-') {
				// 	value = value.slice(0, -1)
				// }
				// console.log(typeof val, value)

				return value
			}
		},

		updateUrlPrefix(evt) {
			// console.log(evt.target.value)
			this.$emit('updateUrlPrefix', evt.target.value)
		},
	},
}
</script>
