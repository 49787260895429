<template>
	<svg
		id="Layer_1"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 620.6 242.4"
		style="enable-background: new 0 0 620.6 242.4"
		xml:space="preserve"
		:style="style"
	>
		<g>
			<path
				:fill="hasFillGreen ? fill.blue : '#00867c'"
				d="M212.6,82.8c4.8-13.6,10.9-24,22.1-32.8c-6,1.8-11.3,4.9-15.9,9.2c-4.6,4.3-8.1,9.8-10.2,15.7v-0.4
        c0.1-2.4,0.1-4.9,0.3-7.3c0.4-4.4,1.5-8.6,3.4-12.6c3.4-7.1,8.8-12,15.9-15.2c4.4-2,9.1-3.3,13.9-3.9c3.8-0.4,7.7-0.6,11.5-0.9
        c0.1,0,0.3,0,0.6,0c0.1,1,0.2,2,0.2,2.9c0.2,10.3-1.7,20-7.5,28.6c-4.4,6.6-10.5,10.9-17.9,13.6c-4.6,1.6-9.3,2.5-14.1,2.8
        C214.1,82.7,213.4,82.7,212.6,82.8"
			/>
			<path
				:fill="hasFillBlue ? fill.blue : '#003764'"
				d="M196.3,6.1c-1.3,2-2.6,4-3.8,6c-2.2,3.9-3.9,7.9-4.6,12.4c-1.3,8.1,1,15,6.7,20.8c3,3.1,6.5,5.5,10.5,7.4
        c0.3,0.1,0.5,0.2,0.9,0.4c5.3-7.6,8.9-15.6,7.2-25.1c-0.9-5.1-3.4-9.4-6.6-13.3c-2.7-3.2-5.8-5.9-9.3-8.1
        C196.9,6.4,196.6,6.3,196.3,6.1"
			/>
			<path
				:fill="hasFillYellow ? fill.blue : '#ffbf3c'"
				d="M200.6,78.1c-1.6-9.6-6.2-15.7-13.6-20.8c3.8,1.2,7.1,3.2,9.9,5.9c2.8,2.7,5,6.2,6.3,9.9V73c0-1.5,0-3-0.1-4.6
        c-0.2-2.8-0.8-5.4-2-7.9c-2.1-4.5-5.5-7.6-9.9-9.7c-2.8-1.3-5.7-2.2-8.7-2.6c-2.4-0.3-4.8-0.4-7.2-0.6c-0.1,0-0.2,0-0.4,0
        c0,0.6-0.1,1.2-0.1,1.8c-0.2,6.4,0.9,12.6,4.5,18.1c2.7,4.2,6.5,7,11.1,8.7c2.9,1.1,5.8,1.6,8.8,1.9
        C199.7,78,200.1,78.1,200.6,78.1"
			/>
			<rect x="445.5" y="177.6" :fill="hasFillGreen ? fill.blue : '#00867c'" width="16.4" height="58" />
			<rect x="539.3" y="177.6" :fill="hasFillGreen ? fill.blue : '#00867c'" width="16.4" height="58" />
			<rect x="199.4" y="89.6" :fill="hasFillBlue ? fill.blue : '#003764'" width="16.4" height="58" />
			<rect x="519.8" y="50" :fill="hasFillBlue ? fill.blue : '#003764'" width="16.4" height="97.7" />
			<path
				:fill="hasFillGreen ? fill.blue : '#00867c'"
				d="M612.5,195.3c-3.5-3.2-7.8-5.1-13.4-5.1c-10.9,0-16.4,7.9-16.4,16.4c0,9.5,7.1,16.5,16.9,16.5
        c3.6,0,8.4-1.1,12.9-5.1v16c-3.2,1.6-8.3,3.6-15,3.6c-9.1,0-17.2-3.5-22.4-8.4c-4.4-4.2-9.2-11.4-9.2-22.3
        c0-10.2,4.2-18.4,10.1-23.6c7.1-6.3,15.3-7.5,20.9-7.5c6,0,11,1.2,15.7,3.5V195.3z"
			/>
			<path
				:fill="hasFillBlue ? fill.blue : '#003764'"
				d="M54.2,78.7c-5.2-7-11.4-7.6-14.6-7.6c-9.4,0-12.3,5.9-12.3,10.5c0,2.1,0.7,4.2,2.8,6c2.1,2,5.1,3.1,10.7,5.1
        c7,2.4,13.5,4.8,18.1,9c4,3.6,7.6,9.6,7.6,18.8c0,17.4-12.6,29.4-31.1,29.4c-16.5,0-26.1-10.1-30.8-16.9L16,121.9
        c4.3,8.7,12.5,11.9,18.8,11.9c7.9,0,13.8-4.8,13.8-12.3c0-3.2-1.1-5.8-3.5-8c-3.4-3.1-8.7-4.8-13.8-6.6c-4.7-1.6-10.3-3.8-14.9-7.8
        c-2.9-2.5-7.1-7.5-7.1-16.6c0-13.1,8.8-26.3,28.7-26.3c5.6,0,16.2,1.1,25.3,10.5L54.2,78.7z"
			/>
			<path
				:fill="hasFillBlue ? fill.blue : '#003764'"
				d="M95,106.1c-3.6,3.1-5.5,7.1-5.5,12.5s2.1,9.8,5.4,12.6c2.1,1.9,5.6,3.9,10.7,3.9c5.4,0,8.8-2.1,11-4.2
        c3.5-3.2,5.1-8,5.1-12.3c0-4.3-1.7-9.1-5-12.2c-2.9-2.7-7.2-4.2-11.1-4.2C101.3,102.2,98,103.4,95,106.1 M91.2,178.4H74.8V89.6
        h16.4v7.5c2.5-3.6,8.2-9.4,18.9-9.4c5.1,0,13.3,1.2,19.8,7.8c4.3,4.3,8.7,11.5,8.7,23.1c0,13.1-5.8,20.2-9.1,23.5
        c-4.4,4.2-11.1,7.5-19.7,7.5c-4,0-12.2-0.7-18.6-9.4V178.4z"
			/>
			<path
				:fill="hasFillBlue ? fill.blue : '#003764'"
				d="M151.2,89.6h16.4v8.7c2.5-5,6-7.4,7.2-8c2.5-1.6,5.8-2.5,9.6-2.5c2.8,0,5.1,0.7,7,1.3l-2.1,15.3
        c-2.1-1.5-4.3-2.1-7.6-2.1c-3.1,0-7.6,0.7-10.9,4.7c-3.1,3.9-3.2,8.8-3.2,12.9v27.9h-16.4V89.6z"
			/>
			<path
				:fill="hasFillBlue ? fill.blue : '#003764'"
				d="M231.3,89.6h16.4v7.5c3.1-4.3,8.6-9.4,18-9.4c5.1,0,12.6,1.5,17.2,7.1c4,5,4.6,10.7,4.6,16.4v36.5H271V116
        c0-3.2-0.1-8.3-3.1-11.4c-2.5-2.7-6-2.9-7.6-2.9c-4.3,0-7.6,1.5-10.1,5.1c-2.4,3.8-2.5,7.9-2.5,11.3v29.6h-16.4V89.6z"
			/>
			<path
				:fill="hasFillBlue ? fill.blue : '#003764'"
				d="M319.2,106.3c-2.7,2.4-5.2,6.3-5.2,12.3c0,5.1,1.9,8.8,4.3,11.4c3.5,3.8,7.9,5.1,11.8,5.1c4,0,8-1.5,10.9-4
        c3.4-3.1,5.2-7.8,5.2-12.5c0-5.5-2.4-9.3-4.7-11.7c-3.2-3.4-7-4.7-11.4-4.7C324.8,102.2,321.2,104.4,319.2,106.3 M344.3,89.6h16.4
        V145c0,8.8-0.8,18.1-8,25.3c-4,4-11,8.2-22.9,8.2c-11.3,0-18-3.4-21.7-6.7c-4.6-3.9-8-10.7-9.4-17.3h17.7c0.7,2.7,2.1,5.4,4,7
        c3.2,2.9,7.6,3.1,9.9,3.1c4.7,0,7.8-1.6,9.9-3.6c3.8-3.6,4.2-8.2,4.2-12.9v-8.2c-5.6,8.6-13.4,9.7-17.8,9.7
        c-7.8,0-14.5-2.4-20.1-7.9c-4.3-4.3-9.2-11.8-9.2-23.1c0-9.1,3.4-17.6,9-23.1c5.1-5,12.2-7.8,20-7.8c10.9,0,16,5.9,18.2,9.4V89.6z"
			/>
			<path
				:fill="hasFillBlue ? fill.blue : '#003764'"
				d="M491.8,111c-1.3-7.9-7.8-11.7-13.8-11.7c-6,0-12.3,3.9-13.7,11.7H491.8z M506.8,131.5
        c-1.7,4.3-8.3,18.1-28.6,18.1c-9.4,0-16.5-2.7-22.3-8.2c-6.4-6-9.1-13.5-9.1-22.7c0-11.5,4.7-18.6,8.8-22.8
        c6.8-6.7,14.9-8.2,21.8-8.2c11.8,0,18.6,4.7,22.7,9.5c6.2,7.4,7,16.5,7,22.8v1.3h-43.4c0,3.5,0.9,7.2,2.8,9.8
        c1.7,2.4,5.4,5.5,11.7,5.5c6.2,0,10.9-2.9,13.5-7.6L506.8,131.5z"
			/>
			<path
				:fill="hasFillGreen ? fill.blue : '#00867c'"
				d="M407.9,194.9c-3.5-3.2-7.8-5.1-13.4-5.1c-10.9,0-16.4,7.9-16.4,16.4c0,9.5,7.1,16.5,16.9,16.5
        c3.6,0,8.4-1.1,12.9-5.1v16c-3.2,1.6-8.3,3.6-15,3.6c-9.1,0-17.2-3.5-22.4-8.4c-4.4-4.2-9.2-11.4-9.2-22.3
        c0-10.2,4.2-18.4,10.1-23.6c7.1-6.3,15.3-7.5,20.9-7.5c6,0,11,1.2,15.7,3.5V194.9z"
			/>
			<rect x="417.9" y="158.3" :fill="hasFillGreen ? fill.blue : '#00867c'" width="16.4" height="77.3" />
			<path
				:fill="hasFillGreen ? fill.blue : '#00867c'"
				d="M473.1,177.6h16.4v7.5c3.1-4.3,8.6-9.4,18-9.4c5.1,0,12.6,1.5,17.2,7.1c4,5,4.6,10.7,4.6,16.4v36.5h-16.4V204
        c0-3.2-0.1-8.3-3.1-11.4c-2.5-2.7-6-2.9-7.6-2.9c-4.3,0-7.6,1.5-10.1,5.1c-2.4,3.8-2.5,7.9-2.5,11.3v29.6h-16.4V177.6z"
			/>
			<path
				:fill="hasFillBlue ? fill.blue : '#003764'"
				d="M565.6,118.6c0,9.2,6.7,16.5,16.1,16.5s16.1-7.2,16.1-16.5c0-9.2-6.7-16.4-16.1-16.4S565.6,109.3,565.6,118.6
         M596.2,50h16.4v97.7h-16.4v-7.5c-5.9,8.4-13.7,9.4-17.8,9.4c-17.8,0-29.6-13.9-29.6-31c0-17.2,11.7-30.8,28.8-30.8
        c4.4,0,13.1,0.8,18.6,9.4V50z"
			/>
			<path
				:fill="hasFillBlue ? fill.blue : '#003764'"
				d="M368.7,89h12.2V72.1c0-15.1,11.5-23.7,26-23.7c16.8,0,25.5,10.7,25.5,21.6v1.4c0,4.3-3.6,7.6-7.9,7.6
        c-4.3,0-7.6-3.2-7.6-7.6v-0.9c0-4.8-4.1-8.1-10-8.1c-5.2,0-10.5,3.5-10.5,11V89h12.5v13.7h-12.5v45.1h-15.5v-45.1h-12.2V89z
         M417.9,89h16.4v58.8h-16.4V89z"
			/>
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		style: {
			type: [String, Object],
			default: function() {
				return {}
			},
		},
		fill: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	computed: {
		hasFillBlue() {
			return typeof this.fill !== 'undefined' && typeof this.fill.blue !== 'undefined'
		},
		hasFillGreen() {
			return typeof this.fill !== 'undefined' && typeof this.fill.green !== 'undefined'
		},
		hasFillYellow() {
			return typeof this.fill !== 'undefined' && typeof this.fill.yellow !== 'undefined'
		},
	},
}
</script>
