import TableCell from '@tiptap/extension-table-cell'

const CustomTableCell = TableCell.extend({
	addAttributes() {
		return {
			...this.parent?.(),
			class: {
				default: 'px-6 py-4 whitespace-nowrap text-sm text-gray-500',
			},
		}
	},
})

export default CustomTableCell
