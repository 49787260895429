import { Node, mergeAttributes } from '@tiptap/core'

const ColumnHeading = Node.create({
	name: 'columnHeading',

	priority: 1000,

	addOptions: {
		HTMLAttributes: {
			class: 'column-heading mb-2 pl-3 border-l-4 border-sc-green text-xl font-medium',
		},
	},

	content: 'text*',

	marks: '',

	group: 'block',

	parseHTML() {
		return [{ tag: 'h4' }]
	},

	renderHTML({ HTMLAttributes }) {
		return ['h4', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
	},

	addCommands() {
		return {
			setColumnHeading: attributes => ({ commands }) => {
				return commands.setNode('columnHeading', attributes)
			},
			toggleColumnHeading: attributes => ({ commands }) => {
				return commands.toggleNode('columnHeading', 'paragraph', attributes)
			},
		}
	},
})

export default ColumnHeading
