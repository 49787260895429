<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M67,49.38,48.23,39.21a22.65,22.65,0,1,0-4.82,10.18l18.2,9.87a2.9,2.9,0,0,0,4-1l2.72-5A2.9,2.9,0,0,0,67,49.39M26,55A20.07,20.07,0,1,1,46.09,35,20.09,20.09,0,0,1,26,55"
		/>
		<path
			:fill="fillComputed"
			d="M22.85,34.41a3.66,3.66,0,0,0-3.07-1.61c-2,0-2.6,1.24-2.6,2.2a1.57,1.57,0,0,0,.59,1.27A6.28,6.28,0,0,0,20,37.34a11.06,11.06,0,0,1,3.81,1.89,5.14,5.14,0,0,1,1.61,4c0,3.67-2.65,6.18-6.55,6.18a7.72,7.72,0,0,1-6.5-3.56l2.4-2.28A4.42,4.42,0,0,0,18.75,46a2.65,2.65,0,0,0,2.9-2.36,1.93,1.93,0,0,0,0-.24,2.18,2.18,0,0,0-.73-1.69A8.32,8.32,0,0,0,18,40.36a9.1,9.1,0,0,1-3.13-1.63,4.38,4.38,0,0,1-1.5-3.5c0-2.77,1.86-5.54,6-5.54a7.25,7.25,0,0,1,5.34,2.2Z"
		/>
		<path
			:fill="fillComputed"
			d="M37,39.39a4.49,4.49,0,0,0-3.17-1.2A3.68,3.68,0,0,0,30,41.69a2.28,2.28,0,0,0,0,.37A3.83,3.83,0,0,0,33.73,46H34A4.54,4.54,0,0,0,37,44.76v3.76a7.83,7.83,0,0,1-3.55.85,7.73,7.73,0,0,1-5.3-2A7,7,0,0,1,26,42.11a7.07,7.07,0,0,1,7.32-7.35,8.12,8.12,0,0,1,3.71.83Z"
		/>
		<path
			:fill="fillComputed"
			d="M31.55,33.59a12.66,12.66,0,0,1,4.29-6.35A7.64,7.64,0,0,0,32.75,29a7.82,7.82,0,0,0-2,3V32c0-.47,0-.94.06-1.41a7.07,7.07,0,0,1,.65-2.43,6.1,6.1,0,0,1,3.09-3,9.14,9.14,0,0,1,2.7-.76c.74-.08,1.48-.11,2.23-.17h.1c0,.2,0,.39,0,.57a9.39,9.39,0,0,1-1.45,5.56A6.76,6.76,0,0,1,34.71,33a9.8,9.8,0,0,1-2.74.54l-.42,0"
		/>
		<path
			:fill="fillComputed"
			d="M28.39,18.71c-.25.39-.5.77-.73,1.16a7.2,7.2,0,0,0-.9,2.4,4.52,4.52,0,0,0,1.3,4.05,6.49,6.49,0,0,0,2,1.42l.17.07a6.3,6.3,0,0,0,1.4-4.87,5.49,5.49,0,0,0-1.28-2.57,7.64,7.64,0,0,0-1.82-1.58l-.16-.08"
		/>
		<path
			:fill="fillComputed"
			d="M29.23,32.67a5.66,5.66,0,0,0-2.64-4,4.9,4.9,0,0,1,3.15,3.06v0c0-.3,0-.59,0-.89a4.27,4.27,0,0,0-.39-1.53,3.8,3.8,0,0,0-1.91-1.88,5.23,5.23,0,0,0-1.69-.5c-.46-.06-.93-.09-1.4-.13h-.07c0,.13,0,.24,0,.36a5.83,5.83,0,0,0,.86,3.5,4.27,4.27,0,0,0,2.16,1.68,6.36,6.36,0,0,0,1.72.36l.26,0"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Careers',
	mixins: [iconMixin],
}
</script>
