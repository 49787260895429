<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path :fill="fillComputed" d="M28.61,36.07a1,1,0,0,0-1-1H3.82a1,1,0,0,0,0,2H27.63a1,1,0,0,0,1-1" />
		<path :fill="fillComputed" d="M17.42,30.84H29.63a1,1,0,0,0,0-2H17.42a1,1,0,0,0,0,2" />
		<path :fill="fillComputed" d="M23.6,24.62H34a1,1,0,0,0,0-1.95H23.6a1,1,0,0,0,0,1.95" />
		<path :fill="fillComputed" d="M30.61,42.28a1,1,0,0,0-1-1H17.42a1,1,0,1,0,0,1.95H29.63a1,1,0,0,0,1-1" />
		<path :fill="fillComputed" d="M30.77,47.52H23.64a1,1,0,1,0,0,1.95h7.13a1,1,0,0,0,0-1.95" />
		<path :fill="fillComputed" d="M12.64,41.31H10.8a1,1,0,0,0-1,1,1,1,0,0,0,1,1h1.84a1,1,0,1,0,0-1.95" />
		<path :fill="fillComputed" d="M10.8,30.93h1.84a1,1,0,0,0,0-2H10.8a1,1,0,0,0,0,2" />
		<path
			:fill="fillComputed"
			d="M37.44,38.31c0-3.94,3.64-14.76,15.36-14.76h.06s4.62-.23,6.78,2.38a.89.89,0,0,0,1.24.12h0A.89.89,0,0,0,61,24.8h0c-2.66-3.22-7.74-3-8.22-3-13.05,0-17.11,12.12-17.11,16.53a11.18,11.18,0,0,0,1.93,6.58.88.88,0,0,0,1.24.15A.89.89,0,0,0,39,43.8h0a9.46,9.46,0,0,1-1.55-5.49"
		/>
		<path
			:fill="fillComputed"
			d="M62.36,27.28A.88.88,0,0,0,62,28.46h0a13.65,13.65,0,0,1,1.13,5c0,11.37-11.32,15.09-15.23,15.09a11.37,11.37,0,0,1-7-2.22.89.89,0,0,0-1.16,1.34,13,13,0,0,0,8.14,2.65,19.14,19.14,0,0,0,10.38-3.77,15.76,15.76,0,0,0,6.63-13.1,15.48,15.48,0,0,0-1.32-5.8.88.88,0,0,0-1.18-.41h0"
		/>
		<path
			:fill="fillComputed"
			d="M40.29,37h1.87a.89.89,0,0,0,.89-.89h0a.88.88,0,0,0-.89-.88H40.29a.89.89,0,0,0,0,1.77h0"
		/>
		<path
			:fill="fillComputed"
			d="M48,43.93,47.84,46a.89.89,0,0,0,.8,1h.08a.88.88,0,0,0,.88-.81l.17-2.09a.88.88,0,0,0-.8-1h0a.89.89,0,0,0-1,.81h0"
		/>
		<path
			:fill="fillComputed"
			d="M52.27,38.19A2.78,2.78,0,0,0,53,37h6.51a.88.88,0,0,0,.88-.89h0a.88.88,0,0,0-.88-.89H53.19a3.12,3.12,0,0,0-.28-1,2.47,2.47,0,0,0-1.12-.95l1-6.79A.88.88,0,1,0,51,26.19L50,33a3.15,3.15,0,0,0-1.92,1.24,3,3,0,0,0,.08,4.13l.05,0a2.88,2.88,0,0,0,1.8.63,3.38,3.38,0,0,0,2.22-.87m-2.85-1.1a1.28,1.28,0,0,1,.1-1.76,1.31,1.31,0,0,1,1-.6h0a1.29,1.29,0,0,1,.83.32,2.17,2.17,0,0,1-.28,1.82,1.39,1.39,0,0,1-1.67.22"
		/>
		<path
			:fill="fillComputed"
			d="M53.72,17.57C39.38,17.57,31.46,30.22,31.46,39c0,8.22,4.94,11.84,5.32,12.1a17.87,17.87,0,0,0,10.68,3.37A21.47,21.47,0,0,0,61.7,48.92c6.73-6.07,7.46-12.94,7.46-15.61,0-.63-.18-15.74-15.44-15.74m6.79,30c-6.53,5.88-16.29,6.75-22.69,2l0,0c-.18-.13-4.57-3.26-4.57-10.65,0-4.13,2-9,5.21-12.67a19.75,19.75,0,0,1,15.28-7c5.54,0,9.49,2.16,11.76,6.41a17.49,17.49,0,0,1,1.91,7.57c0,2.43-.67,8.69-6.87,14.29"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'UrgentWaitTimes',
	mixins: [iconMixin],
}
</script>
