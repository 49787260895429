<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g>
			<g>
				<path
					:fill="fillComputed"
					d="M69.81,49.65,51.07,39.48a22.68,22.68,0,1,0-4.83,10.18l18.21,9.88a2.9,2.9,0,0,0,4-1l2.72-5a2.89,2.89,0,0,0-1.32-3.87M28.86,55.29A20.07,20.07,0,1,1,48.93,35.22,20.09,20.09,0,0,1,28.86,55.29"
				/>
			</g>
		</g>
		<g>
			<path
				:fill="fillComputed"
				d="M28.86,45.58c-2.3,0-4.6,0-6.9,0a4.29,4.29,0,0,1-2.71-.87A3.12,3.12,0,0,1,18,42.52,14.26,14.26,0,0,1,18,39.6a10.62,10.62,0,0,1,1.72-5.2,3.93,3.93,0,0,1,3.25-1.75,1.94,1.94,0,0,1,1.13.4c.54.32,1.07.66,1.63,1a6.73,6.73,0,0,0,3.71.77,7.48,7.48,0,0,0,3.39-1.14c.31-.19.62-.39.92-.6a1.94,1.94,0,0,1,1.74-.32,4.67,4.67,0,0,1,3.21,2.64,9.35,9.35,0,0,1,.9,3,20.3,20.3,0,0,1,.24,3.48,4,4,0,0,1-.74,2.26,3.57,3.57,0,0,1-2.44,1.4,7.26,7.26,0,0,1-1.23.08C33.21,45.59,31,45.58,28.86,45.58Z"
			/>
			<path :fill="fillComputed" d="M28.89,21.62a6,6,0,1,1-6,6v-.18A6,6,0,0,1,28.89,21.62Z" />
		</g>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'StaffRecruitment',
	mixins: [iconMixin],
}
</script>
