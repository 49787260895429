<template>
	<div :id="props.modelValue.name">
		<div>
			<draggable
				:list="props.modelValue.sub_groups"
				handle=".handle"
				item-key="fields"
				class="grid gap-y-2"
				@start="drag = true"
				@end="drag = false"
			>
				<template #item="{ element, index }">
					<div class="flex gap-x-2 rounded-md p-2 bg-white shadow">
						<div
							class="flex-1 relative"
							:class="[
								element.column_count == 2 && element.fields.length == 1
									? 'grid gap-y-2 gap-x-5 grid-cols-' + element.column_count
									: '',
							]"
						>
							<FormBuilderField
								v-model="props.modelValue.sub_groups[index].fields"
								:sub-group-index="index"
								:column-count="element.column_count"
								@editField="editField"
							/>
						</div>

						<div class="flex-none w-12 flex flex-col gap-y-4 justify-start items-center">
							<SwitchVerticalIcon
								class="h-5 w-5 text-gray-400 hover:text-gray-500 handle"
								aria-hidden="true"
							/>

							<div>
								<label for="column_count" class="block text-center text-xs font-medium text-gray-700">
									Cols
								</label>
								<select
									v-model="props.modelValue.sub_groups[index].column_count"
									name="column_count"
									class="block w-full pl-2 pr-6 py-1 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
								>
									<option value="1">1</option>
									<option value="2">2</option>
								</select>
							</div>
						</div>
					</div>
				</template>
			</draggable>

			<div class="mt-2">
				<FormBuilderFieldSelector :show-field-groups="false" @addField="addField" />
			</div>
		</div>
	</div>
</template>

<script>
import { ref, reactive, computed, watchEffect, inject } from 'vue'

import draggable from 'vuedraggable'

import { SwitchVerticalIcon } from '@heroicons/vue/solid'

export default {
	components: {
		draggable,
		SwitchVerticalIcon,
	},
	props: {
		modelValue: {
			type: Object,
			default: function() {
				return {}
			},
		},
		fieldGroupIndex: {
			type: Number,
			default: 0,
		},
	},
	emits: ['update:modelValue', 'editField'],
	setup(props, { emit }) {
		const drag = ref(false)
		const fieldIndex = ref(null)

		const availableFields = inject('availableFields').value

		const isFieldSelected = index => {
			return false
			return fieldIndex.value === index
		}

		const selectSubGroup = index => {
			console.log('sub_groups', props.modelValue.sub_groups[index])
			// fieldIndex.value = index
		}

		const componentName = suffix => {
			return 'FormBuilder' + suffix.charAt(0).toUpperCase() + suffix.slice(1)
		}

		function addField(index) {
			let group = {}
			group = JSON.parse(JSON.stringify(props.modelValue))

			let sub_group = {}
			sub_group = JSON.parse(JSON.stringify(availableFields.fields[index].sub_groups[0]))

			group.sub_groups.push(sub_group)

			console.log(group)

			emit('update:modelValue', group)
		}

		const uuidv4 = () => {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = (Math.random() * 16) | 0,
					v = c == 'x' ? r : (r & 0x3) | 0x8
				return v.toString(16)
			})
		}

		const editField = field => {
			emit('editField', field)
		}

		return {
			props,
			drag,
			fieldIndex,
			componentName,
			isFieldSelected,
			selectSubGroup,
			addField,
			editField,
		}
	},
}
</script>
