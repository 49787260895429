<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g>
			<g>
				<path
					:fill="fillComputed"
					d="M23.47,34.76c1.53-1.22,3-2.46,4.56-3.62Q32.16,28,36.34,25a17.71,17.71,0,0,1,2.2-1.27,1.88,1.88,0,0,1,.81-.18c.48,0,.72.3.49.75a7.71,7.71,0,0,1-1,1.75q-4.34,5-8.75,9.83c-1.43,1.57-2.94,3.06-4.44,4.57a2.93,2.93,0,0,1-4.11.47,2.85,2.85,0,0,1-.76-.9,37.48,37.48,0,0,1-4-7.13,8.59,8.59,0,0,1-.42-1.42c-.16-.9.43-1.41,1.25-1a11.22,11.22,0,0,1,2.24,1.33c1.26,1,2.45,2,3.7,3"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					:fill="fillComputed"
					d="M68.05,47,49.3,36.81A22.65,22.65,0,1,0,44.48,47l18.21,9.88a2.89,2.89,0,0,0,4-1l2.72-5A2.92,2.92,0,0,0,68.05,47m-41,5.64A20.08,20.08,0,1,1,47.17,32.54,20.1,20.1,0,0,1,27.09,52.62"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'QualityManagement',
	mixins: [iconMixin],
}
</script>
