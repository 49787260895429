<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M28.58,47.46H22.43V27.67h6.15ZM25.5,25a3.58,3.58,0,1,1,3.56-3.59A3.57,3.57,0,0,1,25.5,25ZM51.58,47.46H45.45V37.83c0-2.3-.05-5.24-3.2-5.24s-3.69,2.49-3.69,5.07v9.8H32.42V27.67h5.9v2.7h.08a6.46,6.46,0,0,1,5.82-3.2c6.22,0,7.36,4.1,7.36,9.42V47.46Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'LinkedIn',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke: #fff;
	stroke-miterlimit: 10;
}
</style>
