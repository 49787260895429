<template>
	<svg class="w-16" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="#003764"
			class="a"
			d="M36,27.72H19.49A2.76,2.76,0,0,1,19,22.23a3.55,3.55,0,0,1,.46,0H52.58a2.76,2.76,0,0,1,.55,5.46,2.94,2.94,0,0,1-.59.05Z"
		/>
		<path
			fill="#003764"
			class="a"
			d="M36,38.76H19.48A2.76,2.76,0,0,1,19,33.28a3.87,3.87,0,0,1,.48,0H52.54a2.76,2.76,0,1,1,.06,5.51H36Z"
		/>
		<path
			fill="#003764"
			class="a"
			d="M36,44.28H52.5a2.76,2.76,0,0,1,.55,5.48,3.08,3.08,0,0,1-.53,0h-33a2.76,2.76,0,0,1-.15-5.51H36Z"
		/>
	</svg>
</template>
