<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M61.08,36.52,53.67,17.26a2.53,2.53,0,0,0-1.36-1.42,2.49,2.49,0,0,0-1.93,0L23.43,26.16H20a2.55,2.55,0,0,0-2.55,2.55V49.34A2.55,2.55,0,0,0,20,51.89H43.57a.45.45,0,0,0,.45-.45.46.46,0,0,0-.45-.46H20a1.64,1.64,0,0,1-1.65-1.64V28.71A1.64,1.64,0,0,1,20,27.07H50.7a1.63,1.63,0,0,1,1.64,1.64V49.34A1.62,1.62,0,0,1,50.7,51H47a.46.46,0,0,0-.45.46.45.45,0,0,0,.45.45H50.7a2.52,2.52,0,0,0,2.55-2.55V42.27l6.37-2.46h0A2.57,2.57,0,0,0,61.08,36.52ZM50.71,16.64a1.57,1.57,0,0,1,1.24,0,1.59,1.59,0,0,1,.88.92l1,2.66L38.48,26.16H26ZM41,26.16l13.18-5.07.7,1.82-8.45,3.25ZM59.29,39l-6,2.33V28.71a2.56,2.56,0,0,0-2.55-2.55H48.94l6.26-2.41,5,13.09A1.67,1.67,0,0,1,59.29,39Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M19.73,40.72a.45.45,0,0,0-.45.45V43.8a.45.45,0,0,0,.45.45h5.2a.45.45,0,0,0,.45-.45V41.17a.45.45,0,0,0-.45-.45Zm4.75,2.63h-4.3V41.62h4.3Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M28.14,44.25h5.2a.45.45,0,0,0,.45-.45V41.17a.45.45,0,0,0-.45-.45h-5.2a.45.45,0,0,0-.45.45V43.8A.45.45,0,0,0,28.14,44.25Zm.45-2.63h4.3v1.73h-4.3Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M36.55,40.72a.45.45,0,0,0-.45.45V43.8a.45.45,0,0,0,.45.45h5.2a.45.45,0,0,0,.45-.45V41.17a.45.45,0,0,0-.45-.45Zm4.75,2.63H37V41.62h4.3Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M50.61,43.8V41.17a.45.45,0,0,0-.45-.45H45a.45.45,0,0,0-.45.45V43.8a.45.45,0,0,0,.45.45h5.2A.45.45,0,0,0,50.61,43.8Zm-.9-.45h-4.3V41.62h4.3Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M28.53,34.22a2.8,2.8,0,0,0,1.86.72,2.84,2.84,0,0,0,0-5.67,2.8,2.8,0,0,0-1.86.71,2.79,2.79,0,0,0-3.73,0,2.78,2.78,0,0,0-1.87-.72,2.84,2.84,0,1,0,1.87,4.95,2.85,2.85,0,0,0,1.87.72A2.78,2.78,0,0,0,28.53,34.22Zm1.86-4a1.93,1.93,0,1,1-1.29,3.36,0,0,0,0,0,0,0,3.2,3.2,0,0,0,.26-.6l0-.14a2.52,2.52,0,0,0,.09-.67,2.39,2.39,0,0,0-.09-.66l0-.14a3.29,3.29,0,0,0-.26-.61,0,0,0,0,0,0,0A1.9,1.9,0,0,1,30.39,30.18Zm-3.72,0a1.87,1.87,0,0,1,1.5.73,1.9,1.9,0,0,1,.42,1.19,2,2,0,0,1-.42,1.21,2,2,0,0,1-3,0,2,2,0,0,1-.42-1.21,1.9,1.9,0,0,1,.11-.63,2.05,2.05,0,0,1,.31-.56h0A1.93,1.93,0,0,1,26.67,30.18ZM22.93,34a1.93,1.93,0,0,1,0-3.86,1.88,1.88,0,0,1,1.29.49v0a3.29,3.29,0,0,0-.26.61l0,.14a2.44,2.44,0,0,0-.09.66,2.52,2.52,0,0,0,.09.67l0,.14a3.2,3.2,0,0,0,.26.6,0,0,0,0,0,0,0A1.93,1.93,0,0,1,22.93,34Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PaymentOptions',
	mixins: [iconMixin],
}
</script>
