<template>
	<div v-if="errors.length > 0" class="text-red-500 font-bold p-2 mr-5">
		You have errors. Please check your input.
	</div>
	<button
		v-if="showCancel"
		type="button"
		class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600"
		@click.prevent="cancel(item)"
	>
		Cancel
	</button>
	<button
		v-if="showSubmit"
		type="button"
		class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 disabled:opacity-50"
		:disabled="errors.length > 0"
		@click.prevent="save(item)"
	>
		Save
	</button>
</template>

<script>
export default {
	props: {
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
		item: {
			type: [String, Number, Object],
			default: function() {
				return {}
			},
		},
		showCancel: {
			type: Boolean,
			default: true,
		},
		showSubmit: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['cancel', 'save'],
	setup() {},
	methods: {
		cancel(item = false) {
			if (item) {
				this.$emit('cancel', item)
			} else {
				this.$emit('cancel')
			}
		},

		save(item = false) {
			if (item) {
				this.$emit('save', item)
			} else {
				this.$emit('save')
			}
		},
	},
}
</script>
