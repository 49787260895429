<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M49.32,25.72c3.2,1.14,5.13,3.19,5,6.71a6.4,6.4,0,0,1-3.19,5.36,1.21,1.21,0,0,0-.49,1c1.51,4.86-.28,8.32-5.18,9.9-.11,0-.2.11-.36.19.65,2.67.17,5-2,6.84a2.46,2.46,0,0,1-.89.6c-1.54.35-1.73,1.39-1.66,2.75a55.9,55.9,0,0,1,0,5.79c0,.29-.59.78-.91.79s-1.18-.38-1.19-.62c-.08-3-.06-6-.06-9,0-.32,0-.63.08-1,.48-.06.91-.11,1.35-.18a3.89,3.89,0,0,0-.41-7.75c-1.45-.05-2.9,0-4.34-.05-.41,0-.8-.43-1.19-.67.37-.21.76-.61,1.12-.6,2.7.12,5.42-.39,8.1.62a4.37,4.37,0,0,0,5.17-2.12,4.76,4.76,0,0,0-.37-5.62,4.91,4.91,0,0,0-5.46-1.45,4.68,4.68,0,0,1-1.51.31c-5.07,0-10.13,0-15.2,0-.45,0-.89-.39-1.34-.6a4.55,4.55,0,0,1,1.33-.45c7.31,0,14.63,0,22,0a4.29,4.29,0,0,0,4.23-2.82A3.86,3.86,0,0,0,50.6,29a6.91,6.91,0,0,0-3.47-1c-5.54-.1-11.09,0-16.64,0a4,4,0,0,1-1.2-.08.89.89,0,0,1-.52-.66c0-.21.32-.52.57-.6a3.46,3.46,0,0,1,1.07-.05c4.35,0,8.69,0,13,0a4.13,4.13,0,0,0,4.23-2.77,4,4,0,0,0-3.61-5.34c-1.61-.08-3.22-.06-4.83,0-.8,0-1.14-.18-1.12-1.07,0-3.17,0-6.35,0-9.52,0-.86.27-1.12,1.1-1.11s1.17.16,1.15,1.07c-.06,2.45,0,4.9,0,7.35,0,.79.26,1.11,1,1,.52,0,1,0,1.57,0C48.69,16.15,51.47,20.22,49.32,25.72Z"
		/>
		<path
			:fill="fillComputed"
			d="M28.39,43.46H25a6.48,6.48,0,0,1-2.3-12.59c.25-.1.5-.23.81-.37-1.28-2.86-1.1-5.51,1.05-7.87s5-2.13,7.59-2.06c0-4.31,0-8.47,0-12.63,0-.8.08-1.25,1.08-1.26s1.19.41,1.18,1.29c0,3.21,0,6.43,0,9.65C34.41,20.48,36,22,38.8,22c1.41,0,2.81,0,4.22,0a1.43,1.43,0,0,1,1.13.61c-.44,0-.88.13-1.32.13H30c-3,0-5.15,1.88-5.09,4.45S27,31.6,30,31.6c5.68,0,11.35,0,17,0a5.2,5.2,0,0,1,1.43.5,4.81,4.81,0,0,1-1.49.57c-7.16,0-14.32,0-21.48,0-3.34,0-5.4,2.35-4.7,5.31a4.21,4.21,0,0,0,4.28,3.19c4.23,0,8.45,0,12.67,0a6.11,6.11,0,0,1,1.49.36,7.74,7.74,0,0,1-1.55.42c-1,.07-2.09,0-3.13,0a4.33,4.33,0,0,0-4.35,4.51,4.28,4.28,0,0,0,4.54,4.15,20.76,20.76,0,0,1,4.58.35,16.15,16.15,0,0,0-2.07.42,3.81,3.81,0,0,0-2.51,3.75q0,4.57,0,9.17c0,.8,0,1.35-1.11,1.36s-1.21-.54-1.19-1.41c.12-3.69.2-7.38.28-11.07a.88.88,0,0,0-.32-.63C28,49.85,27.52,48.88,28.39,43.46Z"
		/>
		<path
			:fill="fillComputed"
			d="M43.29,38.86c3.59-.2,4.16.25,4.32,3.34.07,1.39-1.84,2.87-3.81,2.94-1.24,0-2.92-2-3-3.57A2.49,2.49,0,0,1,43.29,38.86Z"
		/>
		<rect
			:fill="fillComputed"
			x="53.24"
			y="43.26"
			width="1.59"
			height="4.49"
			rx="0.8"
			transform="translate(-8.68 78.03) rotate(-67.51)"
		/>
		<rect
			:fill="fillComputed"
			x="51.57"
			y="46.14"
			width="1.59"
			height="4.49"
			rx="0.8"
			transform="translate(-18.34 57.58) rotate(-50.14)"
		/>
		<rect
			:fill="fillComputed"
			x="54"
			y="39.85"
			width="1.59"
			height="4.49"
			rx="0.8"
			transform="translate(7.78 92.73) rotate(-84.66)"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Colonoscopy',
	mixins: [iconMixin],
}
</script>
