<template>
	<div class="flex gap-x-2">
		<button
			type="button"
			class="items-center p-2 border rounded-md text-blue-600 border-blue-600 hover:text-blue-700 hover:bg-blue-200 focus:outline-none"
			title="View Media"
			@click="view"
		>
			<EyeIcon class="h-5 w-5" aria-hidden="true" />
		</button>

		<button
			type="button"
			class="items-center p-2 border rounded-md text-yellow-500 border-yellow-500 hover:text-yellow-600 hover:bg-yellow-200 focus:outline-none"
			title="Edit Media"
			@click="edit"
		>
			<PencilAltIcon class="h-5 w-5" aria-hidden="true" />
		</button>

		<button
			type="button"
			class="items-center p-2 border rounded-md text-gray-500 border-gray-500 hover:text-gray-600 hover:bg-gray-200 focus:outline-none"
			title="Copy Media URL"
			@click="copy"
		>
			<ClipboardIcon class="h-5 w-5" aria-hidden="true" />
		</button>

		<button
			v-if="params.showDelete"
			type="button"
			class="items-center p-2 border rounded-md text-red-500 border-red-500 hover:text-red-600 hover:bg-red-200 focus:outline-none"
			title="Delete Media"
			@click="confirmDelete"
		>
			<TrashIcon class="h-5 w-5" aria-hidden="true" />
		</button>

		<ModalsImagePreview
			v-if="imagePreviewOpen"
			ref="imagePreview"
			:open="imagePreviewOpen"
			:image-preview-config="imagePreviewConfig"
			@closeModal="imagePreviewOpen = false"
		/>
		<ModalsVideoPreview
			v-if="videoPreviewOpen"
			ref="videoPreview"
			:open="videoPreviewOpen"
			:video-preview-config="videoPreviewConfig"
			@closeModal="videoPreviewOpen = false"
		/>
		<ModalsDocumentPreview
			v-if="documentPreviewOpen"
			ref="documentPreview"
			:open="documentPreviewOpen"
			:document-preview-config="documentPreviewConfig"
			@closeModal="documentPreviewOpen = false"
		/>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>
	</div>
</template>

<script>
import useClipboard from 'vue-clipboard3'
import router from '@/router'

import { EyeIcon, PencilAltIcon, ClipboardIcon, TrashIcon } from '@heroicons/vue/solid'

// import ModalsImagePreview from './Modals/ImagePreview'
import ModalsImagePreview from '@/components/Modals/ImagePreview'
import ModalsVideoPreview from '@/components/Modals/VideoPreview'
import ModalsDocumentPreview from '@/components/Modals/DocumentPreview'
import ModalsConfirmation from '@/components/Modals/Confirmation'

const { toClipboard } = useClipboard()

export default {
	name: 'TableActions',
	components: {
		ModalsImagePreview,
		ModalsVideoPreview,
		ModalsDocumentPreview,
		ModalsConfirmation,
		EyeIcon,
		PencilAltIcon,
		ClipboardIcon,
		TrashIcon,
	},
	data() {
		return {
			confirmationOpen: false,
			confirmationConfig: {},

			imagePreviewOpen: false,
			imagePreviewConfig: {},
			videoPreviewOpen: false,
			videoPreviewConfig: {},
			documentPreviewOpen: false,
			documentPreviewConfig: {},
		}
	},
	computed: {},
	mounted() {
		// console.log(this.params.data)
	},
	methods: {
		view() {
			if (this.params.mediaType == 'image') {
				this.imagePreviewConfig = {
					title: 'Image Preview',
					image: this.params.data,
				}
				this.imagePreviewOpen = true
			} else if (this.params.mediaType == 'video') {
				this.videoPreviewConfig = {
					title: 'Video Preview',
					video: this.params.data,
				}
				this.videoPreviewOpen = true
			} else if (this.params.mediaType == 'document') {
				this.documentPreviewConfig = {
					title: 'Document Preview',
					document: this.params.data,
				}
				this.documentPreviewOpen = true
			}
		},

		edit() {
			try {
				this.params.parent.editMedia(this.params.mediaType, this.params.data)
			} catch (e) {
				console.error(e)
			}
		},

		async copy() {
			try {
				// console.log(this.params.data.url)
				await toClipboard(this.params.data.url)
				this.params.parent.toast.info('Copied "' + this.params.data.url + '" to clipboard')
			} catch (e) {
				console.error(e)
			}
		},

		closeModal() {
			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.slug
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete() {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'deleteItem',
				title: this.params.deleteTitle,
				content: this.params.deleteContent,
			}
			this.$refs.confirmation.slug = this.params.data.slug
		},

		deleteItem() {
			let id = this.$refs.confirmation.slug

			this.params.appApi[this.params.deleteApi]
				.destroy(id)
				.then(data => {
					delete this.$refs.confirmation.slug

					this.closeModal()

					this.params.parent.refreshTable()
				})
				.catch(error => {
					// this.toast.error('Error deleting clinical research:\n' + error.response.data.message)
				})
				.then(() => {})
		},
	},
}
</script>
