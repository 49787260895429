<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g id="Group_119" data-name="Group 119">
			<g id="Group_118" data-name="Group 118">
				<path
					id="Path_110"
					data-name="Path 110"
					:fill="fillComputed"
					d="M53.65,45.14a4.62,4.62,0,0,1-2.6-3.76,3.74,3.74,0,0,1,1.62-3.74c1.48-.84,1.37-2,1.19-3.38-.32.08-.64.14-.94.24-4.66,1.55-9.3,3.15-14,4.63a4.74,4.74,0,0,1-2.64,0c-4.64-1.47-9.25-3.05-13.87-4.59l-1.14-.35c0,2,0,3.93,0,5.84,0,.32.42.69.73.93A23.34,23.34,0,0,0,32,45a30,30,0,0,0,16.64-1.55,1.59,1.59,0,0,1,2.24.68c.33.87-.06,1.61-1.14,2a31.88,31.88,0,0,1-13.41,2.26,30.67,30.67,0,0,1-13.88-3.71l-.3-.17c-2.65-1.35-4.49-3.1-3.85-6.49a28.58,28.58,0,0,0,.05-4,1,1,0,0,0-.81-1.13h0c-2.22-.7-4.43-1.49-6.65-2.21-.8-.26-1.46-.64-1.45-1.56s.65-1.27,1.46-1.54C19.44,24.74,28,21.86,36.6,19.05a3.5,3.5,0,0,1,2.1,0c8.52,2.79,17,5.63,25.53,8.45.81.27,1.58.61,1.6,1.57s-.76,1.38-1.63,1.65c-2.16.69-4.28,1.47-6.44,2.14-.69.21-.95.54-.86,1.22a2.75,2.75,0,0,1,0,.81,2.52,2.52,0,0,0,1.27,2.71,4.22,4.22,0,0,1-.16,7,1.23,1.23,0,0,0-.53,1.61c.79,3,1.52,6,2.26,9.07.33,1.39-.18,2-1.61,2.07H53.05c-1.81,0-2.3-.66-1.85-2.47l2.44-9.73m5.68-16.08a1.34,1.34,0,0,0-.28-.18c-6.9-2.3-13.8-4.61-20.71-6.87a2.69,2.69,0,0,0-1.54,0q-9,3-18.09,6l-3.14,1.07c6.56,2.19,12.87,4.21,19.11,6.44a7.81,7.81,0,0,0,5.93,0c2.72-1.09,5.55-1.92,8.62-3-.69-.15-1.06-.24-1.43-.3-3.4-.56-6.81-1.1-10.22-1.67a1.54,1.54,0,0,1-1.41-1.77,1.48,1.48,0,0,1,1.72-1.2l.11,0c3.86.64,7.71,1.31,11.57,1.95,3.3.54,6.62,1.59,9.76-.57M56.93,41a1.43,1.43,0,0,0-1.4-1.44h-.09A1.48,1.48,0,0,0,53.94,41v0a1.48,1.48,0,0,0,1.46,1.49h.11a1.39,1.39,0,0,0,1.42-1.37V41M54.38,54.34h2.14c-.35-1.38-.67-2.61-1.08-4.17l-1.06,4.17"
				/>
			</g>
		</g>
	</svg>
</template>
<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Education',
	mixins: [iconMixin],
}
</script>
