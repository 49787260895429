import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')
window.Pusher.Runtime.createXHR = function() {
	var xhr = new XMLHttpRequest()
	xhr.withCredentials = true
	return xhr
}

class LaravelEcho {
	constructor() {
		//console.warn('WebsiteAPI Constructor!!!')
	}
	install(app, options) {
		// console.log('Echo/Pusher Options: ', options)
		// Build our auth url
		var authURL = options.authSsl ? 'https://' : 'http://'
		authURL += options.authHost

		// Setup local config
		this.private = options.private
		this.eventHandler = options.eventHandler

		// Setup the Echo Object - Self Hosted
		// this.echo = new Echo({
		// 	authEndpoint: authURL + '/broadcasting/auth',
		// 	broadcaster: 'pusher',
		// 	key: options.key, // .env
		// 	wsHost: options.host,
		// 	wsPort: options.port,
		// 	forceTLS: false,
		// 	disableStats: true,
		// })
		// Setup the Echo Object - Pusher
		var authPath = authURL + '/broadcasting/auth'
		// console.log('AuthPath:', authPath)
		this.echo = new Echo({
			authEndpoint: authPath,
			broadcaster: 'pusher',
			key: options.key, // .env
			cluster: options.cluster, //'us2',
		})

		// Old Code - Marked for removal
		/*
		this.echo
			.channel('yext')
			// .private('yext')
			.listen('YextListFetchComplete', e => console.log('YextListFetchComplete: ' + e.message))
			*/
		// console.log('Private Channels...')
		// for (const [channelName, events] of Object.entries(options.channels.private)) {
		// 	console.log('Channel: ', channelName)
		// 	var channel = this.echo.private(channelName)
		// 	for (const [event, callback] of Object.entries(events)) {
		// 		console.log('Event: ', event)
		// 		channel.listen(event, callback)
		// 	}
		// }

		// console.log('Echo: ', this.echo)

		app.broadcast = this
	}

	listen(channelName, eventName, callback = null) {
		// console.log('Subscribing to a', this.private ? 'private' : 'public', 'channel:', channelName)

		// Get channel reference
		let channel = this.private ? this.echo.private(channelName) : this.echo.channel(channelName)
		// Listen for this event with the defined callback
		if (eventName instanceof Array) {
			eventName.forEach(singleEvent => {
				// console.log('Event:', singleEvent, 'with a', !callback ? 'standard' : 'custom', 'event handler.')
				channel.listen(singleEvent, callback ?? this.eventHandler)
			})
		} else {
			// console.log('Event:', eventName, 'with a', !callback ? 'standard' : 'custom', 'event handler.')
			channel.listen(eventName, callback ?? this.eventHandler)
		}
	}
}

export default LaravelEcho
