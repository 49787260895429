<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M44.5,20.17h-.35v-.9h2.32a1.81,1.81,0,0,0,1.8-1.8V14.66a1.8,1.8,0,0,0-1.8-1.79H24.24a1.8,1.8,0,0,0-1.8,1.79v2.81a1.81,1.81,0,0,0,1.8,1.8h2.31v.9h-.34a4.42,4.42,0,0,0-4.41,4.41V55.5a4.42,4.42,0,0,0,4.41,4.41H38.83a.49.49,0,1,0,0-1H26.21a3.44,3.44,0,0,1-3.43-3.43V24.58a3.44,3.44,0,0,1,3.43-3.43H27a.49.49,0,0,0,.49-.49V19.27H43.17v1.39a.49.49,0,0,0,.49.49h.84a3.44,3.44,0,0,1,3.43,3.43V55.5a3.44,3.44,0,0,1-3.43,3.43H42.66a.49.49,0,0,0,0,1H44.5a4.42,4.42,0,0,0,4.41-4.41V24.58A4.42,4.42,0,0,0,44.5,20.17Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M33.67,47.19H37a1.79,1.79,0,0,0,1.79-1.79V42.1h3.29a1.8,1.8,0,0,0,1.8-1.79V37a1.81,1.81,0,0,0-1.8-1.8H38.83V31.86A1.8,1.8,0,0,0,37,30.06H33.67a1.8,1.8,0,0,0-1.79,1.8v3.29H28.59a1.8,1.8,0,0,0-1.8,1.8v3.36a1.79,1.79,0,0,0,1.8,1.79h3.29v3.3A1.79,1.79,0,0,0,33.67,47.19Zm-5.08-6.06a.82.82,0,0,1-.82-.82V37a.82.82,0,0,1,.82-.82h3.78a.49.49,0,0,0,.49-.49V31.86a.82.82,0,0,1,.81-.82H37a.82.82,0,0,1,.81.82v3.78a.49.49,0,0,0,.49.49h3.78a.82.82,0,0,1,.82.82v3.36a.82.82,0,0,1-.82.82H38.34a.48.48,0,0,0-.49.48V45.4a.81.81,0,0,1-.81.81H33.67a.81.81,0,0,1-.81-.81V41.61a.48.48,0,0,0-.49-.48Z"
		/>
		<rect :style="'fill:' + fillComputed" x="38.46" y="58.93" width="4.84" height="0.98" />
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PharmacyServices',
	mixins: [iconMixin],
}
</script>
