<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M58.13,18.84a41.5,41.5,0,0,0-21-6,41.5,41.5,0,0,0-21,6v14a31.93,31.93,0,0,0,21,30,31.93,31.93,0,0,0,21-30Z"
		/>
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M37.13,56.36a25.93,25.93,0,0,1-15-23.52V22.4a35.58,35.58,0,0,1,15-3.56,35.66,35.66,0,0,1,15,3.56V32.84A26,26,0,0,1,37.13,56.36Z"
		/>
		<g>
			<path
				:fill="fillComputed"
				d="M37.91,43.79H36.62l0,0c.15-.79-.28-1-1-1.2a5.28,5.28,0,0,1-1.69-.81,3,3,0,0,1-1.25-3c.77,0,1.53,0,2.28,0,.08,0,.19.17.2.28.17,1.65,1.44,2,2.77,1.71A1.39,1.39,0,0,0,39,39.4a1.52,1.52,0,0,0-.71-1.64c-.76-.42-1.56-.76-2.34-1.15a14.13,14.13,0,0,1-1.39-.73,3.26,3.26,0,0,1-1.53-3.16,3.33,3.33,0,0,1,2.06-2.94,6.74,6.74,0,0,1,1.15-.38c.36-.08.5-.26.45-.62a5.42,5.42,0,0,1,0-.57h1.36a4.56,4.56,0,0,1,0,.64c0,.36.07.53.45.61a3.73,3.73,0,0,1,2,1.08,3.63,3.63,0,0,1,1,2.77c-.79,0-1.55,0-2.31,0-.09,0-.22-.27-.25-.44a1.66,1.66,0,0,0-1.75-1.56l-.25,0a1.36,1.36,0,0,0-1.28,1.14,1.54,1.54,0,0,0,.62,1.82c.67.39,1.38.71,2.08,1.06.46.23.93.43,1.38.66a3.33,3.33,0,0,1,1.8,3.57,3.19,3.19,0,0,1-2.28,2.89l-.39.13c-.3.08-.7.07-.87.26s-.09.59-.13.94"
			/>
		</g>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Payments',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1 {
	fill: none;
	stroke-linejoin: round;
	stroke-width: 2px;
}
</style>
