<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g id="Group_66" data-name="Group 66">
			<g id="Group_63" data-name="Group 63">
				<path
					id="Path_57"
					data-name="Path 57"
					:fill="fillComputed"
					d="M38.35,53.68a1.23,1.23,0,0,1-.89-.38c-.62-.66-15.09-16.13-15.09-30a16,16,0,0,1,32,0c0,13.84-14.47,29.31-15.08,30a1.27,1.27,0,0,1-.9.38m0-43.87A13.52,13.52,0,0,0,24.83,23.33c0,10.34,9.13,22.16,13.06,26.76a.6.6,0,0,0,.91,0c3.93-4.6,13.06-16.42,13.06-26.76A13.52,13.52,0,0,0,38.34,9.81"
				/>
			</g>
			<g id="Group_64" data-name="Group 64">
				<path
					id="Path_58"
					data-name="Path 58"
					:fill="fillComputed"
					d="M38.35,59.17c-6.8,0-14-2.35-14-6.71,0-1.14.57-3.29,4.39-5a1.09,1.09,0,0,1,.5-.1,1.23,1.23,0,0,1,.49,2.35c-1.83.8-2.92,1.81-2.92,2.71,0,2,4.95,4.26,11.57,4.26s11.57-2.26,11.57-4.27c0-.9-1.1-1.91-2.94-2.71a1.27,1.27,0,0,1-.65-.68,1.23,1.23,0,0,1,1.15-1.68,1.38,1.38,0,0,1,.49.1c3.83,1.68,4.4,3.83,4.4,5,0,4.36-7.23,6.71-14,6.71"
				/>
			</g>
			<g id="Group_65" data-name="Group 65">
				<path
					id="Path_59"
					data-name="Path 59"
					:fill="fillComputed"
					d="M38.35,64.65c-13.21,0-23.17-4.85-23.17-11.28,0-3.89,3.71-7.31,10.18-9.4a1.15,1.15,0,0,1,.37-.06,1.22,1.22,0,0,1,.38,2.39c-5.3,1.72-8.47,4.35-8.47,7.06,0,4.27,8.32,8.83,20.71,8.83s20.71-4.56,20.71-8.83c0-2.67-3.1-5.29-8.3-7A1.23,1.23,0,0,1,50,44.81,1.22,1.22,0,0,1,51.14,44a1.32,1.32,0,0,1,.39.06c6.34,2.09,10,5.5,10,9.34,0,6.43-10,11.28-23.16,11.28"
				/>
			</g>
		</g>
		<path
			:fill="fillComputed"
			d="M38.34,14a10,10,0,1,0,10,10A10,10,0,0,0,38.34,14Zm2,14.88c0,.27,0,.53,0,.8a.2.2,0,0,1,0,.11.62.62,0,0,1-.68.57q-1.34,0-2.67,0a.61.61,0,0,1-.7-.7c0-1.17,0-2.35,0-3.59-1.16,0-2.23,0-3.29,0a2.74,2.74,0,0,1-.5,0,.58.58,0,0,1-.52-.62c0-.88,0-1.76,0-2.64,0-.59.2-.78.79-.79.79,0,1.58,0,2.37,0h1.12v-2.4c0-.42,0-.84,0-1.26v-.06a.57.57,0,0,1,.6-.55c.95,0,1.89,0,2.83,0a.62.62,0,0,1,.62.71c0,1,0,2.07,0,3.11v.46h1.79c.57,0,1.13,0,1.69,0s.81.23.81.82c0,.83,0,1.66,0,2.48,0,.58-.22.79-.83.8H40.38Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'InHospitalServices',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke: #fff;
	stroke-miterlimit: 10;
}
</style>
