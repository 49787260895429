import TableHeader from '@tiptap/extension-table-header'

const CustomTableHeader = TableHeader.extend({
	addAttributes() {
		return {
			...this.parent?.(),
			class: {
				default: 'px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider',
			},
		}
	},
})

export default CustomTableHeader
