<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M27.58,19.26S16.2,19.57,16.2,31.54A21.85,21.85,0,0,0,21.39,45c5.41,6.55,9.74,6.31,11.38,5.56a4.37,4.37,0,0,0,2.73-3.74c0-1.5-.73-3.37-2.78-5.42s-2.5-3.55-2.5-4.78.59-2.86,3.05-4.73a6.72,6.72,0,0,0-1.1-11.37C29,19.07,27.58,19.26,27.58,19.26Z"
		/>
		<path
			:fill="fillComputed"
			d="M31.88,16.37S31.83,21,35.45,21,40,17.12,40,16.37s-.07-3.51-4.07-3.51S31.85,15.82,31.88,16.37Z"
		/>
		<path
			:fill="fillComputed"
			d="M25.92,15.34s-.16-2.82,2.52-2.82,2.51,2.79,2.51,3a2.46,2.46,0,0,1-2.52,2.41A2.4,2.4,0,0,1,25.92,15.34Z"
		/>
		<path
			:fill="fillComputed"
			d="M22.3,14.05a1.71,1.71,0,0,1,1.64.79,4.59,4.59,0,0,1,1.18,2.78,2.06,2.06,0,0,1-2.32,1.93,2.94,2.94,0,0,1-2.69-3.16A2.21,2.21,0,0,1,22.3,14.05Z"
		/>
		<path
			:fill="fillComputed"
			d="M17.63,17.78a2.19,2.19,0,0,0-2.34,2.32,2.43,2.43,0,0,0,2.46,2.46,2.67,2.67,0,0,0,2.66-2.67,1.89,1.89,0,0,0-1-1.57C18.45,17.8,18.28,17.71,17.63,17.78Z"
		/>
		<path
			:fill="fillComputed"
			d="M14.52,22.78s-2.14,0-2.26,1.5a2.19,2.19,0,0,0,2.44,2.23,2,2,0,0,0,2.07-1.79A2.12,2.12,0,0,0,14.52,22.78Z"
		/>
		<path
			:fill="fillComputed"
			d="M48.61,31.24s10.91,3.26,7.81,14.82a21.86,21.86,0,0,1-8.49,11.62c-6.93,4.92-11,3.57-12.43,2.43a4.37,4.37,0,0,1-1.67-4.33c.39-1.45,1.58-3.06,4.08-4.51s3.34-2.78,3.66-4,.17-2.92-1.72-5.36a6.73,6.73,0,0,1,4-10.7C47.25,30.69,48.61,31.24,48.61,31.24Z"
		/>
		<path
			:fill="fillComputed"
			d="M45.21,27.34s-1.16,4.49-4.66,3.55S37.19,26,37.38,25.24s1-3.37,4.84-2.33S45.38,26.82,45.21,27.34Z"
		/>
		<path
			:fill="fillComputed"
			d="M51.23,27.89s.88-2.68-1.71-3.38-3.14,2-3.2,2.26a2.47,2.47,0,0,0,1.81,3A2.4,2.4,0,0,0,51.23,27.89Z"
		/>
		<path
			:fill="fillComputed"
			d="M55.06,27.58a1.69,1.69,0,0,0-1.79.34,4.51,4.51,0,0,0-1.86,2.38,2,2,0,0,0,1.74,2.46,2.93,2.93,0,0,0,3.41-2.35A2.21,2.21,0,0,0,55.06,27.58Z"
		/>
		<path
			:fill="fillComputed"
			d="M58.6,32.39a2.2,2.2,0,0,1,1.66,2.85,2.42,2.42,0,0,1-3,1.73,2.66,2.66,0,0,1-1.88-3.26,1.88,1.88,0,0,1,1.35-1.26C57.8,32.2,58,32.16,58.6,32.39Z"
		/>
		<path
			:fill="fillComputed"
			d="M60.31,38s2.06.58,1.79,2a2.19,2.19,0,0,1-2.93,1.52,2,2,0,0,1-1.53-2.27A2.11,2.11,0,0,1,60.31,38Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'BabyStepsClasses',
	mixins: [iconMixin],
}
</script>
