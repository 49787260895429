<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g id="Group_548" data-name="Group 548">
			<g id="Path_128-2" data-name="Path 128-2">
				<path
					class="cls-1"
					:stroke="fillComputed"
					d="M22,13.6H50c2.2,0,4,2.08,4,4.64h0V50.75c0,2.56-1.79,4.64-4,4.64H22c-2.2,0-4-2.08-4-4.64h0V18.24c0-2.56,1.79-4.64,4-4.64Z"
				/>
			</g>
		</g>
		<text :style="'fill:' + fillComputed" class="cls-2" transform="translate(30.16 30.69)">E</text>
		<rect :fill="fillComputed" x="29.97" y="36.5" width="3" height="3" rx="0.53" />
		<rect :fill="fillComputed" x="37.94" y="36.5" width="3" height="3" rx="0.53" />
		<rect :fill="fillComputed" x="25.62" y="43.56" width="3" height="3" rx="0.53" />
		<rect :fill="fillComputed" x="33.58" y="43.56" width="3" height="3" rx="0.53" />
		<rect :fill="fillComputed" x="41.39" y="43.56" width="3" height="3" rx="0.53" />
		<path
			class="cls-4"
			d="M43.2,26.61l-1,1c-.15.16,1,1.45,2.48,2.88l10.06,9.55c1.51,1.44,2.86,2.48,3,2.33l1-1c.14-.15-1-1.44-2.48-2.88L46.21,28.93C44.69,27.5,43.35,26.46,43.2,26.61Z"
		/>
		<path
			:fill="fillComputed"
			d="M43.22,26.92l-.67.71a13.48,13.48,0,0,0,2.5,2.74l9.85,9.34a13.15,13.15,0,0,0,2.86,2.35l.67-.7a13.19,13.19,0,0,0-2.5-2.74l-9.84-9.34A13.3,13.3,0,0,0,43.22,26.92Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Optometry',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1 {
	fill: none;
	stroke-miterlimit: 10;
}
.cls-2 {
	font-size: 18px;
	font-family: ProximaNova-Bold, Proxima Nova;
	font-weight: 700;
	stroke: none;
}
.cls-4 {
	fill: #fff;
}
</style>
