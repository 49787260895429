<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<rect fill="none" x="9.54" y="12.64" width="56.06" height="52.13" />
		<path
			:fill="fillComputed"
			d="M17.71,16.14l2.21-2.23a3.32,3.32,0,0,1,4.68-.26l.19.18c.75.7,1.46,1.46,2.24,2.24l1.47-1.45c.28-.28.55-.56.84-.82A3.27,3.27,0,0,1,34,13.74l.08.07c.64.59,1.24,1.22,1.86,1.83l.42.4L38.42,14a3.38,3.38,0,0,1,5-.08l2.22,2.21,1.93-2a3.72,3.72,0,0,1,3.1-1.27,3.25,3.25,0,0,1,3,3.23c.05,2.3,0,4.6,0,6.89a.75.75,0,0,1,0,.16H49.85V17.39l-.14-.08c-.36.38-.7.76-1.07,1.14s-1,1-1.52,1.53a2,2,0,0,1-2.85.16L44.12,20c-.92-.91-1.83-1.83-2.73-2.75-.12-.12-.22-.28-.38-.48-.33.34-.58.62-.84.88-.76.75-1.5,1.52-2.27,2.26a2.14,2.14,0,0,1-3,.07l-.08-.07c-1-1-2-2-3.1-3.17-.5.53-.94,1-1.38,1.46s-1.06,1.07-1.6,1.59a2.17,2.17,0,0,1-3.37,0L22.5,16.94a1.54,1.54,0,0,1-.19-.17,5.91,5.91,0,0,1-.49.63L19.25,20a2,2,0,0,1-2.84.16c-.06,0-.11-.11-.16-.16-.78-.77-1.55-1.55-2.32-2.33l-.38-.35c0,.18,0,.29,0,.4V60.05c0,.61.14.76.78.76H49.07c.66,0,.78-.13.78-.79V54.31h3.82v5.91a4.29,4.29,0,0,1-4.21,4.38h-.14q-17.62,0-35.26,0a4.25,4.25,0,0,1-4.35-4.17.74.74,0,0,1,0-.21V16.39a3.3,3.3,0,0,1,3-3.54h.1A3.51,3.51,0,0,1,15.59,14c.7.7,1.38,1.43,2.11,2.19"
		/>
		<path
			:fill="fillComputed"
			d="M51.92,25.2A13.53,13.53,0,0,1,65.43,38.73h0a13.52,13.52,0,0,1-13.54,13.5h0A13.51,13.51,0,0,1,38.38,38.68v-.06A13.51,13.51,0,0,1,51.92,25.2m0,2.67A10.86,10.86,0,1,0,62.76,38.76,10.86,10.86,0,0,0,51.92,27.87"
		/>
		<path
			:fill="fillComputed"
			d="M32.47,40.32H31.18s-.05,0-.05-.06c.15-.78-.27-1-1-1.19a5.61,5.61,0,0,1-1.69-.81,3,3,0,0,1-1.25-3c.77,0,1.53,0,2.29,0,.07,0,.19.17.2.27.16,1.65,1.43,2,2.76,1.72a1.41,1.41,0,0,0,1.1-1.33,1.49,1.49,0,0,0-.71-1.63c-.76-.42-1.56-.77-2.34-1.15a14.2,14.2,0,0,1-1.39-.74,3.24,3.24,0,0,1-1.53-3.15,3.33,3.33,0,0,1,2.06-2.94,6.74,6.74,0,0,1,1.15-.38c.36-.09.5-.26.45-.63a5.42,5.42,0,0,1,0-.57h1.36a4.71,4.71,0,0,1,0,.65c0,.35.07.53.46.6a3.81,3.81,0,0,1,2,1.08,3.61,3.61,0,0,1,1,2.78c-.79,0-1.55,0-2.31,0-.09,0-.22-.28-.25-.44a1.66,1.66,0,0,0-1.75-1.56,1,1,0,0,0-.24,0A1.34,1.34,0,0,0,30.27,29a1.55,1.55,0,0,0,.61,1.83c.67.39,1.39.71,2.09,1.06.45.22.92.42,1.37.66a3.3,3.3,0,0,1,1.8,3.56A3.21,3.21,0,0,1,33.86,39l-.38.12c-.3.09-.71.07-.88.27s-.09.59-.13.94"
		/>
		<path :fill="fillComputed" d="M34.24,54.93H18V52H34.24Z" />
		<rect fill="none" x="9.54" y="12.64" width="56.06" height="52.13" />
		<path
			:fill="fillComputed"
			d="M38.82,47.42H27.24v-3c.16,0,.31,0,.47,0H36.9a.55.55,0,0,1,.6.38c.4.88.86,1.74,1.33,2.65"
		/>
		<path :fill="fillComputed" d="M23.36,47.41H18v-3h5.41Z" />
		<rect fill="none" x="9.54" y="12.64" width="56.06" height="52.13" />
		<path
			:fill="fillComputed"
			d="M48.43,40.84c1-.77,1.92-1.56,2.9-2.3q2.63-2,5.29-3.91a10.14,10.14,0,0,1,1.4-.8,1.13,1.13,0,0,1,.51-.12c.31,0,.46.19.32.48a5.17,5.17,0,0,1-.66,1.11q-2.76,3.15-5.57,6.25c-.91,1-1.87,2-2.83,2.91a1.86,1.86,0,0,1-2.61.3,1.83,1.83,0,0,1-.49-.58,23.24,23.24,0,0,1-2.56-4.53,4.67,4.67,0,0,1-.27-.9c-.1-.57.27-.9.8-.67a7.85,7.85,0,0,1,1.42.85c.8.61,1.56,1.27,2.35,1.92"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'BillPay',
	mixins: [iconMixin],
}
</script>
