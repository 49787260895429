<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<ellipse :fill="fillComputed" cx="43.87" cy="20.77" rx="2.61" ry="3.84" />
		<ellipse :fill="fillComputed" cx="49.09" cy="21.9" rx="1.51" ry="2.18" />
		<ellipse :fill="fillComputed" cx="52.41" cy="24.02" rx="1.03" ry="1.64" />
		<ellipse :fill="fillComputed" cx="54.9" cy="26.32" rx="0.84" ry="1.26" />
		<ellipse :fill="fillComputed" cx="57.14" cy="28.77" rx="0.77" ry="1.19" />
		<path
			:fill="fillComputed"
			d="M40.75,31.43s.17-6.06,4.12-5.95,9.44,1.94,10.88,6.69.2,6.93-.57,8.5-2.78,8-2.38,11.92-.5,9-5,8.6-7.27-3.65-5.86-7.53,6.29-7.47,4-12.69S41.55,36.55,40.75,31.43Z"
		/>
		<ellipse :fill="fillComputed" cx="33.09" cy="12.85" rx="2.61" ry="3.84" />
		<ellipse :fill="fillComputed" cx="27.87" cy="13.98" rx="1.51" ry="2.18" />
		<ellipse :fill="fillComputed" cx="24.56" cy="16.1" rx="1.03" ry="1.64" />
		<ellipse :fill="fillComputed" cx="22.06" cy="18.4" rx="0.84" ry="1.26" />
		<ellipse :fill="fillComputed" cx="19.83" cy="20.85" rx="0.77" ry="1.19" />
		<path
			:fill="fillComputed"
			d="M36.21,23.51s-.16-6.06-4.11-6-9.44,1.94-10.88,6.7-.2,6.93.57,8.5,2.78,8,2.37,11.92.51,9,5.06,8.6,7.26-3.65,5.86-7.53-6.3-7.47-4-12.69S35.41,28.63,36.21,23.51Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Podiatry',
	mixins: [iconMixin],
}
</script>
