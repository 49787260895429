<template>
	<div class="my-2">
		<div class="" :class="[disabled ? 'opacity-50' : '']">
			<input
				ref="timeElement"
				v-model="time"
				type="time"
				name="time"
				class="py-2 px-3 block w-full rounded-t-md focus:border-gray-300 focus:border-gray-300 focus:outline-none min-w-0 sm:text-sm border-gray-300 border disabled:opacity-50"
				:disabled="disabled"
				@input="updateTime"
			/>

			<div class="flex items-center">
				<button
					type="button"
					class="-my-px w-full items-center px-2 py-1 border border-gray-300 shadow-xs text-sm font-medium rounded-b-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none text-center disabled:opacity-50"
					:disabled="disabled"
					@click="clear"
				>
					Clear Time
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, watch } from 'vue'
import { onMounted } from '@vue/runtime-core'

export default {
	props: {
		modelValue: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const time = ref(props.modelValue)
		const timeElement = ref(null)

		const isEmpty = value => {
			return value === null || value === ''
		}

		const clear = () => {
			timeElement.value.type = 'text'
			timeElement.value.value = null
			console.log(timeElement.value)
			emit('update:modelValue', null)
			timeElement.value.type = 'time'
		}

		const updateTime = evt => {
			let newTime = evt.target.value + ':00'
			console.log(newTime)
			emit('update:modelValue', newTime)
		}

		return {
			clear,
			time,
			timeElement,
			updateTime,
		}
	},
}
</script>
