<template>
	<div>
		<router-view />
	</div>
</template>
<script>
export default {
	setup() {},
}
</script>
