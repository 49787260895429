<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M40.17,57.12h0a3.94,3.94,0,0,1-5.56,0L16.54,39.05a3.94,3.94,0,0,1,0-5.56h0a3.94,3.94,0,0,1,5.56,0L40.17,51.56A3.94,3.94,0,0,1,40.17,57.12Z"
		/>
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M33.22,55.73a5.9,5.9,0,0,1-8.34,0l-6.95-6.95a5.9,5.9,0,0,1,0-8.34Z"
		/>
		<path class="cls-1" d="M25.57,56.43a5.9,5.9,0,1,1-8.34-8.34Z" />
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M58.24,39.05h0a3.94,3.94,0,0,0,0-5.56L40.17,15.42a3.94,3.94,0,0,0-5.56,0h0a3.94,3.94,0,0,0,0,5.56L52.68,39.05A3.94,3.94,0,0,0,58.24,39.05Z"
		/>
		<path :stroke="fillComputed" class="cls-1" d="M56.85,32.1a5.9,5.9,0,0,0,0-8.34l-7-7a5.92,5.92,0,0,0-8.34,0Z" />
		<path :stroke="fillComputed" class="cls-1" d="M57.55,24.46a5.9,5.9,0,0,0-8.34-8.35Z" />
		<rect
			:stroke="fillComputed"
			class="cls-1"
			x="28.54"
			y="33.32"
			width="17.69"
			height="5.9"
			transform="translate(-14.7 37.06) rotate(-45)"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'SportsMedicine',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1 {
	fill: none;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 2px;
}
</style>
