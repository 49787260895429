<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'stroke:' + fillComputed"
			class="cls-1"
			d="M36.27,17.72a2.2,2.2,0,1,1,2.19,2.2A2.19,2.19,0,0,1,36.27,17.72Z"
		/>
		<line :style="'stroke:' + fillComputed" class="cls-1" x1="38.46" y1="19.92" x2="38.46" y2="56.47" />
		<path
			:style="'stroke:' + fillComputed"
			class="cls-1"
			d="M42.1,45.65l.23.2a2.88,2.88,0,0,1,1.05,2.21c0,4-4.92,4.45-4.92,4.45s-4,.06-4,4"
		/>
		<path :style="'stroke:' + fillComputed" class="cls-1" d="M34.13,43.32l.29.11" />
		<path
			:style="'stroke:' + fillComputed"
			class="cls-1"
			d="M36.56,36.54c-2.18,0-5.44.56-5.44,3.4,0,1.69,1.41,2.74,3,3.38"
		/>
		<path :style="'stroke:' + fillComputed" class="cls-1" d="M41.69,54a3.75,3.75,0,0,1,.73,2.47" />
		<path
			:style="'stroke:' + fillComputed"
			class="cls-1"
			d="M40.29,36.54c2.17,0,5.52.52,5.52,3.4,0,3.85-7.35,4.35-7.35,4.35s-4.91.57-4.91,3.77A3.79,3.79,0,0,0,35,51.16"
		/>
		<path
			:style="'stroke:' + fillComputed"
			class="cls-1"
			d="M38.46,32.18c4.6,3.07,4.92-1.62,4.92-1.62,5.5,2.91,4.6-1.88,4.6-1.88,8.37,2.35,3.13-4,3.13-4,6.45,4.38,12.65-3,8.36-3.71-7.51-1.22-7-2.92-11.63-3.75-1.69-.3-3.21.15-4.29,3.67-.83,2.67-3.07,2.82-5.09,2.84s-4.26-.17-5.08-2.84c-1.08-3.52-2.61-4-4.29-3.67C24.44,18,25,19.74,17.45,21c-4.28.7,1.92,8.09,8.37,3.71,0,0-5.24,6.36,3.13,4,0,0-.9,4.79,4.6,1.88,0,0,.32,4.69,4.91,1.62"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PhysicianOpportunities',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1 {
	fill: none !important;
	stroke-miterlimit: 10;
	stroke-width: 2px;
}
</style>
