<template>
	<div class="bg-white border overflow-hidden shadow rounded-lg divide-y divide-gray-200">
		<div class="px-4 py-5 sm:p-6">
			<ContentBuilderSeo :seo="seo" :show-heading="false" @invalidField="invalidField" @validField="validField" />
		</div>
		<div class="px-4 py-4 sm:px-6 flex justify-end">
			<button
				type="button"
				class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
				@click.prevent="cancel"
			>
				<XCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
				Reset
			</button>
			<button
				type="button"
				class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
				@click.prevent="update"
			>
				<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
				Save
			</button>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

import { SaveIcon, XCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		SaveIcon,
		XCircleIcon,
	},
	props: {
		data: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['cancelEditing'],
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		return {
			api,
			toast,
			route,
		}
	},
	data() {
		return {
			seo: {},
		}
	},
	computed: {},
	mounted() {
		this.fetchSeo()
	},
	methods: {
		fetchSeo() {
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.seo.fetch()
				.then(data => {
					this.seo = data
				})
				.catch(error => {
					this.toast.error('Error fetching Location SEO:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		cancel() {
			this.$emit('cancelEditing')
		},

		update() {
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.seo.update(this.seo)
				.then(data => {
					this.toast.success('Successfully updated Location SEO')
				})
				.catch(error => {
					this.toast.error('Error updating Location SEO:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
