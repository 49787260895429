<template>
	<div v-if="provider && page" class="bg-white shadow sm:rounded-lg">
		<div class="border-t border-gray-200 px-4 py-5 sm:p-0">
			<dl class="sm:divide-y sm:divide-gray-200">
				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">MD-Staff ID</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
						{{ provider.id }}
					</dd>
				</div>
				<div class="py-4 sm:py-5 sm:px-6 flex flex-col gap-y-5">
					<div class="sm:grid sm:grid-cols-4 sm:gap-4">
						<dt class="text-sm font-bold text-gray-500">Primary Department Override</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
							<FieldsSelect
								v-model="provider.department_override_id"
								:field="{
									type: 'select',
									model: 'department_override_id',
									options: [
										{ name: 'Select a Department', value: null },
										...provider.available_departments,
									],
									required: false,
									multiSelect: false,
									colSpan: 'col-span-6',
								}"
							/>
						</dd>
					</div>

					<div class="sm:grid sm:grid-cols-4 sm:gap-4">
						<dt class="text-sm font-bold text-gray-500">Department Name</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
							<input
								v-model="provider.department_override"
								type="text"
								name="name"
								:placeholder="
									provider.department_overrided
										? provider.department_overrided.name
										: provider.department.name
								"
								class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50"
							/>
						</dd>
						<div class="p-2 text-gray-500 text-sm col-span-2">
							<span class="text-gray-600 font-bold">MDStaff:</span>
							{{ provider.department.name }}
						</div>
					</div>

					<div class="sm:grid sm:grid-cols-4 sm:gap-4">
						<dt class="text-sm font-bold text-gray-500">
							Department Path
							<p class="w-full block font-normal">
								This is the relative path to the provider's department
							</p>
						</dt>
						<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
							<input
								v-model="provider.department_link_override"
								type="text"
								name="name"
								:placeholder="
									provider.department_overrided_path
										? provider.department_overrided_path
										: provider.department_path
								"
								class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50"
							/>
						</dd>
						<div class="p-2 text-gray-500 text-sm col-span-2">
							<span class="text-gray-600 font-bold">MDStaff:</span>
							{{ provider.department_path }}
						</div>
					</div>
				</div>
				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">
						Accepting New Patients
					</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
						<div class="flex gap-x-10">
							<span
								class="inline-flex items-center justify-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 w-12"
								:class="
									provider.accepting_new_patients === 0
										? 'bg-red-100 text-red-800'
										: 'bg-green-100 text-green-800'
								"
							>
								{{ accepting_new_patients }}
							</span>

							<div class="flex flex-col gap-y-5">
								<div>
									<label for="email" class="block text-sm font-bold leading-6 text-gray-900">
										Override MDStaff
									</label>
									<div class="flex gap-x-5">
										<div class="flex items-center gap-x-2">
											<input
												id="accepting_new_patients_override_off"
												v-model="provider.accepting_new_patients_override"
												:value="null"
												name="accepting_new_patients_override"
												type="radio"
												class="h-4 w-4 border-gray-300 text-gray-600 focus:ring-0"
											/>
											<label
												for="accepting_new_patients_override_off"
												class="block text-sm font-medium leading-6 text-gray-900"
											>
												Off
											</label>
										</div>
										<div class="flex items-center gap-x-2">
											<input
												id="accepting_new_patients_override_no"
												v-model="provider.accepting_new_patients_override"
												:value="false"
												name="accepting_new_patients_override"
												type="radio"
												class="h-4 w-4 border-gray-300 text-red-600 focus:ring-0"
											/>
											<label
												for="accepting_new_patients_override_no"
												class="block text-sm font-medium leading-6 text-red-600"
											>
												Not Accepting
											</label>
										</div>
										<div class="flex items-center gap-x-2">
											<input
												id="accepting_new_patients_override_yes"
												v-model="provider.accepting_new_patients_override"
												:value="true"
												name="accepting_new_patients_override"
												type="radio"
												class="h-4 w-4 border-gray-300 text-green-600 focus:ring-0"
											/>
											<label
												for="accepting_new_patients_override_yes"
												class="block text-sm font-medium leading-6 text-green-600"
											>
												Accepting
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">
						Publish Status
						<!-- <p class="mt-1 max-w-2xl text-sm text-gray-500 font-normal">
							Publish this service now or schedule it to be published later.
						</p> -->
					</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
						<!-- <FieldsDate
							v-model="draft.publish_date"
							:field="{
								label: '',
								model: 'publish_date',
								colSpan: 'col-span-6',
							}"
						/> -->

						<ContentBuilderPublish
							:class="{ 'pointer-events-none opacity-50': dirtyData }"
							:draft="draft"
							:page="page"
							:dirty-data="dirtyData"
							:include-publish="includePublish"
							:show-heading="false"
							:disabled="errors.length != 0"
							@publish="publish"
							@unpublish="unpublish"
							@schedule="schedule"
							@unschedule="unschedule"
						/>
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">Full Name</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
						<input
							v-model="draft.title"
							type="text"
							name="name"
							:placeholder="provider.name"
							class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50"
						/>
					</dd>
				</div>
				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">URL</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
						<FieldsInput
							v-model="draft.slug"
							:field="{
								type: 'input',
								inputType: 'text',
								label: '',
								model: 'url',
								placeholder: provider.slug,
								prefix: draft.url_prefix ?? '/our-providers/',
								enableCopy: true,
								copyWithPrefix: true,
								kebabCase: true,
								colSpan: 'col-span-6',
							}"
						/>
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">
						Header Image
					</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
						<FieldsImage
							v-model="draft.header_image"
							:field="{
								type: 'input',
								inputType: 'text',
								label: '',
								model: 'image',
								required: false,
								colSpan: 'col-span-6',
							}"
							:media-types="['image']"
							:media-categories="['Provider']"
							:media-upload-category="'page'"
							:media-upload-accept="['image/png', 'image/jpeg']"
							:media-upload-extensions="['jpg', 'jpeg', 'png']"
						/>
					</dd>
				</div>
				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">Card Image</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
						<FieldsImage
							v-model="draft.card_image"
							:field="{
								type: 'input',
								inputType: 'text',
								label: '',
								model: 'image',
								required: false,
								colSpan: 'col-span-6',
							}"
							:media-types="['image']"
							:media-categories="['Provider']"
							:media-upload-category="'page'"
							:media-upload-accept="['image/png', 'image/jpeg']"
							:media-upload-extensions="['jpg', 'jpeg', 'png']"
						/>
					</dd>
				</div>
				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">
						Meet the Doc Video
					</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
						<FieldsVideo
							v-model="draft.video"
							:field="{
								type: 'input',
								inputType: 'text',
								label: '',
								model: 'video',
								required: false,
								colSpan: 'col-span-6',
							}"
							:media-types="['video']"
							:media-categories="['Provider']"
							:media-upload-category="'page'"
							:media-upload-accept="['video/mp4']"
							:media-upload-extensions="['mp4']"
						/>
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">
						Direct Book URL
					</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
						<FieldsUrlWithPreview
							v-model="provider.direct_book_cta"
							:field="{
								type: 'input',
								inputType: 'text',
								model: 'direct_book_cta',
								label: '',
								colSpan: 'col-span-6',
							}"
						/>
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">Keywords</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
						<FieldsMultiSelect
							v-model="draft.keywords"
							:field="{
								type: 'select',
								label: '',
								model: 'keywords',
								options: keywordOptions,
								required: false,
								mode: 'tags',
								colSpan: 'col-span-6',
							}"
							:create-tag="true"
							@storeTag="storeTag"
						/>
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">About</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
						<FieldsTipTapEditor
							v-model="draft.body"
							:field="{
								label: '',
								model: 'about',
								required: false,
								colSpan: 'col-span-6',
								rows: 10,
							}"
						/>
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">SEO</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
						<ContentBuilderSeo
							:seo="draft.seo"
							:show-heading="false"
							@invalidField="invalidField"
							@validField="validField"
						/>
					</dd>
				</div>
			</dl>
		</div>

		<div
			class="px-4 py-5 shadow-sm border border-gray-300 rounded-l-md flex justify-end fixed bottom-5 right-4 bg-white bg-opacity-50 z-10"
		>
			<div class="-ml-4 -mt-2 flex items-center justify-end flex-wrap sm:flex-nowrap flex flex-col gap-x-2">
				<div v-if="errors.length != 0" class="mb-2 text-center text-red-500 font-semibold px-3 w-60">
					Your form has errors, please correct them.
				</div>
				<div class="ml-4 mt-2 flex-shrink-0">
					<button
						type="button"
						class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
						@click.prevent="cancel"
					>
						<XCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Reset
					</button>
					<button
						type="button"
						class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none disabled:opacity-50"
						:disabled="errors.length != 0"
						@click.prevent="update"
					>
						<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

import { SaveIcon, XCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		SaveIcon,
		XCircleIcon,
	},
	props: {},
	emits: ['updatedPage', 'updated'],
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		const provider = inject('provider')

		const page = ref(null)
		const draft = ref(null)
		const original = ref(null)
		const dirtyData = ref(false)

		return {
			page,
			draft,
			original,
			dirtyData,
			provider,
			route,
			api,
			toast,
		}
	},
	data() {
		return {
			includePublish: true,
			// page: {
			// 	title: null,
			// 	body: null,
			// 	url_prefix: '/our-providers/',
			// 	slug: null,
			// 	header_image: null,
			// 	card_image: null,
			// 	video: null,
			// 	publish_date: null,
			// },
			generalInformation: {},
			url: '',
			keywordOptions: [],
			errors: [],
		}
	},
	computed: {
		department: function() {
			return typeof this.data.department === 'undefined' || this.data.department === null
				? ''
				: this.data.department.name
		},

		accepting_new_patients: function() {
			// if (this.provider.accepting_new_patients_override === null) {
			// 	return this.provider.accepting_new_patients === 0 ? 'No' : 'Yes'
			// } else {
			// 	return this.provider.accepting_new_patients_override === false ? 'No' : 'Yes'
			// }

			return this.provider.accepting_new_patients === 0 ? 'No' : 'Yes'
		},

		show_on_web: function() {
			return this.data.show_on_web === 0 ? 'No' : 'Yes'
		},
	},
	watch: {
		draft: {
			deep: true,
			handler(val) {
				// set all empty strings to null
				console.log(val)
				if (val !== null)
					Object.keys(val).forEach(key => {
						if (typeof val[key] == 'string' && val[key].length == 0) {
							val[key] = null
						}
					})

				this.dirtyData = JSON.stringify(val) != this.original
				// console.log('dirtyData', this.dirtyData)
			},
		},
	},
	mounted() {
		this.fetchKeywords()
		this.fetchPage()
	},
	beforeMount() {},
	methods: {
		fetchKeywords() {
			this.loaderShow()

			this.api.keywords
				.all()
				.then(data => {
					this.keywordOptions = data
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching keywords:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		storeTag(tag) {
			// console.log('tag', tag)
			this.loaderShow()

			this.api.keywords
				.store({ name: tag })
				.then(data => {
					console.log('data', data)

					this.keywordOptions.push(data)
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching keywords:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		setPage(page) {
			this.draft = null
			this.page = null
			this.original = null
			this.dirtyData = false

			setTimeout(() => {
				if (page.draft_page) {
					if (this.cloneId) {
						delete page.draft_page.id
						page.draft_page.publish_date = null
						page.draft_page.publish_date_display = null
					}

					this.draft = page.draft_page
					this.original = JSON.stringify(this.draft)
					this.page = JSON.parse(JSON.stringify(page))
					delete this.page.page_draft

					this.$emit('updated', page.slug)
				} else {
					if (this.cloneId) {
						delete page.id
						page.publish_date = null
						page.publish_date_display = null
					}

					if (page.menu && page.menu.length > 0) {
						page.menu_enabled = true
					}

					this.draft = page
					this.original = JSON.stringify(this.draft)
					this.page = JSON.parse(JSON.stringify(page))

					this.$emit('updated', page.slug)
				}

				// console.log(this.draft)
			}, 100)
		},

		fetchPage() {
			this.loaderShow()

			this.api.providers
				.slug(this.route.params.id)
				.pages.fetch()
				.then(page => {
					this.setPage(page)
					// if (Object.keys(page).length !== 0) this.page = page
				})
				.then(() => {
					this.loaderHide()
				})
		},

		cancel() {
			this.fetchPage()
		},

		processData() {
			let data = JSON.parse(JSON.stringify(this.draft))
			console.log(data)

			data.header_image_id = null
			if (data.header_image !== null) {
				data.header_image_id = data.header_image.id
			}
			delete data.header_image

			data.card_image_id = null
			if (data.card_image !== null) {
				data.card_image_id = data.card_image.id
			}
			delete data.card_image

			data.video_id = null
			if (data.video !== null) {
				data.video_id = data.video.id
			}
			delete data.video

			if (data.title === '') {
				data.title = null
			}

			data.department_override = this.provider.department_override
			if (typeof data.department_override === 'string' && data.department_override.trim() == '') {
				data.department_override = null
			}
			data.department_link_override = this.provider.department_link_override
			if (typeof data.department_link_override === 'string' && data.department_link_override.trim() == '') {
				data.department_link_override = null
			}
			data.direct_book_cta = this.provider.direct_book_cta
			if (typeof data.direct_book_cta === 'string' && data.direct_book_cta.trim() == '') {
				data.direct_book_cta = null
			}
			data.accepting_new_patients_override = this.provider.accepting_new_patients_override
			data.department_override_id = this.provider.department_override_id
			// if (typeof data.accepting_new_patients_override === 'boolean' && data.direct_book_cta.trim() == '') {
			// 	data.direct_book_cta = null
			// }

			return data
		},

		update() {
			this.loaderShow()

			this.api.providers
				.slug(this.route.params.id)
				.pages.update(this.processData())
				.then(page => {
					this.setPage(page)
					this.$emit('updatedPage')
					this.toast.success('Successfully updated Provider General Information')
				})
				.catch(error => {
					this.toast.error('Error updating Provider General Information:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		publish() {
			this.loaderShow()

			this.api.providers
				.slug(this.route.params.id)
				.pages.publish()
				.then(page => {
					this.setPage(page)
					this.$emit('updatedPage')
					this.toast.success('Successfully published!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
		unpublish() {
			this.loaderShow()

			this.api.providers
				.slug(this.route.params.id)
				.pages.archive()
				.then(page => {
					this.setPage(page)
					this.$emit('updatedPage')
					this.toast.success('Successfully unpublished!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
		schedule(when) {
			this.loaderShow()

			this.api.providers
				.slug(this.route.params.id)
				.pages.schedule({
					publish_date: when,
				})
				.then(page => {
					this.setPage(page)
					this.$emit('updatedPage')
					this.toast.success('Successfully scheduled!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
		unschedule() {
			this.loaderShow()

			this.api.providers
				.slug(this.route.params.id)
				.pages.unschedule()
				.then(page => {
					this.setPage(page)
					this.$emit('updatedPage')
					this.toast.success('Successfully scheduled!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		invalidField(field) {
			if (!this.errors.includes(field)) this.errors.push(field)

			// console.log('invalidField', field, !this.errors.includes(field), this.errors)
		},
		validField(field) {
			if (this.errors.includes(field)) {
				var index = this.errors.indexOf(field)
				if (index !== -1) {
					this.errors.splice(index, 1)
				}
			}

			// console.log('validField', field, this.errors)
		},
	},
}
</script>
