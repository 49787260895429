<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			class="a"
			d="M19.58,31.28a11.92,11.92,0,0,1-4.76-9.64,11.66,11.66,0,0,1,3.64-8.57A12,12,0,0,1,35.38,30.2a11.87,11.87,0,0,1-1.2,1.05l.26.13A22.3,22.3,0,0,1,46.68,43.67,24,24,0,0,1,48.6,53a2.22,2.22,0,0,1-1.22,2.16A2.1,2.1,0,0,1,45,55a2.25,2.25,0,0,1-1-2,19.39,19.39,0,0,0-1.16-6.62,17.83,17.83,0,0,0-9.48-10.57A15.94,15.94,0,0,0,15.9,38.76a18.91,18.91,0,0,0-6,11.55,26.74,26.74,0,0,0-.21,2.83,2.27,2.27,0,0,1-2.25,2.3H7.39a2.25,2.25,0,0,1-2.2-2.31v-.05a24,24,0,0,1,5-14.71,21.55,21.55,0,0,1,9-6.92Zm14.78-9.59a7.51,7.51,0,0,0-7.51-7.51h-.07a7.51,7.51,0,1,0,.14,15h0a7.53,7.53,0,0,0,7.45-7.51Z"
		/>
		<path
			:fill="fillComputed"
			class="a"
			d="M53.22,32.48l.36.16a17.47,17.47,0,0,1,10.5,11.75,29,29,0,0,1,1.11,8.33,2.25,2.25,0,0,1-4.5.16v-.13a27.57,27.57,0,0,0-.75-6.58,13.25,13.25,0,0,0-4.77-7.55A15.88,15.88,0,0,0,49,35.87c-.51-.12-1-.21-1.55-.31a2.33,2.33,0,0,1-2-2.1,2.28,2.28,0,0,1,1.66-2.31A6.77,6.77,0,0,0,47,18.21a6.75,6.75,0,0,0-1.79-.28,2.25,2.25,0,0,1-2.38-2.14,2.23,2.23,0,0,1,2.3-2.36,11,11,0,0,1,9,4.62,11.15,11.15,0,0,1-.73,14.2A2.92,2.92,0,0,0,53.22,32.48Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'AboutUs',
	mixins: [iconMixin],
}
</script>
