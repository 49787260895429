import { Mark, mergeAttributes } from '@tiptap/core'

const ButtonLink = Mark.create({
	name: 'buttonLink',

	priority: 1000,

	inclusive: false,

	addOptions: {
		HTMLAttributes: {},
	},

	// content: 'text*',

	// marks: '',

	// group: 'block',

	addAttributes() {
		return {
			class: {
				default:
					'relative btn btn-sc inline-flex items-center px-5 py-1 border border-transparent shadow-sm font-medium rounded-full text-white bg-sc-green focus:outline-none hover:bg-sc-green-hover',
			},
			onClick: {
				default: '',
			},
		}
	},

	parseHTML() {
		return [{ tag: 'button' }]
	},

	renderHTML({ HTMLAttributes }) {
		return ['button', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
	},

	addCommands() {
		return {
			setButtonLink: attributes => ({ commands }) => {
				return commands.setMark('buttonLink', attributes)
			},
			toggleButtonLink: attributes => ({ commands }) => {
				return commands.toggleMark('buttonLink', attributes)
			},
			unsetButtonLink: () => ({ commands }) => {
				return commands.unsetMark('buttonLink')
			},
		}
	},
})

export default ButtonLink
