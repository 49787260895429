<template>
	<div class="sm:pt-3" :class="[hasColSpan ? field.colSpan : '']">
		<label :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0">
			<div class="flex-1 py-2 px-3 mb-2 block w-full border border-gray-300 rounded-md">
				<span v-show="displayFrom.length == 0" class="text-gray-400">Please select a date range</span>
				{{ displayFrom }}
				<span v-show="displayFrom.length > 0" class="font-semibold text-sm">&mdash;</span>
				{{ displayTo }}
			</div>

			<v-date-picker v-model="inputVal" is-range />
		</div>

		<div v-if="hasErrors" class="mt-2 text-red-500 font-semibold">
			{{ errors.$errors[0].$message }}
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	props: {
		modelValue: {
			type: Object,
			default: function() {
				return {}
			},
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			attrs: [
				{
					key: 'today',
					// highlight: true,
					content: 'blue',
					dates: new Date(),
				},
			],
		}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		displayFrom: function() {
			// console.log(typeof this.inputVal.start.toDateString)
			if (typeof this.inputVal.start !== 'undefined' && typeof this.inputVal.start.toDateString === 'function') {
				return this.inputVal.start.toDateString()
			}

			return ''
		},
		displayTo: function() {
			if (typeof this.inputVal.end !== 'undefined' && typeof this.inputVal.end.toDateString === 'function') {
				return this.inputVal.end.toDateString()
			}

			return ''
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log("INPUT:", val);
				this.$emit('update:modelValue', val)
			},
		},
	},
	beforeMount() {
		if (typeof this.inputVal.start !== 'undefined' && typeof this.inputVal.end !== 'undefined') {
			this.inputVal.start = new Date(this.inputVal.start)
			this.inputVal.end = new Date(this.inputVal.end)
			// console.log(this.displayFrom)
		}
	},
	mounted() {
		// var d = new Date();
		// this.inputVal = d.toISOString();
	},
	methods: {},
}
</script>
