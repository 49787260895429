<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M36,53.37A17.37,17.37,0,1,1,53.37,36.11,17.38,17.38,0,0,1,36,53.37ZM33.44,31.06c.56,0,1.1,0,1.65.05.75.05,1.5.1,2.25.18a25.69,25.69,0,0,1,3.38.59A20.11,20.11,0,0,1,46.28,34a1.58,1.58,0,0,0,.88.22,1.62,1.62,0,0,0,1.5-2.07,1.64,1.64,0,0,0-.8-1,18.7,18.7,0,0,0-2-1A26.16,26.16,0,0,0,41,28.62c-1-.21-1.91-.38-2.88-.5-.74-.09-1.49-.15-2.23-.21s-1.27-.08-1.9-.09c-.79,0-1.59,0-2.38.06s-1.29.08-1.93.14a24.9,24.9,0,0,0-3.31.57,11.34,11.34,0,0,0-1.3.36A1.5,1.5,0,0,0,24,30.3a1.53,1.53,0,0,0,.85,1.59,1.61,1.61,0,0,0,1.29.11,23.69,23.69,0,0,1,5.41-.88C32.2,31.08,32.83,31.08,33.44,31.06Zm12.85,7.12c0-.08,0-.21-.07-.34a1.32,1.32,0,0,0-.63-.78,19.65,19.65,0,0,0-1.92-1,22.63,22.63,0,0,0-3.75-1.34,26,26,0,0,0-5.68-.87,25.38,25.38,0,0,0-3.7.1,22.13,22.13,0,0,0-2.69.42c-.59.14-1.18.29-1.76.47A1.3,1.3,0,0,0,25.18,36a1.36,1.36,0,0,0,1.72,1.38l.62-.18a21.71,21.71,0,0,1,4.81-.68,23.15,23.15,0,0,1,2.63.06,24.39,24.39,0,0,1,2.83.4,19.88,19.88,0,0,1,6.46,2.42,1.28,1.28,0,0,0,1.38,0A1.35,1.35,0,0,0,46.29,38.18ZM33.45,39.45c-.59,0-1.06,0-1.53.06s-1,.06-1.46.11a29.86,29.86,0,0,0-3,.47l-.76.16a1.08,1.08,0,1,0,.49,2.11,29.38,29.38,0,0,1,4.27-.66c.66,0,1.32-.07,2-.07s1.11,0,1.66.08a17,17,0,0,1,2.14.3A16.13,16.13,0,0,1,41,43.26c.49.24,1,.52,1.45.78a1,1,0,0,0,1.09,0,1.07,1.07,0,0,0,0-1.85,16.08,16.08,0,0,0-1.83-1,18.92,18.92,0,0,0-6.39-1.66C34.7,39.49,34,39.48,33.45,39.45Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Spotify',
	mixins: [iconMixin],
}
</script>
