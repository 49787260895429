<template>
	<div v-if="includePublish && page" :class="[showHeading ? 'px-4 py-5 sm:p-6' : '']">
		<div class="space-y-8 sm:space-y-5">
			<div>
				<div class="sr-only">{{ page.publish_date }}</div>
				<div v-if="showHeading" class="border-b pb-2 mb-3">
					<h3 class="text-lg leading-6 font-semibold text-gray-700">
						Publish Status
					</h3>
					<p class="mt-1 max-w-2xl text-sm text-gray-500">
						Publish this service now or schedule it to be published later.
					</p>
				</div>

				<div class="flex gap-x-5">
					<div class="flex-1">
						<div class="gap-x-3">
							<div v-if="page.live && page.publish_date_display" class="font-bold text-lg text-green-500">
								<span class="text-sm font-bold leading-7 text-gray-700 pb-1">Page:</span>
								LIVE
								<span class="text-gray-500 text-sm font-medium">({{ page.publish_date_display }})</span>

								<div
									v-if="page.live && page.publish_date_display"
									class="mt-3"
									:class="{ 'opacity-50 pointer-events-none': disabled }"
								>
									<button
										type="button"
										class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none"
										@click="unpublish"
									>
										Unpublish
									</button>
								</div>
							</div>

							<div
								v-else-if="!page.live && page.publish_date_display"
								class="font-bold text-lg text-yellow-500"
							>
								<span class="text-sm font-bold leading-7 text-gray-700 pb-1">Draft:</span>
								Scheduled
								<span class="text-gray-500 text-sm font-medium">({{ page.publish_date_display }})</span>

								<div
									v-if="draft.publish_date && draft.publish_date_display"
									class="mt-3"
									:class="{ 'opacity-50 pointer-events-none': disabled }"
								>
									<button
										type="button"
										class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none"
										@click="unschedule"
									>
										Unschedule
									</button>
								</div>
							</div>
							<div v-else class="font-bold text-lg text-red-500">Unpublished</div>
						</div>
					</div>

					<div class="flex-1">
						<div
							v-if="
								!showSchedule &&
									!(page.live && page.publish_date_display) &&
									!(!page.live && page.publish_date_display)
							"
							class="flex flex-col gap-y-3 gap-x-3"
							:class="{ 'opacity-50 pointer-events-none': disabled }"
						>
							<button
								type="button"
								class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none"
								@click="toggleSchedule(true)"
							>
								Schedule
							</button>
							<button
								type="button"
								class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none"
								@click="publish"
							>
								Publish Now
							</button>
						</div>
						<div
							v-else-if="!showSchedule && draft && !draft.publish_date"
							class="flex flex-col gap-y-3 gap-x-3"
							:class="{ 'opacity-50 pointer-events-none': disabled }"
						>
							<button
								type="button"
								class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none disabled:opacity-50"
								:disabled="dirtyData"
								@click="toggleSchedule(true)"
							>
								Schedule Draft
							</button>
							<button
								type="button"
								class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
								:disabled="dirtyData"
								@click="publish"
							>
								Publish Draft
							</button>
						</div>

						<div v-if="showSchedule && !dirtyData" class=" grid grid-cols-1 sm:grid-cols-6 gap-5">
							<FieldsDatetime
								v-model="when"
								:field="{
									label: 'Publish DateTime',
									model: 'publish_date',
									required: true,
									colSpan: 'col-span-6',
								}"
								class="-mt-3"
							/>

							<div
								class="col-span-6 mt-5 flex gap-x-3"
								:class="{ 'opacity-50 pointer-events-none': disabled }"
							>
								<button
									type="button"
									class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none"
									@click="toggleSchedule(false)"
								>
									Cancel
								</button>
								<button
									type="button"
									class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
									:disabled="!when"
									@click="schedule"
								>
									Schedule
								</button>
							</div>
						</div>

						<div
							v-if="
								draft.publish_date &&
									draft.publish_date_display &&
									page.publish_date != draft.publish_date
							"
							class=""
						>
							<!-- <div class="block text-sm font-bold leading-7 text-gray-700 pb-1">Status:</div> -->
							<div class="font-bold text-lg text-blue-500">
								<span class="text-sm font-bold leading-7 text-gray-700 pb-1">Draft:</span>
								Scheduled
								<span class="text-gray-500 text-sm font-medium">
									({{ draft.publish_date_display }})
								</span>

								<div
									v-if="draft.publish_date && draft.publish_date_display"
									class="mt-3"
									:class="{ 'opacity-50 pointer-events-none': disabled }"
								>
									<button
										type="button"
										class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none"
										@click="unschedule"
									>
										Unschedule
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="page.unpublished_changes && !dirtyData"
					class="mt-5 text-center text-2xl font-semibold text-yellow-500"
				>
					There are unpublished changes
				</div>
				<div v-if="dirtyData" class="mt-5 text-center text-2xl font-semibold text-red-500">
					Please save your changes before publishing
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject, ref } from 'vue'

export default {
	props: {
		page: {
			type: [Object, Boolean],
			default: false,
		},
		draft: {
			type: [Object, Boolean],
			default: false,
		},
		dirtyData: {
			type: Boolean,
			default: true,
		},
		includePublish: {
			type: Boolean,
			default: true,
		},
		showHeading: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['updatePublish', 'publish', 'unpublish', 'schedule', 'unschedule'],
	setup() {},
	data() {
		return {
			canSchedule: false,
			when: null,
		}
	},
	computed: {
		draftTmp: {
			get() {
				return this.draft
			},
			set(val) {
				// console.log('INPUT:', val)
				this.$emit('updatePublish', val)
			},
		},
		showSchedule() {
			return this.dirtyData == true ? false : this.canSchedule
		},
	},
	mounted() {
		// console.log('PUBLISH:', this.page)
	},
	methods: {
		toggleSchedule(val) {
			this.canSchedule = val
		},

		publish() {
			this.$emit('publish')
		},
		unpublish() {
			this.$emit('unpublish')
		},
		unschedule() {
			this.$emit('unschedule')
		},
		schedule() {
			this.$emit('schedule', this.when)
		},
	},
}
</script>
