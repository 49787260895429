<template>
	<div class="h-full">
		<div v-if="!editing" class="mt-8">
			<div class="pb-5 border-b border-gray-200">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Upload Video
				</h3>
			</div>

			<FieldsFile
				v-model="upload_file"
				:field="{
					type: 'input',
					inputType: 'file',
					label: 'File',
					model: 'media_file',
					required: true,
					colSpan: 'col-span-6',
				}"
				:percent-complete="progress"
				:show-progress="showProgress"
				:media-upload-accept="mediaUploadAccept"
			/>

			<FieldsImage
				v-model="upload_thumbnail"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Thumbnail *',
					model: 'media_upload_thumbnail',
					required: true,
					colSpan: 'col-span-6',
				}"
				:media-types="['image']"
				:media-categories="['Thumbnail']"
				:media-upload-category="'page'"
				:media-upload-accept="['image/png', 'image/jpeg']"
				:media-upload-extensions="['jpg', 'jpeg', 'png']"
			/>

			<FieldsSelect v-model="upload_category" :field="categories" />

			<FieldsInput
				v-model="upload_title"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Title Text',
					model: 'media_title',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<FieldsInput
				v-model="upload_description"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Description Text',
					model: 'media_description',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<div class="mt-4 flex justify-end">
				<button
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
					:disabled="!canSubmit"
					@click="uploadVideo"
				>
					<UploadIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Upload
				</button>
			</div>
		</div>

		<div v-if="editing" class="mt-8">
			<div class="pb-5 border-b border-gray-200">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Editing Video:
					<span class="text-blue-600">{{ media.original_filename }}</span>
				</h3>
			</div>

			<FieldsImage
				v-model="edit.thumbnail"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Thumbnail',
					model: 'media_upload_thumbnail',
					required: true,
					colSpan: 'col-span-6',
				}"
				:media-types="['image']"
				:media-categories="['Thumbnail']"
				:media-upload-category="'page'"
				:media-upload-accept="['image/png', 'image/jpeg']"
				:media-upload-extensions="['jpg', 'jpeg', 'png']"
			/>

			<FieldsSelect v-model="edit.video_category_id" :field="categories" />

			<FieldsInput
				v-model="edit.title"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Title Text',
					model: 'media_title',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<FieldsInput
				v-model="edit.description"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Description Text',
					model: 'media_description',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<div class="mt-4 flex justify-end">
				<button
					type="button"
					class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none"
					@click="cancel"
				>
					<XCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Cancel
				</button>

				<button
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none"
					@click="updateVideo"
				>
					<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Save
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { inject, ref } from 'vue'
import { useToast } from 'vue-toastification'

import getEnv from '@/extensions/env'

import {
	// SearchIcon,
	// PencilAltIcon,
	XCircleIcon,
	SaveIcon,
	UploadIcon,
} from '@heroicons/vue/solid'

export default {
	components: {
		// VideoPlayer,
		// SearchIcon,
		// PencilAltIcon,
		XCircleIcon,
		SaveIcon,
		UploadIcon,
	},
	props: {
		mediaTypes: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaCategories: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaUploadCategory: {
			type: String,
			default: '',
		},
		mediaUploadAccept: {
			type: Array,
			default: function() {
				return ['image/png', 'image/jpeg']
			},
		},
		mediaUploadExtensions: {
			type: Array,
			default: function() {
				return ['jpg', 'jpeg', 'png']
			},
		},
		editing: {
			type: Boolean,
			default: false,
		},
		media: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['mediaCreated', 'mediaUpdated', 'editCancel'],
	setup() {
		const api = inject('api')
		const toast = useToast()

		const apiUrl = ref(getEnv('VUE_APP_API_URL'))

		return {
			api,
			toast,
			apiUrl,
		}
	},
	data() {
		return {
			categories: {
				type: 'select',
				label: 'Video Category',
				model: 'upload_category',
				options: [],
				required: true,
				multiSelect: false,
				colSpan: 'col-span-6',
			},
			videos: [],
			// file: {},
			upload_file: null,
			upload_thumbnail: null,
			upload_category: null,
			upload_title: null,
			upload_description: null,

			edit: {},

			chunks: [],
			uploaded: 0,
			cookies: {},

			label: 'Choose File',
			file: null,
			progress: 0,
			showProgress: false,
			result: null,
			errors: [],
		}
	},
	computed: {
		fileSelected: function() {
			return typeof this.file.id !== 'undefined'
		},

		canSubmit: function() {
			return (
				this.upload_file &&
				this.upload_thumbnail &&
				this.upload_category &&
				this.upload_title &&
				this.upload_description
			)
		},
	},
	watch: {
		editing: function(val) {
			console.log('editing', val)
			if (val) {
				this.edit = {
					thumbnail: this.media.thumbnail,
					video_category_id: this.media.category.id,
					title: this.media.title,
					description: this.media.description,
				}

				console.log('edit', this.edit)
			}
		},
	},
	mounted() {
		this.fetchCategories()
	},
	methods: {
		fetchCategories(reload = false) {
			// this.loaderShow()

			this.api.videos.categories
				.all()
				.then(data => {
					let categories = []

					data.forEach(element => {
						categories.push({
							value: element.id,
							name: element.name,
						})
					})

					this.categories = {
						type: 'select',
						label: 'Video Category',
						model: 'upload_category',
						options: categories,
						required: true,
						multiSelect: false,
						colSpan: 'col-span-6',
					}
				})
				.catch(error => {
					this.toast.error('Error fetching video categories:\n' + error.response.data.message)
				})
				.then(() => {
					// this.loaderHide()
				})
		},

		mediaCreated(video) {
			this.$emit('mediaCreated', video)
		},

		editVideo(file) {
			this.file = JSON.parse(JSON.stringify(file))
		},

		uploadVideo() {
			if (this.formValid()) {
				this.showProgress = true
				this.loaderShow()

				this.chunk(
					this.upload_file,
					// onProgress
					percent => {
						this.progress = percent
					},
					// onError
					err => {
						console.log(err)
						this.showProgress = false
						this.loaderHide()
					},
					// onSuccess
					res => {
						this.progress = 0
						console.log('data', res)

						this.showProgress = false

						this.api.videos
							.store({
								original_filename: res.filename,
								image_id: this.upload_thumbnail.id,
								video_category_id: this.upload_category,
								title: this.upload_title,
								description: this.upload_description,
								mime_type: res.mime_type,
							})
							.then(data => {
								this.upload_file = null
								this.upload_thumbnail = null
								// this.upload_category = null
								this.upload_title = null
								this.upload_description = null

								this.$emit('mediaCreated')
								this.toast.success('Successfully uploaded a video')
							})
							.catch(error => {
								this.toast.error('Error uploading video:\n' + error.response.data.message)
							})
							.then(() => {
								this.loaderHide()
							})
					}
				)
			} else {
				this.toast.error(this.errors.join('\n'))
			}
		},

		formValid() {
			this.errors = []

			if (!this.upload_file) {
				this.errors.push('No file selected.')
			}

			if (!this.upload_thumbnail) {
				this.errors.push('No thumbnail selected.')
			}

			if (!this.upload_category) {
				this.errors.push('No category selected.')
			}

			if (!this.upload_title) {
				this.errors.push('No title specified.')
			} else if (this.upload_title.length < 5) {
				this.errors.push('Title must be more than 5 characters.')
			}

			if (!this.upload_description) {
				this.errors.push('No description specified.')
			} else if (this.upload_description.length < 5) {
				this.errors.push('Description must be more than 5 characters.')
			}

			return this.errors.length === 0
		},

		upload() {
			this.api.videos
				.store(this.formData, null, {
					headers: {
						'Content-Type': 'application/octet-stream',
					},
					onUploadProgress: event => {
						this.uploaded += event.loaded
					},
				})
				.then(data => {
					this.chunks.shift()
					// TODO close slide and reload the images table
					this.toast.success('Successfully uploaded image')
				})
				.catch(error => {
					this.toast.error('Error uploading video:\n' + error.response.data.message)
				})
				.then(() => {
					// this.loaderHide()
				})
		},

		cancel() {
			this.$emit('editCancel')
		},

		updateVideo() {
			this.loaderShow()

			this.api.videos
				.slug(this.media.id)
				.update({
					image_id: this.edit.thumbnail.id,
					video_category_id: this.edit.video_category_id,
					title: this.edit.title,
					description: this.edit.description,
				})
				.then(data => {
					this.edit = {}

					this.$emit('mediaUpdated')

					this.toast.success('Successfully updated video')
				})
				.catch(error => {
					this.toast.error('Error updating video:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
