import './assets/css/tailwind.css'
import './assets/css/main.scss'
import './assets/css/media-library-pro.scss'
import 'video.js/dist/video-js.css'
import '@videojs/themes/sea/index.css'
import 'vue-toastification/dist/index.css'
import '@ag-grid-community/core/dist/styles/ag-grid.css'
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css'
import './assets/tailwind.css'

import Toast, { POSITION, useToast } from 'vue-toastification'
import { createApp, inject, shallowRef } from 'vue'

import App from './App.vue'
import LaravelEcho from './extensions/LaravelEcho'
import VCalendar from 'v-calendar'
import WebsiteAPI from '@springfieldclinic/website-node-api'
import axios from 'axios'
import getEnv from './extensions/env'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'

const toast = useToast()
const app = createApp(App)
store.$app = app

// if (getEnv('VUE_APP_ENVIRONMENT') !== 'local') {
Sentry.init({
	app,
	environment: getEnv('VUE_APP_ENVIRONMENT'),
	dsn: 'https://79b1e8b70f854d93a7a2292bf6102c66@o1326143.ingest.sentry.io/4505130980933632',
	beforeSend(event, hint) {
		// Check if it is an exception, and if so, show the report dialog
		if (event.exception) {
			// console.log('Showing report dialog')
			Sentry.showReportDialog({
				eventId: event.event_id,
				user: {
					name: 'Jane Doe',
					email: 'janedoe@example.com',
				},
				labelSubmit: 'Submit Report',
			})
		}
		return event
	},
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
// }

app.use(router)
app.use(store)

window.store = store

var req = require.context('./components/', true, /\.vue$/)
req.keys().forEach(key => {
	let filename = key
		.replace('./', '')
		.replaceAll('/', '')
		.match(/\w+/)[0]

	app.component(filename, req(key).default)
})

app.use(Toast, {
	position: POSITION.BOTTOM_LEFT,
})

// Start Axios Configuration
let axiosErrorFunction = error => {
	if (error instanceof axios.Cancel) return Promise.reject(error)
	// console.error('Interceptor Response : Cancellation received!')

	// console.warn('Error: ', error.response)

	if (error.response != null && (error.response.status === 401 || error.response.status === 419)) {
		// If we're already 'logged out' then ignore this and fail
		if (!store.state.user.loaded) {
			console.warn('401 but we are already logged out!')
			return Promise.reject(error)
		}

		console.warn('Unauthorized! Redirecting to login...')
		console.warn('with path: ', router.currentRoute.value.path)
		if (error.response.status == 401) toast.error('Not logged in!\nRedirecting you to the login page.')
		if (store.state.user.loaded) store.commit('user/LOGOUT')

		router.push({
			name: 'login',
			query: { redirect: router.currentRoute.value.path },
		})
	} else if (error.response !== null && error.response.status == 405) {
		console.error('Method not allowed: ' + error.response.data.error_message)
		toast.error('Method not allowed: ' + error.response.data.error_message)
		// console.error(error.response)
	} else if (error.response != null && error.response.status != 422) {
		console.error('Unhandled API Error!')
		toast.error('Unhandled API Error!')

		// toast.error('Unhandled API Error!')
	} else if (error.response.status == 422 && error.response.data.errors != undefined) {
		// console.log('Error message on 422: ', error.response.data.errors)
		let errorList = ''
		Object.values(error.response.data.errors).forEach(value => {
			errorList += value + '\n'
		})
		toast.error(errorList)
	} else if (error.message !== undefined && error.message != '') {
		console.error('error message defined: ', error)
		toast.error('Error:' + error.message)
	} else if (!error.response && error.message === undefined) {
		console.error('unknown error: ', error)
		toast.error('No response received from the server.\nCheck your connection to the server and try again.')
		router.push('/login')
	} else {
		console.error('Uncaught Interceptor Error: ', error)
	}

	return Promise.reject(error)
}

let axiosPrerequest = request => {
	// console.warn('store: ', store)
	// console.warn('store loaded state: ', store.state.user.loaded)
	if (
		request.url != 'csrf-cookie' &&
		request.url != 'login' &&
		request.url != 'forgot-password' &&
		request.url != 'reset-password'
	) {
		if (!store.state.user.loaded) {
			// console.error('Canceling request to ', request.url, ' - Not logged in')
			throw new axios.Cancel('')
		}
	}
	// console.debug('Axios request: ', request)
	return request
}

// console.debug('API URL: ', getEnv('VUE_APP_API_URL'))
const website_api = new WebsiteAPI()
app.use(website_api, {
	baseURL: getEnv('VUE_APP_API_URL'),
	prerequest: axiosPrerequest,
	success: response => {
		return response
	},
	failure: axiosErrorFunction,
})
app.provide('api', app.api)
// End Axios Configuration

// Echo configuration
const echo = new LaravelEcho()

console.log('Booting CMS!')
console.log('VUE_APP_API_DOMAIN: ' + getEnv('VUE_APP_API_DOMAIN'))
console.log('VUE_APP_API_URL: ' + getEnv('VUE_APP_API_URL'))

const api_root_url = getEnv('VUE_APP_API_DOMAIN') //'api.local.sc.care'

let authRoot = api_root_url + (getEnv('VUE_APP_ENVIRONMENT') == 'local' ? ':8000' : '')
let handleEvent = event => {
	console.log('Event:', event)
	toast.info(event.message)
}
var pusherKey = getEnv('VUE_APP_PUSHER_KEY')
// console.log('Pusher App Key:', pusherKey)
app.use(echo, {
	// Config goes here
	key: pusherKey,
	authHost: authRoot,
	//host: api_root_url,
	//port: 6001,
	authSsl: getEnv('VUE_APP_ENVIRONMENT') == 'local' ? false : true,
	eventHandler: handleEvent,
	private: true,
	cluster: getEnv('VUE_APP_PUSHER_CLUSTER'),
})
app.provide('broadcast', app.broadcast)
// End Echo configuration

app.mixin({
	setup() {
		const api = inject('api')

		return {
			api,
		}
	},
	data() {
		return {
			chunkSize: 5242880, // 5MB
		}
	},
	methods: {
		chunkUploader(file, options) {
			const start = options.chunkNumber * this.chunkSize
			const end = Math.min(file.size, start + this.chunkSize)

			let currentChunkSize = this.chunkSize
			if (options.chunkNumber + 1 === options.blockCount) {
				currentChunkSize = file.size - start
			}

			const params = new FormData()
			params.append('chunkNumber', options.chunkNumber + 1)
			params.append('chunkSize', currentChunkSize)
			params.append('currentChunkSize', currentChunkSize)
			params.append('totalSize', file.size)
			params.append('type', file.type)
			params.append('identifier', options.identifier)
			params.append('filename', file.name)
			params.append('relativePath', file.name)
			params.append('totalChunks', options.blockCount)
			params.append('file', file.slice(start, end), file.name)

			return this.api.storage
				.chunk(params)
				.then(res => {
					// console.log('res', res)
					options.onProgress && options.onProgress(parseInt((end / file.size) * 100, 10), res)
					if (end === file.size) {
						options.onSuccess && options.onSuccess(res)
					} else {
						options.chunkNumber++
						return this.chunkUploader(file, options)
					}
				})
				.catch(err => {
					options.onError && options.onError(err)
				})
		},

		chunk(file, onProgress, onError, onSuccess) {
			const blockCount = Math.ceil(file.size / this.chunkSize)
			const chunkNumber = 0
			const identifier = `${file.size}-${file.name.replace('.', '')}`

			return this.chunkUploader(file, {
				blockCount,
				identifier,
				chunkNumber,
				onProgress,
				onError,
				onSuccess,
			})
		},

		loaderShow() {
			window.store.dispatch('loader/show')
		},
		loaderHide() {
			window.store.dispatch('loader/hide')
		},
	},
})

app.use(VCalendar, {})

app.mount('#app')
