<template>
	<div class="sm:pt-3" :class="[hasColSpan ? field.colSpan : '']">
		<label for="title" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0 relative">
			<div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>
			<textarea
				v-model="inputVal"
				:name="field.model"
				:autocomplete="field.model"
				class="max-w-full shadow-sm block w-full focus:ring-blue-500 sm:text-sm rounded-md"
				:class="[hasErrors ? 'border-red-300 text-red-900' : 'border-gray-300 focus:border-blue-500']"
				:rows="field.rows"
				@blur="$emit('blur')"
			/>
		</div>

		<div v-if="hasErrors" class="mt-2 text-red-500 font-semibold">
			{{ errors.$errors[0].$message }}
		</div>
	</div>
</template>

<script>
import { ExclamationCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		ExclamationCircleIcon,
	},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue', 'blur'],
	data() {
		return {}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log("INPUT:", val);
				this.$emit('update:modelValue', val)
			},
		},
	},
	mounted() {
		// console.log("INPUT:", this.modelValue);
	},
	methods: {},
}
</script>
