<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M26.86,23.28c0-3.59,0-7.17,0-10.75,0-3.28,1.91-5.2,5.17-5.2H49a4.69,4.69,0,0,1,5,5c0,7.06,0,14.12,0,21.18a8.31,8.31,0,0,1-8.27,8.39c-3.53,0-7.07,0-10.6,0a8.09,8.09,0,0,1-7.77-5.39,8.25,8.25,0,0,1-.48-3C26.82,30.09,26.86,26.68,26.86,23.28Zm2.31-3.35h22.5c0-2.76,0-5.47,0-8.18a2.2,2.2,0,0,0-2.38-2.09q-8.84,0-17.69,0a2.29,2.29,0,0,0-2.39,2.4c0,.95,0,1.89,0,2.84Z"
		/>
		<path
			:fill="fillComputed"
			d="M39.23,48.14a3.8,3.8,0,0,1-3.58-1.62,3.11,3.11,0,0,1-.57-2.29H45.67c.56,1.94-1.08,3.57-4.05,4,0,.27,0,.55,0,.84,0,3.33,0,6.66,0,10A5.82,5.82,0,0,1,31,62.39,5.68,5.68,0,0,1,29.91,59c0-1.84,0-3.68,0-5.52A3.51,3.51,0,0,0,24,50.89a3.58,3.58,0,0,0-1.15,2.81c0,1.59,0,3.18,0,4.77,0,.94-.41,1.44-1.14,1.44s-1.13-.51-1.13-1.44q0-2.46,0-4.92a5.82,5.82,0,1,1,11.64,0c0,1.76,0,3.53,0,5.29a3.53,3.53,0,1,0,7.05,0c0-3.31,0-6.61,0-9.92C39.27,48.65,39.25,48.43,39.23,48.14Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'CancerTreatments',
	mixins: [iconMixin],
}
</script>
