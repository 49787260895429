<template>
	<ul class="mr-5">
		<li v-for="(errorGroup, errorGroupName) in props.errors" :key="errorGroupName">
			<div v-for="error in errorGroup" :key="error" class="py-1">
				{{ error }}
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	setup(props) {
		return {
			props,
		}
	},
}
</script>
