<template>
	<div class="bg-white shadow overflow-hidden sm:rounded-lg">
		<Gallery type="providers" :slug="provider.slug" :initial-gallery="provider.gallery" />
	</div>
</template>
<script>
import { computed, onMounted, ref, reactive, inject, watch } from 'vue'

export default {
	setup() {
		const provider = inject('provider')
		console.log('Provider: ', provider)
		return {
			provider,
		}
	},
}
</script>
