<template>
	<div :id="props.field.name">
		<div class="pointer-events-none">
			<label :for="props.field.name" class="block font-medium text-gray-700">
				{{ props.field.label }}
				<span v-show="isRequired" class="text-red-500">*</span>
			</label>
			<div class="mt-1">
				<div
					class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
				>
					<div class="space-y-1 text-center">
						<svg
							class="mx-auto h-12 w-12 text-gray-400"
							stroke="currentColor"
							fill="none"
							viewBox="0 0 48 48"
							aria-hidden="true"
						>
							<path
								d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<div class="flex text-sm text-gray-600">
							<label
								for="file-upload"
								class="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none"
							>
								<span>Upload a file</span>
								<input id="file-upload" type="file" :name="props.field.name" class="sr-only" />
							</label>
							<p class="pl-1">or drag and drop</p>
						</div>
					</div>
				</div>
			</div>
			<div v-if="props.field.help" class="text-sm text-gray-400">
				{{ props.field.help }}
			</div>

			<!-- <div class="text-sm text-blue-400 mt-2 whitespace-pre">
				{{ props.field }}
			</div> -->
		</div>
	</div>
</template>

<script>
import { reactive, computed, watchEffect } from 'vue'

export default {
	props: {
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		index: {
			type: Number,
			default: 0,
		},
		selected: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const isRequired = computed(() => {
			let requiredIndex = props.field.field_validations.findIndex(
				x => x.field_validation_rule.rule === 'required'
			)

			return props.field.field_validations[requiredIndex].rule_value === 'true'
		})

		return {
			props,
			isRequired,
		}
	},
}
</script>
