<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M38,8.3C38,8.3,38,8.3,38,8.3C22.7,8.3,10.3,20.7,10.3,36c0,15.3,12.4,27.7,27.8,27.7
    c15.3,0,27.7-12.4,27.7-27.7C65.8,20.7,53.3,8.3,38,8.3z M47.9,50.4l-9.8-5.1l-9.8,5.1l1.9-10.9l-7.9-7.7l10.9-1.6l4.9-9.9l4.9,9.9
    l10.9,1.6l-7.9,7.7L47.9,50.4z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'FeaturedJobs',
	mixins: [iconMixin],
}
</script>
