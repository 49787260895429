<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M56.06,23.53a16.6,16.6,0,0,1-4.74,1.3A8.32,8.32,0,0,0,55,20.26a17.06,17.06,0,0,1-5.25,2,8.25,8.25,0,0,0-14.28,5.63,8.55,8.55,0,0,0,.21,1.89,23.39,23.39,0,0,1-17-8.61,8.11,8.11,0,0,0-1.11,4.16,8.25,8.25,0,0,0,3.67,6.88,8.21,8.21,0,0,1-3.74-1v.1a8.26,8.26,0,0,0,6.62,8.1,8.31,8.31,0,0,1-3.71.15,8.29,8.29,0,0,0,7.72,5.73,16.54,16.54,0,0,1-10.24,3.53,16.1,16.1,0,0,1-2-.11,23.39,23.39,0,0,0,12.68,3.7C43.74,52.36,52,39.79,52,28.89c0-.35,0-.7,0-1.05a16.81,16.81,0,0,0,4.13-4.28Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Twitter',
	mixins: [iconMixin],
}
</script>
