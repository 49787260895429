import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'
import { loader } from './modules/loader'

//import modulesList from './modules'

const debug = process.env.NODE_ENV !== 'production'

const plugins = [
	createPersistedState({
		paths: ['user'],
	}),
]
if (debug)
	plugins.push(
		createLogger({
			filter(mutation, stateBefore, stateAfter) {
				// returns `true` if a mutation should be logged
				// `mutation` is a `{ type, payload }`
				return !mutation.type.endsWith('updateField')
			},
		})
	)

export default createStore({
	namespaced: true,
	modules: {
		//videos: VideosModule,
		user,
		loader,
		//modulesList,
	},
	strict: debug,
	//plugins: debug ? [createLogger()] : [],
	plugins: plugins,
})
