<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M20.48,59.31l-.11,0a4.35,4.35,0,0,1-2.91-3.33.94.94,0,0,0-.12-.22V18.21c.05-.07-.08-1.85,2.57-3.42a4,4,0,0,1,1.82-.44h1a5,5,0,0,0-.23,1.91c0,.2-.06.27-.25.26a2.4,2.4,0,0,0-2.71,2.22c0,.12,0,.25,0,.37V54.93a2.49,2.49,0,0,0,2.42,2.45q13.61,0,27.19,0A2.34,2.34,0,0,0,51.47,55V18.87a2.4,2.4,0,0,0-1.87-2.32,4.52,4.52,0,0,0-.9,0c-.16,0-.22-.05-.21-.21a3.38,3.38,0,0,0,0-.45c0-.43-.07-.87-.13-1.3,0-.15,0-.22.15-.23a4.74,4.74,0,0,1,3.79,1.32,2.42,2.42,0,0,1,.8,1.24v40a1.1,1.1,0,0,1-.21.66,4.34,4.34,0,0,1-2.36,1.8"
		/>
		<path
			:fill="fillComputed"
			d="M35.47,20.47H25.9a1.14,1.14,0,0,1-1.18-.7,1.16,1.16,0,0,1-.08-.48c0-1.25,0-2.5,0-3.74a1.13,1.13,0,0,1,1.06-1.21h.16c1.57,0,3.15,0,4.72,0,.21,0,.3,0,.32-.28a4.5,4.5,0,0,1,1.55-3.2A4.44,4.44,0,0,1,35.6,9.72a4.58,4.58,0,0,1,4.53,4.38c0,.21.08.24.27.24h4.82a1.06,1.06,0,0,1,1.15.95.81.81,0,0,1,0,.22c0,1.24,0,2.47,0,3.71a1.14,1.14,0,0,1-1,1.25H35.47Zm0-8.6A2.46,2.46,0,0,0,33,14.33v0a2.45,2.45,0,0,0,2.45,2.44h0a2.47,2.47,0,0,0,0-4.94Z"
		/>
		<path
			:fill="fillComputed"
			d="M35.46,28.34a6.19,6.19,0,0,1,7.45-1.26l-.64.65c-.2.19-.37.48-.6.54s-.51-.11-.77-.17a4.15,4.15,0,0,0-4.6,2,.9.9,0,0,1-1.21.39.86.86,0,0,1-.37-.36A3.94,3.94,0,0,0,30.8,28a4.43,4.43,0,0,0-4,3,6.19,6.19,0,0,0-.2,3.92,9.58,9.58,0,0,0,1.67,3.33,23,23,0,0,0,4.09,4.22c1,.8,2,1.56,3,2.35a.2.2,0,0,0,.29,0,0,0,0,0,0,0,0c1.25-1,2.52-2,3.76-3A20.64,20.64,0,0,0,42.16,39a11.26,11.26,0,0,0,2.2-3.92,5.4,5.4,0,0,0,.26-2,.29.29,0,0,1,.07-.22L46,31.61l.11,0c0,.24.08.49.11.74A8,8,0,0,1,46,35.08a9.64,9.64,0,0,1-.95,2.47,17.27,17.27,0,0,1-2.74,3.68,43.13,43.13,0,0,1-5.78,4.93l-.49.37a.87.87,0,0,1-1.16,0c-1.6-1.19-3.18-2.38-4.66-3.72a21,21,0,0,1-4-4.66A10.47,10.47,0,0,1,25,35a8.29,8.29,0,0,1-.06-3.14A6.67,6.67,0,0,1,26.3,28.6a6.23,6.23,0,0,1,5.83-2.14,5.46,5.46,0,0,1,3.27,1.78Z"
		/>
		<path
			:fill="fillComputed"
			d="M30.52,32.4a1,1,0,0,1,.79.33l3.44,3.41c.17.17.36.32.53.49s.22.14.36,0c1.69-1.7,3.39-3.41,5.09-5.1l6.35-6.36a1.1,1.1,0,0,1,1.49-.08,1.07,1.07,0,0,1,.14,1.48c-.44.52-.94,1-1.43,1.49l-11,11a1.2,1.2,0,0,1-1.7,0h0c-1.6-1.6-3.22-3.18-4.83-4.77a1.1,1.1,0,0,1-.1-1.53A1,1,0,0,1,30.52,32.4Z"
		/>
		<path
			:fill="fillComputed"
			d="M35.49,53.74h11.6c.37,0,.59.19.6.51s-.21.54-.6.55H24a.54.54,0,0,1-.58-.32.49.49,0,0,1,.12-.61.69.69,0,0,1,.39-.12H35.49Z"
		/>
		<path
			:fill="fillComputed"
			d="M35.5,50.17H47a.56.56,0,0,1,.6.34.52.52,0,0,1-.26.69.47.47,0,0,1-.17,0H23.81a.49.49,0,0,1-.49-.49v0a.56.56,0,0,1,.51-.54H35.5Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'OurServices',
	mixins: [iconMixin],
}
</script>
