<template>
	<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.03 69.41" :width="size" :height="size">
		<circle :fill="fillComputed" cx="31.02" cy="29.75" r="7.6" />
		<path
			:fill="fillComputed"
			d="m38.67,44.76c-.23-5.33-7.66-5.19-7.66-5.19,0,0-7.42-.14-7.66,5.19-.23,5.33,1.27,15.43,1.27,15.43l1.08,5.47s.74,2.83,3.12,3.17c0,0,1.45.35,2.19.35s2.19-.35,2.19-.35c2.38-.35,3.12-3.17,3.12-3.17l1.08-5.47s1.5-10.1,1.27-15.43Z"
		/>
		<path
			:fill="fillComputed"
			d="m51.65,32.01c0-12.84-11.79-22.98-25.11-20.16-7.04,1.49-14.22,8.7-15.69,15.74-1.85,8.86,2.02,17,8.59,21.47.21.14.49-.03.48-.28-.08-1.51-.11-2.95-.06-4.17,0-.09.02-.18.03-.27-4.33-3.92-6.62-10.05-4.96-16.62,1.48-5.87,6.2-10.49,12.09-11.88,10.9-2.56,20.64,5.69,20.64,16.16,0,4.89-2.13,9.28-5.51,12.33,0,.09.02.18.03.27h0c.05,1.23.02,2.67-.06,4.17-.01.25.27.42.48.28,5.46-3.72,9.06-9.96,9.06-17.05Z"
		/>
		<path
			:fill="fillComputed"
			d="m62.03,31.95C62.03,14.85,48.12.94,31.02.94S0,14.85,0,31.95c0,13.71,8.94,25.36,21.3,29.45l-.14-.69c-.02-.16-.25-1.68-.51-3.81-9.77-4.07-16.66-13.72-16.66-24.95,0-14.9,12.12-27.02,27.02-27.02s27.02,12.12,27.02,27.02c0,11.23-6.89,20.88-16.66,24.95-.26,2.13-.48,3.65-.51,3.81l-.03.16-.1.53c12.36-4.09,21.3-15.74,21.3-29.45Z"
		/>
	</svg>
</template>
<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'AppleMusic',
	mixins: [iconMixin],
}
</script>
