<template>
	<div class="sm:pt-3" :class="[hasColSpan ? field.colSpan : '']">
		<label :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0 relative" :class="[field.clearButton ? 'flex' : '']">
			<select
				v-model="inputVal"
				class="pl-3 pr-10 py-2 text-base border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
				:class="[field.clearButton ? 'flex-1 rounded-l-md' : 'block w-full rounded-md']"
				:name="field.model"
				:multiple="field.multiSelect"
			>
				<!-- <option value=""></option> -->
				<option v-for="option in options" :key="option.id" :value="option.value">
					{{ option.name }}
				</option>
			</select>

			<button
				v-if="field.clearButton"
				class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none"
				@click="clearInput"
			>
				<span>Clear</span>
			</button>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
export default {
	components: {},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue'],
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			forms: [],
			options: [],
		}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasValue: function() {
			return this.modelValue !== ''
		},
		hasDefaultValue: function() {
			return typeof this.field.defaultValue !== 'undefined'
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				this.$emit('update:modelValue', val)
			},
		},
	},
	mounted() {
		this.fetchForms()
	},
	methods: {
		fetchForms() {
			this.loaderShow()

			this.api.forms
				.formList()
				.then(data => {
					this.forms = data
					this.forms.forEach(element => {
						this.options.push({
							name: element.form_revision_published.name,
							value: element.id,
						})
					})
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching forms:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		clearInput() {
			this.inputVal = null
		},
	},
}
</script>
