<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M36.9,52.06a45.55,45.55,0,0,1-17.85-3.52A61.69,61.69,0,0,1,1.67,37.76c-1-.9-1-1.49,0-2.39,8-6.84,16.92-11.74,27.34-13.66a40,40,0,0,1,20,1.54A60.37,60.37,0,0,1,70.51,35.66c.77.68.78,1.14,0,1.8A58.56,58.56,0,0,1,44.05,51.22,36.32,36.32,0,0,1,36.9,52.06Zm-1-27.71A12.23,12.23,0,1,0,48.18,36.71,12.17,12.17,0,0,0,35.94,24.35Zm11.68,1.91q7.7,10.35,0,20.62c3.9-.56,17.22-7.87,18.86-10.36C62.36,32.73,51.79,27,47.62,26.26Zm-23.22-.1A58.51,58.51,0,0,0,5.27,36.58,58.5,58.5,0,0,0,24.42,47,15.76,15.76,0,0,1,24.4,26.16Z"
		/>
		<path
			:fill="fillComputed"
			d="M37.89,29.51a3.44,3.44,0,0,0-2.39,4,3.37,3.37,0,0,0,3.23,2.7c1.85,0,3-1,3.55-3.21,1.61,1.57,1.31,5.83-.58,8.19a7.39,7.39,0,0,1-12.33-8A7.27,7.27,0,0,1,37.89,29.51Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Ophthamology',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke-miterlimit: 10;
}
</style>
