<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M28.92,35.84l-1-.92,4.51-4.74a1.84,1.84,0,0,0,.25-.7l.94-6.71.45-.1A4.23,4.23,0,0,1,38,23.8c1.4,1.63,1.24,4.66-.46,9l-.25.62-1.24-.49.24-.62c1.88-4.79,1.42-6.8.7-7.64a2.63,2.63,0,0,0-2.16-.76L34,29.68A3.18,3.18,0,0,1,33.49,31Z"
		/>
		<path
			:fill="fillComputed"
			d="M44.86,37.73H36.77V36.39h8.09a1.34,1.34,0,0,0,1.34-1.33,1.32,1.32,0,0,0-.39-.94l-.47-.48,1-.94.47.48a2.68,2.68,0,0,1-1.91,4.55Z"
		/>
		<rect :fill="fillComputed" x="36.89" y="32.38" width="3.64" height="1.34" />
		<path
			:fill="fillComputed"
			d="M45.3,41.74H37.21V40.4H45.3a1.34,1.34,0,0,0,0-2.67H37.21V36.39H45.3a2.68,2.68,0,0,1,0,5.35Z"
		/>
		<path
			:fill="fillComputed"
			d="M44.6,45.75H36.51V44.41H44.6a1.34,1.34,0,0,0,0-2.67H36.51V40.4H44.6a2.68,2.68,0,0,1,0,5.35Z"
		/>
		<path
			:fill="fillComputed"
			d="M43.46,49.76H36.05a11.77,11.77,0,0,1-5.35-1.32H28.57V47.1h2.5l.16.1a10.37,10.37,0,0,0,4.82,1.22h7.41a1.34,1.34,0,1,0,0-2.67H35.38V44.41h8.08a2.68,2.68,0,1,1,0,5.35Z"
		/>
		<path :fill="fillComputed" d="M29.56,50.56H25.31V34.23h4.25Zm-2.92-1.34h1.59V35.56H26.64Z" />
		<polygon
			:fill="fillComputed"
			points="26.64 52.22 16.26 52.22 16.26 50.88 25.3 50.88 25.3 34.48 16.26 34.48 16.26 33.14 26.64 33.14 26.64 52.22"
		/>
		<path
			:fill="fillComputed"
			d="M49.28,27.45l-3.52-3.52a2.82,2.82,0,0,1,3.52-4.37,2.82,2.82,0,0,1,3.52.38h0a2.81,2.81,0,0,1,0,4Zm-1.52-7A1.49,1.49,0,0,0,46.7,23l2.58,2.57L51.85,23a1.48,1.48,0,0,0,0-2.11h0a1.48,1.48,0,0,0-2.1,0l-.47.48-.47-.48A1.48,1.48,0,0,0,47.76,20.45Z"
		/>
		<path
			:fill="fillComputed"
			d="M42,33.38l1.1-5.05a8,8,0,1,1,4,2.58Zm7.25-17a6.73,6.73,0,0,0-6.72,6.72,6.66,6.66,0,0,0,1.79,4.55l.23.25-.66,3L47,29.48l.25.08a6.72,6.72,0,1,0,2-13.14Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'SocialMedia',
	mixins: [iconMixin],
}
</script>
