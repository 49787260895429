<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g id="Group_78" data-name="Group 78">
			<g id="Group_76" data-name="Group 76">
				<path
					id="Path_67"
					data-name="Path 67"
					:style="'fill:' + fillComputed"
					d="M36.5,61.25C26.43,56.8,20.08,49,16.47,38.82A47.38,47.38,0,0,1,14,20c.13-2.13,1.27-3.27,3.57-3.32a31.36,31.36,0,0,0,11.59-2.32,48.24,48.24,0,0,0,5.67-2.93,3.74,3.74,0,0,1,4.37,0,32.38,32.38,0,0,0,17,5.25c2.75.07,3.8,1.18,3.9,4,.31,8.84-1.22,17.29-5.62,25.07a35.28,35.28,0,0,1-15.58,14.9l-1.31.6Zm-19.43-41c0,.39-.12,1.05-.13,1.72A44.72,44.72,0,0,0,19.2,37.35c3,8.9,8.39,15.9,16.87,20.28a1.82,1.82,0,0,0,1.87,0,31.07,31.07,0,0,0,10.23-8.12c6.83-8.4,9.36-18.16,8.88-28.82a5.39,5.39,0,0,0-.16-.8l-.31-.08a36,36,0,0,1-18.9-5.61,1.91,1.91,0,0,0-1.48.12c-3,1.3-5.82,3-8.89,3.92s-6.64,1.32-10.23,2"
				/>
			</g>
		</g>
		<path
			:style="'fill:' + fillComputed"
			d="M41.08,31.1h-.81V28.55a3.14,3.14,0,0,0-3.35-2.7,3.15,3.15,0,0,0-3.36,2.7V31.1H33a1.5,1.5,0,0,0-1.5,1.5v8.12A1.51,1.51,0,0,0,33,42.23h8.13a1.51,1.51,0,0,0,1.5-1.51V32.6A1.5,1.5,0,0,0,41.08,31.1Zm-2.69,7.68H35.63l.83-1.92a1.38,1.38,0,1,1,1.1,0Zm1.13-7.68H34.3V28.65a2.41,2.41,0,0,1,2.54-2H37a2.41,2.41,0,0,1,2.54,2Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PrivacyCharter',
	mixins: [iconMixin],
}
</script>
