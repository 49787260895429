<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 72 72"
		style="enable-background: new 0 0 72 72"
		xml:space="preserve"
	>
		<path
			:stroke="fillComputed"
			:fill="fillComputed"
			d="M58.5,33.3c-2-12.4-13.7-20.8-26.1-18.8S11.7,28.2,13.7,40.5c2,12.4,13.7,20.8,26.1,18.8S60.5,45.6,58.5,33.3z
	 M52.6,48.8c-3.2,4.4-7.9,7.3-13.3,8.2c-1.1,0.2-2.2,0.3-3.3,0.3c-4.2,0-8.4-1.3-11.9-3.8c-4.4-3.2-7.3-7.9-8.2-13.3
	c-0.9-5.4,0.4-10.8,3.6-15.2c3.2-4.4,7.9-7.3,13.3-8.2c5.4-0.9,10.8,0.4,15.2,3.6s7.3,7.9,8.2,13.3C57.1,39,55.8,44.4,52.6,48.8z"
		/>
		<line :stroke="fillComputed" class="st1" x1="27.1" y1="30.5" x2="36.2" y2="39.6" />
		<line :stroke="fillComputed" class="st1" x1="41.1" y1="34.6" x2="35.7" y2="40" />
		<text :fill="fillComputed" transform="matrix(0.9511 0.309 -0.309 0.9511 53.2501 19.31)" class="st0 st2 st3">
			z
		</text>
		<text :fill="fillComputed" transform="matrix(0.9511 0.309 -0.309 0.9511 46.5899 13.38)" class="st0 st2 st3">
			z
		</text>
		<text :fill="fillComputed" transform="matrix(0.9511 0.309 -0.309 0.9511 59.0399 26.64)" class="st0 st2 st4">
			z
		</text>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'SleepDisordersCenter',
	mixins: [iconMixin],
}
</script>

<style scoped>
.st1 {
	fill: none;
	stroke-width: 1.75;
	stroke-miterlimit: 10;
}
.st2 {
	font-family: ProximaNova-Bold, Proxima Nova;
	font-weight: 700;
}
.st3 {
	font-size: 14px;
}
.st4 {
	font-size: 10px;
}
</style>
