<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M21.59,39.32a.49.49,0,0,0,0,.11c0,3.32-.09,6.65.05,10a14.22,14.22,0,0,0,3.24,8.68,8.16,8.16,0,0,0,4.91,3.07,7.19,7.19,0,0,0,6.17-1.79A13.11,13.11,0,0,0,40,52.29,17.38,17.38,0,0,0,40.49,48q0-3.34,0-6.7c0-.37-.07-.54-.47-.65a7.86,7.86,0,0,1-5-3.85L26.07,21.32a8.18,8.18,0,0,1,2.78-11.23A8.05,8.05,0,0,1,31.61,9a.88.88,0,0,0,.51-.31,3.07,3.07,0,0,1,4.29-.62l.14.12a3,3,0,0,1,.36,4.27,1.71,1.71,0,0,1-.18.2,3,3,0,0,1-4.3,0l-.15-.16a.51.51,0,0,0-.63-.17,4.93,4.93,0,0,0-3.29,6.15,4.41,4.41,0,0,0,.48,1.09c2.44,4.27,4.92,8.53,7.38,12.8l1.58,2.74a4.92,4.92,0,0,0,6.69,1.92,4.83,4.83,0,0,0,1.92-1.91q4.52-7.78,9-15.58a4.92,4.92,0,0,0-1.77-6.74,4.79,4.79,0,0,0-1-.45.6.6,0,0,0-.77.19,3,3,0,0,1-4.28.24l-.11-.1a3,3,0,0,1-.07-4.28l.14-.14a3,3,0,0,1,4.28.17l.12.14a1.26,1.26,0,0,0,.77.48,8.14,8.14,0,0,1,6.61,8.55,8,8,0,0,1-1.13,3.62L49.3,36.64a8.1,8.1,0,0,1-5.29,4c-.33.08-.27.28-.27.48,0,2.35,0,4.7,0,7a19.43,19.43,0,0,1-2.09,8.88,14.1,14.1,0,0,1-5.12,5.78,10.27,10.27,0,0,1-12.12-.77A16.42,16.42,0,0,1,18.87,53a21.08,21.08,0,0,1-.58-5.13q0-4,0-8a.54.54,0,0,0-.42-.63h0a7.23,7.23,0,1,1,4.1,0l-.36.12"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'FindProvider',
	mixins: [iconMixin],
}
</script>
