<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<circle
			:stroke="fillComputed"
			class="cls-1"
			cx="45.17"
			cy="25.1"
			r="8.49"
			transform="translate(-4.52 39.29) rotate(-45)"
		/>
		<line :stroke="fillComputed" class="cls-2" x1="47.1" y1="57.17" x2="48.91" y2="49.99" />
		<path
			:stroke="fillComputed"
			class="cls-3"
			d="M19.31,33.68C22.48,39.84,28.05,43,33.48,44.8l-2,12.37H56.44l1.4-8.59c.67-6-2.76-9.68-8.64-11L41.46,48.31,37.88,36.25A23.84,23.84,0,0,1,30.47,34"
		/>
		<line :stroke="fillComputed" class="cls-1" x1="41.46" y1="48.31" x2="39.9" y2="57.17" />
		<path
			:stroke="fillComputed"
			class="cls-3"
			d="M44.74,43.77l-.13-3.4a2.22,2.22,0,0,0,.3-.49c.2-.44.9-2.17.9-2.17.07-.49-.29-.74-.78-.8l-3-.39c-.48-.07-.89.08-1,.57,0,0,.13,1.68.25,2.32a2.47,2.47,0,0,0,.22.68L40.18,44"
		/>
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M16.69,19.56l.72-4a.76.76,0,0,1,.87-.65l14.53,1.67a.77.77,0,0,1,.6.91l-3.18,17.8a.77.77,0,0,1-.87.65L15.13,32.75a.76.76,0,0,1-.6-.9l.72-4"
		/>
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M18.52,24.47c.45-2.26-.71-4.5-2.28-5.14a4.1,4.1,0,0,0-1.75-.3c-2.27-.1-2.53,1.53-3,3.91-.32,1.56-.59,3-.14,4a1.66,1.66,0,0,0,.9.84,5.12,5.12,0,0,0,1.61.24A4.37,4.37,0,0,0,18.52,24.47Z"
		/>
		<line :stroke="fillComputed" class="cls-4" x1="27.61" y1="22.06" x2="29.17" y2="22.34" />
		<line :stroke="fillComputed" class="cls-4" x1="21.16" y1="20.92" x2="22.7" y2="21.2" />
		<line :stroke="fillComputed" class="cls-4" x1="27.28" y1="25.41" x2="28.68" y2="25.64" />
		<line :stroke="fillComputed" class="cls-4" x1="20.42" y1="24.18" x2="21.82" y2="24.42" />
		<line :stroke="fillComputed" class="cls-4" x1="26.92" y1="29.08" x2="28.2" y2="29.3" />
		<line :stroke="fillComputed" class="cls-4" x1="19.6" y1="27.78" x2="20.88" y2="28" />
		<path
			:stroke="fillComputed"
			class="cls-5"
			d="M26.6,30.89a21.78,21.78,0,0,1-6-1.07c.27-1.54.41-2.31.68-3.84a21.78,21.78,0,0,0,6,1.07C27,28.58,26.87,29.35,26.6,30.89Z"
		/>
		<path :stroke="fillComputed" class="cls-5" d="M21.53,26.07c.24-1.39.37-2.09.61-3.48a20,20,0,0,0,5.47,1L27,27" />
		<path :stroke="fillComputed" class="cls-5" d="M22.47,22.67l.55-3.1a17.55,17.55,0,0,0,4.87.86l-.55,3.1" />
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Imaging',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5 {
	fill: none;
}
.cls-1 {
	stroke-miterlimit: 10;
}
.cls-1,
.cls-2,
.cls-3 {
	stroke-width: 2px;
}
.cls-2,
.cls-4 {
	stroke-linecap: round;
}
.cls-2,
.cls-3,
.cls-4,
.cls-5 {
	stroke-linejoin: round;
}
.cls-4,
.cls-5 {
	stroke-width: 1.5px;
}
</style>
