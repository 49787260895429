<template>
	<div :style="videoStyle">
		<video ref="videoPlayer" class="video-js vjs-16-9 vjs-theme-sea"></video>
	</div>
</template>

<script>
import videojs from 'video.js'

export default {
	name: 'VideoPlayer',

	props: {
		maxWidth: {
			type: Number,
			default: 0,
		},
		minWidth: {
			type: Number,
			default: 0,
		},
		options: {
			type: Object,
			default() {
				return {}
			},
		},
	},

	data() {
		return {
			player: null,
		}
	},

	computed: {
		// a computed getter
		videoStyle: function() {
			let style = []

			if (this.minWidth) {
				style.push('min-width: ' + this.minWidth + 'px')
			}
			if (this.maxWidth) {
				style.push('max-width: ' + this.maxWidth + 'px')
			}
			// `this` points to the vm instance
			return style.join(';')
		},
	},

	mounted() {
		this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
			// console.log("onPlayerReady", this);
		})
	},

	beforeUnmount() {
		if (this.player) {
			this.player.dispose()
		}
	},
}
</script>
