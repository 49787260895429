<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M36.18,13.57A24.61,24.61,0,1,0,60.79,38.18,24.62,24.62,0,0,0,36.18,13.57Zm0,42.25A17.64,17.64,0,1,1,53.81,38.18,17.64,17.64,0,0,1,36.18,55.82Z"
		/>
		<path
			:fill="fillComputed"
			d="M60.65,18.88a2.61,2.61,0,0,1-.16,3.71,2.71,2.71,0,0,1-2.66.73,26.8,26.8,0,0,0-6.8-6.8,2.69,2.69,0,0,1,.75-2.66,2.63,2.63,0,0,1,3.71-.16l.06.06"
		/>
		<path
			:fill="fillComputed"
			d="M42.35,9.66a2.78,2.78,0,0,1-1.57,2.55s0,.08,0,.13a26.23,26.23,0,0,0-9.24,0c0-.05,0-.09,0-.13A2.78,2.78,0,0,1,30,9.66a2.62,2.62,0,0,1,2.5-2.72h7.33A2.63,2.63,0,0,1,42.35,9.66Z"
		/>
		<path
			:fill="fillComputed"
			d="M29.94,39.68h-.78V34.09c0-.23-.14-.29-.33-.29H27.74c-.19,0-.32.06-.33.29,0,.86-.49,1-.7,1h-.25c-.26,0-.32.11-.32.33v.92c0,.22.06.33.32.33h.95v3h-.95c-.26,0-.32.13-.32.33V41c0,.2.06.33.32.33h3.48c.26,0,.33-.13.33-.33V40C30.27,39.81,30.2,39.68,29.94,39.68Z"
		/>
		<path
			:fill="fillComputed"
			d="M33.4,39.35H32.14c-.19,0-.31.07-.31.33V41c0,.25.12.33.31.33H33.4c.2,0,.32-.08.32-.33V39.68C33.72,39.42,33.6,39.35,33.4,39.35Zm0-3.14H32.14c-.19,0-.31.07-.31.33V37.8c0,.26.12.34.31.34H33.4c.2,0,.32-.08.32-.34V36.54C33.72,36.28,33.6,36.21,33.4,36.21Z"
		/>
		<path
			:fill="fillComputed"
			d="M39.81,39.68H37.43s.82-.71,1.48-1.32A3.09,3.09,0,0,0,40.23,36a2.56,2.56,0,0,0-2.68-2.38,2.49,2.49,0,0,0-2.7,2.68c0,.25.08.34.3.34h1c.24,0,.3-.12.3-.32a1.14,1.14,0,0,1,1.13-1.16c.54,0,1,.25,1,.84s-.7,1.21-1.42,1.84c-1.09.93-2,1.78-2,1.78a.56.56,0,0,0-.21.48V41c0,.25,0,.33.3.33h4.66c.25,0,.32-.13.32-.33V40C40.13,39.81,40.06,39.68,39.81,39.68Z"
		/>
		<path
			:fill="fillComputed"
			d="M43.91,36.43a2.43,2.43,0,0,0-1.15.29l.09-1.33h2.79c.25,0,.33-.13.33-.33v-.93c0-.21-.08-.33-.33-.33h-4c-.25,0-.3.12-.32.33l-.28,3.78c0,.31.08.35.31.35h.92a.4.4,0,0,0,.24,0,1.64,1.64,0,0,1,1.13-.43,1.07,1.07,0,0,1,1.11,1,1,1,0,0,1-1.13,1,1.05,1.05,0,0,1-1.08-.69c0-.19-.09-.22-.27-.22h-1c-.25,0-.3,0-.3.34a2.46,2.46,0,0,0,2.66,2.16,2.6,2.6,0,0,0,2.78-2.52A2.41,2.41,0,0,0,43.91,36.43Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'AthleticTrainers',
	mixins: [iconMixin],
}
</script>
