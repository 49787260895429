<template>
	<div>
		<div class="bg-white shadow overflow-hidden sm:rounded-lg">
			<div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
				<div class="-ml-4 -mt-2 flex items-center justify-end flex-wrap sm:flex-nowrap">
					<div class="ml-4 mt-2 flex-shrink-0">
						<button
							type="button"
							class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
							@click="openSlide"
						>
							<PlusIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
							Add Images
						</button>
					</div>
				</div>
			</div>

			<div class="m-5">
				<div class="mt-5">
					<div class="sm:rounded-md sm:overflow-hidden">
						<div class="bg-white">
							<div class="pt-4">
								<draggable
									v-model="media.images"
									handle=".handle"
									item-key="storage_path"
									@start="drag = true"
									@end="drag = false"
									@change="itemMoved"
								>
									<template #item="{ element }">
										<div class="bg-gray-50 shadow overflow-hidden mb-2">
											<div class="flex items-center px-4 py-4 sm:px-6">
												<div class="min-w-0 flex-1 flex  gap-x-5">
													<div class="flex-shrink-0">
														<img class="max-w-xs" :src="element.url" alt="" />
													</div>
													<div class="flex-1 text-sm text-gray-900 truncate">
														<dl class="flex gap-x-5 mb-2">
															<dt class="font-semibold w-20">
																File Name:
															</dt>
															<dd>
																{{ element.original_filename }}
															</dd>
														</dl>
														<dl class="flex gap-x-5 mb-2">
															<dt class="font-semibold w-20">
																Alt Attribute:
															</dt>
															<dd>
																{{ element.alt_text }}
															</dd>
														</dl>
														<dl class="flex gap-x-5 mb-2">
															<dt class="font-semibold w-20">
																Category:
															</dt>
															<dd>
																{{ element.category.name }}
															</dd>
														</dl>
														<dl class="flex gap-x-5 mb-2">
															<dt class="font-semibold w-20">
																Dimensions:
															</dt>
															<dd>
																{{ element.width }}
																x
																{{ element.height }}
															</dd>
														</dl>
													</div>
												</div>
												<div class="w-32">
													<button
														type="button"
														class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
														@click="confirmDelete(element)"
													>
														<XIcon class="h-5 w-5 text-white" aria-hidden="true" />
													</button>
												</div>
												<div class="max-w-full">
													<SwitchVerticalIcon
														class="h-5 w-5 text-gray-400 handle"
														aria-hidden="true"
													/>
												</div>
											</div>
										</div>
									</template>
								</draggable>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>

		<SlidePanel
			slide-title="Select Image"
			slide-component="MediaImageSelect"
			:open="slideOpen"
			:media-types="['image']"
			:media-categories="['Location']"
			:media-upload-category="'page'"
			:media-upload-accept="['image/png', 'image/jpeg']"
			:media-upload-extensions="['jpg', 'jpeg', 'png']"
			@closeSlide="closeSlide"
			@selectMedia="selectMedia"
		/>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

import ModalsConfirmation from '@/components/Modals/Confirmation'

import draggable from 'vuedraggable'
import {
	SwitchVerticalIcon,
	// SparklesIcon,
	XIcon,
	PlusIcon,
	// PhotographIcon,
} from '@heroicons/vue/solid'

export default {
	components: {
		draggable,
		SwitchVerticalIcon,
		XIcon,
		PlusIcon,
		ModalsConfirmation,
	},
	props: {
		data: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		return {
			api,
			toast,
			route,
		}
	},
	data() {
		return {
			media: {
				images: [],
			},
			dragging: false,
			slideOpen: false,
			confirmationOpen: false,
			confirmationConfig: {},
		}
	},
	beforeMount() {
		this.fetchMedia()
	},
	methods: {
		fetchMedia() {
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.images.all()
				.then(data => {
					console.log(data)
					data.forEach(element => {
						this.media.images.push(element)
					})
				})
				.catch(error => {
					this.toast.error('Error adding Location Image:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		openSlide() {
			this.slideOpen = true
		},

		closeSlide() {
			this.slideOpen = false
		},

		selectMedia(image) {
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.images.store({ image_id: image.id })
				.then(data => {
					this.media.images = []
					data.forEach(element => {
						this.media.images.push(element)
					})
					this.toast.success('Successfully added Location Image')
				})
				.catch(error => {
					this.toast.error('Error adding Location Image:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		checkSort(evt) {
			console.log(evt)
		},

		itemMoved(evt) {
			let ids = []
			this.media.images.forEach(element => {
				ids.push(element.id)
			})
			console.log(ids)
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.images.reorder(ids)
				.then(data => {
					this.media.images = []
					data.forEach(element => {
						this.media.images.push(element)
					})
					this.toast.success('Successfully reordered Location Images')
				})
				.catch(error => {
					this.toast.error('Error reordering Location Images:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		closeModal() {
			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.id
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete(item) {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'deleteImageItem',
				title: 'Delete Image',
				content: 'Are you sure you want to delete this image?',
			}
			this.$refs.confirmation.id = item.id
		},

		deleteImageItem() {
			let id = this.$refs.confirmation.id
			let index = this.media.images.findIndex(x => x.id === id)
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.images.destroy(id)
				.then(data => {
					delete this.$refs.confirmation.id

					this.media.images = []
					data.forEach(element => {
						this.media.images.push(element)
					})

					this.closeModal()
				})
				.catch(error => {
					this.toast.error('Error deleting image:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
