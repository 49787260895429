<template>
	<div class="m-5 mt-2 sm:rounded-md sm:overflow-hidden bg-white">
		<form @submit.prevent="submitForm()">
			<div>
				<div class="grid grid-cols-2 py-4">
					<div class="">
						<FieldsInput
							v-model="form.name"
							:field="{
								type: 'input',
								inputType: 'text',
								label: 'Name',
								model: 'url',
								placeholder: 'Photo Gallery',
							}"
						/>
					</div>
					<div class="px-4 pt-8">
						<SwitchGroup as="div" class="flex items-center justify-between">
							<span class="flex-grow flex flex-col">
								<SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
									Age Restricted
								</SwitchLabel>
								<SwitchDescription as="span" class="text-sm text-gray-500">
									Require user consent to view the media.
								</SwitchDescription>
							</span>
							<Switch
								v-model="form.age_restricted"
								class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								:class="form.age_restricted ? 'bg-green-600' : 'bg-gray-500'"
							>
								<span
									aria-hidden="true"
									class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
									:class="form.age_restricted ? 'translate-x-5' : 'translate-x-0'"
								/>
							</Switch>
						</SwitchGroup>
					</div>
				</div>
				<media-library-collection
					name="cover_image"
					:initial-value="form.media"
					:upload-domain="apiUrl"
					route-prefix="media"
					:validation-rules="{ accept: ['image/jpeg', 'image/png', 'video/mp4'], maxSizeInKB: 100 * 1024 }"
					:vapor-signed-storage-url="apiUrl + '/signed-storage-url'"
					vapor
					:with-credentials="true"
					@change="newMedia => (form.media = newMedia)"
					@has-uploads-in-progress-change="inProgress => (saveEnabled = !inProgress)"
				/>
				<!-- @is-ready-to-submit-change="uploadComplete() -->
			</div>

			<!-- Button Row -->
			<div class="flex justify-end p-3">
				<Button :disabled="!saveEnabled" text="Save" />
			</div>
		</form>
	</div>
</template>
<script>
import { MediaLibraryCollection } from '@spatie/media-library-pro-vue3-collection'
import { Switch, SwitchGroup, SwitchLabel, SwitchDescription } from '@headlessui/vue'
import { computed, onMounted, ref, reactive } from 'vue'
import axios from 'axios'
import getEnv from '@/extensions/env'
import { useToast } from 'vue-toastification'

export default {
	components: { MediaLibraryCollection, Switch, SwitchGroup, SwitchLabel, SwitchDescription },
	props: {
		type: {
			type: String,
			required: true,
		},
		slug: {
			type: String,
			required: true,
		},
		initialGallery: {
			type: Object,
			default: null,
		},
	},
	setup(props) {
		const toast = useToast()
		const apiUrl = ref(getEnv('VUE_APP_API_URL'))
		// console.log('Initial Gallery:', props.initialGallery)
		const form =
			props.initialGallery != null
				? reactive(JSON.parse(JSON.stringify(props.initialGallery)))
				: { name: null, description: null, media: {} }

		// console.log('Form Initial Value:', form)
		const submitForm = async () => {
			// console.log('Submitting', props.type, 'gallery form.')
			// console.log('Form Data:', form)
			saveEnabled.value = false
			try {
				const res = await axios.post(props.type + '/' + props.slug + '/gallery/upload', form)
				if (res.status != 200) throw res
				// console.log('200 returned. Res:', res)
				toast.success('Gallery saved!')
			} catch (err) {
				console.log('Failed to save gallery! Error: ', err.response.status)
				// toast.error('Failed to save gallery!')
			} finally {
				saveEnabled.value = true
			}
		}

		let saveEnabled = ref(false)

		const uploadComplete = () => {
			console.log('upload completed!')
			saveEnabled.value = true
		}
		const toggleAgeRestriction = () => {
			console.log('Toggling age restriction.')
		}

		return {
			form,
			formProcessing: ref(false),
			saveEnabled,
			toggleAgeRestriction,
			submitForm,
			apiUrl,
		}
	},
}
</script>
