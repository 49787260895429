<template>
	<div class="sm:pt-3" :class="[hasColSpan ? field.colSpan : '']">
		<label :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>

		<div class="mt-1 sm:mt-0 flex relative flex rounded-md">
			<div v-if="hasErrors" class="absolute inset-y-0 right-36 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>
			<input
				:value="inputVal ? inputVal.name : ''"
				:type="field.inputType"
				class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 sm:text-sm border-gray-300 focus:outline-none rounded-l-md"
				:class="[hasErrors ? 'border-red-300 text-red-900' : '']"
				:name="field.model"
				:autocomplete="field.model"
				read-only
				:disabled="true"
			/>
			<button
				class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none disabled:opacity-50"
				:disabled="inputVal === null"
				@click="removeComponent"
			>
				<XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
			</button>
			<button
				class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:border-blue-500"
				@click.prevent="openSlide"
			>
				<CollectionIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				<span>Select Component</span>
			</button>
		</div>

		<div v-if="hasErrors" class="mt-2 text-red-500 font-semibold">
			{{ errors.$errors[0].$message }}
		</div>

		<SlidePanel
			slide-title="Select Component"
			slide-component="MediaComponentSelect"
			:open="slideOpen"
			:component="inputVal"
			:test="{ test: '1' }"
			@closeSlide="closeSlide"
			@saveComponent="saveComponent"
		/>
	</div>
</template>

<script>
import { CollectionIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/vue/solid'
import { provide, ref, computed } from 'vue'

export default {
	components: {
		CollectionIcon,
		ExclamationCircleIcon,
		XCircleIcon,
	},
	props: {
		modelValue: {
			type: Object,
			default: function() {
				return {}
			},
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue'],
	setup(props) {
		const component = ref({})

		provide(
			'component',
			computed(() => component.value)
		)

		return {
			component,
		}
	},
	data() {
		return {
			slideOpen: false,
		}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasPrefix: function() {
			return typeof this.field.prefix !== 'undefined'
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				console.log('INPUT:', val)
				this.$emit('update:modelValue', val)
			},
		},
	},
	mounted() {
		if (this.inputVal) {
			this.component = this.inputVal
		}
	},
	methods: {
		openSlide() {
			this.slideOpen = true
		},

		closeSlide() {
			this.slideOpen = false
		},

		removeComponent() {
			this.component = {}
			this.inputVal = null
		},

		saveComponent(component) {
			// console.log(component)
			this.component = component
			this.inputVal = component
		},
	},
}
</script>
