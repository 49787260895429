<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M33.9,64.67c3.5-9.88,8-17.43,16.12-23.74A29,29,0,0,0,38.46,47.6,29.77,29.77,0,0,0,31,59v-.32c.07-1.76.07-3.52.24-5.27A26,26,0,0,1,33.7,44.3a23,23,0,0,1,11.6-11,33.54,33.54,0,0,1,10.1-2.81c2.77-.32,5.56-.42,8.34-.61.11,0,.22,0,.41,0,0,.73.11,1.43.13,2.13.13,7.45-1.26,14.5-5.49,20.79a25.26,25.26,0,0,1-13,9.82,37.45,37.45,0,0,1-10.26,2l-1.59.11"
		/>
		<path
			:fill="fillComputed"
			d="M22.21,8.93c-.93,1.47-1.9,2.88-2.74,4.36a26.72,26.72,0,0,0-3.39,9,16.9,16.9,0,0,0,4.81,15.15,24.94,24.94,0,0,0,7.57,5.36l.63.27c3.89-5.49,6.49-11.32,5.28-18.24a20.65,20.65,0,0,0-4.78-9.65,29.64,29.64,0,0,0-6.77-5.91,6.21,6.21,0,0,0-.61-.32"
		/>
		<path
			:fill="fillComputed"
			d="M25.24,61.24c-1.13-7-4.48-11.44-9.84-15.1a18.11,18.11,0,0,1,7.18,4.32,18.43,18.43,0,0,1,4.56,7.18v-.13c0-1.1,0-2.21-.08-3.31a16.7,16.7,0,0,0-1.46-5.76,14.37,14.37,0,0,0-7.14-7.07,21.23,21.23,0,0,0-6.32-1.88c-1.73-.23-3.48-.33-5.23-.48H6.66c0,.45-.09.9-.1,1.33a21.94,21.94,0,0,0,3.2,13.12,15.88,15.88,0,0,0,8.07,6.32,24,24,0,0,0,6.42,1.37l1,.08"
		/>
	</svg>
</template>
<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'EmployeeRecruitment',
	mixins: [iconMixin],
}
</script>
