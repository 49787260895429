<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M45,38.41l1.07-7h-6.7V26.9c0-1.91.93-3.77,3.93-3.77h3V17.18a39.11,39.11,0,0,0-5.41-.47c-5.51,0-9.12,3.35-9.12,9.4v5.32H25.67v7H31.8V55.29h7.55V38.41Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'FB',
	mixins: [iconMixin],
}
</script>
