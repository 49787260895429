<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M56.28,13.73h-37a4.62,4.62,0,0,0-4.6,4.62l0,41.63,9.25-9.25H56.28a4.65,4.65,0,0,0,4.63-4.63V18.35A4.63,4.63,0,0,0,56.28,13.73ZM23.91,41.48V35.77L39.82,19.85a1.15,1.15,0,0,1,1.62,0l0,0,4.1,4.1a1.15,1.15,0,0,1,0,1.62l0,0L29.62,41.48Zm27.75,0H34.31l4.63-4.63H51.66Z"
		/>
	</svg>
</template>
<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PatientStories',
	mixins: [iconMixin],
}
</script>
