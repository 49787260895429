<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M35.61,42.07c2.27-2.41,4.43-4.72,6.6-7,1.36-1.43,2.93-2,4.47-.42s.81,3.12-.57,4.49c-2.76,2.75-5.47,5.54-8.26,8.25-2.11,2.06-3.47,2.08-5.63.14-1.6-1.44-3.13-3-5.15-4.87V56.31c-6.39,0-12.44,0-18.48-.07-.45,0-1.24-1.06-1.25-1.63-.08-5.61-.17-11.22,0-16.82.12-3.1,2.16-4.94,5.34-5.1,3.71-.17,7.43-.2,11.14,0a6,6,0,0,1,3.31,1.52c2.73,2.37,5.3,4.92,8.44,7.88"
		/>
		<path
			:fill="fillComputed"
			d="M60,52V21.14c-1-.08-1.87-.2-2.74-.2-5.81,0-11.62.07-17.42-.05-2.18-.05-3.14.5-3,2.85.19,2.8,0,5.62,0,8.42a5.45,5.45,0,0,1,0,1.89c-.51,1.24-1.22,2.4-1.84,3.59-.9-1.21-2.48-2.38-2.57-3.65-.33-4.32-.16-8.69-.13-13,0-5.42-.8-5.14,5.08-5.15,8.07,0,16.15.07,24.23,0,2.24,0,3.18.45,3.15,3-.14,11.59-.06,23.18-.07,34.77,0,.79-.08,1.58-.14,2.75H35.65c-1.59,0-3.36.44-3.34-2.23s2-2,3.45-2c7,0,14,0,21,0H60"
		/>
		<path :fill="fillComputed" d="M25.54,23.26a7.6,7.6,0,1,1-15.19.11v-.12a7.59,7.59,0,0,1,15.18,0" />
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'ClassesEvents',
	mixins: [iconMixin],
}
</script>
