<template>
	<div :class="[hasColSpan ? field.colSpan : '', field.label.length > 0 ? 'sm:pt-3' : '']">
		<label v-if="field.label.length > 0" :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0 flex" :class="[disabled ? 'opacity-50 pointer-events-none' : '']">
			<div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>
			<input
				v-model="inputVal"
				type="datetime-local"
				:name="field.model"
				class="flex-1 py-2 px-3 block w-full rounded-l-md focus:border-gray-300 focus:border-gray-300 focus:outline-none min-w-0 sm:text-sm border-gray-300 border h-9 disabled:opacity-50"
				:disabled="disabled"
			/>
			<div class="flex items-center">
				<button
					type="button"
					class=" inline-flex items-center h-9 px-4 py-2 border-t border-b border-r border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none disabled:opacity-50"
					:disabled="disabled"
					@click="clear"
				>
					Clear
				</button>
			</div>

			<!-- <v-date-picker v-model="inputVal" mode="dateTime" :attributes="attrs" :timezone="'America/Chicago'">
				<template #default="{ inputValue, togglePopover }">
					<div class="flex items-center relative">
						<div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
							<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
						</div>
						<button
							class="p-2.5 bg-blue-100 border rounded-l-md border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
							@click="togglePopover()"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4 fill-current">
								<path
									d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
								></path>
							</svg>
						</button>
						<input
							:name="field.model"
							:autocomplete="field.model"
							:value="inputValue"
							class="flex-1 py-2 px-3 block w-full focus:border-gray-300 focus:border-gray-300 focus:outline-none min-w-0 sm:text-sm border-gray-300 border cursor-pointer"
							:class="[hasErrors ? 'border-red-300 text-red-900' : '']"
							readonly
							@click="togglePopover()"
						/>
						<div class="flex items-center">
							<button
								type="button"
								class="-mx-1 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
								@click="clear"
							>
								Clear
							</button>
						</div>
					</div>
				</template>
			</v-date-picker> -->
		</div>

		<div v-if="hasErrors" class="mt-2 text-red-500 font-semibold">
			{{ errors.$errors[0].$message }}
		</div>
	</div>
</template>

<script>
// import LitepieDatepicker from "litepie-datepicker";
import { ExclamationCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		ExclamationCircleIcon,
		// LitepieDatepicker,
	},
	props: {
		modelValue: {
			type: [String, Date],
			default: '',
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			attrs: [
				{
					key: 'today',
					// highlight: true,
					content: 'blue',
					dates: new Date(),
				},
			],
			modelConfig: {
				type: 'string',
				mask: 'YYYY-MM-DD HH:MM:SS', // Uses 'iso' if missing
			},
		}
	},
	beforeMounted() {
		// this.inputVal =
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log("INPUT:", val);
				this.$emit('update:modelValue', val)
			},
		},
	},
	mounted() {
		// var d = new Date();
		// this.inputVal = d.toISOString();
	},
	methods: {
		clear() {
			this.inputVal = null
		},
	},
}
</script>
