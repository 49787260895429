<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" :width="size" :height="size">
		<path
			:fill="fillComputed"
			d="M67.52,23.51c-.59.57-1.18,1.14-1.76,1.72-1.17,1.17-2.33,2.35-3.5,3.53A37.21,37.21,0,0,0,36,18.15,36.7,36.7,0,0,0,9.84,28.83l-5.17-5c.6-.56,1.13-1.07,1.68-1.57a45.35,45.35,0,0,1,6.08-4.6A43.69,43.69,0,0,1,20,13.85a44.88,44.88,0,0,1,47.37,9.51l.11.08Z"
		/>
		<path
			:fill="fillComputed"
			d="M20.49,39.43,15.2,34.3A29.89,29.89,0,0,1,57,34l-5.27,5.27a22.62,22.62,0,0,0-31.24.16Z"
		/>
		<path
			:fill="fillComputed"
			d="M46.5,44.57l-5.28,5.27A7.69,7.69,0,0,0,31,50l-5.33-5.17A15,15,0,0,1,46.5,44.57Z"
		/>
		<path :fill="fillComputed" d="M36.14,63.26a5.06,5.06,0,1,1,5-5A5,5,0,0,1,36.14,63.26Z" />
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Amenities',
	mixins: [iconMixin],
}
</script>
