<template>
	<div>
		<div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
			<div class="sm:col-span-4">
				<label for="formName" class="block text-sm font-medium text-gray-700">
					Form Name
				</label>
				<div class="mt-1 flex rounded-md shadow-sm">
					<input
						type="text"
						name="formName"
						class="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
					/>
				</div>
			</div>
		</div>

		<div class="bg-white border shadow rounded-lg divide-y border divide-gray-200 mt-8">
			<div class="px-4 py-5 sm:p-6">
				<h3 v-if="fields.length === 0" class="text-center text-gray-700">
					Add a field below
				</h3>
				<draggable
					v-if="fields.length !== 0"
					v-model="fields"
					item-key="sort"
					tag="ul"
					handle=".handle"
					class="space-y-3"
					@start="drag = true"
					@end="drag = false"
				>
					<template #item="{ element, index }">
						<FormBuilderField :element="element" :index="index" />
					</template>
				</draggable>
			</div>
			<div class="px-4 py-4 sm:px-6 bg-gray-100">
				<FormBuilderAddField :schema="schema" @submitField="submitField" />
			</div>
		</div>
	</div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
	components: {
		draggable,
	},
	data() {
		return {
			drag: false,
			schema: {
				label: null,
				name: null,
				type: null,
			},
			fields: [
				{
					id: 1,
					label: 'Test Label',
					name: 'test-label',
					type: 'text',
				},
				{
					id: 2,
					label: 'Test Label 2',
					name: 'test-label-2',
					type: 'text',
				},
				{
					id: 3,
					label: 'Test Label 3',
					name: 'test-label-3',
					type: 'text',
				},
			],
		}
	},

	methods: {
		submitField(schema) {
			console.log(schema)
			console.log(this.fields)
		},
	},
}
</script>
