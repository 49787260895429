<template>
	<div class="bg-white border shadow rounded-lg divide-y divide-gray-200">
		<div class="px-4 py-5 sm:p-6">
			<ContentBuilder
				ref="builder"
				parent-endpoint="departments"
				url-prefix="/medical-specialties/"
				:only-section-builder="true"
			/>
		</div>
		<!-- <div class="px-4 py-4 sm:px-6 flex justify-end">
			<button
				type="button"
				class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
				@click.prevent="cancel"
			>
				<XCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
				Reset
			</button>
			<button
				type="button"
				class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
				@click="update"
			>
				<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
				Save
			</button>
		</div> -->
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

// import { SaveIcon, XCircleIcon, PencilAltIcon } from '@heroicons/vue/solid'

export default {
	components: {
		// SaveIcon,
		// XCircleIcon,
		// PencilAltIcon,
	},
	props: {
		data: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['cancelEditing'],
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		const department = inject('department')

		return {
			api,
			toast,
			route,
			department,
		}
	},
	data() {
		return {}
	},
	computed: {},
	mounted() {
		// this.fetchPage()
	},
	methods: {
		// fetchPage() {
		// 	this.loaderShow()

		// 	this.api.departments
		// 		.slug(this.route.params.id)
		// 		.pages.fetch()
		// 		.then(page => {
		// 			if (Object.keys(page).length !== 0) this.page = page
		// 		})
		// 		.then(() => {
		// 			this.loaderHide()
		// 		})
		// },

		cancel() {
			// this.fetchPage()
			this.$emit('cancelEditing')
		},

		processData() {
			let data = JSON.parse(JSON.stringify(this.page))
			// console.log(data)

			data.header_image_id = null
			if (data.header_image !== null) {
				data.header_image_id = data.header_image.id
			}
			delete data.header_image

			if (Object.keys(data.sections).length > 0) {
				data.sections.forEach(section => {
					if (typeof section === 'object') {
						section.columns.forEach((column, index) => {
							if (column.video && typeof column.video.id === '') {
								column.video_id = column.video.id
							} else {
								column.video_id = null
							}

							delete column.video

							if (column.text === '' && column.video_id === null) {
								delete section.columns.splice(index, 1)
							}
						})
					}

					if (section.anchor === '') {
						section.anchor = null
					}

					if (!section.component || Object.keys(section.component).length === 0) {
						section.component = null
					}
				})
			}

			// console.log(data)
			return data
		},

		update() {
			// console.log(this.page)
			// console.log(JSON.stringify(this.page))
			this.loaderShow()

			this.api.departments
				.slug(this.route.params.id)
				.pages.update(this.processData())
				.then(page => {
					this.page = page
					this.toast.success('Successfully updated Department Custom Sections')
				})
				.catch(error => {
					this.toast.error('Error updating Department Custom Sections:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
