import WebsiteAPI from '@springfieldclinic/website-node-api'
import axios from 'axios'

const BaseMutations = {
	SET_CURRENT: 'SET_CURRENT',
}

export default class {
	constructor(endpoint) {
		this.state = {
			endpoint: endpoint,
			current: null,
			currentId: null,
			all: [],
			loaded: false,
		}
		this.getters = {
			//id: state => id => state.known.find(o => o.id === id),
		}
		this.actions = {
			all({ state, commit, dispatch, rootState }) {
				//console.log('loadAll on BaseModule')
				return new Promise((resolve, reject) => {
					this.$app.api.images.categories
						.all()
						.then(categories => {
							commit('STORE_ALL', categories)
							resolve(categories)
						})
						.catch(error => {
							reject(error)
						})
				})
			},
			async fetch({ state, commit }, slug) {
				// console.log('Load on base module. Slug: ', slug)
				const item = await this.$app.api[state.endpoint].fetch(slug)
				if (item) commit(BaseMutations.SET_CURRENT, item)
				return item
			},
			async store(context, payload) {
				//(call to API)
				console.log('store on base module. payload: ', payload)
				const item = await this.$app.api[state.endpoint].store(payload)
				if (item) commit(BaseMutations.SET_CURRENT, item)
				return item
			},
			async update(context, slug, payload) {
				//(call to API)
				console.log('store on base module. payload: ', payload)
				const item = await this.$app.api[state.endpoint].update(payload, slug)
				if (item) commit(BaseMutations.SET_CURRENT, item)
				return item
			},
		}
		this.mutations = {
			/*
			STORE_ALL(state, items) {
				//console.log('STORE_ALL on base module:', items)
				//state.known.push(item)
				state.all = items.slice()
				state.loaded = true
			},
			*/
			[BaseMutations.SET_CURRENT](state, item) {
				// console.debug('[MUTATION-STORE_CURRENT]: ', item)
				state.current = item
				state.loaded = true
			},
			/*...etc*/
		}

		/*
		const capitalize = s => {
			if (typeof s !== 'string') return ''
			return s.charAt(0).toUpperCase() + s.slice(1)
		}
		*/
	}
}
