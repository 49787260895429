<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M6.62,33.13c1.21-2.46,2.46-4.9,3.62-7.38,1.65-3.5,3.22-7,4.83-10.57.57-1.26,1-1.41,2.2-.83l2.56,1.23c1.43.71,1.66,1.36,1,2.92C19.53,21.33,18.24,24.15,17,27s-2.55,5.65-3.84,8.47c-.74,1.61-1.38,1.84-2.93,1C9,35.73,7.81,34.92,6.62,34.17Z"
		/>
		<path
			:fill="fillComputed"
			d="M21.49,22.53c.39-.82.68-1.34.88-1.9a1.14,1.14,0,0,1,1.29-.79c2.16,0,4.32.05,6.48,0a5.66,5.66,0,0,0,2.32-.56,12.72,12.72,0,0,1,10-.7c1.91.59,4,.76,5.92,1.17a1.61,1.61,0,0,1,1,.68c2.49,5.24,4.92,10.52,7.42,15.77a1.94,1.94,0,0,1-.25,1.93,2.79,2.79,0,0,0,0,3.06,4.14,4.14,0,0,1-2.93,6.06c-.65.07-.89.32-1,1A3.55,3.55,0,0,1,49,51.54c-.33,0-.88.31-.91.54-.4,2.69-3.13,4-5.78,2.39a5.94,5.94,0,0,0-.57-.27c-1.54,2.67-3.53,2.71-5.71,1.76-1,1.12-1.82,2.55-3.69,1.82-1.19-.47-1.65-1.44-1.55-3.2-1.13.89-2.18,1.86-3.68.91S25.48,53.21,26,51.58c-2.66,1.59-5.6.56-4.94-3.19a8.85,8.85,0,0,1-2.93.22,3,3,0,0,1-1.93-3.72,3.06,3.06,0,0,1,.4-.81c.52-.79,1.09-1.55,1.67-2.3a1,1,0,0,0,0-1.36l-.09-.09c-.67-.73-1.19-1.59-1.88-2.3S15.2,36.34,16,35.31a6.9,6.9,0,0,0,.45-.85c1,1.37,2,2.65,3,4a2,2,0,0,0,1.89,1c1.88-.08,3,1.05,2.9,3,0,1.14.35,1.53,1.46,1.57a2.52,2.52,0,0,1,2.47,2.07c.27,1.13.71,1.8,2.06,1.73a2.31,2.31,0,0,1,2.32,1.91,3.65,3.65,0,0,0,2.09,2.11,10.13,10.13,0,0,1,1.39,1,7.53,7.53,0,0,0,1.61,1.26,2.54,2.54,0,0,0,1.8-.06,1.07,1.07,0,0,0,.45-1.44,1.05,1.05,0,0,0-.39-.42c-1.37-1.12-2.74-2.24-4.13-3.34-.52-.41-1-.86-.53-1.5s1.1-.4,1.67,0l6.79,5c1.22.89,2.13.87,2.82,0s.47-2.08-.77-3l-7.18-5.08c-.18-.13-.36-.23-.54-.36-.49-.37-.9-.9-.38-1.38a1.56,1.56,0,0,1,1.44,0,27.71,27.71,0,0,1,2.73,2c2.08,1.54,4.15,3.1,6.23,4.63,1.21.9,2,.89,2.69,0s.56-2.09-.6-3l-9.35-7.16a5.08,5.08,0,0,0-.51-.41,1,1,0,0,1-.54-1.26,1,1,0,0,1,.15-.25c.22-.25,1.09-.21,1.48,0a31.22,31.22,0,0,1,2.84,2l7.71,5.68c1.1.81,2.19.82,2.91.06a2.34,2.34,0,0,0,0-3.31.46.46,0,0,0-.07-.07c-.69-.72-1.45-1.36-2.18-2q-6.7-6.1-13.42-12.2a3.28,3.28,0,0,0-5.54,1.67c-.62,2.53-2,4.39-4.61,5.07a4.69,4.69,0,0,1-2.38-.08c-1.49-.44-1.78-1.36-1.24-2.81a9.25,9.25,0,0,0,.78-3.1,10,10,0,0,1,1.53-5.42Z"
		/>
		<path
			:fill="fillComputed"
			d="M60.9,36.73c-1.28.06-1.56-.64-1.88-1.32L53,22.52c-.65-1.4-1.35-2.78-2-4.18s-.41-1.86.83-2.52c.87-.47,1.75-.93,2.64-1.37,1.16-.58,1.81-.41,2.41.77.78,1.53,1.49,3.1,2.22,4.66Q62,26.14,65,32.4c.73,1.56.54,2.1-1,2.87C62.86,35.83,61.73,36.34,60.9,36.73Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PartnershipBenefits',
	mixins: [iconMixin],
}
</script>
