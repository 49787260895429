<template>
	<div class="h-full">
		<div class="flex items-center px-6 pb-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
			<div class="w-full">
				<label for="search" class="sr-only">Search</label>
				<div class="relative">
					<div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
						<SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
					</div>
					<input
						v-model="search"
						name="image-search"
						class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 sm:text-sm"
						placeholder="Search"
						type="search"
						@input="filter"
					/>
				</div>
			</div>
		</div>

		<div class="h-[32rem] overflow-y-scroll">
			<ul role="list" class="p-2 flex flex-wrap gap-x-8 gap-y-8">
				<li
					v-for="item in filteredFiles"
					:key="item.storage_path"
					class="relative flex-initial"
					style="width: 32%"
				>
					<div class="group relative block w-full rounded-lg bg-gray-100 overflow-hidden">
						<img
							:src="item.url"
							alt=""
							class="object-contain h-64 w-full pointer-events-none group-hover:opacity-75"
							loading="lazy"
						/>
						<button
							type="button"
							class="absolute w-full h-full inset-0 focus:outline-none"
							@click.prevent="selectMedia(item)"
						></button>
					</div>
					<div class="flex mt-2 px-2">
						<div class="flex-1 text-sm text-gray-900 truncate">
							<dl class="flex gap-x-5">
								<dt class="font-medium w-20">File Name:</dt>
								<dd>{{ item.original_filename }}</dd>
							</dl>
							<dl class="flex gap-x-5">
								<dt class="font-medium w-20">Alt Attribute:</dt>
								<dd>{{ item.alt_text }}</dd>
							</dl>
							<dl class="flex gap-x-5">
								<dt class="font-medium w-20">Category:</dt>
								<dd>{{ item.category.name }}</dd>
							</dl>
							<dl class="flex gap-x-5">
								<dt class="font-medium w-20">Dimensions:</dt>
								<dd>{{ item.width }} x {{ item.height }}</dd>
							</dl>
							<!-- <dl class="flex gap-x-5">
                <dt class="font-medium w-20">File Size:</dt>
                <dd>{{ item.size }}</dd>
              </dl> -->
						</div>

						<div>
							<button
								type="button"
								class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none"
								@click="editImage(item)"
							>
								<PencilAltIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
								Edit
							</button>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<div v-if="!editing" class="mt-8">
			<div class="pb-5 border-b border-gray-200">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Upload Image
				</h3>
			</div>

			<FieldsFile
				v-model="upload_file"
				:field="{
					type: 'input',
					inputType: 'file',
					label: 'File',
					model: 'file',
					required: true,
					colSpan: 'col-span-6',
				}"
				:percent-complete="progress"
				:show-progress="showProgress"
				:media-upload-accept="mediaUploadAccept"
			/>

			<FieldsSelect v-model="upload_category" :field="categories" />

			<FieldsInput
				v-model="upload_alt"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Alt Text',
					model: 'alt',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<div class="mt-4 flex justify-end">
				<button
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
					:disabled="!canSubmit"
					@click="uploadImage"
				>
					<UploadIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Upload
				</button>
			</div>
		</div>

		<div v-if="editing && file" class="mt-8">
			<div class="pb-5 border-b border-gray-200">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Update Image
					<span class="font-bold">{{ file.name }}</span>
				</h3>
			</div>

			<FieldsSelect v-model="edit.image_category_id" :field="categories" />

			<FieldsInput
				v-model="edit.alt_text"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Alt Text',
					model: 'alt',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<div class="mt-4 flex justify-end">
				<button
					type="button"
					class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none"
					@click="editReset"
				>
					<XCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Cancel
				</button>

				<button
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
					:disabled="!canUpdate"
					@click="updateImage"
				>
					<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Save
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { SearchIcon, PencilAltIcon, XCircleIcon, SaveIcon, UploadIcon } from '@heroicons/vue/solid'

export default {
	components: {
		SearchIcon,
		PencilAltIcon,
		XCircleIcon,
		SaveIcon,
		UploadIcon,
	},
	props: {
		mediaTypes: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaCategories: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaUploadCategory: {
			type: String,
			default: '',
		},
		mediaUploadAccept: {
			type: Array,
			default: function() {
				return ['image/png', 'image/jpeg']
			},
		},
		mediaUploadExtensions: {
			type: Array,
			default: function() {
				return ['jpg', 'jpeg', 'png']
			},
		},
	},
	emits: ['selectMedia'],
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			editing: false,
			search: '',
			files: [],
			filteredFiles: [],
			categories: {
				required: true,
			},
			file: null,
			edit: {},
			progress: 0,
			showProgress: false,
			result: null,
			errors: [],
			upload_file: {},
			upload_category: '',
			upload_alt: '',
		}
	},
	computed: {
		fileSelected: function() {
			return typeof this.file.id !== 'undefined'
		},

		canSubmit: function() {
			return this.upload_file && this.upload_category && this.upload_alt
		},

		canUpdate: function() {
			return this.edit.image_category_id && this.edit.alt_text
		},
	},
	mounted() {
		this.fetchCategories()
		//this.fetchImages()
	},
	methods: {
		filter() {
			if (typeof this.timeout !== 'undefined') {
				clearTimeout(this.timeout)
			}

			this.timeout = setTimeout(() => {
				this.filteredFiles = []

				if (this.search !== '') {
					this.files.forEach(element => {
						if (
							element.original_filename.toLowerCase().includes(this.search) ||
							element.alt_text.toLowerCase().includes(this.search)
						) {
							this.filteredFiles.push(element)
						}
					})
				} else {
					this.filteredFiles = this.files
				}
			}, 200)
		},

		fetchCategories(reload = false) {
			// this.loaderShow()

			this.api.images.categories
				.all()
				.then(data => {
					let categories = []

					data.forEach(element => {
						if (this.mediaCategories.includes(element.name) || this.mediaCategories.length == 0) {
							categories.push({
								value: element.id,
								name: element.name,
							})
						}
					})

					this.categories = {
						type: 'select',
						label: 'Image Category',
						model: 'upload_category',
						options: categories,
						required: true,
						multiSelect: false,
						colSpan: 'col-span-6',
					}

					// Now fetch images if we've gotten this far
					this.fetchImages()
				})
				.catch(error => {
					// console.error('failed to fetch image categories: ', error)
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching image categories:\n' + error.response.data.message)
				})
				.then(() => {
					// this.loaderHide()
				})
		},

		fetchImages() {
			this.files = []
			// this.loaderShow()

			this.api.images
				.all()
				.then(data => {
					// console.log('files: ', data)
					this.files = data

					this.filter()
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching images:\n' + error.response.data.message)
				})
				.then(() => {
					// this.loaderHide()
				})
		},

		selectMedia(file) {
			this.$emit('selectMedia', file)
		},

		editImage(file) {
			this.file = JSON.parse(JSON.stringify(file))
			this.edit = {
				image_category_id: this.file.category.id,
				alt_text: this.file.alt_text,
			}

			console.log(this.file)
			this.editing = true
		},

		uploadImage() {
			if (this.formValid()) {
				this.showProgress = true
				this.loaderShow()

				this.chunk(
					this.upload_file,
					// onProgress
					percent => {
						this.progress = percent
					},
					// onError
					err => {
						console.log(err)
						this.showProgress = false
						this.loaderHide()
					},
					// onSuccess
					res => {
						this.progress = 0
						console.log('data', res)

						this.showProgress = false

						this.api.images
							.store({
								original_filename: res.filename,
								image_category_id: this.upload_category,
								alt_text: this.upload_alt,
								mime_type: res.mime_type,
							})
							.then(data => {
								this.upload_file = null
								this.upload_category = null
								this.upload_alt = null

								// this.fetchImages()
								this.selectMedia(data)

								this.toast.success('Successfully uploaded an image')
							})
							.catch(error => {
								this.toast.error('Error uploading image:\n' + error.response.data.message)
							})
							.then(() => {
								this.loaderHide()
							})
					}
				)
			} else {
				this.toast.error(this.errors.join('\n'))
			}
		},

		formValid() {
			this.errors = []

			if (!this.upload_file) {
				this.errors.push('No file selected.')
			}

			if (!this.upload_category) {
				this.errors.push('No category selected.')
			}

			if (!this.upload_alt) {
				this.errors.push('No title specified.')
			} else if (this.upload_alt.length < 5) {
				this.errors.push('Title must be more than 5 characters.')
			}

			return this.errors.length === 0
		},

		editReset() {
			this.file = null
			this.edit = {}
			this.editing = false
		},

		updateImage() {
			this.loaderShow()

			this.api.images
				.slug(this.file.id)
				.update(this.edit)
				.then(data => {
					this.editReset()
					this.fetchImages()

					this.toast.success('Successfully updated image')
				})
				.catch(error => {
					this.toast.error('Error updating image:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
