<template>
	<div class="h-full">
		<div class="flex items-center px-6 pb-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
			<div class="w-full">
				<label for="search" class="sr-only">Search</label>
				<div class="relative">
					<div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
						<SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
					</div>
					<input
						name="image-search"
						class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 sm:text-sm"
						placeholder="Search"
						autocomplete="image-search"
						type="search"
					/>
				</div>
			</div>
		</div>

		<div class="h-[32rem] overflow-y-scroll">
			<ul role="list" class="p-2 flex flex-wrap gap-x-8 gap-y-8">
				<li
					v-for="video in files"
					:key="video.storage_path"
					class="group flex-initial relative p-1.5 border border-gray-50 hover:bg-gray-100 hover:border-gray-300 rounded-md"
					style="width: 32%"
				>
					<div class="relative block w-full rounded-lg bg-gray-100">
						<video-player :options="video.videoOptions" :min-width="320" class="object-contain w-full" />
					</div>
					<div class="flex mt-2 px-2">
						<div class="flex-1 text-sm text-gray-900 truncate">
							<dl class="flex gap-x-5">
								<dt class="font-medium w-20">File Name:</dt>
								<dd>{{ video.filename }}</dd>
							</dl>
							<dl class="flex gap-x-5">
								<dt class="font-medium w-20">Title:</dt>
								<dd>{{ video.title }}</dd>
							</dl>
							<dl class="flex gap-x-5">
								<dt class="font-medium w-20">Description:</dt>
								<dd>{{ video.description }}</dd>
							</dl>
							<dl class="flex gap-x-5">
								<dt class="font-medium w-20">Category:</dt>
								<dd>{{ video.category.name }}</dd>
							</dl>
							<dl class="flex gap-x-5">
								<dt class="font-medium w-20">Dimensions:</dt>
								<dd>{{ video.width }} x {{ video.height }}</dd>
							</dl>
							<!-- <dl class="flex gap-x-5">
                <dt class="font-medium w-20">File Size:</dt>
                <dd>{{ file.size }}</dd>
              </dl> -->
						</div>

						<div class="flex flex-col">
							<button
								type="button"
								class="relative inline-flex items-center px-4 py-2 border rounded-md border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-100 focus:z-10 focus:outline-none"
								@click.prevent="selectMedia(video)"
							>
								Select Video
							</button>

							<button
								type="button"
								class="relative inline-flex mt-4 items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none"
								@click="editVideo(video)"
							>
								<PencilAltIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
								Edit
							</button>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<div v-if="!editing" class="mt-8">
			<div class="pb-5 border-b border-gray-200">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Upload Video
				</h3>
			</div>

			<FieldsFile
				v-model="upload_file"
				:field="{
					type: 'input',
					inputType: 'file',
					label: 'File',
					model: 'file',
					required: true,
					colSpan: 'col-span-6',
				}"
				:percent-complete="progress"
				:show-progress="showProgress"
				:media-upload-accept="mediaUploadAccept"
			/>

			<FieldsImage
				v-model="upload_thumbnail"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Thumbnail *',
					model: 'upload_thumbnail',
					required: true,
					colSpan: 'col-span-6',
				}"
				:media-types="['image']"
				:media-categories="['Thumbnail']"
				:media-upload-category="'page'"
				:media-upload-accept="['image/png', 'image/jpeg']"
				:media-upload-extensions="['jpg', 'jpeg', 'png']"
			/>

			<FieldsSelect v-model="upload_category" :field="categories" />

			<FieldsInput
				v-model="upload_title"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Title Text',
					model: 'title',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<FieldsInput
				v-model="upload_description"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Description Text',
					model: 'description',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<div class="mt-4 flex justify-end">
				<button
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
					:disabled="!canSubmit"
					@click="uploadVideo"
				>
					<UploadIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Upload
				</button>
			</div>
		</div>

		<div v-if="editing" class="mt-8">
			<div class="pb-5 border-b border-gray-200">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Update Video
					<span class="font-bold">{{ edit.name }}</span>
				</h3>
			</div>

			<FieldsImage
				v-model="edit.thumbnail"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Thumbnail',
					model: 'select_upload_thumbnail',
					required: true,
					colSpan: 'col-span-6',
				}"
				:media-types="['image']"
				:media-categories="['Thumbnail']"
				:media-upload-category="'page'"
				:media-upload-accept="['image/png', 'image/jpeg']"
				:media-upload-extensions="['jpg', 'jpeg', 'png']"
			/>

			<FieldsSelect v-model="edit.video_category_id" :field="categories" />

			<FieldsInput
				v-model="edit.title"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Title Text',
					model: 'title',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<FieldsInput
				v-model="edit.description"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Description Text',
					model: 'description',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<div class="mt-4 flex justify-end">
				<button
					type="button"
					class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none"
					@click="editReset"
				>
					<XCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Cancel
				</button>

				<button
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none"
					@click="updateVideo"
				>
					<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Save
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import VideoPlayer from '@/components/VideoPlayer.vue'
import { SearchIcon, PencilAltIcon, XCircleIcon, SaveIcon, UploadIcon } from '@heroicons/vue/solid'

export default {
	components: {
		VideoPlayer,
		SearchIcon,
		PencilAltIcon,
		XCircleIcon,
		SaveIcon,
		UploadIcon,
	},
	props: {
		mediaTypes: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaCategories: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaUploadCategory: {
			type: String,
			default: '',
		},

		mediaUploadAccept: {
			type: Array,
			default: function() {
				return ['image/png', 'image/jpeg']
			},
		},
		mediaUploadExtensions: {
			type: Array,
			default: function() {
				return ['jpg', 'jpeg', 'png']
			},
		},
	},
	emits: ['selectMedia'],
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			editing: false,
			files: [],
			file: null,
			edit: {},
			progress: 0,
			showProgress: false,
			result: null,
			errors: [],
			filteredFiles: [],
			categories: {
				required: true,
			},
			upload_file: {},
			upload_thumbnail: null,
			upload_category: null,
			upload_title: '',
			upload_description: '',
		}
	},
	computed: {
		fileSelected: function() {
			return typeof this.file.id !== 'undefined'
		},

		canSubmit: function() {
			return (
				this.upload_file &&
				this.upload_thumbnail &&
				this.upload_category &&
				this.upload_title &&
				this.upload_description
			)
		},
	},
	mounted() {
		this.fetchCategories()
		this.fetchVideos()
	},
	methods: {
		fetchCategories(reload = false) {
			// this.loaderShow()

			this.api.videos.categories
				.all()
				.then(data => {
					let categories = []

					data.forEach(element => {
						if (this.mediaCategories.includes(element.name) || this.mediaCategories.length == 0) {
							categories.push({
								value: element.id,
								name: element.name,
							})
						}
					})

					this.categories = {
						type: 'select',
						label: 'Video Category',
						model: 'upload_category',
						options: categories,
						required: true,
						multiSelect: false,
						colSpan: 'col-span-6',
					}
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching image categories:\n' + error.response.data.message)
				})
				.then(() => {
					// this.loaderHide()
				})
		},

		filter() {
			if (typeof this.timeout !== 'undefined') {
				clearTimeout(this.timeout)
			}

			this.timeout = setTimeout(() => {
				this.filteredFiles = []

				if (this.search !== '') {
					this.files.forEach(element => {
						if (
							element.filename.toLowerCase().includes(this.search) ||
							element.title.toLowerCase().includes(this.search) ||
							element.description.toLowerCase().includes(this.search)
						) {
							this.filteredFiles.push(element)
						}
					})
				} else {
					this.filteredFiles = this.files
				}
			}, 200)
		},

		fetchVideos() {
			this.files = []
			// this.loaderShow()

			this.api.videos
				.all()
				.then(data => {
					// console.log('files: ', data)

					data.forEach(element => {
						element.videoOptions = {
							fluid: true,
							autoplay: false,
							controls: true,
							poster: element.thumbnail.url,
							sources: [
								{
									src: element.url,
									type: 'video/mp4',
								},
							],
						}

						this.files.push(element)
					})

					this.files = data

					this.filter()
				})
				.catch(error => {
					this.toast.error('Error fetching images:\n' + error.response.data.message)
				})
				.then(() => {
					// this.loaderHide()
				})
		},

		selectMedia(video) {
			this.$emit('selectMedia', video)
		},

		editVideo(file) {
			this.file = JSON.parse(JSON.stringify(file))
			this.edit = {
				thumbnail: this.file.thumbnail,
				video_category_id: this.file.category.id,
				title: this.file.title,
				description: this.file.description,
			}

			this.editing = true
		},

		uploadVideo() {
			if (this.formValid()) {
				this.showProgress = true
				this.loaderShow()

				this.chunk(
					this.upload_file,
					// onProgress
					percent => {
						this.progress = percent
					},
					// onError
					err => {
						console.log(err)
						this.showProgress = false
						this.loaderHide()
					},
					// onSuccess
					res => {
						this.progress = 0
						console.log('data', res)

						this.showProgress = false

						this.api.videos
							.store({
								original_filename: res.filename,
								image_id: this.upload_thumbnail.id,
								video_category_id: this.upload_category,
								title: this.upload_title,
								description: this.upload_description,
								mime_type: res.mime_type,
							})
							.then(data => {
								this.upload_file = null
								this.upload_thumbnail = null
								this.upload_category = null
								this.upload_title = null
								this.upload_description = null

								this.selectMedia(data)
								// this.fetchVideos()

								this.toast.success('Successfully uploaded a video')
							})
							.catch(error => {
								this.toast.error('Error uploading video:\n' + error.response.data.message)
							})
							.then(() => {
								this.loaderHide()
							})
					}
				)
			} else {
				this.toast.error(this.errors.join('\n'))
			}
		},

		formValid() {
			this.errors = []

			if (!this.upload_file) {
				this.errors.push('No file selected.')
			}

			if (!this.upload_thumbnail) {
				this.errors.push('No thumbnail selected.')
			}

			if (!this.upload_category) {
				this.errors.push('No category selected.')
			}

			if (!this.upload_title) {
				this.errors.push('No title specified.')
			} else if (this.upload_title.length < 5) {
				this.errors.push('Title must be more than 5 characters.')
			}

			if (!this.upload_description) {
				this.errors.push('No description specified.')
			} else if (this.upload_title.length < 5) {
				this.errors.push('Description must be more than 5 characters.')
			}

			return this.errors.length === 0
		},

		editReset() {
			this.file = null
			this.edit = {}
			this.editing = false
		},

		updateVideo() {
			this.loaderShow()

			this.api.videos
				.slug(this.file.id)
				.update({
					image_id: this.edit.thumbnail.id,
					video_category_id: this.edit.video_category_id,
					title: this.edit.title,
					description: this.edit.description,
				})
				.then(data => {
					this.editReset()
					this.fetchVideos()

					this.toast.success('Successfully updated video')
				})
				.catch(error => {
					this.toast.error('Error updating video:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
