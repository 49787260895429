<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			fill="none"
			:stroke="fillComputed"
			d="M51.45,20.38a3,3,0,0,0-3-3H27.35a3,3,0,0,0-3,3v13.2l7.74,7.13,3.82-3.52a3,3,0,0,1,4,0l3.82,3.52,7.75-7.13Z"
		/>
		<path
			fill="none"
			:stroke="fillComputed"
			d="M54,27.62l-2.58-2v8L43.7,40.71l-3.82-3.52a3,3,0,0,0-4,0l-3.82,3.52-7.74-7.13v-8l-2.59,2a6,6,0,0,0-1.93,4.43V49a3,3,0,0,0,3,3H53A3,3,0,0,0,55,51.15,3,3,0,0,0,56,49V32.05A6,6,0,0,0,54,27.62Z"
		/>
		<path fill="none" :stroke="fillComputed" d="M55.51,29.77A6.19,6.19,0,0,0,54,27.62l-2.58-2v7.93Z" />
		<path fill="none" :stroke="fillComputed" d="M21.75,27.62a6.19,6.19,0,0,0-1.48,2.15l4.07,3.76V25.6Z" />
		<path
			fill="none"
			:stroke="fillComputed"
			d="M39.88,37.29a3,3,0,0,0-4,0L20.83,51.22a3,3,0,0,0,2,.78H53a3,3,0,0,0,2-.78Z"
		/>
		<path fill="none" :stroke="fillComputed" d="M40,16.65a3.17,3.17,0,0,0-4.18,0l-.92.71h6Z" />
		<path
			fill="none"
			:stroke="fillComputed"
			d="M38.36,24.87a1.55,1.55,0,0,0-.56-.11,2.53,2.53,0,0,0-2.16,1.68,2.75,2.75,0,0,0-.09,2.1,1.6,1.6,0,0,0,.87.9,1.54,1.54,0,0,0,.56.1,2.54,2.54,0,0,0,2.17-1.68,2.77,2.77,0,0,0,.08-2.09A1.6,1.6,0,0,0,38.36,24.87Z"
		/>
		<path
			fill="none"
			:stroke="fillComputed"
			d="M42.15,22.68a6.32,6.32,0,0,0-4.26-1.55,6,6,0,0,0,0,12.05,8.41,8.41,0,0,0,4.69-1.43s0,0,0,0,0,0,0,0a8.39,8.39,0,0,1-4.67,1.42,6,6,0,0,1,0-12,6.26,6.26,0,0,1,4.24,1.54,5.35,5.35,0,0,1,1.76,4A3.9,3.9,0,0,1,43,29.17a2.33,2.33,0,0,1-1.64,1,1.3,1.3,0,0,1-1-.41,2.19,2.19,0,0,1-.22-1.8c.15-.78.42-2.18.56-2.86,0,0,0,0,0,0s0,0,0,0c-.14.68-.42,2.09-.57,2.87a2.16,2.16,0,0,0,.23,1.81,1.32,1.32,0,0,0,1.06.43,2.4,2.4,0,0,0,1.67-1,4,4,0,0,0,.87-2.47A5.35,5.35,0,0,0,42.15,22.68Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Subscribe',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1 {
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 1.5px;
}
</style>
