<template>
	<svg height="30.667" viewBox="0 0 30.662 30.667" width="30.662" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M343.459,47.122a7.666,7.666,0,1,1,7.666-7.666A7.662,7.662,0,0,1,343.459,47.122Zm17.787,6.39-5.971-5.971a1.437,1.437,0,0,0-1.018-.419h-.976a12.451,12.451,0,1,0-2.156,2.156v.976a1.432,1.432,0,0,0,.419,1.018l5.971,5.972a1.433,1.433,0,0,0,2.03,0l1.695-1.695a1.445,1.445,0,0,0,.006-2.037Z"
			data-name="Trace 86"
			fill="#003764"
			transform="translate(-331.001 -26.999)"
		/>
	</svg>
</template>
