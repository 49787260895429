<template>
	<div class="flex gap-x-2">
		<a
			v-if="params.showEdit"
			:href="link"
			class="items-center p-1 border shadow-sm leading-4 font-medium rounded-md text-yellow-600 border-yellow-600 hover:text-yellow-700 hover:bg-yellow-200 focus:outline-none"
		>
			<PencilAltIcon class="h-5 w-5" aria-hidden="true" />
		</a>

		<button
			v-if="params.showClone"
			type="button"
			class="items-center p-1 border shadow-sm leading-4 font-medium rounded-md text-gray-500 border-gray-500 hover:text-gray-600 hover:bg-gray-200 focus:outline-none"
			@click="clone"
		>
			<DuplicateIcon class="h-5 w-5" aria-hidden="true" />
		</button>

		<button
			v-if="params.showDelete"
			type="button"
			class="items-center p-1 border shadow-sm leading-4 font-medium rounded-md text-red-500 border-red-500 hover:text-red-600 hover:bg-red-200 focus:outline-none"
			@click="confirmDelete"
		>
			<TrashIcon class="h-5 w-5" aria-hidden="true" />
		</button>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>
	</div>
</template>

<script>
import { getCurrentInstance, defineComponent, inject } from 'vue'
// import { useToast } from 'vue-toastification'
import router from '@/router'

import { PencilAltIcon, DuplicateIcon, TrashIcon } from '@heroicons/vue/solid'

import ModalsConfirmation from './Modals/Confirmation'

export default defineComponent({
	name: 'TableActions',
	components: {
		ModalsConfirmation,
		PencilAltIcon,
		DuplicateIcon,
		TrashIcon,
	},
	setup() {
		// const api = inject('api')
		// const toast = useToast()

		return {
			// api,
			// toast,
		}
	},
	data() {
		return {
			confirmationOpen: false,
			confirmationConfig: {},
		}
	},
	computed: {
		link: function() {
			const { href } = router.resolve({
				name: this.params.editRoute,
				params: { id: this.params.data.slug },
			})

			// console.log('href', href)

			return href
		},
	},
	mounted() {
		// console.log(this.params.data)
	},
	methods: {
		// view() {
		// 	if (this.params.newTab) {
		// 		const { href } = router.resolve({
		// 			name: this.params.editRoute,
		// 			params: { id: this.params.data.slug },
		// 		})
		// 		let handle = window.open(href, '_blank')
		// 		handle.blur()
		// 		window.focus()
		// 		// window.open(href, '_blank')
		// 	} else {
		// 		router.push({
		// 			name: this.params.editRoute,
		// 			params: { id: this.params.data.slug },
		// 		})
		// 	}
		// },

		clone() {
			router.push({
				name: this.params.cloneRoute,
				query: { id: this.params.data.slug },
			})
		},

		closeModal() {
			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.slug
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete() {
			if (this.params.deleteCheck) {
				this.params.appApi[this.params.deleteApi]
					.destroyCheck(this.params.data.slug)
					.then(data => {
						if (data.count) {
							this.confirmationOpen = true
							this.confirmationConfig = {
								callback: 'deleteItem',
								title: this.params.deleteTitle,
								content: data.message,
								futureEvents: true,
							}
							this.$refs.confirmation.slug = this.params.data.slug
						} else {
							this.confirmationOpen = true
							this.confirmationConfig = {
								callback: 'deleteItem',
								title: this.params.deleteTitle,
								content: this.params.deleteContent,
							}
							this.$refs.confirmation.slug = this.params.data.slug
						}
					})
					.catch(error => {
						// this.toast.error('Error deleting clinical research:\n' + error.response.data.message)
					})
					.then(() => {})
			} else {
				this.confirmationOpen = true
				this.confirmationConfig = {
					callback: 'deleteItem',
					title: this.params.deleteTitle,
					content: this.params.deleteContent,
				}
				this.$refs.confirmation.slug = this.params.data.slug
			}
		},

		deleteItem() {
			let id = this.$refs.confirmation.slug

			this.params.appApi[this.params.deleteApi]
				.destroy(id)
				.then(data => {
					delete this.$refs.confirmation.slug

					this.closeModal()

					this.params.parent.refreshTable()
				})
				.catch(error => {
					// this.toast.error('Error deleting clinical research:\n' + error.response.data.message)
				})
				.then(() => {})
		},
	},
})
</script>
