<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M43.72,51.55H28.5a9.13,9.13,0,0,1-9.12-9.11V27.21A9.13,9.13,0,0,1,28.5,18.1H43.72a9.12,9.12,0,0,1,9.11,9.11V42.44A9.12,9.12,0,0,1,43.72,51.55ZM28.5,21.1a6.12,6.12,0,0,0-6.12,6.11V42.44a6.12,6.12,0,0,0,6.12,6.11H43.72a6.12,6.12,0,0,0,6.11-6.11V27.21a6.12,6.12,0,0,0-6.11-6.11Z"
		/>
		<path
			:fill="fillComputed"
			d="M36.17,42.35a7.59,7.59,0,1,1,7.51-8.7h0a7.57,7.57,0,0,1-6.4,8.61A6.92,6.92,0,0,1,36.17,42.35Zm0-12.18a5,5,0,0,0-.67,0,4.59,4.59,0,1,0,1.34,9.08,4.59,4.59,0,0,0,0-9.08A5.19,5.19,0,0,0,36.17,30.17Z"
		/>
		<path
			:fill="fillComputed"
			d="M44.48,28a1.49,1.49,0,0,1-1.39-2.08,1.3,1.3,0,0,1,.14-.26,2,2,0,0,1,.18-.23A1.5,1.5,0,0,1,46,26.46a1.51,1.51,0,0,1-.43,1.06,1.54,1.54,0,0,1-.49.32A1.42,1.42,0,0,1,44.48,28Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Instagram',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke: #fff;
	stroke-miterlimit: 10;
}
</style>
