<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M58.53,17.38s0-.07,0-.1h0v0l0-.11h0a.3.3,0,0,0,0-.1v0l0-.09v0a.43.43,0,0,0,0-.07v0h0l-.05-.06,0,0,0-.06,0,0,0,0L49,7.38l0,0-.07-.07h0l-.07-.06h0l-.08,0h0l-.1,0h0l-.11,0h0l-.11,0h0l-.1,0H17.59a1.34,1.34,0,0,0-1.34,1.34V63.67A1.34,1.34,0,0,0,17.59,65h39.6a1.35,1.35,0,0,0,1.35-1.34V17.51S58.53,17.42,58.53,17.38Zm-3.94-1.21H49.36V10.93Zm1.86,47H18.33V8.8H47v8.09a1.39,1.39,0,0,0,1.39,1.39h8.09Z"
		/>
		<path
			:fill="fillComputed"
			d="M28.24,38.81a.9.9,0,0,0-.6.34L25.41,42l-.86-.67a.9.9,0,0,0-.67-.19.85.85,0,0,0-.6.33.9.9,0,0,0-.19.67.93.93,0,0,0,.33.6L25,44a.89.89,0,0,0,.56.2h.11a.9.9,0,0,0,.6-.34l2.8-3.6a.87.87,0,0,0,.18-.66.92.92,0,0,0-.34-.61A.94.94,0,0,0,28.24,38.81Z"
		/>
		<path
			:fill="fillComputed"
			d="M28.24,20.93a.91.91,0,0,0-.6.35l-2.23,2.87-.86-.68a.9.9,0,0,0-.67-.19.89.89,0,0,0-.6.33.9.9,0,0,0-.19.67,1,1,0,0,0,.33.61L25,26.13a.89.89,0,0,0,.56.2h.11a.9.9,0,0,0,.6-.34l2.8-3.59a.91.91,0,0,0-.16-1.27A1,1,0,0,0,28.24,20.93Z"
		/>
		<path
			:fill="fillComputed"
			d="M30.07,23.63a.9.9,0,0,0,.9.9H50.79a.91.91,0,1,0,0-1.81H31A.9.9,0,0,0,30.07,23.63Z"
		/>
		<path
			:fill="fillComputed"
			d="M28.24,29.87a.87.87,0,0,0-.6.35l-2.23,2.87-.86-.68a.89.89,0,0,0-.67-.19.85.85,0,0,0-.6.33.9.9,0,0,0-.19.67.93.93,0,0,0,.33.6L25,35.07a.89.89,0,0,0,.56.2h.11a.9.9,0,0,0,.6-.34l2.8-3.59a.89.89,0,0,0,.18-.67.88.88,0,0,0-.34-.6A.9.9,0,0,0,28.24,29.87Z"
		/>
		<path :fill="fillComputed" d="M50.79,31.66H31a.91.91,0,0,0,0,1.81H50.79a.91.91,0,1,0,0-1.81Z" />
		<path :fill="fillComputed" d="M50.79,40.6H31a.91.91,0,0,0,0,1.81H50.79a.91.91,0,1,0,0-1.81Z" />
		<path
			:fill="fillComputed"
			d="M28.24,47.75a.9.9,0,0,0-.6.34L25.41,51l-.86-.68a1,1,0,0,0-.67-.19.91.91,0,0,0-.79,1,.93.93,0,0,0,.33.6L25,53a.89.89,0,0,0,.56.2h.11a.9.9,0,0,0,.6-.34l2.8-3.6a.87.87,0,0,0,.18-.66.92.92,0,0,0-.34-.61A.89.89,0,0,0,28.24,47.75Z"
		/>
		<path :fill="fillComputed" d="M50.79,49.54H31a.91.91,0,0,0,0,1.81H50.79a.91.91,0,1,0,0-1.81Z" />
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'TermsOfUse',
	mixins: [iconMixin],
}
</script>
