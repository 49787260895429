<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M38.72,32.1a4.23,4.23,0,0,0,3.36,1.16,4.45,4.45,0,0,0,3.16-2.2c1.92-2.89,2.23-7.81,2.24-8a.54.54,0,0,0-.17-.43h0a.5.5,0,0,0-.43-.13c-.22,0-5.2.74-7.79,3.05A4.69,4.69,0,0,0,37.27,29,4.11,4.11,0,0,0,38.72,32.1Zm1.08-5.77c1.82-1.63,5.14-2.41,6.55-2.68-.15,1.43-.64,4.77-2,6.82a3.49,3.49,0,0,1-2.41,1.73,3.18,3.18,0,0,1-2.5-.89A3.09,3.09,0,0,1,38.34,29,3.75,3.75,0,0,1,39.8,26.33Z"
		/>
		<path
			:fill="fillComputed"
			d="M61.69,24.79l-18-16.26a3.74,3.74,0,0,0-5.28.27L24.26,24.48c-5.05,5.59-4.18,12.57-.51,17.81l-8.37,9.26a.55.55,0,0,0,0,.76l5.57,5a.54.54,0,0,0,.76,0l4.51-5h0v0l3.83-4.23c5.59,3.12,12.62,3.28,17.67-2.3L62,30.06A3.73,3.73,0,0,0,61.69,24.79ZM16.53,51.87l3.81-4.21L25.11,52,21.3,56.19Zm12.64-4.38-3.34,3.69-4.77-4.32,3.34-3.69.09.11c.19.24.39.46.59.69s.27.32.42.47c.35.38.72.74,1.1,1.09s.78.68,1.19,1l.52.37.74.52ZM47,45C42.15,50.37,35.32,50,30,46.75c-.34-.2-.67-.42-1-.65l-.45-.32a19.54,19.54,0,0,1-2.43-2.2l-.37-.42c-.26-.31-.51-.61-.75-.92-3.74-4.94-4.82-11.69,0-17L39.24,9.52A2.66,2.66,0,0,1,43,9.33L61,25.58a2.67,2.67,0,0,1,.19,3.76Z"
		/>
		<path
			:fill="fillComputed"
			d="M57.5,27.34,41.6,13a.54.54,0,0,0-.76,0L29.54,25.5a2.06,2.06,0,0,0,.15,2.92L43.32,40.74a2.07,2.07,0,0,0,2.92-.14l11.3-12.5A.54.54,0,0,0,57.5,27.34Zm-12,12.54A1,1,0,0,1,44,40L30.4,27.62a1,1,0,0,1-.07-1.41l10.94-12.1L56.39,27.78Z"
		/>
		<path
			:fill="fillComputed"
			d="M33.36,35.29l-6.57-5.95a.54.54,0,0,0-.76,0,6.4,6.4,0,0,0,.46,9l2.22,2a.54.54,0,0,0,.76,0L33.4,36A.53.53,0,0,0,33.36,35.29Zm-4.33,4L27.2,37.62a5.33,5.33,0,0,1-.71-7.11l5.76,5.21Z"
		/>
		<path
			:fill="fillComputed"
			d="M42.69,43.72l-6.57-5.94a.55.55,0,0,0-.76,0l-3.93,4.36a.53.53,0,0,0,0,.75l2.22,2a6.4,6.4,0,0,0,9-.45A.54.54,0,0,0,42.69,43.72Zm-8.28.42-1.83-1.65,3.22-3.56,5.76,5.21A5.34,5.34,0,0,1,34.41,44.14Z"
		/>
		<path
			:fill="fillComputed"
			d="M22,50.59a1.85,1.85,0,1,0,.13,2.61A1.86,1.86,0,0,0,22,50.59Zm-1.76,1.94a.78.78,0,0,1,1-1.15.79.79,0,0,1,.06,1.1A.78.78,0,0,1,20.26,52.53Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'DiabetesWellnessCenter',
	mixins: [iconMixin],
}
</script>
