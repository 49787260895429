<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M32.08,60.13H41.2a4.88,4.88,0,0,0,4.87-4.88V46.33H55a4.88,4.88,0,0,0,4.87-4.87V32.34A4.88,4.88,0,0,0,55,27.46H46.07V18.54a4.87,4.87,0,0,0-4.87-4.87H32.08a4.88,4.88,0,0,0-4.87,4.87v8.92H18.29a4.89,4.89,0,0,0-4.88,4.88v9.12a4.88,4.88,0,0,0,4.88,4.87h8.92v8.92A4.88,4.88,0,0,0,32.08,60.13ZM18.29,43.67a2.21,2.21,0,0,1-2.22-2.21V32.34a2.21,2.21,0,0,1,2.22-2.22H28.54a1.33,1.33,0,0,0,1.33-1.33V18.54a2.22,2.22,0,0,1,2.21-2.22H41.2a2.23,2.23,0,0,1,2.22,2.22V28.79a1.32,1.32,0,0,0,1.33,1.33H55a2.22,2.22,0,0,1,2.22,2.22v9.12A2.22,2.22,0,0,1,55,43.67H44.75A1.33,1.33,0,0,0,43.42,45V55.25a2.22,2.22,0,0,1-2.22,2.22H32.08a2.21,2.21,0,0,1-2.21-2.22V45a1.34,1.34,0,0,0-1.33-1.33Z"
		/>
		<line class="cls-2" x1="30.88" y1="40.65" x2="23.12" y2="48.4" />
		<line class="cls-2" x1="31.49" y1="41.26" x2="23.73" y2="49.02" />
		<line class="cls-2" x1="31.88" y1="41.64" x2="24.12" y2="49.4" />
		<line class="cls-2" x1="32.74" y1="41.9" x2="24.98" y2="49.66" />
		<path
			:fill="fillComputed"
			d="M16.12,56c1.1-4.07,2.83-5.82,7.11-7.2a11.14,11.14,0,0,0,7.55-7.33c1.77,1.29,4.81.55,7.18-1.85a7.69,7.69,0,0,0,2.42-5.15,3.28,3.28,0,0,0-.93-2.4,3.68,3.68,0,0,0-3.56-.75,8.33,8.33,0,0,0-4,2.35,7.7,7.7,0,0,0-2.43,5.15A3.68,3.68,0,0,0,30,40.69a10.2,10.2,0,0,1-7.09,7.16c-4.62,1.49-6.58,3.48-7.77,7.89a.59.59,0,0,0,0,.13.5.5,0,0,0,.37.48A.52.52,0,0,0,16.12,56ZM32.64,34.37a7.41,7.41,0,0,1,3.48-2.08,2.76,2.76,0,0,1,2.63.49,2.31,2.31,0,0,1,.63,1.7,6.83,6.83,0,0,1-2.13,4.44c-2.07,2.09-4.87,2.82-6.11,1.58a2.29,2.29,0,0,1-.63-1.69A6.76,6.76,0,0,1,32.64,34.37Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'FertilityPreservation',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke: #fff;
	stroke-miterlimit: 10;
}
</style>
