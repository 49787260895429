<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M35.24,61.88c-10.07-4.44-16.43-12.22-20-22.42a47.2,47.2,0,0,1-2.48-18.79c.13-2.14,1.27-3.28,3.58-3.33A31.11,31.11,0,0,0,27.89,15a46,46,0,0,0,5.67-2.92,3.72,3.72,0,0,1,4.38,0,32.41,32.41,0,0,0,17,5.25c2.75.07,3.8,1.18,3.9,3.95.31,8.85-1.22,17.29-5.61,25.07a35.15,35.15,0,0,1-15.59,14.9l-1.31.61Zm-19.43-41c0,.38-.12,1.05-.14,1.72A45,45,0,0,0,17.93,38c3,8.89,8.4,15.89,16.88,20.28a1.84,1.84,0,0,0,1.87,0A31.19,31.19,0,0,0,46.9,50.18c6.84-8.4,9.36-18.15,8.88-28.81a4.36,4.36,0,0,0-.16-.8,1.67,1.67,0,0,0-.3-.08,36.05,36.05,0,0,1-18.9-5.61,1.93,1.93,0,0,0-1.49.12c-3,1.3-5.81,3-8.89,3.92s-6.64,1.32-10.23,2"
		/>
		<path
			:fill="fillComputed"
			d="M32.88,40.38,43.21,29.71c.29-.3.55-.76.89-.82A3,3,0,0,1,46,29a1.36,1.36,0,0,1,.28,1.92.21.21,0,0,1-.06.08,4.64,4.64,0,0,1-.58.68L34.22,43.44c-1.24,1.26-1.84,1.26-3.08,0q-2.76-2.82-5.5-5.65a1.68,1.68,0,0,1-.29-2.33c.58-.82,1.53-.77,2.41.07l5.12,4.86"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'HealthPlans',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke: #fff;
	stroke-miterlimit: 10;
}
</style>
