<template>
	<button
		:type="type"
		:method="method"
		:disabled="disabled"
		:class="[
			`
			px-4
			py-2
			text-sm
			align-middle
			font-medium
			text-white
			border border-transparent
			rounded-md
			active:bg-gray-900
			focus:outline-none focus:border-gray-900 focus:shadow-outline-gray
			disabled:opacity-50 disabled:cursor-not-allowed`,
			'bg-blue-600',
			'hover:bg-blue-700',
		]"
	>
		<div class="my-auto">
			<slot>{{ text }}</slot>
		</div>
	</button>
</template>

<script>
import { computed } from 'vue'
export default {
	props: {
		type: {
			type: String,
			default: 'submit',
		},
		text: {
			require: false,
			type: String,
			default: 'Button',
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false,
		},
		method: {
			require: false,
			type: String,
			default: 'post',
		},
	},
	setup(props, { slots }) {
		return {
			// hasIcon: computed(() => !!slots.icon),
		}
	},
}
</script>
