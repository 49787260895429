<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M39.85,46h0a.32.32,0,0,1-.3-.21L38.83,44a1.1,1.1,0,0,0-.6-.6l-1.79-.71a.34.34,0,0,1-.21-.31.33.33,0,0,1,.21-.31l1.79-.71a1.1,1.1,0,0,0,.6-.6l.72-1.8a.33.33,0,0,1,.3-.2.33.33,0,0,1,.31.2l.72,1.8a1.07,1.07,0,0,0,.6.6l1.79.71a.33.33,0,0,1,.2.31.34.34,0,0,1-.2.31l-1.79.71a1.07,1.07,0,0,0-.6.6l-.72,1.79A.33.33,0,0,1,39.85,46Zm-2.4-3.62,1,.41a1.74,1.74,0,0,1,1,1l.41,1,.41-1a1.77,1.77,0,0,1,1-1l1-.41-1-.41a1.77,1.77,0,0,1-1-1l-.41-1-.41,1a1.74,1.74,0,0,1-1,1Z"
		/>
		<path
			:fill="fillComputed"
			d="M34.26,42.34a.33.33,0,0,1-.31-.21l-.43-1.07a.52.52,0,0,0-.29-.29l-1.07-.43A.33.33,0,0,1,32,40a.32.32,0,0,1,.21-.3l1.07-.43a.52.52,0,0,0,.29-.29L34,37.94a.33.33,0,0,1,.31-.21.32.32,0,0,1,.3.21L35,39a.52.52,0,0,0,.29.29l1.07.43a.32.32,0,0,1,.21.3.33.33,0,0,1-.21.31l-1.07.43a.52.52,0,0,0-.29.29l-.43,1.07A.32.32,0,0,1,34.26,42.34ZM33.17,40l.31.13a1.22,1.22,0,0,1,.65.65l.13.31.12-.31a1.2,1.2,0,0,1,.66-.65l.3-.13-.3-.12a1.2,1.2,0,0,1-.66-.65L34.26,39l-.13.31a1.22,1.22,0,0,1-.65.65Z"
		/>
		<path
			:fill="fillComputed"
			d="M43.14,24.09H31.3a.85.85,0,0,1,0-1.69h11V17.32H32.15v2.54a.85.85,0,0,1-.85.85.84.84,0,0,1-.84-.85V16.48a.84.84,0,0,1,.84-.85H43.14a.84.84,0,0,1,.84.85v6.76A.84.84,0,0,1,43.14,24.09Z"
		/>
		<path
			:fill="fillComputed"
			d="M31.3,15.63H43.14a.84.84,0,0,1,.84.85.83.83,0,0,1-.84.84h-11V22.4H42.29V19.86a.84.84,0,0,1,.85-.84.83.83,0,0,1,.84.84v3.38a.84.84,0,0,1-.84.85H31.3a.85.85,0,0,1-.84-.85V16.48A.85.85,0,0,1,31.3,15.63Z"
		/>
		<path
			:fill="fillComputed"
			d="M48.21,56.22h-22a3.38,3.38,0,0,1-3.38-3.38V29.46A4.22,4.22,0,0,1,25,25.77l5.87-3.27a.85.85,0,0,1,1.15.33A.84.84,0,0,1,31.71,24l-5.87,3.26a2.55,2.55,0,0,0-1.3,2.22V52.84a1.69,1.69,0,0,0,1.69,1.69h22a1.69,1.69,0,0,0,1.69-1.69V29.46a2.55,2.55,0,0,0-1.3-2.22L42.73,24a.84.84,0,0,1-.33-1.15.85.85,0,0,1,1.15-.33l5.87,3.27a4.22,4.22,0,0,1,2.17,3.69V52.84A3.38,3.38,0,0,1,48.21,56.22Z"
		/>
		<path
			:fill="fillComputed"
			d="M47.37,52H27.07a.85.85,0,0,1-.84-.84V34.23a.85.85,0,0,1,1.69,0V50.3h18.6V31.7H27.07a.85.85,0,0,1,0-1.69h20.3a.85.85,0,0,1,.84.84v20.3A.85.85,0,0,1,47.37,52Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Skincare',
	mixins: [iconMixin],
}
</script>
