<template>
	<router-link :to="{ name: route.name }" class="inline-flex items-center text-sc-blue hover:text-sc-blue-hover">
		<ArrowNarrowLeftIcon class="h-5 w-5 mr-2" aria-hidden="true" />
		Go back to {{ route.label }}
	</router-link>
</template>

<script>
import { ArrowNarrowLeftIcon } from '@heroicons/vue/solid'

export default {
	components: {
		ArrowNarrowLeftIcon,
	},
	props: {
		route: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
}
</script>
