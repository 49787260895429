<template>
	<div class="absolute top-0 w-full h-full z-50 flex justify-center items-center backdrop-filter backdrop-blur-sm">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			style="margin: auto; background: none; display: block; shape-rendering: crispedges;"
			width="211px"
			height="211px"
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
		>
			<rect x="17.5" y="21.5" width="15" height="57" fill="#003865">
				<animate
					attributeName="y"
					repeatCount="indefinite"
					dur="1s"
					calcMode="spline"
					keyTimes="0;0.5;1"
					values="4.399999999999999;21.5;21.5"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
					begin="-0.2s"
				></animate>
				<animate
					attributeName="height"
					repeatCount="indefinite"
					dur="1s"
					calcMode="spline"
					keyTimes="0;0.5;1"
					values="91.2;57;57"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
					begin="-0.2s"
				></animate>
			</rect>
			<rect x="42.5" y="21.5" width="15" height="57" fill="#00897b">
				<animate
					attributeName="y"
					repeatCount="indefinite"
					dur="1s"
					calcMode="spline"
					keyTimes="0;0.5;1"
					values="8.674999999999997;21.5;21.5"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
					begin="-0.1s"
				></animate>
				<animate
					attributeName="height"
					repeatCount="indefinite"
					dur="1s"
					calcMode="spline"
					keyTimes="0;0.5;1"
					values="82.65;57;57"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
					begin="-0.1s"
				></animate>
			</rect>
			<rect x="67.5" y="21.5" width="15" height="57" fill="#ffbf3f">
				<animate
					attributeName="y"
					repeatCount="indefinite"
					dur="1s"
					calcMode="spline"
					keyTimes="0;0.5;1"
					values="8.674999999999997;21.5;21.5"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
				></animate>
				<animate
					attributeName="height"
					repeatCount="indefinite"
					dur="1s"
					calcMode="spline"
					keyTimes="0;0.5;1"
					values="82.65;57;57"
					keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
				></animate>
			</rect>
		</svg>
	</div>
</template>
