<template>
	<div class="flex-row">
		<div class="mb-8">
			<div class="block">
				<div class="border-b border-gray-200">
					<nav class="-mb-px flex space-x-8" aria-label="Tabs">
						<button
							:class="[
								!external
									? 'border-blue-500 text-blue-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
							]"
							:aria-current="!external ? 'page' : undefined"
							@click.prevent="external = false"
						>
							Internal Link
						</button>
						<button
							:class="[
								external
									? 'border-blue-500 text-blue-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
							]"
							:aria-current="external ? 'page' : undefined"
							@click.prevent="external = true"
						>
							External Link
						</button>
					</nav>
				</div>
			</div>
		</div>

		<div>
			<FieldsInput
				v-model="title"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Title',
					model: 'title',
					required: true,
					colSpan: 'col-span-6',
				}"
			/>
		</div>

		<div>
			<FieldsIconPicker
				v-model="icon"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Icon',
					model: 'icon',
					required: true,
					colSpan: 'col-span-6',
				}"
			/>
		</div>

		<div v-if="!external" class="sm:pt-3 col-span-6">
			<div>
				<label for="page" class="block text-sm font-bold text-gray-700 pb-1">
					Page
					<span>*</span>
				</label>

				<div class="mt-1 sm:mt-0 relative">
					<div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
					</div>
					<Multiselect
						v-model="value"
						placeholder="Search for internal page"
						:filter-results="true"
						:min-chars="1"
						:resolve-on-load="true"
						:loading="false"
						:delay="500"
						:searchable="true"
						:options="pageOptions"
					/>
				</div>
			</div>
		</div>

		<div v-if="external" class="sm:pt-3 col-span-6">
			<div>
				<label for="site_url" class="block text-sm font-bold text-gray-700 pb-1">
					URL
					<span>*</span>
				</label>
			</div>

			<div class="mt-1 sm:mt-0 relative">
				<div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
					<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
				</div>
				<input
					v-model="value"
					type="url"
					class="block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 sm:text-sm border-gray-300 rounded-md"
					:class="[hasErrors ? 'border-red-300 text-red-900' : '']"
					name="site_url"
					autocomplete="site_url"
				/>
			</div>

			<template v-if="hasErrors">
				<div v-for="error of errors.$errors" :key="error.$uid" class="mt-2 text-red-500">
					{{ error.$message }}
				</div>
			</template>
		</div>

		<div class="mt-8 p-5 flex justify-end">
			<button
				type="button"
				class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600"
				@click.prevent="selectButtonLink"
			>
				Select Button Link
			</button>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import Multiselect from '@vueform/multiselect'
import { ExclamationCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		Multiselect,
		ExclamationCircleIcon,
	},
	props: {
		menuButton: {
			type: [Object, null],
			default: null,
		},
	},
	emits: ['selectButtonLink'],
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			value: '',
			title: '',
			external: false,
			icon: '',
			errors: [],
			pageOptions: [],
		}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.length > 0
		},
	},
	mounted() {
		setTimeout(() => {
			this.fetchPages()
		}, 100)
	},
	methods: {
		selectButtonLink() {
			let buttonLink = {
				icon: this.icon,
				title: this.title,
				external: this.external,
			}

			if (this.external) {
				buttonLink.href = this.value
			} else {
				buttonLink.content_page_id = this.value
			}

			if (this.menuButton) {
				buttonLink.id = this.menuButton.id
			}

			// console.log(buttonLink);
			this.$emit('selectButtonLink', buttonLink)
		},

		fetchPages() {
			this.loaderShow()

			this.api.pages
				.internal()
				.then(data => {
					// console.log('data', data)
					this.pageOptions = data.map(item => {
						return {
							value: item.id,
							label: '(' + item.path + ') ' + item.display_title,
						}
					})

					if (this.menuButton) {
						// console.log('this.menuButton', this.menuButton)
						this.title = this.menuButton.title
						this.icon = this.menuButton.icon

						if (this.menuButton.content_page_id) {
							// console.log('this.menuButton.content_page_id', this.menuButton.content_page_id)
							this.external = false
							this.value = this.menuButton.content_page_id

							// console.log('this.value', this.value)
						} else if (this.menuButton.href || this.menuButton.external) {
							this.external = true
							this.value = this.menuButton.href || this.menuButton.external
						}
					}
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching pages:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		async searchPages(query) {
			const data = await this.api.urls.internal()
			//const data = []
			console.log('Internal Urls: ', data)
			return data.map(item => {
				return {
					value: item.to,
					label: item.title,
				}
			})
		},
	},
}
</script>

<style>
.icon {
	min-width: 72px;
}
</style>
