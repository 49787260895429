<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<circle
			:fill="fillComputed"
			cx="36"
			cy="28.85"
			r="14.47"
			transform="matrix(0.99, -0.16, 0.16, 0.99, -4.16, 6.14)"
		/>
		<circle
			:stroke="fillComputed"
			class="cls-2"
			cx="36"
			cy="28.85"
			r="12.66"
			transform="translate(-4.16 6.14) rotate(-9.22)"
		/>
		<path
			id="Path_1"
			data-name="Path 1"
			:fill="fillComputed"
			d="M33.24,50.1V42.76c0-.67,1.17-1.24,2.69-1.25s2.59.43,2.8,1a.69.69,0,0,1,0,.2v14.7c0,.68-1.24,1.22-2.76,1.22-1.31,0-2.44-.41-2.7-1a.47.47,0,0,1,0-.26C33.24,55,33.24,52.54,33.24,50.1Z"
		/>
		<path
			id="Path_1-2"
			data-name="Path 1"
			:fill="fillComputed"
			d="M36.1,47.33h7.34c.68,0,1.24,1.17,1.26,2.7,0,1.38-.44,2.58-1.05,2.79a.64.64,0,0,1-.2,0H28.76c-.68,0-1.23-1.24-1.23-2.76,0-1.31.41-2.44,1-2.7a.48.48,0,0,1,.26,0Z"
		/>
		<path
			id="Path_1-3"
			data-name="Path 1"
			:fill="fillComputed"
			d="M30.57,28.86V22.58c0-.58.78-1.06,1.79-1.07s1.72.37,1.86.89a1.1,1.1,0,0,1,0,.18V35.14c0,.58-.82,1-1.84,1a1.86,1.86,0,0,1-1.79-.84.56.56,0,0,1,0-.22Q30.57,32,30.57,28.86Z"
		/>
		<path
			id="Path_1-4"
			data-name="Path 1"
			:fill="fillComputed"
			d="M37.93,28.86V22.58c0-.58.78-1.06,1.79-1.07s1.71.37,1.85.89a1.13,1.13,0,0,1,0,.18V35.14c0,.58-.83,1-1.84,1A1.86,1.86,0,0,1,38,35.35a.57.57,0,0,1,0-.22Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'MenopauseLivingWell',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke-miterlimit: 10;
}
</style>
