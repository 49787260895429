F
<template>
	<div v-if="draft && page && original">
		<div v-if="!onlySectionBuilder" class="md:flex md:items-center md:justify-between my-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					<span v-if="!editing && !cloneId">New {{ pageType }}:</span>
					<span v-if="!editing && cloneId">Cloning {{ pageType }}:</span>
					<span v-if="editing">Editing {{ pageType }}:</span>
					<span class="ml-4 text-blue-500">
						{{ draft.title }}
					</span>
				</h2>
			</div>
			<div v-if="page_id && !isEvent" class="ml-4 mt-2 flex gap-x-5 flex-shrink-0">
				<a
					:href="previewUrl + draft.path + '?preview=' + draft.preview_key"
					target="_blank"
					class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none"
				>
					<ExternalLinkIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Preview Draft
				</a>

				<a
					:href="previewUrl + draft.path"
					target="_blank"
					class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none"
					:class="[page.live ? '' : 'opacity-50 pointer-events-none']"
				>
					<ExternalLinkIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					View Page
				</a>
			</div>
		</div>

		<div
			class="bg-white "
			:class="{
				'border shadow rounded-lg mb-6 sm:mb-5': !onlySectionBuilder,
			}"
		>
			<div v-if="!onlySectionBuilder" class="flex" :class="disabled ? 'disabled opacity-50' : ''">
				<div class="flex-1">
					<ContentBuilderPublish
						v-if="page_id && !hidePublish"
						:draft="draft"
						:page="page"
						:dirty-data="dirtyData"
						:include-publish="includePublish"
						:disabled="errors.length != 0"
						@publish="publish"
						@unpublish="unpublish"
						@schedule="schedule"
						@unschedule="unschedule"
					/>

					<div class="px-4 py-5 sm:p-6">
						<div v-if="!onlySectionBuilder" class="border-b pb-2 mb-1">
							<h3 class="text-lg leading-6 font-semibold text-gray-700">
								Page Details
							</h3>
							<p class="mt-1 max-w-2xl text-sm text-gray-500">
								This information will be the content of the page.
							</p>
						</div>

						<div class="">
							<FieldsUrl
								v-if="!onlySectionBuilder"
								v-model="draft.slug"
								:field="{
									type: 'input',
									inputType: 'text',
									label: 'URL',
									model: 'slug',
									prefix: draft.url_prefix || urlPrefix,
									restrictUrlPrefix: restrictUrlPrefix,
									required: true,
									min: 3,
									enableCopy: true,
									copyWithPrefix: true,
									kebabCase: true,
									colSpan: 'col-span-6',
								}"
								:disabled="disabled"
								@input="makeFullPath"
								@updateUrlPrefix="updateUrlPrefix"
							/>

							<FieldsInput
								v-if="!onlySectionBuilder"
								v-model="draft.title"
								:field="{
									type: 'input',
									inputType: 'text',
									label: 'Header Title',
									model: 'title',
									required: true,
									min: 5,
									wordcount: true,
									colSpan: 'col-span-6',
								}"
								:disabled="disabled"
								@invalidField="invalidField"
								@validField="validField"
							/>

							<FieldsImage
								v-if="!onlySectionBuilder"
								v-model="draft.header_image"
								:field="{
									type: 'input',
									inputType: 'text',
									label: 'Header Image',
									model: 'header_image',
									required: true,
									colSpan: 'col-span-6',
								}"
								:disabled="disabled"
								:media-types="['image']"
								:media-categories="['Provider', 'Location', 'Department', 'Service', 'Event']"
								:media-upload-category="'Service'"
								:media-upload-accept="['image/png', 'image/jpeg']"
								:media-upload-extensions="['jpg', 'jpeg', 'png']"
								@invalidField="invalidField"
								@validField="validField"
							/>
						</div>
					</div>
				</div>

				<ContentBuilderSeo
					v-if="includeSeo"
					:seo="draft.seo"
					class="flex-1"
					@invalidField="invalidField"
					@validField="validField"
				/>
			</div>

			<div class="px-6 pb-5" :class="disabled ? 'disabled opacity-50' : ''">
				<div class="space-y-8 sm:space-y-5">
					<div>
						<div v-if="!onlySectionBuilder" class="border-b pb-2 mb-5">
							<h3 class="text-lg leading-6 font-semibold text-gray-700">
								Page Content
							</h3>
							<p class="mt-1 max-w-2xl text-sm text-gray-500">
								This information will be the content of the page.
							</p>
						</div>

						<div class="my-6 sm:my-5 grid grid-cols-1 sm:grid-cols-6 gap-x-5">
							<FieldsMultiSelect
								v-if="includeKeywords"
								v-model="draft.keywords"
								:field="{
									type: 'select',
									label: pageType + ' Keywords',
									model: 'page_keywords',
									options: keywordOptions,
									required: true,
									mode: 'tags',
									colSpan: 'col-span-6',
								}"
								:create-tag="true"
								:disabled="disabled"
								@storeTag="storeTag"
							/>

							<FieldsTextarea
								v-if="includeTeaserText"
								v-model="draft.teaser"
								:field="{
									label: pageType + ' Teaser Text',
									model: 'text',
									required: true,
									colSpan: 'col-span-6',
								}"
								:disabled="disabled"
							/>

							<!-- <FieldsTipTapEditor
								v-if="includeDetailsText"
								v-model="draft.details_text"
								:field="{
									label: pageType + ' Details Text',
									model: 'text',
									required: true,
									colSpan: 'col-span-6',
								}"
								:disabled="disabled"
							/> -->

							<div
								v-if="includeBodyText"
								class="col-span-6"
								:class="disabled ? 'disabled opacity-50' : ''"
							>
								<div class="space-y-8 sm:space-y-5">
									<div>
										<FieldsTipTapEditor
											v-model="draft.body"
											:field="{
												label:
													pageType + (includeDetailsText ? ' Details Text' : ' Content Body'),
												model: 'body',
												colSpan: 'col-span-6',
											}"
										/>
									</div>
								</div>
							</div>

							<div v-if="isEvent" class="col-span-6">
								<div class="">
									<div class="flex gap-x-8 items-center border-b pb-2 mt-5 mb-3">
										<div>
											<h3 class="text-lg leading-6 font-semibold text-gray-700">
												Registration Configuration
											</h3>
											<p class="mt-1 text-sm text-gray-500">
												This information will be used to enable event registration.
											</p>
										</div>
										<div>
											<SwitchGroup as="div" class="flex items-center">
												<Switch
													v-model="draft.registration_enabled"
													:class="[
														draft.registration_enabled ? 'bg-blue-600' : 'bg-gray-200',
														'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
													]"
												>
													<span
														aria-hidden="true"
														:class="[
															draft.registration_enabled
																? 'translate-x-5'
																: 'translate-x-0',
															'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
														]"
													/>
												</Switch>
												<SwitchLabel as="span" class="ml-3">
													<span class="text-sm font-medium text-gray-900">
														Enabled
													</span>
												</SwitchLabel>
											</SwitchGroup>
										</div>
									</div>

									<div v-if="draft.registration_enabled">
										<FieldsTipTapEditor
											v-model="draft.registration_info"
											:field="{
												label: 'Event Registration Text',
												model: 'registration_info',
												colSpan: 'col-span-6',
											}"
											data-type="registration_info"
										/>

										<FieldsForm
											v-model="draft.form_id"
											:field="{
												type: 'input',
												inputType: 'text',
												model: 'form_id',
												label: 'Event Registration Form',
												colSpan: 'col-span-6',
												required: true,
											}"
											@invalidField="invalidField"
											@validField="validField"
										/>

										<FieldsInputNumber
											v-model="draft.max_registrations"
											:field="{
												type: 'input',
												inputType: 'number',
												label: 'Max Submissions',
												model: 'max_registrations',
												required: true,
												min: 0,
												max: 10000,
												step: 1,
												colSpan: 'col-span-6',
											}"
										/>

										<FieldsInput
											v-model="draft.confirmation_text"
											:field="{
												type: 'input',
												inputType: 'text',
												label: 'Registration Confirmation Message',
												model: 'confirmation_text',
												required: true,
												colSpan: 'col-span-6',
											}"
										/>

										<FieldsUser
											v-model="draft.user_id"
											:field="{
												label: 'Send Reporting Emails To',
												model: 'user_id',
												colSpan: 'col-span-6',
											}"
										/>

										<!-- <div class="border-b pb-2 mt-10 mb-3">
											<h3 class="text-lg leading-6 font-semibold text-gray-700">
												Registration Email Configuration
											</h3>
											<p class="mt-1 max-w-2xl text-sm text-gray-500">
												This information will be used to send email notifications on each registration.
											</p>
										</div> -->

										<div class="grid grid-cols-2 gap-x-8">
											<div class="col-span-1">
												<div class="border-b pb-2 mt-5 mb-3">
													<h3 class="text-lg leading-6 font-semibold text-gray-700">
														Confirmation Email
													</h3>
													<p class="mt-1 text-sm text-gray-500">
														This information will be used to send an event registration
														confirmation email to the user.
													</p>
												</div>

												<input
													v-if="draft.confirmation_email_id"
													type="hidden"
													name="confirmation_email_id"
													:value="draft.confirmation_email_id"
												/>

												<FieldsEmailTemplate
													v-model="draft.confirmation_email_template_id"
													:field="{
														label: 'Email Template',
														model: 'confirmation_email_template_id',
														colSpan: 'col-span-6',
														required: true,
														type: 'Confirmation',
														category: 'Events',
													}"
												/>

												<!-- <FieldsInput
													v-model="draft.confirmation_email_subject"
													:field="{
														type: 'input',
														inputType: 'text',
														label: 'Email Subject',
														model: 'confirmation_email_subject',
														required: true,
														colSpan: 'col-span-6',
													}"
												/> -->

												<!-- <FieldsInput
													v-model="draft.confirmation_email_message"
													:field="{
														type: 'input',
														inputType: 'text',
														label: 'Email Message',
														model: 'confirmation_email_message',
														required: true,
														colSpan: 'col-span-6',
													}"
												/> -->
											</div>

											<div class="col-span-1">
												<div class="border-b pb-2 mt-5 mb-3">
													<h3 class="text-lg leading-6 font-semibold text-gray-700">
														Reporting Email
													</h3>
													<p class="mt-1 text-sm text-gray-500">
														This information will be used to send an event registration
														reporting email to the event coordinator.
													</p>
												</div>

												<input
													v-if="draft.reporting_email_id"
													type="hidden"
													name="reporting_email_id"
													:value="draft.reporting_email_id"
												/>

												<FieldsEmailTemplate
													v-model="draft.reporting_email_template_id"
													:field="{
														label: 'Email Template',
														model: 'reporting_email_template_id',
														colSpan: 'col-span-6',
														required: true,
														type: 'Reporting',
														category: 'Events',
													}"
												/>

												<!-- <FieldsInput
													v-model="draft.reporting_email_subject"
													:field="{
														type: 'input',
														inputType: 'text',
														label: 'Email Subject',
														model: 'reporting_email_subject',
														required: true,
														colSpan: 'col-span-6',
													}"
												/> -->

												<!-- <FieldsInput
													v-model="draft.reporting_email_message"
													:field="{
														type: 'input',
														inputType: 'text',
														label: 'Email Message',
														model: 'reporting_email_message',
														required: true,
														colSpan: 'col-span-6',
													}"
												/> -->
											</div>
										</div>
									</div>
								</div>

								<div class="border-b pb-2 mt-10 mb-3">
									<h3 class="text-lg leading-6 font-semibold text-gray-700">
										Default Event Configuration
									</h3>
									<p class="mt-1 max-w-2xl text-sm text-gray-500">
										This information will be used as default configuration when scheduling events.
									</p>
								</div>

								<div class="grid grid-cols-6 gap-x-8">
									<FieldsInput
										v-show="!draft.location_id && !draft.location"
										v-model="draft.virtual_link"
										:field="{
											type: 'input',
											inputType: 'text',
											label: 'Virtual Link',
											model: 'virtual_link',
											required: true,
											colSpan: 'col-span-6',
										}"
									/>

									<FieldsTipTapEditor
										v-show="draft.virtual_link"
										v-model="draft.virtual_info"
										:field="{
											label: 'Virtual Event Text',
											model: 'virtual_info',
											colSpan: 'col-span-6',
										}"
									/>

									<FieldsLocation
										v-show="!draft.virtual_link && !draft.location"
										v-model="draft.location_id"
										:field="{
											type: 'select',
											label: 'Springfield Clinic Location',
											model: 'location_id',
											required: true,
											multiSelect: false,
											showMap: true,
											clearButton: true,
											colSpan: 'col-span-6',
										}"
									/>

									<FieldsPlacesAutoComplete
										v-show="!draft.virtual_link && !draft.location_id"
										v-model="draft.location"
										:field="{
											label: 'Non-Springfield Clinic Location',
											model: 'location_virtual',
											colSpan: 'col-span-6',
											required: true,
											showMap: true,
											clearButton: true,
										}"
									/>
								</div>
							</div>

							<div
								v-if="!hideMenu"
								:class="[
									!onlySectionBuilder
										? 'mt-8 col-span-6 border-2 border-dashed border-yellow-200 rounded-md'
										: 'col-span-6 border-2 border-dashed border-yellow-200 rounded-md',
									'relative pt-4',
								]"
							>
								<div
									class="absolute z-10 -top-4 left-1/2 transform -translate-x-1/2 text-lg font-medium px-3 bg-white rounded-md border-2 border-dashed border-yellow-200"
								>
									Menu
								</div>
								<!-- <div class="sm:pt-3 flex items-center justify-between border-b p-5 bg-gray-50">
									<span class="flex-grow flex flex-col">
										<span class="block text-sm font-bold text-gray-700 pb-1">Menu Items</span>
										<span class="text-sm text-gray-500">
											Display a menu of buttons under the page header.
										</span>
									</span>
								</div> -->

								<div class="w-full">
									<div
										v-show="draft.menu.length === 0"
										class="flex justify-center mt-5 text-gray-600"
									>
										<span class="text-lg">No Menu Items</span>
									</div>

									<draggable
										v-model="draft.menu"
										handle=".handle"
										item-key="title"
										tag="ul"
										class="space-y-3 p-5"
										@start="drag = true"
										@end="drag = false"
									>
										<template #item="{ element, index }">
											<li
												v-if="element"
												class="bg-white shadow-sm overflow-hidden rounded-md border px-4 py-2"
											>
												<div class="flex">
													<div class="flex-grow">
														<div class="flex">
															<div
																v-if="element.icon"
																class="rounded bg-sc-blue p-1 mr-6 inline-flex"
															>
																<component
																	:is="element.icon"
																	:key="index"
																	color="#ffffff"
																	size="32"
																	class="m-auto"
																></component>
															</div>
															<div
																class="col-span-10 flex-1 text-xl font-semibold text-sc-blue pt-2"
															>
																{{ element.title }}
															</div>
														</div>
													</div>
													<div
														v-if="!element.section_ref"
														class="group flex-none pr-6 flex gap-x-5 justify-end items-center"
													>
														<button
															type="button"
															class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-yellow-200 text-yellow-500 hover:bg-yellow-600 focus:outline-none"
															@click.prevent="editMenuItem(element, index)"
														>
															<PencilAltIcon class="h-5 w-5" aria-hidden="true" />
														</button>

														<button
															type="button"
															class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-red-100 text-red-400 hover:bg-red-600 focus:outline-none"
															@click.prevent="removeMenuItem(index)"
														>
															<XIcon class="h-5 w-5" aria-hidden="true" />
														</button>
													</div>
													<div class="flex-none pl-6 flex justify-end items-center">
														<SwitchVerticalIcon
															class="h-5 w-5 text-gray-400 handle"
															aria-hidden="true"
														/>
													</div>
												</div>
											</li>
										</template>
									</draggable>

									<div class="bg-yellow-50 border-t border-yellow-200 px-5 py-3 flex justify-end">
										<button
											type="button"
											class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none"
											@click.prevent="slideOpen = true"
										>
											<PlusCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
											Add Menu Button
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="!noSectionBuilder" class="px-4 py-5 sm:p-6" :class="disabled ? 'disabled opacity-50' : ''">
				<div class="space-y-8">
					<div v-if="Object.keys(draft.sections).length > 0" class="flex flex-col gap-y-16">
						<div
							v-for="(section, index) in draft.sections"
							:key="section.ref_id"
							class="relative block bg-blue-50 border-2 border-dashed border-blue-300 border-opacity-50 rounded"
						>
							<div
								class="absolute z-10 -top-4 left-1/2 transform -translate-x-1/2 text-lg font-medium px-3 bg-white rounded-md border-2 border-dashed border-blue-300 border-opacity-50"
							>
								Section
							</div>
							<ContentBuilderSection
								v-model="draft.sections[index]"
								:section-index="index"
								:num-sections="draft.sections.length"
								:main-menu-enabled="draft.menu_enabled"
								:page-menu="draft.menu"
								:hide-section-icon="hideSectionIcon"
								:hide-section-title="hideSectionTitle"
								:hide-column-icon="hideColumnIcon"
								:hide-column-title="hideColumnTitle"
								@removeSection="removeSection"
								@reorderSectionLower="reorderSectionLower"
								@reorderSectionHigher="reorderSectionHigher"
								@addSectionMenuItemEnabled="addSectionMenuItemEnabled"
								@removeSectionMenuItemEnabled="removeSectionMenuItemEnabled"
								@updateSectionTitle="updateSectionTitle"
								@updateSectionIcon="updateSectionIcon"
							></ContentBuilderSection>
						</div>
					</div>
					<div
						class="block border-2 border-dashed border-gray-400 rounded bg-white h-16 w-full text-gray-200 col-span-6 flex items-center justify-center"
					>
						<button
							class="-ml-px relative inline-flex space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:border-gray-400"
							@click.prevent="addSection"
						>
							<ViewGridAddIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
							<span>Add Section</span>
						</button>
					</div>
				</div>
			</div>

			<div
				v-if="!onlySectionBuilder"
				class="px-4 py-5 shadow-sm border border-gray-300 rounded-l-md flex justify-end fixed bottom-5 right-4 bg-white bg-opacity-50 z-10  flex flex-col gap-x-2"
			>
				<div v-if="errors.length != 0" class="mb-2 text-center text-red-500 font-semibold px-3 w-60">
					Your form has errors, please correct them.
				</div>

				<button
					v-if="editing && !isEvent"
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
					:disabled="errors.length != 0"
					@click.prevent="update"
				>
					<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Save Draft
				</button>

				<button
					v-if="editing && isEvent"
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
					:disabled="errors.length != 0"
					@click.prevent="update"
				>
					<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Update
				</button>

				<button
					v-if="!editing"
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
					:disabled="errors.length != 0"
					@click.prevent="store"
				>
					<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Save
				</button>
			</div>

			<ModalsConfirmation
				ref="confirmation"
				:open="confirmationOpen"
				:confirmation-config="confirmationConfig"
				@closeModal="confirmationOpen = false"
				@confirm="confirm"
			/>

			<SlidePanel
				slide-title="Select Button Link"
				slide-component="MediaButtonLinkSelect"
				:open="slideOpen"
				:menu-button="button"
				@closeSlide="closeSlide"
				@selectButtonLink="selectButtonLink"
			/>
		</div>

		<form id="preview" :action="previewUrl" method="POST" target="_blank" class="hidden">
			<input id="preview-data" v-model="preview" type="hidden" name="preview" />
		</form>
	</div>
</template>

<script>
import { ref, reactive, provide, inject, computed, onBeforeMount } from 'vue'
import { useToast } from 'vue-toastification'
import draggable from 'vuedraggable'

import {
	ViewGridAddIcon,
	SwitchVerticalIcon,
	PlusCircleIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	XIcon,
	PencilAltIcon,
	SaveIcon,
	ExternalLinkIcon,
} from '@heroicons/vue/solid'

import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

import { useRoute } from 'vue-router'
import getEnv from '@/extensions/env'

export default {
	components: {
		Switch,
		SwitchGroup,
		SwitchLabel,
		ViewGridAddIcon,
		draggable,
		SwitchVerticalIcon,
		PlusCircleIcon,
		ChevronLeftIcon,
		ChevronRightIcon,
		XIcon,
		PencilAltIcon,
		SaveIcon,
		ExternalLinkIcon,
	},
	props: {
		sections: {
			type: [Object, Boolean],
			default: false,
		},
		parentEndpoint: {
			type: [String, Boolean],
			default: false,
		},
		pageType: {
			type: [String, Boolean],
			default: false,
		},
		urlPrefix: {
			type: [String, Boolean],
			default: null,
		},
		restrictUrlPrefix: {
			type: Boolean,
			default: false,
		},
		cloneId: {
			type: [String, Boolean],
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		onlySectionBuilder: {
			type: Boolean,
			default: false,
		},
		noSectionBuilder: {
			type: Boolean,
			default: false,
		},
		isEvent: {
			type: Boolean,
			default: false,
		},
		includeSeo: {
			type: Boolean,
			default: true,
		},
		includePublish: {
			type: Boolean,
			default: true,
		},
		includeKeywords: {
			type: Boolean,
			default: false,
		},
		includeTeaserText: {
			type: Boolean,
			default: false,
		},
		includeBodyText: {
			type: Boolean,
			default: false,
		},
		includeDetailsText: {
			type: Boolean,
			default: false,
		},
		hideMenu: {
			type: Boolean,
			default: false,
		},
		hideSectionIcon: {
			type: Boolean,
			default: false,
		},
		hideSectionTitle: {
			type: Boolean,
			default: false,
		},
		hideColumnIcon: {
			type: Boolean,
			default: false,
		},
		hideColumnTitle: {
			type: Boolean,
			default: false,
		},
		hidePublish: {
			type: Boolean,
			default: false,
		},
		editRoute: {
			type: [String, Boolean],
			default: false,
		},
	},
	emits: ['created', 'updated', 'updatedSection'],
	setup(props) {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		const keywordOptions = ref([])
		provide(
			'keywordOptions',
			computed(() => keywordOptions.value)
		)

		const page = ref(null)
		const draft = ref(null)
		const original = ref(null)
		const dirtyData = ref(false)
		// provide(
		// 	'page',
		// 	computed(() => page.value)
		// )

		// const page = ref({
		// 	url_prefix: '/',
		// 	url: 'slug',
		// 	title: 'Testing 1',
		// 	publish_date: '2021-05-29 15:17:34',
		// 	header_image: {},
		// 	seo: {
		// 		title: 'Testing 1',
		// 		description: 'The SEO Description',
		// 		keywords: [],
		// 		robots: 'index, follow',
		// 		canonical: '',
		// 	},
		// 	menu: [],
		// 	sections: [
		// 		{
		// 			ref_id: '23da4c49-7a2a-4509-8015-168f9040aaf3',
		// 			icon: 'IconAboutUs',
		// 			title: 'Testing 1',
		// 			anchor: 'testing-1',
		// 			columns: [
		// 				{
		// 					ref_id: 'f00a9f04-e833-415d-af9b-a6ffc8c888b0',
		// 					text: '<p class="mb-2">fgjtjjjt</p>',
		// 					video: null,
		// 					video_position: null,
		// 					component: null,
		// 				},
		// 			],
		// 		},
		// 	],
		// })

		return {
			draft,
			page,
			original,
			dirtyData,
			api,
			toast,
			keywordOptions,
		}
	},
	data() {
		return {
			previewUrl: getEnv('VUE_APP_FRONTEND_URL'),
			preview: null,
			endpoint: null,
			page_id: this.$route.params.id || null,
			confirmationOpen: false,
			confirmationConfig: {},
			slideOpen: false,
			drag: false,
			attrs: [
				{
					key: 'today',
					// highlight: true,
					content: 'blue',
					dates: new Date(),
				},
			],
			errors: [],
			buttonBin: [],
			button: null,
			buttonIndex: null,
		}
	},
	computed: {
		editing: function() {
			return typeof this.draft && typeof this.draft.id !== 'undefined'
		},
	},
	watch: {
		'draft.event_date': function(val) {
			// console.log(val)
			if (this.draft) {
				if (this.draft.event_start_time === '' || this.draft.event_end_time === '') {
					this.draft.event_start_time = new Date(val)
					this.draft.event_end_time = new Date(val)
				}
			}
			// this.fullName = val + ' ' + this.lastName
		},
		draft: {
			deep: true,
			handler(val) {
				this.dirtyData = JSON.stringify(val) != this.original

				if (this.onlySectionBuilder) {
					this.$emit('updatedSection', this.draft.sections)
				}
				// console.log('dirtyData', this.dirtyData, JSON.stringify(val))
				// console.log('dirtyDataDetails', JSON.stringify(val))
				// console.log('dirtyDataDetails', this.original)
			},
		},
	},
	mounted() {
		if (this.sections) {
			this.draft = this.sections
			this.original = JSON.parse(JSON.stringify(this.draft))
			this.page = JSON.parse(JSON.stringify(this.draft))
		} else if ((this.page_id || this.cloneId) && !this.onlySectionBuilder) {
			this.setEndpoint(this.cloneId)

			this.endpoint.fetch().then(page => {
				this.setPage(page)
			})
		} else {
			this.draft = {
				url: null,
				slug: null,
				url_prefix: this.urlPrefix,
				path: null,
				title: null,
				header_image: null,
				seo: {
					title: null,
					description: null,
					keywords: [],
					robots: null,
					canonical: null,
				},
				publish_date: null,
				menu_enabled: false,
				menu: [],
				sections: [],
				teaser: null,
				keywords: [],
			}

			this.original = JSON.parse(JSON.stringify(this.draft))

			this.page = JSON.parse(JSON.stringify(this.draft))
		}

		if (this.isEvent && !this.page_id && !this.cloneId) {
			this.draft.registration_enabled = false
		}

		this.fetchKeywords()
	},
	methods: {
		fetchKeywords() {
			this.loaderShow()

			this.api.keywords
				.all()
				.then(data => {
					this.keywordOptions = data
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching keywords:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		storeTag(tag) {
			this.loaderShow()

			this.api.keywords
				.store({ name: tag })
				.then(data => {
					this.keywordOptions.push(data)
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching keywords:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		makeFullPath() {
			this.draft.path = this.draft.url_prefix + this.draft.slug
		},

		updateUrlPrefix(val) {
			this.draft.url_prefix = val
		},

		openSlide() {
			this.slideOpen = true
		},

		closeSlide() {
			this.button = null
			this.buttonIndex = null
			this.slideOpen = false
		},

		selectButtonLink(buttonLink) {
			let newMenuItem = {
				icon: buttonLink.icon,
				title: buttonLink.title,
				anchor: null,
				external: buttonLink.href ? buttonLink.href : null,
				content_page_id: buttonLink.content_page_id ? buttonLink.content_page_id : null,
				section_ref: null,
				is_external: buttonLink.external,
			}

			if (buttonLink.id) {
				newMenuItem.id = buttonLink.id
			}

			if (this.buttonIndex) {
				this.draft.menu[this.buttonIndex] = newMenuItem
			} else {
				this.draft.menu.push(newMenuItem)
			}
		},

		addSection() {
			let newSection = {
				ref_id: this.uuidv4(),
				menu_item_enabled: false,
				icon: null,
				title: null,
				columns: [],
				minimized: false,
				anchor: null,
				component: null,
			}

			this.draft.sections.push(newSection)
		},

		confirm(callback) {
			this[callback]()
		},

		removeSection(ref_id) {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'confirmRemoveSection',
				title: 'Remove Section',
				content: 'Are you sure you want to remove this section?',
			}
			this.$refs.confirmation.ref_id = ref_id
		},

		confirmRemoveSection() {
			let ref_id = this.$refs.confirmation.ref_id
			let index = this.draft.sections.findIndex(x => x.ref_id === ref_id)

			if (this.draft.sections[index].menu_item_enabled) {
				let menuIndex = this.draft.menu.findIndex(x => x.section_ref === ref_id)

				// delete this.draft.sections[index].ref_id

				this.draft.menu.splice(menuIndex, 1)
			}

			this.draft.sections.splice(index, 1)

			delete this.$refs.confirmation.ref_id
			this.confirmationConfig = {}
			this.confirmationOpen = false
		},

		array_move(arr, old_index, new_index) {
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1
				while (k--) {
					arr.push(undefined)
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
			return arr // for testing
		},

		reorderSectionLower(ref_id) {
			let index = this.draft.sections.findIndex(x => x.ref_id === ref_id)

			this.array_move(this.draft.sections, index, index - 1)

			// if (this.draft.sections[index].menu_item_enabled && this.draft.menu.length > 1) {
			// 	let menuIndex = this.draft.menu.findIndex(x => x.section_ref === this.draft.sections[index].ref_id)

			// 	this.array_move(this.draft.menu, menuIndex, index)
			// }
		},

		reorderSectionHigher(ref_id) {
			let index = this.draft.sections.findIndex(x => x.ref_id === ref_id)

			this.array_move(this.draft.sections, index, index + 1)

			// if (this.draft.sections[index].menu_item_enabled && this.draft.menu.length > 1) {
			// 	let menuIndex = this.draft.menu.findIndex(x => x.section_ref === this.draft.sections[index].ref_id)

			// 	this.array_move(this.draft.menu, menuIndex, index)
			// }
		},

		uuidv4() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = (Math.random() * 16) | 0,
					v = c == 'x' ? r : (r & 0x3) | 0x8
				return v.toString(16)
			})
		},

		addSectionMenuItemEnabled(ref_id) {
			let newMenuItem = null
			let index = null

			let buttonBinIndex = this.buttonBin.findIndex(x => x.section_ref === ref_id)
			if (buttonBinIndex != -1) {
				// console.log('buttonBinIndex', buttonBinIndex)
				newMenuItem = JSON.parse(JSON.stringify(this.buttonBin[buttonBinIndex]))
				index = newMenuItem.index

				this.buttonBin.splice(buttonBinIndex, 1)

				delete newMenuItem.index
			} else {
				newMenuItem = {
					icon: '',
					title: '',
					anchor: null,
					external: null,
					content_page_id: null,
					section_ref: this.uuidv4(),
					is_external: false,
				}

				index = this.draft.sections.findIndex(x => x.ref_id === ref_id)

				if (index != -1) {
					let anchor = this.draft.sections[index].title
						.toLowerCase()
						.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\\\/]/gi, '')
						.replace(/ +/g, '-')

					this.draft.sections[index].anchor = anchor

					newMenuItem.icon = this.draft.sections[index].icon
					newMenuItem.title = this.draft.sections[index].title
					newMenuItem.anchor = this.draft.sections[index].anchor
					newMenuItem.section_id = this.draft.sections[index].id || null
					newMenuItem.section_ref = this.draft.sections[index].ref_id || ref_id
				} else {
					this.toast.error('Could not find section index for: ' + ref_id)
				}
			}

			// console.log('addSectionMenuItemEnabled', newMenuItem)

			this.draft.menu.push(newMenuItem)

			if (index !== null) {
				// console.log('array move', this.draft.menu.length - 1, index)
				this.array_move(this.draft.menu, this.draft.menu.length - 1, index)
			}
		},

		updateSectionTitle(data) {
			let buttonBinIndex = this.buttonBin.findIndex(x => x.section_ref === data.ref_id)
			let menuIndex = this.draft.menu.findIndex(x => x.section_ref === data.ref_id)

			if (buttonBinIndex !== -1) {
				this.buttonBin[buttonBinIndex].title = data.title
				this.buttonBin[buttonBinIndex].anchor = data.anchor
			} else if (menuIndex !== -1) {
				this.draft.menu[menuIndex].title = data.title
				this.draft.menu[menuIndex].anchor = data.anchor
			}
		},

		updateSectionIcon(data) {
			let buttonBinIndex = this.buttonBin.findIndex(x => x.section_ref === data.ref_id)
			let menuIndex = this.draft.menu.findIndex(x => x.section_ref === data.ref_id)

			if (buttonBinIndex !== -1) {
				this.buttonBin[buttonBinIndex].icon = data.icon
			} else if (menuIndex !== -1) {
				this.draft.menu[menuIndex].icon = data.icon
			}
		},

		removeSectionMenuItemEnabled(ref_id) {
			let index = this.draft.sections.findIndex(x => x.ref_id === ref_id)
			let menuIndex = this.draft.menu.findIndex(x => x.section_ref === this.draft.sections[index].ref_id)

			let button = JSON.parse(JSON.stringify(this.draft.menu[menuIndex]))
			button.index = menuIndex
			this.buttonBin.push(button)
			// console.log('removeSectionMenuItemEnabled', this.buttonBin)

			// delete this.draft.sections[index].ref_id
			this.draft.menu.splice(menuIndex, 1)
		},

		editMenuItem(button, index) {
			this.buttonIndex = index
			this.button = JSON.parse(JSON.stringify(button))
			this.slideOpen = true
		},

		removeMenuItem(index) {
			this.draft.menu.splice(index, 1)
		},

		processFormData() {
			let processedFormData = JSON.parse(JSON.stringify(this.draft))

			delete processedFormData.url
			delete processedFormData.path

			if (processedFormData.header_image) {
				processedFormData.header_image_id = processedFormData.header_image.id
				delete processedFormData.header_image
			}

			if (processedFormData.seo) {
				if (processedFormData.seo.canonical === '') processedFormData.seo.canonical = null
			}

			// Remove SEO ID if cloning
			if (
				this.cloneId &&
				typeof processedFormData.seo == 'object' &&
				typeof processedFormData.seo.id !== 'undefined'
			) {
				delete processedFormData.seo.id
			}

			if (!this.editing && processedFormData.menu && processedFormData.menu.length > 0) {
				processedFormData.menu.forEach(buttons => {
					buttons.section_ref = null
				})
			}

			if (processedFormData.sections && Object.keys(processedFormData.sections).length > 0) {
				processedFormData.sections.forEach(section => {
					if (typeof section === 'object') {
						// Remove Section ID if cloning
						if (this.cloneId && typeof section.id !== 'undefined') {
							delete section.id
						}

						section.columns.forEach((column, index) => {
							// Remove Column ID if cloning
							if (this.cloneId && typeof column.id !== 'undefined') {
								delete column.id
							}

							if (
								column.video &&
								typeof column.video == 'object' &&
								typeof column.video.id !== 'undefined'
							) {
								column.video_id = column.video.id
								delete column.video
							} else {
								column.video_id = null
								delete column.video
							}

							if (column.text === '' && column.video_id === null) {
								delete section.columns.splice(index, 1)
							}
						})
					}

					if (section.anchor === '') {
						section.anchor = null
					}

					if (!section.component || Object.keys(section.component).length === 0) {
						section.component = null
					}
				})
			}

			if (this.onlySectionBuilder) {
				processedFormData.seo = null
			}

			// console.log(data)
			return processedFormData
		},

		store() {
			this.loaderShow()

			this.setEndpoint()

			this.endpoint
				.store(this.processFormData())
				.then(page => {
					if (this.editRoute) {
						this.$router.push({ name: this.editRoute, params: { id: page.slug } })
					}
					this.toast.success('Successfully saved!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		update() {
			this.loaderShow()

			this.setEndpoint()

			this.endpoint
				.update(this.processFormData())
				.then(page => {
					this.setPage(page)
					this.buttonBin = []

					this.toast.success('Successfully saved!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		setPage(page) {
			this.draft = null
			this.page = null
			this.original = null
			this.dirtyData = false

			setTimeout(() => {
				if (page.draft_page) {
					if (this.cloneId) {
						delete page.draft_page.id
						page.draft_page.publish_date = null
						page.draft_page.publish_date_display = null
					}

					// if (page.draft_page.menu && page.draft_page.menu.length > 0) {
					// 	page.draft_page.menu_enabled = true
					// }

					this.draft = page.draft_page
					this.original = JSON.stringify(this.draft)
					this.page = JSON.parse(JSON.stringify(page))
					delete this.page.page_draft

					this.makeFullPath()

					this.$emit('updated', page.slug)
				} else {
					if (this.cloneId) {
						delete page.id
						page.publish_date = null
						page.publish_date_display = null
					}

					if (page.menu && page.menu.length > 0) {
						page.menu_enabled = true
					}

					this.draft = page
					this.original = JSON.stringify(this.draft)
					this.page = JSON.parse(JSON.stringify(page))

					this.makeFullPath()

					this.$emit('updated', page.slug)
				}

				// console.log(this.draft)
			}, 100)
		},

		setEndpoint(clone_id = null) {
			let fetchId = this.page_id || clone_id
			if (this.parentEndpoint) {
				if (fetchId) {
					this.endpoint = this.api[this.parentEndpoint].slug(fetchId).pages
				} else {
					this.endpoint = this.api[this.parentEndpoint].pages
				}
			} else {
				if (fetchId) {
					// console.log(fetchId)
					this.endpoint = this.api.pages.slug(fetchId)
				} else {
					this.endpoint = this.api.pages
				}
			}
		},

		publish() {
			this.loaderShow()

			this.setEndpoint()

			this.endpoint
				.publish()
				.then(page => {
					this.setPage(page)

					this.toast.success('Successfully published!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
		unpublish() {
			this.loaderShow()

			this.setEndpoint()

			this.endpoint
				.archive()
				.then(page => {
					this.setPage(page)

					this.toast.success('Successfully unpublished!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
		schedule(when) {
			this.loaderShow()

			this.setEndpoint()

			this.endpoint
				.schedule({
					publish_date: when,
				})
				.then(page => {
					this.setPage(page)

					this.toast.success('Successfully scheduled!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
		unschedule() {
			this.loaderShow()

			this.setEndpoint()

			this.endpoint
				.unschedule()
				.then(page => {
					this.setPage(page)

					this.toast.success('Successfully scheduled!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		openPreview() {
			let previewData = JSON.parse(JSON.stringify(this.draft))

			previewData.menu.forEach(button => {
				button.to = button.anchor || button.to
			})

			// console.log(previewData)

			document.getElementById('preview-data').value = JSON.stringify(previewData)
			// console.log('Preview Data: ', document.getElementById('preview-data').value)
			document.getElementById('preview').submit()
		},

		invalidField(field) {
			if (!this.errors.includes(field)) this.errors.push(field)

			// console.log('invalidField', field, !this.errors.includes(field), this.errors)
		},
		validField(field) {
			if (this.errors.includes(field)) {
				var index = this.errors.indexOf(field)
				if (index !== -1) {
					this.errors.splice(index, 1)
				}
			}

			// console.log('validField', field, this.errors)
		},
	},
}
</script>
