<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M45.05,28.55H43V22.27l0-.16a8,8,0,0,0-8.5-6.84A8,8,0,0,0,26,22.11v6.44H24.46a3.81,3.81,0,0,0-3.8,3.8V52.93a3.81,3.81,0,0,0,3.8,3.81H45.05a3.81,3.81,0,0,0,3.8-3.81V32.35A3.81,3.81,0,0,0,45.05,28.55ZM38.25,48h-7l2.11-4.86a3.5,3.5,0,1,1,2.77,0ZM41.1,28.55H27.88v-6.2a6.09,6.09,0,0,1,6.44-5.18v0l.18,0,.17,0v0a6.1,6.1,0,0,1,6.43,5.18Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PatientPrivacy',
	mixins: [iconMixin],
}
</script>
