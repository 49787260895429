<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" :open="open">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="ease-in duration-200"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div
						class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6"
					>
						<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
							<button
								type="button"
								class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
								@click="closeModal"
							>
								<span class="sr-only">Close</span>
								<XIcon class="h-6 w-6" aria-hidden="true" />
							</button>
						</div>
						<div class="mt-8">
							<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<div class="mt-2">
									<div class="relative block w-full overflow-hidden">
										<img
											:src="imagePreviewConfig.image.url"
											alt=""
											class="object-contain max-h-96 w-full pointer-events-none"
										/>
									</div>
									<div class="mt-5 px-2 text-sm text-gray-900 truncate">
										<dl class="flex gap-x-5">
											<dt class="font-medium w-20">
												File Name:
											</dt>
											<dd>
												{{ imagePreviewConfig.image.original_filename }}
											</dd>
										</dl>
										<dl class="flex gap-x-5">
											<dt class="font-medium w-20">
												Alt Attribute:
											</dt>
											<dd>
												{{ imagePreviewConfig.image.alt_text }}
											</dd>
										</dl>
										<dl class="flex gap-x-5">
											<dt class="font-medium w-20">
												Category:
											</dt>
											<dd>
												{{ imagePreviewConfig.image.category.name }}
											</dd>
										</dl>
										<dl class="flex gap-x-5">
											<dt class="font-medium w-20">
												Dimensions:
											</dt>
											<dd>
												{{ imagePreviewConfig.image.width }}
												x
												{{ imagePreviewConfig.image.height }}
											</dd>
										</dl>
									</div>

									<!-- <p class="text-sm text-gray-500">
										{{ imagePreviewConfig.image.content }}
									</p> -->
								</div>
							</div>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
// import { ref } from "vue";
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

export default {
	components: {
		Dialog,
		DialogOverlay,
		TransitionChild,
		TransitionRoot,
		XIcon,
	},
	props: {
		open: Boolean,
		imagePreviewConfig: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['closeModal', 'confirm'],
	data() {
		return {}
	},
	beforeMount() {
		console.log(this.imagePreviewConfig)
	},
	methods: {
		closeModal() {
			this.$emit('closeModal')
		},
	},
}
</script>
