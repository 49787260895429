<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M46.79,31.38a1,1,0,0,1-.9.62H32.78V24.28h4.94c1.57,0,2,.3,3.78,1.75,1.26,1,4.89,4.17,5,4.29A1,1,0,0,1,46.79,31.38Z"
		/>
		<circle
			:stroke="fillComputed"
			class="cls-2"
			cx="22.13"
			cy="44.41"
			r="5.32"
			transform="matrix(0.61, -0.8, 0.8, 0.61, -26.61, 35.12)"
		/>
		<circle
			:stroke="fillComputed"
			class="cls-2"
			cx="52.37"
			cy="44.41"
			r="5.32"
			transform="translate(0.16 89) rotate(-80.8)"
		/>
		<line :stroke="fillComputed" class="cls-3" x1="35.22" y1="35.82" x2="37.2" y2="35.82" />
		<line :stroke="fillComputed" class="cls-3" x1="23.96" y1="35.82" x2="25.93" y2="35.82" />
		<line :stroke="fillComputed" class="cls-3" x1="61.02" y1="36.35" x2="63.11" y2="36.35" />
		<line :stroke="fillComputed" class="cls-2" x1="27.45" y1="44.41" x2="47.04" y2="44.41" />
		<path
			:stroke="fillComputed"
			class="cls-2"
			d="M57.69,44.41H60.2a1,1,0,0,0,.74-.34l2-2.29a1,1,0,0,0,.25-.66V35.66a3.22,3.22,0,0,0-2.46-3.1L52.66,30s-5.95-4.9-7.84-6.48c-2.42-2-3.76-2.72-6.57-2.72H27.94a6.33,6.33,0,0,0-4.07,1.28c-2.88,1.81-7.6,6.34-8.66,7.38l-2.63.47A3.15,3.15,0,0,0,10,33V40.3a1,1,0,0,0,.28.69l3,3.12a1,1,0,0,0,.71.3h2.77"
		/>
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M32.78,32h-9a1,1,0,0,1-.87-1.36,22.49,22.49,0,0,1,2-3.31c1.85-2.53,3.44-3,3.61-3h4.27Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'DriveUpLabs',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1,
.cls-2,
.cls-3 {
	fill: none;
	stroke-width: 2px;
}
.cls-1,
.cls-3 {
	stroke-linejoin: round;
}
.cls-2 {
	stroke-miterlimit: 10;
}
.cls-3 {
	stroke-linecap: round;
}
</style>
