<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M37.13,12.58A23.81,23.81,0,1,0,60.94,36.39,23.81,23.81,0,0,0,37.13,12.58Zm9.59,24.26L31,46.34a.51.51,0,0,1-.69-.18.5.5,0,0,1-.07-.27v-19a.49.49,0,0,1,.49-.51.42.42,0,0,1,.27.07L46.72,36a.52.52,0,0,1,.17.72A.46.46,0,0,1,46.72,36.84Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'MeetTheDoc',
	mixins: [iconMixin],
}
</script>
