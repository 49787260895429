<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M58.89,38.31,46.43,50.76l-.13.11L37,60.18l-9.43-9.39-.06,0-.82-.83-.33-.33L15.08,38.26a12.18,12.18,0,0,1-1.68-2.07H24.94a1.09,1.09,0,0,0,.92-.5l3.35-5.19,8.08,16.11a1.09,1.09,0,0,0,1,.63h0a1.08,1.08,0,0,0,1-.62l5.58-10.23h4.79a1.09,1.09,0,1,0,0-2.18H44.15a1.08,1.08,0,0,0-1,.62L38.3,43.61,30.35,27.79a1.09,1.09,0,0,0-1.9-.13L24.35,34H12.23A13.35,13.35,0,0,1,34,19.44l3,3,3-3A13.35,13.35,0,0,1,58.89,38.31Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Heartbeat',
	mixins: [iconMixin],
}
</script>
