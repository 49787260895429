<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 44 44"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		enable-background="new 0 0 44 44"
		fill="currentColor"
	>
		<path
			d="m22,0c-12.2,0-22,9.8-22,22s9.8,22 22,22 22-9.8 22-22-9.8-22-22-22zm-5.2,24.4l-1.4,1.4c-0.4,0.4-1,0.4-1.4,0l-2.5-2.5c-1.6-1.6-2.5-3.7-2.5-6 0-4.6 3.8-8.4 8.4-8.4 2.2,0 4.4,0.9 6,2.5l2.5,2.5c0.4,0.4 0.4,1 0,1.4l-1.4,1.4c-0.4,0.4-1,0.4-1.4,0l-2.5-2.5c-0.8-0.8-1.9-1.3-3.1-1.3-2.4,0-4.4,2-4.4,4.4 0,1.2 0.5,2.3 1.3,3.1l2.5,2.5c0.2,0.5 0.2,1.1-0.1,1.5zm.5-5.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l6.6,6.6c0.4,0.4 0.4,1 0,1.4l-1.4,1.4c-0.4,0.4-1,0.4-1.4,0l-6.6-6.6c-0.4-0.4-0.4-1 3.55271e-15-1.4zm9.3,16.3c-2.2,0-4.4-0.9-6-2.5l-2.5-2.5c-0.4-0.4-0.4-1 0-1.4l1.4-1.4c0.4-0.4 1-0.4 1.4,0l2.5,2.5c0.8,0.8 1.9,1.3 3.1,1.3 2.4,0 4.4-2 4.4-4.4 0-1.2-0.5-2.3-1.3-3.1l-2.5-2.5c-0.4-0.4-0.4-1 0-1.4l1.4-1.4c0.4-0.4 1-0.4 1.4,0l2.5,2.5c1.6,1.6 2.5,3.7 2.5,6 0.1,4.5-3.7,8.3-8.3,8.3z"
		/>
	</svg>
</template>
