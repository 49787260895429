<template>
	<div class="bg-white shadow overflow-hidden sm:rounded-lg">
		<div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
			<div class="-ml-4 -mt-2 flex items-center justify-end flex-wrap sm:flex-nowrap">
				<div class="ml-4 mt-2 flex-shrink-0">
					<button
						type="button"
						class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
						@click="openSlide"
					>
						<PlusIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						New Gallery
					</button>
				</div>
			</div>
		</div>
		<Gallery type="departments" :slug="department.slug" />
	</div>
</template>
<script>
import { computed, onMounted, ref, reactive, inject, watch } from 'vue'
import {
	SwitchVerticalIcon,
	// SparklesIcon,
	XIcon,
	PlusIcon,
	// PhotographIcon,
} from '@heroicons/vue/solid'

export default {
	components: { PlusIcon },
	setup() {
		const department = inject('department')
		console.log('Department: ', department)
		return {
			department,
		}
	},
}
</script>
