<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			:stroke="fillComputed"
			class="cls-1"
			d="M64.52,38.32,59.14,36a22.36,22.36,0,0,0-.77-4.88l4.38-3.9a.51.51,0,0,0,.12-.6l-2.24-4.39a.5.5,0,0,0-.55-.26l-5.74,1.25a20.46,20.46,0,0,0-1.65-1.84,22.23,22.23,0,0,0-1.84-1.66L52.1,14a.5.5,0,0,0-.26-.55l-4.39-2.24a.52.52,0,0,0-.6.11L43,15.66a22,22,0,0,0-4.89-.77L35.71,9.51a.52.52,0,0,0-.54-.3L30.31,10a.51.51,0,0,0-.43.44l-.57,5.84a21.57,21.57,0,0,0-4.41,2.25l-5.06-3a.51.51,0,0,0-.61.08l-3.48,3.48a.49.49,0,0,0-.08.61l3,5.06a21.89,21.89,0,0,0-2.25,4.42l-5.83.57a.51.51,0,0,0-.45.42l-.77,4.86a.5.5,0,0,0,.29.54L15,38a22.55,22.55,0,0,0,.77,4.89l-4.38,3.89a.49.49,0,0,0-.11.6l2.23,4.39a.52.52,0,0,0,.56.27l5.72-1.26a22.55,22.55,0,0,0,1.66,1.85,20.74,20.74,0,0,0,1.85,1.65L22,60a.5.5,0,0,0,.26.55l4.39,2.24a.5.5,0,0,0,.6-.11l3.9-4.39a21.77,21.77,0,0,0,4.89.77l2.35,5.38a.49.49,0,0,0,.46.3H39l4.86-.77a.52.52,0,0,0,.43-.45l.57-5.84a21.57,21.57,0,0,0,4.41-2.25l5.07,3a.5.5,0,0,0,.6-.08L58.4,54.8a.5.5,0,0,0,.07-.61l-3-5.06a22.08,22.08,0,0,0,2.25-4.41l5.83-.58a.49.49,0,0,0,.45-.42l.77-4.86A.49.49,0,0,0,64.52,38.32Zm-1.41,4.86-5.76.57a.51.51,0,0,0-.43.33,20.65,20.65,0,0,1-2.42,4.75.49.49,0,0,0,0,.53l2.93,5L54.48,57.3l-5-2.93a.5.5,0,0,0-.54,0,21.05,21.05,0,0,1-4.75,2.42.5.5,0,0,0-.33.42L43.29,63l-4.09.65-2.33-5.31a.51.51,0,0,0-.44-.3,20.7,20.7,0,0,1-5.26-.83l-.14,0a.52.52,0,0,0-.38.17L26.8,61.68,23.1,59.8l1.24-5.66a.5.5,0,0,0-.18-.51,20.81,20.81,0,0,1-2-1.76,21.2,21.2,0,0,1-1.77-2,.5.5,0,0,0-.5-.18l-5.66,1.24-1.88-3.7,4.32-3.84a.49.49,0,0,0,.15-.52A21.78,21.78,0,0,1,16,37.6a.49.49,0,0,0-.3-.45l-5.31-2.32.65-4.1,5.76-.57a.48.48,0,0,0,.43-.33,20.65,20.65,0,0,1,2.42-4.75.49.49,0,0,0,0-.53l-2.93-5,2.93-2.93,5,2.92a.52.52,0,0,0,.54,0A20.69,20.69,0,0,1,30,17.1a.5.5,0,0,0,.33-.42l.57-5.76,4.09-.65,2.33,5.31a.49.49,0,0,0,.44.3,21,21,0,0,1,5.27.83.54.54,0,0,0,.51-.15l3.85-4.33L51,14.12,49.8,19.78a.49.49,0,0,0,.18.5A21,21,0,0,1,52,22a22.32,22.32,0,0,1,1.77,2,.51.51,0,0,0,.5.18L59.91,23l1.89,3.7-4.34,3.85a.49.49,0,0,0-.14.51,21.78,21.78,0,0,1,.83,5.26.49.49,0,0,0,.3.45l5.31,2.32Z"
		/>
		<path
			:fill="fillComputed"
			:stroke="fillComputed"
			class="cls-1"
			d="M33.43,31.6a5.15,5.15,0,1,0,7.28,0A5.13,5.13,0,0,0,33.43,31.6ZM40,38.17a4.23,4.23,0,0,1-5.86,0,4.15,4.15,0,1,1,5.86,0Z"
		/>
		<path
			:fill="fillComputed"
			:stroke="fillComputed"
			class="cls-1"
			d="M54.15,36.9a17,17,0,0,0-5-12l0,0h0a17,17,0,0,0-12.07-5H37a17,17,0,0,0-12,5l0,0h0A17,17,0,0,0,20,37v0h0a17,17,0,0,0,9.75,15.38h0l.15.06a14.4,14.4,0,0,0,1.41.58l.37.13c.47.16,1,.29,1.44.41l.31.07a14.85,14.85,0,0,0,1.75.29h0a17,17,0,0,0,1.88.12,17.23,17.23,0,0,0,3.65-.41l.28-.07c.5-.11,1-.25,1.48-.41l.33-.12c.49-.18,1-.38,1.46-.6l.12,0h0A16.78,16.78,0,0,0,49.15,49a17,17,0,0,0,5-12.1v0S54.15,36.91,54.15,36.9ZM43.67,51.61l-.08,0c-.44.2-.89.37-1.35.53l-.24.08a14.47,14.47,0,0,1-1.47.4l-.12,0a16.12,16.12,0,0,1-6.67,0l-.19,0c-.47-.1-.93-.23-1.39-.37l-.31-.11c-.43-.15-.85-.31-1.27-.5l-.11,0V50.35a6.6,6.6,0,1,1,13.2,0Zm4.78-3.28a16.14,16.14,0,0,1-3.77,2.79v-.77a7.61,7.61,0,0,0-15.22,0v.77A16,16,0,0,1,21,37.49h1.6a.5.5,0,0,0,0-1H21A15.89,15.89,0,0,1,25.34,26l1.13,1.13a.49.49,0,0,0,.35.15.51.51,0,0,0,.36-.15.51.51,0,0,0,0-.71l-1.13-1.13A16,16,0,0,1,36.54,20.9v1.6a.5.5,0,0,0,1,0V20.9a16,16,0,0,1,10.51,4.32l-1.13,1.13a.5.5,0,0,0,0,.71.5.5,0,0,0,.35.15.51.51,0,0,0,.36-.15l1.13-1.13a16,16,0,0,1,4.36,10.49H51.53a.5.5,0,0,0,0,1h1.6A16.07,16.07,0,0,1,48.45,48.33Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Accessibility',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1 {
	stroke-miterlimit: 10;
	stroke-width: 2px;
}
</style>
