<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M43.31,9.69H38.74a1.81,1.81,0,0,1-1.69-.91,2,2,0,0,0-3.59,0,1.78,1.78,0,0,1-1.63.92h-4l-.6,0v5.84h16.1Zm-18,4c-2.66,0-5.23,0-7.79,0a.47.47,0,0,0-.33.36c0,.52,0,1.06,0,1.59V59.07c0,.27,0,.53,0,.81H53.37v-.75q0-6,0-11.94,0-16.42,0-32.85c0-.54-.15-.68-.68-.67-2.27,0-4.54,0-6.82,0h-.64v2.48c0,1.05-.29,1.34-1.35,1.34H26.55c-.95,0-1.25-.3-1.26-1.25s0-1.65,0-2.55m0-1.88c0-.66,0-1.25,0-1.83a2,2,0,0,1,2.21-2.16c1.32,0,2.64,0,4,0A.82.82,0,0,0,32,7.5a3.92,3.92,0,0,1,6.49,0,.84.84,0,0,0,.6.31c1.3,0,2.6,0,3.91,0a1.92,1.92,0,0,1,2.18,2.11c0,.6,0,1.21,0,1.88h7.37c2.08,0,2.67.59,2.67,2.7v29q0,7.81,0,15.63c0,.11,0,.22,0,.33,0,1.65-.7,2.34-2.35,2.34H17.62c-1.67,0-2.33-.66-2.35-2.34s0-3.08,0-4.62V14.11c0-1.6.75-2.3,2.35-2.31H25.3Z"
		/>
		<path
			:fill="fillComputed"
			d="M35.27,32.83a2.81,2.81,0,1,0-2.83-2.77,2.84,2.84,0,0,0,2.83,2.77m5.23,6.6a5.52,5.52,0,0,0-10.47,0Zm3.12,0V24.15H26.9V39.4c1,.15,1,.14,1.33-.71a7.38,7.38,0,0,1,4.2-4.33l.51-.22a4.7,4.7,0,1,1,5.65-7.4A4.44,4.44,0,0,1,40,30.11a4.73,4.73,0,0,1-2.36,4c.06,0,.08.08.11.09a7.54,7.54,0,0,1,4.75,5,.58.58,0,0,0,.39.28,5,5,0,0,0,.78,0M35.25,22.22h9c1,0,1.27.28,1.27,1.28q0,8.22,0,16.45c0,1.15-.25,1.39-1.4,1.39H26.28c-1,0-1.29-.27-1.29-1.31V23.52c0-1.05.25-1.3,1.29-1.3h9"
		/>
		<path
			:fill="fillComputed"
			d="M35.26,49.26H47.85a3,3,0,0,1,.6,0,.93.93,0,0,1,0,1.84,5,5,0,0,1-.55,0H22.53a2.41,2.41,0,0,1-.54,0,.84.84,0,0,1-.75-.88.86.86,0,0,1,.71-.92,2.81,2.81,0,0,1,.65-.05H35.26"
		/>
		<path
			:fill="fillComputed"
			d="M35.31,44.58h13a.94.94,0,1,1,0,1.87c-.88,0-1.76,0-2.64,0H22.16a.93.93,0,0,1-.92-1,.89.89,0,0,1,1-.89c1.08,0,2.17,0,3.25,0Z"
		/>
		<path :fill="fillComputed" d="M36.08,54.94a.82.82,0,1,1-1.63,0,.82.82,0,1,1,1.63,0" />
		<path
			:fill="fillComputed"
			d="M32.31,55a.78.78,0,0,1-.81.79.83.83,0,0,1-.83-.85.86.86,0,0,1,.83-.8.82.82,0,0,1,.81.86"
		/>
		<path
			:fill="fillComputed"
			d="M38.2,54.94a.81.81,0,0,1,.78-.83.83.83,0,0,1,.86.82.81.81,0,0,1-.84.81.78.78,0,0,1-.8-.8"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'MedicalRecords',
	mixins: [iconMixin],
}
</script>
