<template>
	<div class="relative" @mouseover="hover = true" @mouseleave="hover = false">
		<div class="px-3">
			<div class="hidden sm:block">
				<div class="border-b border-purple-200">
					<nav class="-mb-px flex space-x-8" aria-label="Tabs">
						<a
							:class="[
								tab == 'text'
									? 'border-purple-500 text-purple-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm hover:cursor-pointer',
							]"
							:aria-current="tab == 'text' ? 'page' : undefined"
							@click="tab = 'text'"
						>
							Text
						</a>
						<a
							:class="[
								tab == 'video'
									? 'border-purple-500 text-purple-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm hover:cursor-pointer',
							]"
							:aria-current="tab == 'video' ? 'page' : undefined"
							@click="tab = 'video'"
						>
							Video
							<span v-if="columnTmp.video" class="text-green-600">(configured)</span>
						</a>
					</nav>
				</div>
			</div>
		</div>

		<div v-show="tab == 'text'" class="px-3 py-2">
			<div class="grid grid-cols-1 sm:grid-cols-6 gap-x-5 gap-y-2">
				<FieldsTipTapEditor
					v-model="columnTmp.text"
					:field="{
						label: '',
						model: 'text',
						required: false,
						colSpan: 'col-span-6',
						rows: 10,
					}"
				/>
			</div>
		</div>

		<div v-show="tab == 'video'" class="px-4 py-2">
			<div class="pb-5">
				<FieldsVideo
					v-model="columnTmp.video"
					:field="{
						type: 'input',
						inputType: 'text',
						label: 'Video',
						model: 'video',
						required: false,
						colSpan: 'col-span-6',
					}"
					:media-types="['video']"
					:media-categories="['Provider', 'Location', 'Service', 'Department', 'Page']"
					:media-upload-category="'page'"
					:media-upload-accept="['video/mp4']"
					:media-upload-extensions="['mp4']"
				/>

				<FieldsSelect
					v-model="columnTmp.video_position"
					:field="{
						type: 'select',
						label: 'Position',
						model: 'video_position',
						options: [
							{ name: 'Top', value: 'top' },
							{ name: 'Bottom', value: 'bottom' },
						],
						required: false,
						multiSelect: false,
						colSpan: 'col-span-6',
					}"
				/>
			</div>
		</div>

		<!-- <div class="px-4 py-5 sm:p-6">
      <div class="text-gray-300 whitespace-pre">{{ column }}</div>
    </div> -->

		<div
			v-show="hover"
			class="absolute top-0 right-0 bg-gray-50 transition duration-500 ease-in-out opacity-0"
			:class="{ 'opacity-100': hover }"
		>
			<span class="relative z-0 inline-flex shadow-sm rounded-md">
				<button
					:disabled="numColumns <= 1 || columnIndex === 0"
					type="button"
					class="relative inline-flex items-center p-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50"
					@click="reorderColumn('lower', columnTmp.ref_id)"
				>
					<ArrowSmLeftIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				</button>

				<button
					:disabled="numColumns <= 1 || columnIndex + 1 === numColumns"
					type="button"
					class="relative inline-flex items-center p-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50"
					@click="reorderColumn('higher', columnTmp.ref_id)"
				>
					<ArrowSmRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				</button>

				<button
					:disabled="numColumns === 1"
					type="button"
					class="relative inline-flex items-center p-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50"
					@click="removeColumn(columnTmp.ref_id)"
				>
					<MinusCircleIcon class="h-5 w-5 mr-2 text-gray-400" aria-hidden="true" />
					Remove Column
				</button>
			</span>
		</div>
	</div>
</template>

<script>
import { MinusCircleIcon, ArrowSmLeftIcon, ArrowSmRightIcon } from '@heroicons/vue/solid'
// import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default {
	components: {
		MinusCircleIcon,
		ArrowSmLeftIcon,
		ArrowSmRightIcon,
	},
	props: {
		modelValue: {
			type: Object,
			default: function() {
				return {}
			},
		},
		columnIndex: {
			type: Number,
			default: 0,
		},
		mainMenuEnabled: Boolean,
		numColumns: {
			type: Number,
			default: 0,
		},
		hideColumnIcon: {
			type: Boolean,
			default: false,
		},
		hideColumnTitle: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue', 'updateColumn', 'removeColumn', 'reorderColumnLower', 'reorderColumnHigher'],
	data() {
		return {
			hover: false,
			tab: 'text',
		}
	},
	computed: {
		columnTmp: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log('COLUMN INPUT:', val)
				this.$emit('update:modelValue', val)
			},
		},
	},
	// watch: {
	// 	modelValue: {
	// 		deep: true,
	// 		handler(val) {
	// 			// console.log('val', val)
	// 			this.columnTmp = val
	// 		},
	// 	},
	// },
	methods: {
		removeColumn(ref_id) {
			this.$emit('removeColumn', ref_id)
		},

		reorderColumn(dir, ref_id) {
			if (dir === 'lower') {
				this.$emit('reorderColumnLower', ref_id)
			} else {
				this.$emit('reorderColumnHigher', ref_id)
			}
		},
	},
}
</script>
