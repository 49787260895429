<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			fill="none"
			d="M25,37.14V48.06a8.45,8.45,0,0,0,0,1l-.55,3.28a3.72,3.72,0,0,1-1.16-1.87c.21-1.33.42-2.66.63-3.84A14.13,14.13,0,0,0,23,39V33Z"
		/>
		<path fill="none" d="M25,48.06V37.14l4.07,8.58L25.29,47.5,25,49.1A8.45,8.45,0,0,1,25,48.06Z" />
		<path
			:fill="fillComputed"
			d="M51.35,11c3.71,0,5.23,1.55,5.23,5.35v31.7c0,3.8-1.52,5.35-5.23,5.35H45a7,7,0,0,0,1.73-2h4.57c2.6,0,3.27-.7,3.27-3.39V16.37c0-2.7-.67-3.4-3.27-3.4H28.24c-2.59,0-3.26.69-3.26,3.38v9.2l-2,.94V16.37C23,12.56,24.52,11,28.24,11Z"
		/>
		<path
			fill="none"
			d="M51.34,13c2.6,0,3.27.7,3.27,3.4V48.06c0,2.69-.67,3.39-3.27,3.39H46.77a3.41,3.41,0,0,0,.43-1.33A3.36,3.36,0,0,0,45.39,47h6.52V44.33H40.76c-2.65-1.56-5.83-3.47-7.53-4.58a17.27,17.27,0,0,1-1.65-1.23l3.22-3.63-1.48-1.3-3.13,3.53a4.87,4.87,0,0,1-.81-1.31L26.6,30a5.49,5.49,0,0,1-.6-2.7c.7-2-.84-1.78-.84-1.78l-.18.08v-9.2c0-2.69.67-3.39,3.26-3.38Zm.57,7.51V17.77H36.44v2.71Zm0,8.85V26.62H36.44v2.7Zm0,8.85v-2.7H36.44v2.71ZM34.27,17.72l-1.48-1.3L29.61,20l-1.38-1.23-1.38,1.57,2.85,2.53Zm.26,8.59L33.06,25l-3.18,3.59L28.5,27.37l-1.39,1.56L30,31.46Z"
		/>
		<rect :fill="fillComputed" x="36.45" y="17.77" width="15.46" height="2.71" />
		<rect :fill="fillComputed" x="36.44" y="26.62" width="15.46" height="2.71" />
		<rect :fill="fillComputed" x="36.44" y="35.48" width="15.46" height="2.71" />
		<path :fill="fillComputed" d="M51.91,44.33V47H45.39l-.26-.14s-2-1.14-4.37-2.57Z" />
		<polygon
			:fill="fillComputed"
			points="32.79 16.42 34.27 17.72 29.7 22.88 26.85 20.35 28.23 18.78 29.61 20.01 32.79 16.42"
		/>
		<polygon
			:fill="fillComputed"
			points="33.06 25 34.53 26.31 29.96 31.46 27.11 28.93 28.5 27.37 29.88 28.59 33.06 25"
		/>
		<path fill="none" d="M43.72,48.52s2.75,1.63.63,2.93h-2.9l.54-.26c2-.95-.1-2.21-.1-2.21L38.5,47h2.76Z" />
		<path fill="none" d="M41.45,51.45h2.9a4.13,4.13,0,0,1-.37.2l-3.73,1.77h-3Z" />
		<path fill="none" d="M41.26,47H38.5l-2.06-1.17V44.33h.25Z" />
		<path :fill="fillComputed" d="M33.32,33.59l1.48,1.3-3.22,3.63a10.61,10.61,0,0,1-1.39-1.4Z" />
		<path
			fill="none"
			d="M36.69,44.33h-.25v1.54l-3.5-2-3.76,1.78L25,36.81v-6l2.29,4.84a9.89,9.89,0,0,0,.62,1.27l-.51.58L30.2,40l0,0a13.18,13.18,0,0,0,2.91,2.25C34.24,42.91,35.46,43.62,36.69,44.33Z"
		/>
		<path fill="none" d="M40.25,53.42l-6,2.86-.54-1.14,3.62-1.72Z" />
		<path fill="none" d="M30.2,40l-2.82-2.5.51-.58A15.19,15.19,0,0,0,30.2,40Z" />
		<polygon fill="none" points="24.04 28.87 24.98 30.83 24.98 36.81 23.01 32.66 23.01 29.36 24.04 28.87" />
		<polygon fill="none" points="23.01 29.36 23.01 32.66 21.73 29.96 23.01 29.36" />
		<path fill="none" d="M33.68,55.14l.54,1.14-.13.06-.54-1.14Z" />
		<path :fill="fillComputed" d="M32.86,53.42l.82,1.72-.13.06-.85-1.78Z" />
		<polygon :fill="fillComputed" points="31.93 51.45 32.86 53.42 32.7 53.42 31.77 51.45 31.93 51.45" />
		<polygon :fill="fillComputed" points="29.18 45.66 31.93 51.45 31.77 51.45 29.05 45.72 29.18 45.66" />
		<polygon fill="none" points="24.98 36.81 29.18 45.66 29.05 45.72 24.98 37.14 24.98 36.81" />
		<polygon fill="none" points="23.01 32.66 24.98 36.81 24.98 37.14 23.01 32.99 23.01 32.66" />
		<polygon fill="none" points="21.73 29.96 23.01 32.66 23.01 32.99 21.6 30.03 21.73 29.96" />
		<path :fill="fillComputed" d="M34.22,56.28l1,2.12-.13.06-1-2.12Z" />
		<rect
			:fill="fillComputed"
			x="21.04"
			y="27.54"
			width="0.14"
			height="2.58"
			transform="translate(-10.33 11.84) rotate(-25.4)"
		/>
		<path
			fill="none"
			d="M33.55,55.2l.54,1.14L24.33,61c-2.77,1.32-2.26-2.17-2.26-2.17s.59-4.17,1.24-8.29a3.72,3.72,0,0,0,1.16,1.87l-.89,5.29s-.34,2.42,1.66,1.48Z"
		/>
		<path
			:fill="fillComputed"
			d="M41.89,49,38.5,47l-2.06-1.17-3.5-2-3.76,1.78,2.75,5.8.93,2,.82,1.72,3.62-1.72,4.15-2,.54-.26C44,50.24,41.89,49,41.89,49Z"
		/>
		<path
			:fill="fillComputed"
			d="M31.77,51.45l-2.72-5.73L25.29,47.5,25,49.1l-.55,3.28-.89,5.29s-.34,2.42,1.66,1.48l8.31-3.95-.85-1.78Z"
		/>
		<path class="cls-1" d="M21.6,30,23,33v6a10,10,0,0,0-.5-1l-3.23-6.8Z" />
		<path
			:fill="fillComputed"
			d="M45.39,47l-.26-.14s-2-1.14-4.37-2.57c-2.65-1.56-5.83-3.47-7.53-4.58a17.27,17.27,0,0,1-1.65-1.23,10.61,10.61,0,0,1-1.39-1.4,4.87,4.87,0,0,1-.81-1.31L26.6,30a5.49,5.49,0,0,1-.6-2.7c.7-2-.84-1.78-.84-1.78l-.18.08-2,.94-2.39,1.13L21.73,30,23,29.36l1-.49.94,2,2.29,4.84a9.89,9.89,0,0,0,.62,1.27A15.19,15.19,0,0,0,30.2,40l0,0a13.18,13.18,0,0,0,2.91,2.25c1.1.61,2.32,1.32,3.55,2L41.26,47l2.46,1.48s2.75,1.63.63,2.93a4.13,4.13,0,0,1-.37.2l-3.73,1.77-6,2.86,1,2.12,7.87-3.73A10.34,10.34,0,0,0,45,53.41a7,7,0,0,0,1.73-2,3.41,3.41,0,0,0,.43-1.33A3.36,3.36,0,0,0,45.39,47Z"
		/>
		<path
			:fill="fillComputed"
			d="M24.33,61c-2.77,1.32-2.26-2.17-2.26-2.17s.59-4.17,1.24-8.29c.21-1.33.42-2.66.63-3.84A14.13,14.13,0,0,0,23,39a10,10,0,0,0-.5-1l-3.23-6.8L21.6,30l-1.11-2.34L16,29.85s-1.12,1.07.85,1.78a5.43,5.43,0,0,1,1.71,2.18l2.78,5.84A7.61,7.61,0,0,1,22,43.52c0,.48,0,1-.1,1.6-.41,3.87-2,13.74-2,13.74s-.62,2.43,1.18,3.64,4.9.28,6.12-.3l7.87-3.74-1-2.12Z"
		/>
		<path
			fill="none"
			d="M38.86,48.07a.58.58,0,0,1,.2.79h0a.57.57,0,0,1-.79.19l-2.11-1.26A.57.57,0,0,1,36,47h0a.57.57,0,0,1,.79-.19Z"
		/>
		<path
			fill="none"
			d="M35.63,46.13c.12.07.09.36-.08.62h0c-.16.28-.39.44-.51.37l-.94-.57c-.13-.07-.09-.35.07-.62h0c.17-.28.4-.43.52-.36Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'CurrentTrials',
	mixins: [iconMixin],
}
</script>
