<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g>
			<path
				fill="none"
				d="M30.7,20.7V19c0-2.1-1.7-3.7-3.7-3.7c-1,0-1.9,0.4-2.6,1.1l-12,12c1,0.6,2.3,0.5,3.2-0.4l6.3-6.3
		c0.2-0.2,0.6-0.2,0.9,0c0.2,0.2,0.2,0.6,0,0.9l-1.6,1.6c0,0,0,0.1,0,0.1l-4.9,4.9c1,0.6,2.2,0.5,3.1-0.2c0.6-0.6,1.7-1.7,3.4-3.4
		c0,0,0.1,0,0.1,0l0.1-0.1c0.2-0.2,0.6-0.2,0.9,0c0.2,0.2,0.2,0.6,0,0.9l-1.4,1.4L20,30c0.4,0.3,0.9,0.4,1.4,0.4
		c0.8,0,1.3-0.2,2-0.9l1.3-1.3c0.2-0.2,0.5-0.5,0.8-0.8c0.2-0.2,0.6-0.2,0.9,0c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.4
		l-2.1,2.1c1,0.7,2.4,0.5,3.3-0.4l1.4-1.4c-1.2-1.1-2-2.8-2-4.7c0-0.3,0.4-0.6,0.7-0.5c0.3,0,0.5,0.3,0.5,0.5c0,4.3,5.1,6.6,7,4.2
		c0.3-0.4,0.6-0.6,1-0.7v-1.8h-0.5C32.9,25.7,30.7,23.4,30.7,20.7z M27.5,22.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6
		c0.3,0,0.6,0.3,0.6,0.6C28.1,21.9,27.9,22.1,27.5,22.2z"
			/>
			<path
				fill="none"
				d="M32.6,32.2c-0.4,0-0.6,0.3-0.7,0.6c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0.1,0.2,0.3,0.4,0.6,0.4
		c0.4,0,0.7-0.3,0.7-0.7C33.2,32.5,32.9,32.2,32.6,32.2z"
			/>
			<path fill="none" d="M37.5,59c0.5-0.2,0.8-0.7,0.9-1.2c0,0,0-0.1,0-0.1c0-0.5-0.4-1-0.9-1.4V59z" />
			<path
				fill="none"
				d="M41.1,33.5c0.3,0,0.5-0.2,0.6-0.5c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0-0.3-0.2-0.6-0.5-0.6
		c-0.4-0.1-0.7,0.2-0.8,0.6c0,0.1,0,0.2,0,0.2h0C40.4,33.3,40.7,33.5,41.1,33.5z"
			/>
			<path
				fill="none"
				d="M44,16.3C43.3,17,42.9,18,42.9,19v1.7c0,2.8-2.2,5-5,5h-0.4v1.9c0.2,0.1,0.3,0.1,0.5,0.3
		c0.1,0.1,0.3,0.2,0.4,0.4c1.6,2,5,0.7,6.3-1.6c0.2-0.3,0.6-0.4,0.9-0.2c0.3,0.2,0.4,0.6,0.2,0.8c-0.3,0.5-0.7,1-1.1,1.4
		c1.5,1.5,2,2.2,3.3,2.2c0.5,0,1-0.1,1.4-0.4l-2.1-2.1c-0.2-0.2-0.2-0.6,0-0.9c0.2-0.2,0.5-0.2,0.7-0.1c0.1,0,0.1,0.1,0.2,0.1
		l2.2,2.2c0.9,0.9,2.2,1,3.3,0.4l-3.7-3.7c0,0-0.1-0.1-0.1-0.2c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.9,0l3.4,3.4
		c0.9,0.9,2.2,1,3.3,0.4L57,28.7c0,0,0,0,0,0l-0.6-0.6l-3.9-3.9c-0.1-0.1-0.1-0.1-0.2-0.2l-1.5-1.5c-0.2-0.3-0.2-0.7,0.1-0.9
		c0.2-0.2,0.6-0.2,0.8,0l6.1,6.1c0.9,0.8,2.2,1,3.2,0.4L49.2,16.3C47.8,14.9,45.4,14.9,44,16.3z M46.9,25.4
		c-0.2,0.3-0.5,0.4-0.8,0.3c0,0,0,0,0,0c-1-0.5,0.7-1.4,0.8-4.8c0-0.3,0.4-0.6,0.7-0.5c0.3,0,0.5,0.3,0.5,0.5
		C48.1,22.5,47.7,24,46.9,25.4z"
			/>
			<circle fill="none" cx="36.8" cy="17.9" r="2.3" />
			<path
				:fill="fillComputed"
				d="M31,52.6c-0.3-0.1-0.6-0.2-0.9-0.3c0,0,0,0,0,0c-0.3-0.1-0.7,0-0.8,0.4c0,0,0,0,0,0c-0.1,0.3,0,0.7,0.4,0.8
		l0.9,0.3c0,0,0,0,0,0c0.3,0.1,0.7-0.1,0.8-0.4C31.5,53.1,31.3,52.7,31,52.6z"
			/>
			<path
				:fill="fillComputed"
				d="M18.3,34.9C18.3,34.9,18.3,34.9,18.3,34.9c0.4,0,0.7-0.3,0.7-0.6l0.1-0.9c0-0.3-0.2-0.7-0.5-0.7
		c-0.3,0-0.7,0.2-0.7,0.5c0,0.3-0.1,0.7-0.1,1C17.7,34.6,17.9,34.9,18.3,34.9z"
			/>
			<path
				:fill="fillComputed"
				d="M26.7,50.5c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.1-0.7,0-0.8,0.3c-0.1,0.3,0,0.7,0.3,0.8l0.8,0.5
		c0.3,0.1,0.6,0.1,0.8-0.2c0.2-0.3,0.2-0.7-0.1-0.9L26.7,50.5z"
			/>
			<path
				:fill="fillComputed"
				d="M19.8,41.2C19.7,41.2,19.7,41.2,19.8,41.2c-0.1-0.3-0.5-0.5-0.8-0.4c0,0,0,0,0,0c-0.3,0.1-0.5,0.5-0.4,0.8
		c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0.3,0.4,0.6,0.4c0.1,0,0.2,0,0.2,0c0.3-0.1,0.5-0.5,0.4-0.8C19.9,41.7,19.8,41.5,19.8,41.2z"
			/>
			<path
				:fill="fillComputed"
				d="M21.4,44.7C21.4,44.7,21.4,44.7,21.4,44.7c-0.2-0.3-0.6-0.4-0.9-0.2c-0.3,0.2-0.4,0.6-0.2,0.9
		c0.2,0.3,0.3,0.5,0.5,0.8c0,0,0,0,0,0c0.2,0.3,0.6,0.4,0.9,0.2c0.3-0.2,0.4-0.6,0.2-0.9C21.7,45.2,21.5,45,21.4,44.7z"
			/>
			<path
				:fill="fillComputed"
				d="M19.1,38.2c0-0.3-0.1-0.6-0.1-0.9c0-0.4-0.3-0.6-0.7-0.6c-0.4,0-0.6,0.3-0.6,0.7c0,0.3,0.1,0.7,0.1,1h0
		c0,0.3,0.4,0.6,0.7,0.5C18.9,38.9,19.1,38.6,19.1,38.2z"
			/>
			<path
				:fill="fillComputed"
				d="M23.7,47.9c-0.2-0.3-0.6-0.3-0.9,0c-0.3,0.2-0.3,0.6,0,0.9c0.2,0.2,0.5,0.5,0.7,0.7c0.2,0.2,0.6,0.2,0.8,0
		c0.3-0.2,0.3-0.6,0.1-0.9C24.1,48.3,23.9,48.1,23.7,47.9z"
			/>
			<path
				:fill="fillComputed"
				d="M43.7,52.2c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.4,0.4-0.3,0.7c0.1,0.3,0.4,0.5,0.8,0.5l0.9-0.4
		c0.3-0.1,0.4-0.5,0.3-0.7C44.4,52.3,44,52.1,43.7,52.2z"
			/>
			<path
				:fill="fillComputed"
				d="M50.1,47.7c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.2,0.6,0,0.8c0.2,0.3,0.6,0.3,0.9,0.1c0.2-0.2,0.4-0.5,0.7-0.7
		c0.2-0.2,0.2-0.6,0-0.8C50.7,47.5,50.3,47.5,50.1,47.7z"
			/>
			<path
				:fill="fillComputed"
				d="M55.4,36.6c-0.3-0.1-0.7,0.2-0.7,0.5c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.3,0.2,0.7,0.5,0.7s0.7-0.2,0.7-0.5
		c0-0.4,0.1-0.7,0.1-1C56,36.9,55.7,36.6,55.4,36.6z"
			/>
			<path
				:fill="fillComputed"
				d="M55.4,34.7c0.3,0,0.6-0.3,0.6-0.7c0-0.3-0.1-0.7-0.1-1c-0.1-0.3-0.3-0.5-0.6-0.5c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.1,0.6,0.1,0.9C54.7,34.4,55,34.7,55.4,34.7z"
			/>
			<path
				:fill="fillComputed"
				d="M54.8,40.6c-0.3-0.1-0.7,0.1-0.8,0.4c-0.1,0.3-0.2,0.6-0.3,0.9v0c-0.1,0.3,0,0.7,0.4,0.8
		c0.3,0.1,0.7,0,0.8-0.4c0.1-0.3,0.2-0.6,0.3-0.9C55.3,41,55.1,40.7,54.8,40.6z"
			/>
			<path
				:fill="fillComputed"
				d="M53.2,44.3c-0.3-0.2-0.7-0.1-0.8,0.2c-0.2,0.3-0.3,0.5-0.5,0.8v0c-0.2,0.3-0.1,0.7,0.2,0.9
		c0.3,0.2,0.7,0.1,0.9-0.2c0.2-0.3,0.3-0.6,0.5-0.8C53.7,44.9,53.5,44.5,53.2,44.3z"
			/>
			<path
				:fill="fillComputed"
				d="M47.1,50.3c-0.2,0.2-0.5,0.3-0.8,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0.1,0.3,0.5,0.5,0.8,0.3
		c0.3-0.2,0.5-0.4,0.8-0.5c0.2-0.2,0.2-0.4,0.1-0.7C47.8,50.3,47.5,50.2,47.1,50.3z"
			/>
			<path
				:fill="fillComputed"
				d="M27.5,20.9c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6
		C28.1,21.2,27.9,20.9,27.5,20.9z"
			/>
			<path
				:fill="fillComputed"
				d="M62.4,27.8L50.1,15.5c-0.9-0.9-2.1-1.4-3.4-1.4c-2.8,0-5,2.1-5.1,4.9v1.7c0,2.1-1.7,3.7-3.7,3.7h-0.5v-3.1
		c1.6-0.3,2.9-1.7,2.9-3.4c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5c0,1.7,1.2,3.2,2.9,3.4v3.1h-0.5c-2.1,0-3.7-1.7-3.7-3.7V19
		c0-1.3-0.5-2.5-1.4-3.4c-1.9-2-5.1-2-7.1-0.1L11,28c-0.2,0.2-0.2,0.6,0,0.8c1.1,1.1,2.7,1.4,4,0.9c1,0.9,2.4,1.2,3.6,0.9
		c0.7,0.8,2.7,1.6,4.5,0.7c1,0.9,3.5,1.5,5.3-0.2l1.5-1.5c2.1,1.4,4.7,1.5,6.3-0.5c0,0,0,0,0,0v7.4c-1.2-0.5-2.3-1-3.1-1.6
		c0.8-0.2,1.4-1,1.4-1.8c0,0,0,0,0,0c0-1.1-0.9-1.9-1.9-1.9c-0.9,0-1.7,0.6-1.9,1.5c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0.2,0,0.4,0.1,0.5c0.5,2.1,3,3.3,5.5,4.3v7.6c-2-1-3.7-2-3.7-3.7c0-1.1,0.8-1.9,2.8-2.9
		c0.2-0.2,0.4-0.5,0.2-0.7c-0.1-0.3-0.5-0.5-0.8-0.4c-1.6,0.8-3.5,2-3.5,4.1c0,2.6,2.6,3.9,5,5.1v7.2c-1.5-0.9-2.9-1.9-2.9-3.3
		c0-1.1,0.7-1.9,2.2-2.8c0.3-0.2,0.4-0.6,0.2-0.9c-0.2-0.3-0.6-0.4-0.9-0.2c-1.2,0.7-2.8,1.9-2.8,3.9c0,2.2,2,3.4,3.7,4.5
		c0.2,0.1,0.3,0.2,0.5,0.3V59c-0.1,0-0.3-0.1-0.4-0.2c-0.7-0.4-0.9-1.4-0.4-2c0.1-0.2,0-0.5-0.1-0.6c-0.2-0.3-0.6-0.3-0.9-0.1
		c-0.3,0.5-0.5,1-0.5,1.5c0,1.5,1.2,2.7,2.7,2.7c0,0,0.1,0,0.1,0c1.5,0,2.8-1.2,2.8-2.7c0-1.3-1-2.2-2.2-3v-7.3
		c1.5,0.8,2.8,1.7,2.8,3.2c0,1-0.6,1.8-2.1,2.8c-0.3,0.2-0.4,0.6-0.2,0.9c0.2,0.3,0.6,0.4,0.9,0.2c1.3-0.9,2.6-2,2.6-3.8
		c0-2.3-2-3.5-4-4.6v-7.7c2,0.9,3.6,1.8,3.6,3.3c0,1.4-1.2,2.3-2.9,3.2c-0.3,0.2-0.4,0.5-0.3,0.8c0.2,0.3,0.5,0.4,0.8,0.3
		c1.8-0.9,3.6-2.2,3.6-4.4c0-2.4-2.4-3.6-4.9-4.7v-7.8c1.6,1.9,4.2,1.7,6.2,0.4l1.6,1.6c0,0,0,0,0,0c1.4,1.4,3.7,1.5,5.2,0.2
		c1.4,0.7,3.2,0.5,4.4-0.6c0.3,0.1,0.7,0.1,1.1,0.1c1,0,2-0.4,2.7-1.1c1.3,0.4,2.7,0.1,3.8-1c0,0,0,0,0,0
		C62.7,28.4,62.7,28,62.4,27.8z M32.6,33.6c-0.3,0-0.5-0.2-0.6-0.4c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
		c0-0.3,0.3-0.6,0.7-0.6c0.4,0,0.7,0.3,0.7,0.7C33.2,33.3,32.9,33.6,32.6,33.6z M36.2,27.5c-0.4,0.1-0.8,0.3-1,0.7
		c-1.9,2.4-7,0.1-7-4.2c0-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.7,0.2-0.7,0.5c0,1.9,0.8,3.5,2,4.7l-1.4,1.4c-0.9,0.9-2.2,1-3.3,0.4l2.1-2.1
		c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2,0-0.4-0.2-0.5c-0.2-0.2-0.6-0.2-0.9,0c-0.3,0.3-0.5,0.5-0.8,0.8l-1.3,1.3c-0.7,0.6-1.2,0.9-2,0.9
		c-0.5,0-1-0.1-1.4-0.4l2.4-2.4l1.4-1.4c0.2-0.2,0.2-0.6,0-0.9c-0.2-0.2-0.6-0.2-0.9,0l-0.1,0.1c0,0-0.1,0-0.1,0
		c-1.8,1.8-2.8,2.8-3.4,3.4c-0.9,0.7-2.1,0.8-3.1,0.2l4.9-4.9c0,0,0-0.1,0-0.1l1.6-1.6c0.2-0.2,0.2-0.6,0-0.9
		c-0.2-0.2-0.6-0.2-0.9,0L15.5,28c-0.9,0.8-2.2,1-3.2,0.4l12-12c0.7-0.7,1.6-1.1,2.6-1.1c2.1,0,3.7,1.7,3.7,3.7v1.7c0,2.8,2.2,5,5,5
		h0.5V27.5z M38.3,57.6c0,0,0,0.1,0,0.1c-0.1,0.6-0.4,1-0.9,1.2v-2.8C38,56.6,38.3,57.1,38.3,57.6z M36.8,20.1c-1.2,0-2.3-1-2.3-2.3
		s1-2.3,2.3-2.3s2.3,1,2.3,2.3S38.1,20.1,36.8,20.1z M57.9,27.8l-6.1-6.1c-0.2-0.2-0.6-0.2-0.8,0c-0.3,0.2-0.3,0.6-0.1,0.9l1.5,1.5
		c0,0.1,0.1,0.1,0.2,0.2l3.9,3.9l0.6,0.6c0,0,0,0,0,0l0.4,0.4c-1,0.7-2.4,0.5-3.3-0.4l-3.4-3.4c-0.2-0.2-0.6-0.2-0.9,0
		c-0.2,0.2-0.2,0.6,0,0.8c0,0.1,0,0.1,0.1,0.2l3.7,3.7c-1.1,0.7-2.4,0.5-3.3-0.4l-2.2-2.2c-0.1,0-0.1-0.1-0.2-0.1
		c-0.2-0.1-0.5-0.1-0.7,0.1c-0.2,0.2-0.2,0.6,0,0.9l2.1,2.1c-0.4,0.3-0.9,0.4-1.4,0.4c-1.3,0-1.8-0.7-3.3-2.2
		c0.4-0.4,0.8-0.9,1.1-1.4c0.2-0.3,0.1-0.7-0.2-0.8c-0.3-0.2-0.7-0.1-0.9,0.2c-1.3,2.3-4.8,3.6-6.3,1.6c-0.1-0.1-0.2-0.3-0.4-0.4
		c-0.2-0.1-0.3-0.2-0.5-0.3v-1.9h0.4c2.8,0,5-2.2,5-5V19c0-1,0.4-2,1.1-2.7c1.5-1.4,3.8-1.4,5.2,0l11.9,11.8
		C60.1,28.8,58.7,28.6,57.9,27.8z"
			/>
			<path
				:fill="fillComputed"
				d="M47.6,20.3c-0.3,0-0.7,0.2-0.7,0.5c-0.1,3.4-1.8,4.3-0.8,4.8c0,0,0,0,0,0c0.3,0.2,0.7,0,0.8-0.3
		c0.8-1.4,1.2-3,1.2-4.6C48.1,20.6,47.9,20.4,47.6,20.3z"
			/>
			<path
				:fill="fillComputed"
				d="M38,36.7c0.1,0.3,0.5,0.5,0.8,0.4c1.9-0.9,3.7-1.9,4-3.7c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0,0,0,0c0,0,0-0.1,0-0.1
		c0,0,0,0,0,0c0,0,0,0,0,0C42.8,31.7,42,31,41,31c-1.1,0-1.9,0.9-1.9,1.9c0,0.9,0.6,1.6,1.4,1.8c-0.5,0.4-1.3,0.8-2.2,1.2
		C38,36.2,37.9,36.5,38,36.7z M40.3,32.9L40.3,32.9c0-0.1,0-0.2,0-0.2c0.1-0.4,0.4-0.6,0.8-0.6c0.3,0.1,0.5,0.3,0.5,0.6c0,0,0,0,0,0
		c0,0.1,0,0.2,0,0.3c-0.1,0.2-0.3,0.4-0.6,0.5C40.7,33.5,40.4,33.3,40.3,32.9z"
			/>
		</g>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'MedicalCrest',
	mixins: [iconMixin],
}
</script>
