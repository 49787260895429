<template>
	<div class="file-upload">
		<div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
			<h3>Drop files to upload</h3>
		</div>
		<div class="upload">
			<div class="flex flex-col">
				<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
						<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
							<table class="min-w-full divide-y divide-gray-200">
								<thead class="bg-gray-50">
									<tr>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											#
										</th>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Thumb
										</th>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Name
										</th>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Size
										</th>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Status
										</th>
										<th
											scope="col"
											class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
										>
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="!files.length">
										<td colspan="9">
											<div class="text-center p-5">
												<h4 class="pb-3">
													Drop files anywhere to upload
												</h4>

												<h4 class="pb-3">or</h4>

												<label
													:for="name"
													class="btn inline-flex items-center px-3 py-2 border border-transparent shadow-sm leading-4 font-medium rounded-md text-white bg-sc-green hover:bg-sc-green-hover"
												>
													<DocumentAddIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
													Add files
												</label>

												<button
													type="button"
													:for="name"
													class="btn inline-flex items-center ml-5 px-3 py-2 border border-transparent shadow-sm leading-4 font-medium rounded-md text-white bg-sc-green hover:bg-sc-green-hover"
													@click="onAddFolder"
												>
													<FolderAddIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
													Add Folder
												</button>
											</div>
										</td>
									</tr>

									<tr
										v-for="(file, index) in files"
										:key="file.id"
										:class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
									>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											{{ index }}
										</td>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<img v-if="file.thumb" class="td-image-thumb" :src="file.thumb" />
											<span v-else>No Image</span>
										</td>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<div class="filename">
												{{ file.name }}
											</div>
											<div v-if="file.active || file.progress !== '0.00'" class="progress">
												<div
													:class="{
														'progress-bar': true,
														'progress-bar-striped': true,
														'bg-danger': file.error,
														'progress-bar-animated': file.active,
													}"
													role="progressbar"
													:style="{
														width: file.progress + '%',
													}"
												>
													{{ file.progress }}%
												</div>
											</div>
										</td>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											{{ file.size }}
										</td>

										<td v-if="file.error" class="px-6 py-4 whitespace-nowrap text-sm text-red-500">
											{{ file.error }}
										</td>
										<td
											v-else-if="file.success"
											class="px-6 py-4 whitespace-nowrap text-sm text-green-500"
										>
											success
										</td>
										<td
											v-else-if="file.active"
											class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
										>
											active
										</td>
										<td v-else class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
										<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											<span class="relative z-0 inline-flex shadow-sm rounded-md">
												<button
													type="button"
													class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-500 text-sm font-medium text-gray-100 bg-yellow-500 hover:bg-yellow-600 disabled:opacity-50 focus:z-10 focus:outline-none"
													:disabled="!file.active"
													@click.prevent="
														file.active
															? $refs.upload.update(file, {
																	error: 'cancel',
															  })
															: false
													"
												>
													Cancel
												</button>
												<button
													type="button"
													class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-500 text-sm font-medium text-gray-100 bg-red-500 hover:bg-red-600 disabled:opacity-50 focus:z-10 focus:outline-none"
													:disabled="!file.active"
													@click.prevent="
														$refs.upload.update(file, {
															active: false,
														})
													"
												>
													Abort
												</button>
												<button
													type="button"
													class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-500 text-sm font-medium text-gray-100 bg-gray-500 hover:bg-gray-600 disabled:opacity-50 focus:z-10 focus:outline-none"
													@click.prevent="$refs.upload.remove(file)"
												>
													Remove
												</button>
												<button
													v-if="
														file.error &&
															file.error !== 'compressing' &&
															file.error !== 'image parsing' &&
															$refs.upload.features.html5
													"
													type="button"
													class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-500 text-sm font-medium text-gray-100 bg-sc-green hover:bg-sc-green-hover disabled:opacity-50 focus:z-10 focus:outline-none"
													:class="file.error ? 'rounded-r-md' : ''"
													@click.prevent="
														$refs.upload.update(file, {
															active: true,
															error: '',
															progress: '0.00',
														})
													"
												>
													Retry upload
												</button>
												<button
													v-if="!file.error && !file.active"
													type="button"
													class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-500 text-sm font-medium text-gray-100 bg-sc-green hover:bg-sc-green-hover disabled:opacity-50 focus:z-10 focus:outline-none"
													:disabled="
														file.success ||
															file.error === 'compressing' ||
															file.error === 'image parsing'
													"
													@click.prevent="
														file.success ||
														file.error === 'compressing' ||
														file.error === 'image parsing'
															? false
															: $refs.upload.update(file, {
																	active: true,
															  })
													"
												>
													Upload
												</button>
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div class="footer">
				<FileUpload
					ref="upload"
					v-model="files"
					class="btn btn-primary dropdown-toggle"
					:post-action="postAction"
					:put-action="putAction"
					:extensions="extensions"
					:accept="accept"
					:multiple="multiple"
					:directory="directory"
					:create-directory="createDirectory"
					:size="size || 0"
					:thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
					:headers="headers"
					:data="data"
					:drop="drop"
					:drop-directory="dropDirectory"
					:add-index="addIndex"
					@input-filter="inputFilter"
					@input-file="inputFile"
				/>

				<button
					v-show="!$refs.upload || !$refs.upload.active"
					type="button"
					class="btn inline-flex items-center px-3 py-2 border border-transparent shadow-sm leading-4 font-medium rounded-md text-white bg-sc-blue hover:bg-sc-blue-hover disabled:opacity-50"
					:disabled="!$refs.upload || $refs.upload.files.length === 0"
					@click.prevent="$refs.upload.active = true"
				>
					<UploadIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					Start Upload
				</button>
				<button
					v-show="$refs.upload && $refs.upload.active"
					type="button"
					class="btn inline-flex items-center px-3 py-2 border border-transparent shadow-sm leading-4 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 disabled:opacity-50"
					@click.prevent="$refs.upload.active = false"
				>
					<XCircleIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					Stop Upload
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { DocumentAddIcon } from '@heroicons/vue/outline'
import { FolderAddIcon } from '@heroicons/vue/outline'
import { UploadIcon } from '@heroicons/vue/outline'
import { XCircleIcon } from '@heroicons/vue/outline'

export default {
	components: {
		DocumentAddIcon,
		FolderAddIcon,
		UploadIcon,
		XCircleIcon,
	},
	props: {
		mediaUploadAccept: {
			type: Array,
			default: function() {
				return ['image/png', 'image/jpeg']
			},
		},
		mediaUploadExtensions: {
			type: Array,
			default: function() {
				return ['jpg', 'jpeg', 'png']
			},
		},
	},
	data() {
		return {
			files: [],
			accept: '',
			extensions: [],
			minSize: 1024,
			size: 1024 * 1024 * 100,
			multiple: true,
			directory: false,
			drop: true,
			dropDirectory: true,
			createDirectory: false,
			addIndex: false,
			thread: 3,
			name: 'file',
			postAction: '/upload/post',
			putAction: '/upload/put',
			headers: {
				'X-Csrf-Token': 'xxxx',
			},
			data: {
				_csrf_token: 'xxxxxx',
			},
			uploadAuto: false,
		}
	},
	beforeMount() {
		this.accept = this.mediaUploadAccept
		this.extensions = this.mediaUploadExtensions
	},
	mounted() {},
	methods: {
		inputFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				// Before adding a file
				// Filter system files or hide files
				if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
					return prevent()
				}
				// Filter php html js file
				if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
					return prevent()
				}
			}
			if (newFile && newFile.error === '' && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
				// Create a blob field
				newFile.blob = ''
				let URL = window.URL || window.webkitURL
				if (URL) {
					newFile.blob = URL.createObjectURL(newFile.file)
				}
				// Thumbnails
				newFile.thumb = ''
				if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
					newFile.thumb = newFile.blob
				}
			}
			// image size
			if (
				newFile &&
				newFile.error === '' &&
				newFile.type.substr(0, 6) === 'image/' &&
				newFile.blob &&
				(!oldFile || newFile.blob !== oldFile.blob)
			) {
				newFile.error = 'image parsing'
				let img = new Image()
				img.onload = () => {
					this.$refs.upload.update(newFile, {
						error: '',
						height: img.height,
						width: img.width,
					})
				}
				img.οnerrοr = () => {
					this.$refs.upload.update(newFile, {
						error: 'parsing image size',
					})
				}
				img.src = newFile.blob
			}
		},
		// add, update, remove File Event
		inputFile(newFile, oldFile) {
			if (newFile && oldFile) {
				// update
				if (newFile.active && !oldFile.active) {
					// beforeSend
					// min size
					if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
						this.$refs.upload.update(newFile, { error: 'size' })
					}
				}
				if (newFile.progress !== oldFile.progress) {
					// progress
				}
				if (newFile.error && !oldFile.error) {
					// error
				}
				if (newFile.success && !oldFile.success) {
					// success
				}
			}
			if (!newFile && oldFile) {
				// remove
				if (oldFile.success && oldFile.response.id) {
					// $.ajax({
					//   type: 'DELETE',
					//   url: '/upload/delete?id=' + oldFile.response.id,
					// })
				}
			}
			// Automatically activate upload
			if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
				if (this.uploadAuto && !this.$refs.upload.active) {
					this.$refs.upload.active = true
				}
			}
		},
		alert(message) {
			alert(message)
		},
		// add folder
		onAddFolder() {
			if (!this.$refs.upload.features.directory) {
				this.alert('Your browser does not support')
				return
			}
			let input = document.createElement('input')
			input.style =
				'background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;'
			input.type = 'file'
			input.setAttribute('allowdirs', true)
			input.setAttribute('directory', true)
			input.setAttribute('webkitdirectory', true)
			input.multiple = true
			document.querySelector('body').appendChild(input)
			input.click()
			input.onchange = () => {
				this.$refs.upload.addInputFile(input).then(function() {
					document.querySelector('body').removeChild(input)
				})
			}
		},
	},
}
</script>

<style scoped>
.file-upload .btn-group .dropdown-menu {
	display: block;
	visibility: hidden;
	transition: all 0.2s;
}
.file-upload .btn-group:hover > .dropdown-menu {
	visibility: visible;
}
.file-upload label.dropdown-item {
	margin-bottom: 0;
}
.file-upload .btn-group .dropdown-toggle {
	margin-right: 0.6rem;
}
.td-image-thumb {
	max-width: 4em;
	max-height: 4em;
}
.file-upload .filename {
	margin-bottom: 0.3rem;
}
.file-upload .btn-is-option {
	margin-top: 0.25rem;
}
.file-upload .footer {
	padding: 0.5rem 0;
}
.file-upload .edit-image img {
	max-width: 100%;
}
.file-upload .edit-image-tool {
	margin-top: 0.6rem;
}
.file-upload .edit-image-tool .btn-group {
	margin-right: 0.6rem;
}
.file-upload .footer-status {
	padding-top: 0.4rem;
}
.file-upload .drop-active {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: fixed;
	z-index: 9999;
	opacity: 0.8;
	text-align: center;
	background: #000;
}
.file-upload .drop-active h3 {
	margin: -0.5em 0 0;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 40px;
	color: #fff;
	padding: 0;
}
label.btn {
	cursor: pointer;
}
</style>
