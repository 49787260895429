<template>
	<div class="grid grid-cols-8 gap-5">
		<div v-for="(icon, index) in icons" :key="index" class="p-2 rounded-md shadow icon hover:bg-gray-100">
			<button class="flex justify-center w-full focus:outline-none" @click="selectIcon(icon)">
				<div>
					<component :is="'Icon' + icon" :key="index" color="#003764" class="m-auto"></component>
					<p class="text-lg">{{ iconName(icon) }}</p>
				</div>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	emits: ['selectIcon'],
	setup() {},
	data() {
		return {
			icons: [
				'AboutUs',
				'AcceptingNewPatients',
				'Accessibility',
				'AdditionalPhotos',
				'AdolescenceCare',
				'AmazonMusic',
				'Amenities',
				'AppleMusic',
				'APPJobs',
				'Appointments',
				'AthleticTrainers',
				'AwardsAchievements',
				'BabyStepsClasses',
				'Benefits',
				'BillPay',
				'Bones',
				'Cancer',
				'CancerTreatments',
				'Careers',
				'ClassesEvents',
				'ClinicalTrials',
				'Colonoscopy',
				'CommunitySchoolSupport',
				'ConcussionManagement',
				'CurrentTrials',
				'DepartmentResources',
				'DiabetesWellnessCenter',
				'DriveUpLabs',
				'Education',
				'Email',
				'EmployeeRecruitment',
				'FAQs',
				'FB',
				'FeaturedJobs',
				'FertilityPreservation',
				'FindLocation',
				'FindProvider',
				'GetDirections',
				'GoogleListening',
				'HealthPlans',
				'Heartbeat',
				'Hours',
				'Imaging',
				'InHospitalServices',
				'InOfficeServices',
				'Instagram',
				'LinkedIn',
				'Locums',
				'LungCancerScreenings',
				'MedicalCrest',
				'MedicalRecords',
				'MedicalSpa',
				'MeetOurTeam',
				'MeetTheDoc',
				'MenopauseLivingWell',
				'MissionValues',
				'NewPatientInfo',
				'Ophthamology',
				'OpticalCentre',
				'Optometry',
				'OurServices',
				'PartnershipBenefits',
				'PatientAccounting',
				'PatientPortal',
				'PatientPrivacy',
				'PatientRights',
				'PatientStories',
				'PaymentOptions',
				'Payments',
				'PharmacyServices',
				'Phone',
				'PhysicalTherapy',
				'PhysicianOpportunities',
				'Podiatry',
				'PregnancyChildbirth',
				'PrivacyCharter',
				'ProviderRecruitment',
				'Psychotherapy',
				'QualityManagement',
				'Questions',
				'RehabServices',
				'Resources',
				'SCEmail',
				'Skincare',
				'SleepDisordersCenter',
				'SocialMedia',
				'SportsMedicine',
				'Spotify',
				'StaffRecruitment',
				'Subscribe',
				'TermsOfUse',
				'TikTok',
				'TotalJointProcedures',
				'Twitter',
				'UrgentWaitTimes',
				'VaccineTypes',
				'VoiceOfThePatient',
				'WhySC',
				'YouTube',
			],
		}
	},
	methods: {
		iconName(icon) {
			// return icon.replace('Icon', '')
			return icon.replace(/([A-Z])/g, ' $1')
		},

		selectIcon(icon) {
			this.$emit('selectIcon', 'Icon' + icon)
		},
	},
}
</script>

<style>
.icon {
	min-width: 72px;
}
</style>
