<template>
	<div class="bg-white shadow overflow-hidden sm:rounded-lg">
		<Gallery type="locations" :slug="location.slug" :initial-gallery="location.gallery" />
	</div>
</template>
<script>
import { computed, onMounted, ref, reactive, inject, watch } from 'vue'

export default {
	setup() {
		const location = inject('location')
		console.log('Location: ', location)
		return {
			location,
		}
	},
}
</script>
