<template>
	<div class="flex-row">
		<div>
			<div>
				<!-- <FieldsInput
					v-model="path"
					:field="{
						type: 'input',
						inputType: 'text',
						label: 'Requested Path',
						model: 'path',
						required: true,
						colSpan: 'col-span-6',
					}"
				/> -->
				<FieldsUrl
					v-model="path"
					:field="{
						type: 'input',
						inputType: 'text',
						label: 'Requested Path',
						model: 'path',
						prefix: '/',
						restrictUrlPrefix: true,
						required: true,
						min: 2,
						enableCopy: false,
						copyWithPrefix: false,
						kebabCase: true,
						colSpan: 'col-span-6',
					}"
				/>
				<!-- @input="makeFullPath" -->
			</div>

			<div class="sm:pt-3">
				<label for="page" class="block text-sm font-bold text-gray-700 pb-1">
					Page
					<span>*</span>
				</label>

				<div class="mt-1 sm:mt-0 relative">
					<div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
						<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
					</div>
					<Multiselect
						v-model="page_id"
						placeholder="Search for internal page"
						:filter-results="true"
						:min-chars="1"
						:resolve-on-load="false"
						:loading="!pageOptions"
						:disabled="!pageOptions"
						:delay="500"
						:searchable="true"
						:options="pageOptions"
					/>
					<IconButtonLoading :loading="!pageOptions" class="text-black absolute top-2 right-0" />
				</div>
			</div>
		</div>

		<div class="mt-8 p-5 flex justify-end">
			<button
				type="button"
				class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600"
				@click="createDetour"
			>
				Create Detour
			</button>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import Multiselect from '@vueform/multiselect'
// import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { ExclamationCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		Multiselect,
		// Switch,
		// SwitchGroup,
		// SwitchLabel,
		ExclamationCircleIcon,
	},
	props: {},
	emits: ['createDetour'],
	setup() {
		const api = inject('api')
		const toast = useToast()
		return {
			api,
			toast,
		}
	},
	data() {
		return {
			page_id: null,
			path: null,
			errors: [],
			pageOptions: null,
		}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.length > 0
		},
	},
	mounted() {
		this.fetchPages()
	},
	methods: {
		createDetour() {
			let detour = {
				path: '/' + this.path,
				page_id: this.page_id,
			}
			console.log('Creating detour:', detour)
			this.$emit('createDetour', detour)
		},

		fetchPages() {
			// this.loaderShow()

			this.api.pages
				.internal()
				.then(data => {
					// console.log('Pages:', data)
					this.pageOptions = data.map(item => {
						return {
							value: item.id,
							label: '(' + item.path + ') ' + item.display_title,
						}
					})
				})
				.catch(error => {
					console.log(error)
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching pages:\n' + error.response.data.message)
				})
				.then(() => {
					// this.loaderHide()
				})
		},
		makeFullPath() {
			this.path = '/' + this.draft.slug
		},
	},
}
</script>

<style>
.icon {
	min-width: 72px;
}
</style>
