import { mergeAttributes } from '@tiptap/core'
import Table from '@tiptap/extension-table'

const CustomTable = Table.extend({
	renderHTML({ HTMLAttributes }) {
		return [
			'table',
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
			[
				'tbody',
				{
					class: 'bg-white divide-y divide-gray-200',
				},
				0,
			],
		]
	},
	addAttributes() {
		return {
			...this.parent?.(),
			class: {
				default: 'sc-table min-w-full divide-y divide-gray-200',
			},
		}
	},
})

export default CustomTable
