<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M33.67,63.74c-1.83-.39-3.7-.64-5.49-1.17a27.71,27.71,0,1,1,35-31.92c.25,1.16.38,2.34.56,3.5v4.19a2.88,2.88,0,0,0-.2.56c-1.59,11.71-8,19.52-19.11,23.46a48.91,48.91,0,0,1-6.33,1.38ZM10.76,35.79C11,50.25,22.21,61.43,36.38,61.25,50.07,61.07,61.44,49.54,61.26,36A25.3,25.3,0,0,0,35.92,10.75h-.5c-13.45.19-24.83,11.75-24.66,25"
		/>
		<path
			:fill="fillComputed"
			d="M41.83,47.08a1.53,1.53,0,0,1-1.19,1.82l-.14,0a25.52,25.52,0,0,1-4,1c-3.51.27-5.43-2-4.64-5.41.53-2.27,1.27-4.49,1.85-6.75.86-3.41-.06-4.3-3.42-3.41a2.2,2.2,0,0,1-.36,0,1.63,1.63,0,0,1,1.27-1.92,1,1,0,0,1,.25,0c1.75-.31,3.55-1,5.26-.8,2.8.25,3.85,2.45,3,5.67-.57,2.22-1.3,4.4-1.82,6.63-.72,3.12.12,3.93,3.29,3.32l.66-.14"
		/>
		<path
			:fill="fillComputed"
			d="M38.75,28.55a3.38,3.38,0,0,1-3.53-3.18,3.42,3.42,0,0,1,3.51-3.22,3.38,3.38,0,0,1,3.57,3.18.28.28,0,0,0,0,.09,3.33,3.33,0,0,1-3.51,3.13h0"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'NewPatientInfo',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke-miterlimit: 10;
}
</style>
