<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g id="Group_368" data-name="Group 368">
			<path
				id="Path_291"
				data-name="Path 291"
				:fill="fillComputed"
				d="M15.85,54.15l-3.8-9.44c.4-.19.74-.39,1.1-.53,4.18-1.69,8.38-3.35,12.55-5.08a4.93,4.93,0,0,1,4.13,0C34,41,38.13,42.68,42.28,44.46c1.77.76,2.38,2.06,1.71,3.59a2.81,2.81,0,0,1-3.7,1.44l-.12-.06c-1.53-.63-3-1.34-4.56-2-.3-.12-.73.08-1.1.13l0,.57c1.94.8,3.85,1.68,5.82,2.36A3.69,3.69,0,0,0,45,48.24a3.76,3.76,0,0,0,.16-1.7c-.1-.83.17-1.08.83-1.35,2.5-1,5-2,7.47-3,1.75-.69,3.31-.18,4,1.3a3.16,3.16,0,0,1-1.5,4.2l-.13.06c-2,.87-4,1.65-6.08,2.47-3.73,1.5-7.47,3-11.19,4.51a6.31,6.31,0,0,1-5.78-.2c-2.32-1.25-4.72-2.35-7.13-3.41a2.84,2.84,0,0,0-2-.08C21.06,52,18.51,53.08,15.85,54.15Z"
			/>
		</g>
		<path
			:fill="fillComputed"
			d="M36.75,16.69c-3.86,0-6.55,2.54-6.55,6.19a6.39,6.39,0,0,0,2,4.58l8.16,8.16a1.24,1.24,0,0,0,.48.36,1.28,1.28,0,0,0,.48.09.62.62,0,0,0,.19,0,.9.9,0,0,0,.27-.07,1.17,1.17,0,0,0,.48-.34l8.18-8.18a6.39,6.39,0,0,0,2-4.58c0-3.65-2.69-6.19-6.54-6.19a6.45,6.45,0,0,0-4.61,1.9A6.44,6.44,0,0,0,36.75,16.69Zm5.85,4.79c0-.93,1.41-2.29,3.36-2.29,2.45,0,4,1.45,4,3.69a4,4,0,0,1-1.29,2.81l-7.36,7.36L34,25.69a4,4,0,0,1-1.29-2.81c0-2.24,1.59-3.69,4-3.69,1.94,0,3.35,1.36,3.35,2.29a1.25,1.25,0,0,0,2.5,0Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'MissionValues',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke-miterlimit: 10;
}
</style>
