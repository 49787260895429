<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M36.68,54.7c-2.33,0-4.66,0-7,0-.51,0-.7-.16-.74-.67a7.87,7.87,0,0,0-3-5.46,15,15,0,0,1-5.14-9.52,15.53,15.53,0,0,1,4.73-13.93,15.89,15.89,0,0,1,21.18-1,16.07,16.07,0,0,1,2.43,22.6A16.36,16.36,0,0,1,46.94,49a7,7,0,0,0-2.47,4.69c-.11,1-.1,1-1.09,1Zm4.18-22.45c.18-.73.35-1.5.57-2.26.11-.37,0-.56-.34-.64-.72-.2-1.43-.42-2.16-.56-.91-.17-.91-.13-1-1.1a1.17,1.17,0,0,0-1.17-1.18h-.05a1.15,1.15,0,0,0-1.24,1v.05a2.61,2.61,0,0,0,0,.68A.64.64,0,0,1,35,29H34.9a6.84,6.84,0,0,0-1.57.68,4.05,4.05,0,0,0-.06,7c.82.48,1.73.78,2.59,1.2a9.3,9.3,0,0,1,2,1,1.3,1.3,0,0,1,.3,1.82,1.24,1.24,0,0,1-.74.5,3,3,0,0,1-.71.2,7.81,7.81,0,0,1-4.23-.74l-.61-.26c-.22.87-.4,1.67-.63,2.45-.1.35,0,.57.33.66.92.26,1.82.57,2.75.74s1,.12,1,1.08c0,.13,0,.27,0,.4a1.24,1.24,0,0,0,2.45-.13,7.06,7.06,0,0,0,0-.92c0-.35.12-.5.45-.6a8.06,8.06,0,0,0,1.65-.64,4.35,4.35,0,0,0,1.82-5.89,4.08,4.08,0,0,0-.52-.78,7.7,7.7,0,0,0-3-1.79c-.83-.34-1.65-.7-2.44-1.11a1.16,1.16,0,0,1-.68-1.28,1.22,1.22,0,0,1,1.1-1,6.11,6.11,0,0,1,1.81-.07c.92.14,1.82.42,2.81.66Z"
		/>
		<path
			:fill="fillComputed"
			d="M36.68,56.52H43.1a1.13,1.13,0,0,1,.29,2.25h-13c-.74,0-1.21-.3-1.35-.82a1.15,1.15,0,0,1,1.23-1.45Z"
		/>
		<path
			:fill="fillComputed"
			d="M36.72,61.89H30.2a1.11,1.11,0,0,1-1.19-1,1.08,1.08,0,0,1,.17-.68,1.36,1.36,0,0,1,1.3-.55h12.3a4.34,4.34,0,0,1,.51,0,1.14,1.14,0,0,1,1.07,1.19,1.08,1.08,0,0,1-1.1,1.06H36.72Z"
		/>
		<path
			:fill="fillComputed"
			d="M61.23,35.55c1.2,0,2.4,0,3.6,0a1.11,1.11,0,0,1,.51,2.13,1.84,1.84,0,0,1-.66.12H57.76a1.2,1.2,0,0,1-1.34-1s0-.08,0-.12c0-.67.52-1.1,1.33-1.11h3.49Z"
		/>
		<path
			:fill="fillComputed"
			d="M35.55,12.19c0-1.2,0-2.4,0-3.61a1.17,1.17,0,0,1,.83-1.18,1,1,0,0,1,1.16.39,1.42,1.42,0,0,1,.27.79c0,2.39,0,4.77,0,7.16a1.13,1.13,0,0,1-2.26.14v-.19c0-1.17,0-2.33,0-3.5Z"
		/>
		<path
			:fill="fillComputed"
			d="M12.13,37.81c-1.18,0-2.36,0-3.55,0a1.17,1.17,0,0,1-1.19-.88,1,1,0,0,1,.45-1.14,1.54,1.54,0,0,1,.8-.23c2.35,0,4.69,0,7,0a1.13,1.13,0,0,1,1.27,1,.86.86,0,0,1,0,.16,1.19,1.19,0,0,1-1.26,1.11h0c-1.18,0-2.36,0-3.54,0Z"
		/>
		<path
			:fill="fillComputed"
			d="M26.18,19.84c-.3-.23-.7-.4-.88-.7-1.26-2.09-2.48-4.21-3.68-6.33a1.11,1.11,0,0,1,.4-1.53h0a1.11,1.11,0,0,1,1.51.39l0,.05C24.79,13.8,26,15.89,27.19,18a1.2,1.2,0,0,1-.39,1.65A1.35,1.35,0,0,1,26.18,19.84Z"
		/>
		<path
			:fill="fillComputed"
			d="M53.51,26.19c.24-.32.42-.74.73-.93,2.08-1.24,4.18-2.44,6.29-3.64A1.09,1.09,0,0,1,62,22l0,.05a1.07,1.07,0,0,1-.29,1.47l-.11.06c-2.14,1.26-4.28,2.5-6.45,3.71a1.2,1.2,0,0,1-1.59-.58A1.31,1.31,0,0,1,53.51,26.19Z"
		/>
		<path
			:fill="fillComputed"
			d="M19.86,26.16a1.21,1.21,0,0,1-1.24,1.19,1.09,1.09,0,0,1-.56-.15c-.89-.47-1.76-1-2.63-1.5C14.2,25,13,24.29,11.76,23.58a1.09,1.09,0,0,1-.62-1.13,1,1,0,0,1,.81-.93,1.46,1.46,0,0,1,.92.13c2.09,1.18,4.18,2.37,6.24,3.6A3.38,3.38,0,0,1,19.86,26.16Z"
		/>
		<path
			:fill="fillComputed"
			d="M47.16,19.71a1.08,1.08,0,0,1-1.17-1,1.16,1.16,0,0,1,.19-.7c.65-1.16,1.33-2.31,2-3.47.51-.89,1-1.78,1.55-2.67.41-.71,1-.93,1.61-.59a1.16,1.16,0,0,1,.42,1.58.83.83,0,0,1-.08.12l-3.46,6C48,19.4,47.67,19.74,47.16,19.71Z"
		/>
		<path :fill="fillComputed" d="M33,62.84h7.41c-.13,1.31-1.8,2.6-3.49,2.71A3.89,3.89,0,0,1,33,62.84Z" />
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Benefits',
	mixins: [iconMixin],
}
</script>
