<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g id="Group_56" data-name="Group 56">
			<g id="Group_51" data-name="Group 51">
				<path
					id="Path_47"
					data-name="Path 47"
					:fill="fillComputed"
					d="M34.55,29.34A1.8,1.8,0,0,0,33,28.55c-1,0-1.28.61-1.28,1.08a.76.76,0,0,0,.29.63,3.08,3.08,0,0,0,1.11.53,5.34,5.34,0,0,1,1.88.93,2.55,2.55,0,0,1,.79,2,3,3,0,0,1-3.23,3A3.84,3.84,0,0,1,29.4,35l1.19-1.12a2.14,2.14,0,0,0,1.94,1.23A1.29,1.29,0,0,0,34,33.91s0-.08,0-.12a1.1,1.1,0,0,0-.36-.83,4.06,4.06,0,0,0-1.44-.68,4.56,4.56,0,0,1-1.54-.81,2.14,2.14,0,0,1-.74-1.72,2.7,2.7,0,0,1,3-2.73A3.56,3.56,0,0,1,35.5,28.1Z"
				/>
			</g>
			<g id="Group_52" data-name="Group 52">
				<path
					id="Path_48"
					data-name="Path 48"
					:fill="fillComputed"
					d="M41.54,31.8A2.19,2.19,0,0,0,40,31.21a1.81,1.81,0,0,0-1.9,1.72v.18A1.88,1.88,0,0,0,39.91,35H40a2.21,2.21,0,0,0,1.5-.59v1.85a3.84,3.84,0,0,1-1.75.43,3.79,3.79,0,0,1-2.61-1,3.46,3.46,0,0,1-1.08-2.59,3.48,3.48,0,0,1,3.61-3.62,4.14,4.14,0,0,1,1.83.4Z"
				/>
			</g>
			<g id="Group_53" data-name="Group 53">
				<path
					id="Path_49"
					data-name="Path 49"
					:fill="fillComputed"
					d="M38.84,28.94A6.2,6.2,0,0,1,41,25.81a3.75,3.75,0,0,0-1.52.88,4,4,0,0,0-1,1.51v-.05c0-.23,0-.46,0-.69a3.81,3.81,0,0,1,.32-1.2,3.06,3.06,0,0,1,1.53-1.46,4.39,4.39,0,0,1,1.33-.37c.36,0,.73-.06,1.09-.08h.06c0,.09,0,.18,0,.28a4.54,4.54,0,0,1-.71,2.73,3.35,3.35,0,0,1-1.71,1.3,4.82,4.82,0,0,1-1.35.26l-.21,0"
				/>
			</g>
			<g id="Group_54" data-name="Group 54">
				<path
					id="Path_50"
					data-name="Path 50"
					:fill="fillComputed"
					d="M37.28,21.61c-.12.19-.25.38-.36.57a3.42,3.42,0,0,0-.44,1.19,2.21,2.21,0,0,0,.64,2,3.3,3.3,0,0,0,1,.7l.09,0a3.17,3.17,0,0,0,.69-2.41,2.84,2.84,0,0,0-.63-1.26,3.72,3.72,0,0,0-.9-.78l-.08,0"
				/>
			</g>
			<g id="Group_55" data-name="Group 55">
				<path
					id="Path_51"
					data-name="Path 51"
					:fill="fillComputed"
					d="M37.7,28.49a2.81,2.81,0,0,0-1.3-2,2.31,2.31,0,0,1,.94.56A2.58,2.58,0,0,1,38,28v0c0-.15,0-.29,0-.44a2,2,0,0,0-.19-.75,1.89,1.89,0,0,0-.94-.93,2.74,2.74,0,0,0-.83-.25l-.69-.06h0c0,.06,0,.12,0,.18a2.89,2.89,0,0,0,.43,1.72,2,2,0,0,0,1.06.83,3.07,3.07,0,0,0,.85.18h.13"
				/>
			</g>
		</g>
		<path
			:stroke="fillComputed"
			class="cls-2"
			d="M50.38,22.16a3,3,0,0,0-3-3H26.28a3,3,0,0,0-3,3v13.2L31,42.49,34.83,39a3,3,0,0,1,4,0l3.83,3.52,7.74-7.13Z"
		/>
		<path
			:stroke="fillComputed"
			class="cls-2"
			d="M53,29.41l-2.58-2v8l-7.74,7.13L38.81,39a3,3,0,0,0-4,0L31,42.49l-7.74-7.13v-8l-2.58,2a6,6,0,0,0-1.94,4.42V50.77a3,3,0,0,0,.93,2.16,3,3,0,0,0,2.08.85H51.88a3,3,0,0,0,3-3V33.83A6,6,0,0,0,53,29.41Z"
		/>
		<path :stroke="fillComputed" class="cls-2" d="M54.44,31.55A5.93,5.93,0,0,0,53,29.41l-2.58-2v7.92Z" />
		<path :stroke="fillComputed" class="cls-2" d="M20.69,29.41a6,6,0,0,0-1.49,2.14l4.07,3.76V27.39Z" />
		<path
			:stroke="fillComputed"
			class="cls-2"
			d="M38.81,39.07a3,3,0,0,0-4,0L19.77,53a3,3,0,0,0,2,.78H51.88a3,3,0,0,0,2-.78Z"
		/>
		<path :stroke="fillComputed" class="cls-2" d="M38.92,18.43a3.18,3.18,0,0,0-4.19,0l-.92.72h6Z" />
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'SCEmail',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: none;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 1.5px;
}
</style>
