<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M26.59,39.48c.09,1.85,4.92,4.91,8.22.46-1.4,0-2.05,1-4.1.93a8.94,8.94,0,0,1-4.12-1.39Zm22.37,0c-.1,1.85-4.92,4.91-8.22.46,1.4,0,2,1,4.1.93A8.94,8.94,0,0,0,49,39.48Zm-15.53,20C30,57.67,27,53.55,25.5,50c-.35,1.23-1.95,1.12-2.42-.29a6.09,6.09,0,0,1-.13-2c-.07-1.25-1.68-3.31-2.35-4.73-1-2.11-1-5,.84-5.52h0c-8.61-20.6,9.16-26.64,10.52-26-9.13,3.35-13.82,13.41-10.51,26,1.64,0,3.07,2.11,2.4,3.08-.45-1.3-1.21-2.2-2.09-2-2.87.63,1.47,7.74,1.54,9.24.07,1.3.43,3.1,2,1.88a11.17,11.17,0,0,1-.93-3.49C26,50.11,30,56.1,34.13,58.85c3.1,2.07,4.86,1.66,7.65-.4,3.92-2.9,7.61-8.52,9.14-12.26a11.1,11.1,0,0,1-1,3.54c1.21.39,2.14-.74,2.26-1.95.21.57.27,3.51-2.52,2.54a22.17,22.17,0,0,1-7.22,8.86,8.27,8.27,0,0,1-9,.34Zm-.7-8.15a1.78,1.78,0,0,0,2,.8c1.46-.06,1.89.6,3.38.34a5.68,5.68,0,0,1,2.54-.32c-1.3,1.2-4.45,1.51-6.73.12a3.68,3.68,0,0,0,3.67,2.09,5.19,5.19,0,0,0,3.82-1.85c.46-.41.59-.24,1.23-.6.23-.13.16-.23,0-.32-2.28,0-2.46-1.19-3.49-.92a2.51,2.51,0,0,0-.88.45c-.59.47-.45.34-1-.08a1.88,1.88,0,0,0-.93-.39c-.84-.13-1.71,1.26-3.63.68ZM54.08,40.89c.63-2.4-1.36-2.86-2.78-1.55a12.43,12.43,0,0,1-.82,2.32c.54-6.26-.72-10.77-3.3-14-4.22-5.27-11-6.36-11.29-6.43C29,23.56,23.78,29.5,23.51,38.53c-1.86-7.28,3-16.25,10-18.7,9.35-3.3,17.56,1.05,17.91,2A25.24,25.24,0,0,0,36.08,21C49.64,22,52.59,33.49,51.4,38.91a1.26,1.26,0,0,1,.19-.27c2.18-2.12,4-.36,3.31,2.16-.3,1.84-2,4.49-2.5,6-.41-.67,1.59-5.33,1.68-5.91Zm0-3.26a5.74,5.74,0,0,0,1-1.2c4.3-7.13.62-22.88-10.48-25.14,7.2,5,13.76,14.64,9.44,26.34ZM25.1,35.32a10.44,10.44,0,0,1,8.55,2c.75.67,1.3,1.37,1.64,1.16.11-.27-.6-1.29-1.25-2-1.88-2.15-5-2.9-8.94-1.18Zm16.11,2.47c1.85-2,4.93-3.07,8.88-2.47-4.05-1.79-7.23-1-9,1.36a5.91,5.91,0,0,0-.95,1.62C40,39.08,40.25,38.84,41.21,37.79Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'MedicalSpa',
	mixins: [iconMixin],
}
</script>
