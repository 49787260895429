import BaseModule from './base-module'

const UserMutations = {
	INCREMENT_LOGIN_ATTEMPTS: 'INCREMENT_LOGIN_ATTEMPTS',
	SET_USER: 'SET_USER',
	SET_ROLES: 'SET_ROLES',
	LOGOUT: 'LOGOUT',
}

var crud = new BaseModule('users/')

const state = {
	...crud.state,
	current: {
		id: '',
		name: 'Logged Out',
		email: 'Logged Out',
	},
}
const getters = {
	...crud.getters,
}
const actions = {
	...crud.actions,
	login({ commit, dispatch, resolve, reject }, credentials) {
		return this.$app.api.users
			.csrf()
			.then(res => {
				//console.log('CSRF Success - Result: ', res)
				commit(UserMutations.INCREMENT_LOGIN_ATTEMPTS)
				return this.$app.api.users
					.login(credentials)
					.then(userData => {
						commit(UserMutations.SET_USER, userData)
						//console.log('Login Success! Response: ', userData)
						return userData
					})
					.catch(err => {
						console.log('Failed to login! Error: ', err)
						throw err
					})
			})
			.catch(error => {
				console.log('Failed to load CSRF! Error: ', error)
				throw error
			})
	},

	logout({ commit, resolve, reject }) {
		//console.debug('Starting Logout Action on store...')
		commit(UserMutations.LOGOUT)
		return this.$app.api.users
			.logout()
			.then(res => {
				console.debug('Logged out from server.')
				return res
			})
			.catch(error => {
				console.log('Failed to logout! Error: ', error)
				throw error
			})
	},
	me({ commit }) {
		//console.debug("Loading the logged in user's data...")
		return this.$app.api.users
			.me()
			.then(res => {
				//console.log('Me from user store: ', res)
				return res
			})
			.catch(error => {
				// console.log('Failed to fetch me! Error: ', error)
				throw error
			})
		/*
		try {
			const res = await axios.get('me')
			if (res.status != 200) throw res
			commit(UserMutations.SET_USER, res.data.data)
			return res
		} catch (err) {
			//console.log("Failed to load user data! Error: ", err.response.status)
			commit(LOGOUT)
			throw err
		}
		*/
	},
}
const mutations = {
	...crud.mutations,
	[UserMutations.INCREMENT_LOGIN_ATTEMPTS](state) {
		this.state.loginAttempts = ++this.loginAttempts
	},
	[UserMutations.SET_USER](state, userData) {
		//console.log('SET_USER Starting!')
		state.current = userData
		state.loaded = true
		//console.log('SET_USER Complete! CurUser: ', state.current)
	},
	/*
	[SET_ROLES](state, roles) {
		let newRoles = []
		roles.forEach(element => {
			//console.log("Role: " + element.name)
			newRoles.push(element.name)
		})
		//Vue.set(state, 'roles', newRoles)
		state.roles = newRoles
		localStorage.setItem('roles', newRoles)
	},
	*/
	[UserMutations.LOGOUT](state) {
		//console.log('Logging out at the browser level.')
		state.current.name = 'Logged Out!'
		state.roles = ['Guest']
		state.loaded = false
		console.debug('Logged out at the browser level.')
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}

/*
const getters = {
	getUserId: state => state.current.id,
	getUserName: state => state.current.name,
	getLoggedIn: state => state.isLoggedIn,
	getRoles: state => state.roles,
	isLoggedIn: state => !!localStorage.getItem('loggedIn'),
}
*/
