<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M8,51.59V41.2c0-3.37,0-6.75,0-10.12,0-3.84,0-7.69,0-11.53a2.19,2.19,0,0,1,2-2.37H61.64a2.16,2.16,0,0,1,2.33,2c0,.1,0,.21,0,.31,0,2.89,0,5.78,0,8.67V51.59h5.76c.51,0,.72.19.69.7a3.76,3.76,0,0,1-.19,1.25,2,2,0,0,1-2,1.28H3.8a2.16,2.16,0,0,1-2.23-2.11.28.28,0,0,1,0-.13c0-.92.07-1,1-1ZM61.81,19.36H10.2V49.41H61.81ZM41.88,51.61H30.12a.86.86,0,0,0,.62.89,3.57,3.57,0,0,0,1,.16q4.29,0,8.58,0a3.92,3.92,0,0,0,1-.15.84.84,0,0,0,.64-.9"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M31.69,28.19c-.1-.59-.23-1.14-.29-1.69a4,4,0,0,1,3.41-4.44,4.06,4.06,0,0,1,.7,0,7,7,0,0,1,2.51.4c.25.1.55.05.81.13a2.39,2.39,0,0,1,1.58,1.76,6.53,6.53,0,0,1-.1,3.84c.46.18.55.32.51.82,0,.28-.07.55-.12.83A1.87,1.87,0,0,1,40,31a.56.56,0,0,0-.19.36,4.92,4.92,0,0,1-1,2.88,1.16,1.16,0,0,0-.22.62c0,.48,0,1,0,1.46a1.21,1.21,0,0,0,.61,1A1.89,1.89,0,0,1,40.11,39c0,.3-.07.6-.09.8a10,10,0,0,0-1.52.4A3.27,3.27,0,0,0,37,42.12c-.06.15-.1.32-.17.47a.86.86,0,0,1-.21.29.92.92,0,0,0-.32,1.27.22.22,0,0,0,.05.08.93.93,0,0,0,1.48-1.11l0,0a.27.27,0,0,1-.08-.23A2.71,2.71,0,0,1,39,41a1.68,1.68,0,0,1,2.42,1.35,7.21,7.21,0,0,1-.08,1.39c0,.1-.17.22-.28.29a.92.92,0,0,0,.25,1.71.93.93,0,0,0,1-1.39.56.56,0,0,1,0-.38,4.72,4.72,0,0,0,0-2.39A2.23,2.23,0,0,0,41,40.21V38a16.67,16.67,0,0,1,1.62.67,15.63,15.63,0,0,1,1.9,1.1,3.22,3.22,0,0,1,1.28,2.28c.19,1.17.32,2.35.44,3.54a.91.91,0,0,1-.71,1.09.68.68,0,0,1-.2,0l-.62,0H26.89c-.83,0-1.16-.33-1.09-1.15.11-1.07.26-2.15.41-3.22,0-.21.1-.42.14-.64A3.42,3.42,0,0,1,28,39.43,16,16,0,0,1,31.24,38c0,.73,0,1.45,0,2.16,0,.42.15.83.2,1.24a.47.47,0,0,1-.11.34A1.49,1.49,0,0,0,31,43.55a1.48,1.48,0,0,0,2.77-.8,1.42,1.42,0,0,0-1.22-1.37.29.29,0,0,1-.26-.26,7.85,7.85,0,0,1-.19-2.6,1.66,1.66,0,0,1,.69-1.32,1.23,1.23,0,0,0,.55-1c0-.1,0-.19,0-.29a2.62,2.62,0,0,0-.41-2,4.23,4.23,0,0,1-.82-2.54.64.64,0,0,0-.2-.39,1.9,1.9,0,0,1-.69-1.24,5.71,5.71,0,0,1-.09-.82c0-.49.06-.59.49-.69"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PatientPortal',
	mixins: [iconMixin],
}
</script>
