<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M51.26,21.19c-1.07-1.28-2.44-2.6-4.2-2.76h-.3a4.78,4.78,0,0,0-3.37,1.69,13.23,13.23,0,0,0-3.1,6.51,7.36,7.36,0,0,0,7.12,5.22,1,1,0,0,1,0,2,9.23,9.23,0,0,1-9.35-9.09v-5H34v5a9.23,9.23,0,0,1-9.35,9.1,1,1,0,1,1,0-2,7.35,7.35,0,0,0,7.07-5.1,13.19,13.19,0,0,0-3.1-6.55,4.78,4.78,0,0,0-3.37-1.69h-.3c-1.76.16-3.13,1.48-4.2,2.76-5.2,6.18-9,14.94-8.52,23.12.17,3.19,1.06,7.6,4.45,8.9a5.43,5.43,0,0,0,2,.37,11.23,11.23,0,0,0,6.21-2.44,18.38,18.38,0,0,0,6.33-7.82,17.45,17.45,0,0,0,1.24-6.62c0-.05,0-.1,0-.15a10.84,10.84,0,0,0-4.33,2.78,1,1,0,0,1-.71.3,1,1,0,0,1-.68-.27,1,1,0,0,1,0-1.39,13.1,13.1,0,0,1,18.52-.33,1,1,0,1,1-1.36,1.42,11.11,11.11,0,0,0-4.33-2.58c0,.07,0,.15,0,.22a17.45,17.45,0,0,0,1.24,6.62,18.38,18.38,0,0,0,6.33,7.82,11.23,11.23,0,0,0,6.21,2.44,5.43,5.43,0,0,0,2-.37c3.39-1.3,4.28-5.71,4.45-8.9C60.22,36.13,56.46,27.37,51.26,21.19Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'LungCancerScreenings',
	mixins: [iconMixin],
}
</script>
