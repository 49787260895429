<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M56.83,29.86,54.2,27.23l-8.08-8.08-2.63-2.63a.65.65,0,0,0-.93,0l-.2.2a.65.65,0,0,0,0,.93L45,20.28,25.77,39.49a2.19,2.19,0,0,0,0,3.09l5,5a2.19,2.19,0,0,0,3.09,0L53.07,28.37,55.7,31a.64.64,0,0,0,.92,0l.21-.2A.65.65,0,0,0,56.83,29.86ZM32.78,45,28.4,40.57l3.22-3.22L33.21,39a.44.44,0,0,0,.62,0l.57-.57a.43.43,0,0,0,0-.61l-1.6-1.6,2.06-2.06,1.6,1.6a.44.44,0,0,0,.62,0l.56-.57a.44.44,0,0,0,0-.62l-1.59-1.6,2.13-2.13,1.6,1.59a.44.44,0,0,0,.62,0l.57-.56a.44.44,0,0,0,0-.62l-1.6-1.6,3.14-3.14,4.38,4.38Z"
		/>
		<path
			:fill="fillComputed"
			d="M29.38,48.45l-.5-.5-3.49-3.48L24.9,44a.44.44,0,0,0-.62,0l-.36.36a.44.44,0,0,0,0,.62l.49.49-3.15,3.14a1.11,1.11,0,0,0,0,1.55l2,1.95a1.09,1.09,0,0,0,1.55,0l3.14-3.14.49.49a.44.44,0,0,0,.62,0l.36-.36A.44.44,0,0,0,29.38,48.45Z"
		/>
		<polygon :fill="fillComputed" points="14.02 60.31 12.12 60.25 21.34 51.03 22.32 52.01 14.02 60.31" />
		<path
			:fill="fillComputed"
			d="M61.82,19.81l-2-1.95-4.38-4.38-2-2a.65.65,0,0,0-.93,0l-.21.2a.67.67,0,0,0,0,.93l2,1.95-5.31,5.31,4.38,4.38L58.74,19l1.95,2a.65.65,0,0,0,.93,0l.2-.2A.65.65,0,0,0,61.82,19.81Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'VaccineTypes',
	mixins: [iconMixin],
}
</script>
