<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M36.45,15.53a2.54,2.54,0,0,1-1.51-1.32,1.92,1.92,0,0,1,0-1.54l.45-1.14A26.74,26.74,0,0,1,25.18,8.69a1.5,1.5,0,0,0-2,.51l-1.39,2.39a1.53,1.53,0,0,0,.83,2c.82.42,1.66.8,2.51,1.14C24.29,20.19,23,32.61,27.47,40.6L25.22,55.53A1.41,1.41,0,1,0,28,56l2.18-14.47c1-1,6.11-6.31,8.8-18.18a71.15,71.15,0,0,1-.37-7.14C37.85,16,37.15,15.76,36.45,15.53ZM29.38,38.17a29.74,29.74,0,0,1-2.24-11.56,16.2,16.2,0,0,0,4,1.16,13.34,13.34,0,0,0,3.64.06A34.51,34.51,0,0,1,29.38,38.17ZM35.7,24.78a12.13,12.13,0,0,1-8.55-1.29,69.47,69.47,0,0,1,.71-7.76,31.92,31.92,0,0,0,9.35,1.41A57.38,57.38,0,0,1,35.7,24.78Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M26.36,58.55a2.83,2.83,0,0,1-2.28-2,2.74,2.74,0,0,0-.52,1.21L23.08,61a2.71,2.71,0,0,0,5.36.82l.48-3.21a2.68,2.68,0,0,0,0-.94A2.81,2.81,0,0,1,26.36,58.55Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M58.22,12.94l-1-2.56a1.51,1.51,0,0,0-1.87-.81,26.5,26.5,0,0,1-16.88,0,1.51,1.51,0,0,0-1.87.81l-1,2.56a1.54,1.54,0,0,0,1.12,1.9c.88.29,1.76.54,2.66.76-.05,5.48.53,17.95,6.14,25.19v15.1a1.41,1.41,0,0,0,2.82,0V41.25c1-1.3,6.55-9.22,6.12-25.65.9-.21,1.79-.46,2.68-.76A1.54,1.54,0,0,0,58.22,12.94Zm-6.61,3.2a58.37,58.37,0,0,1-.35,7.77,12.1,12.1,0,0,1-8.65,0,69.13,69.13,0,0,1-.45-7.77A32.53,32.53,0,0,0,51.61,16.14ZM47,38.1A29.77,29.77,0,0,1,43.06,27a16.2,16.2,0,0,0,4.13.55,13.23,13.23,0,0,0,3.6-.48A34.37,34.37,0,0,1,47,38.1Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M47.07,58.7a2.82,2.82,0,0,1-2.56-1.65,2.73,2.73,0,0,0-.33,1.27v3.25a2.71,2.71,0,0,0,5.42,0V58.32a2.59,2.59,0,0,0-.17-.92A2.8,2.8,0,0,1,47.07,58.7Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'RehabServices',
	mixins: [iconMixin],
}
</script>
