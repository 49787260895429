<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.03 65.92" :width="size" :height="size">
		<path
			:fill="fillComputed"
			d="m39.41,33.64c1.5-1.27,1.89-3.93,1.59-4.31-.31-.38-2.94-.71-4.54.43-.25.17-.2.42.07.38.9-.11,2.92-.35,3.27.11.36.46-.4,2.37-.74,3.22-.1.26.12.36.35.17Z"
		/>
		<path
			:fill="fillComputed"
			d="m57.7,20.81c-.45-.69-1.15-.94-1.86-.94-1.1,0-1.73.52-2.17,1.61h-.03v-1.22c-.03-.12-.14-.22-.28-.22h-1.48c-.16,0-.29.11-.31.26v7.95c0,.15.13.28.29.29h1.59c.17,0,.31-.13.31-.29v-4.28c0-.54.03-1.02.25-1.51.18-.38.54-.64.92-.64,1.1,0,1,1.27,1,2.14v4.32c.02.14.14.24.28.25h1.6c.16,0,.29-.11.31-.25v-5c0-.77,0-1.84-.42-2.48Z"
		/>
		<path
			:fill="fillComputed"
			d="m41.9,44.38l-1.58-.61c-.64-.24-1.06-.46-1.28-.67-.22-.2-.32-.48-.32-.83,0-.87.65-1.31,1.95-1.31.74,0,1.47.11,2.19.32.22.07.37.1.47.1.18,0,.26-.12.26-.36v-.54c0-.17-.03-.31-.1-.39-.07-.09-.18-.16-.34-.21-.84-.31-1.7-.46-2.57-.46-1.11,0-2,.27-2.69.81-.68.54-1.02,1.24-1.02,2.12,0,1.33.75,2.27,2.25,2.82l1.72.65c.55.2.94.4,1.15.61.21.2.31.46.31.79,0,.48-.17.85-.52,1.11-.34.26-.85.38-1.51.38-.96,0-1.89-.14-2.78-.42-.24-.08-.41-.12-.51-.12-.16,0-.24.12-.24.36v.56c0,.18.03.3.09.38.06.08.17.16.33.22.91.38,1.93.56,3.06.56s2.14-.28,2.86-.85c.72-.56,1.07-1.32,1.07-2.28,0-.67-.18-1.22-.53-1.64-.35-.42-.93-.79-1.72-1.1Z"
		/>
		<path
			:fill="fillComputed"
			d="m36.65,28.52c1.73-.91,3.81-.82,5.37-.01.19.1.37-.09.37-.26v-1.2c-.02-.16-.07-.33-.27-.44-.89-.5-1.92-.64-2.9-.62l2.64-3.73c.24-.34.38-.55.38-.71v-.96c0-.17-.13-.28-.29-.28h-5.09c-.16,0-.29.11-.29.28v1.12c0,.17.13.28.29.28h2.66l-3.05,4.34c-.19.28-.19.59-.19.77v1.14c0,.17.18.35.36.26Z"
		/>
		<path
			:fill="fillComputed"
			d="m46.74,19.87c-2.23,0-3.46,1.89-3.46,4.32s1.22,4.35,3.46,4.35,3.52-1.89,3.52-4.27-1.24-4.4-3.52-4.4Zm0,7.06c-1.21,0-1.19-2.03-1.19-2.99s.07-2.48,1.21-2.48c.49,0,.83.21,1.01.75.2.62.23,1.41.23,2.07,0,1-.06,2.64-1.25,2.64Z"
		/>
		<path
			:fill="fillComputed"
			d="m46.98,35.7c-.41,0-.73.12-.96.36-.23.24-.35.55-.35.94s.12.7.35.94c.23.24.55.36.96.36s.73-.12.96-.36c.23-.24.35-.55.35-.94s-.12-.71-.35-.94c-.23-.24-.55-.36-.96-.36Z"
		/>
		<path
			:fill="fillComputed"
			d="m47.64,39.85h-1.3c-.29,0-.44.14-.44.41v9.58c0,.27.15.41.44.41h1.3c.29,0,.44-.14.44-.41v-9.58c0-.27-.15-.41-.44-.41Z"
		/>
		<path
			:fill="fillComputed"
			d="m12.45,23.18c-.89.1-2.06.16-2.89.52-.96.41-1.64,1.24-1.64,2.46,0,1.56,1.01,2.34,2.3,2.34,1.09,0,1.69-.25,2.53-1.09.28.39.37.59.88,1,.11.06.26.05.36-.03h0c.31-.26.87-.74,1.18-.99.13-.1.1-.26,0-.4-.28-.38-.58-.69-.58-1.39v-2.34c0-.99.07-1.9-.68-2.59-.59-.55-1.57-.75-2.32-.75-1.46,0-3.1.53-3.44,2.3-.04.19.1.29.23.31l1.49.16c.14,0,.24-.14.27-.28.13-.61.65-.9,1.24-.9.32,0,.68.11.86.39.22.31.19.73.19,1.09v.19Zm-.29,3.12c-.24.42-.63.68-1.06.68-.59,0-.93-.44-.93-1.09,0-1.28,1.17-1.51,2.29-1.51v.33c0,.59.01,1.07-.29,1.59Z"
		/>
		<path
			:fill="fillComputed"
			d="m20.71,39.42c-1.35,0-2.7.47-4.05,1.41-.45-.94-1.31-1.41-2.58-1.41s-2.52.44-3.82,1.31l-.12-.64c-.03-.15-.08-.25-.15-.3-.08-.05-.18-.08-.32-.08h-.9c-.27,0-.41.13-.41.4v9.3c0,.27.14.4.41.4h1.21c.27,0,.41-.13.41-.4v-7.53c.95-.58,1.95-.88,2.99-.88.56,0,.97.16,1.23.48.26.32.39.81.39,1.47v6.45c0,.27.14.4.41.4h1.21c.27,0,.41-.13.41-.4v-7.21c0-.11,0-.21-.02-.3,1.01-.6,2.01-.9,3.01-.9.56,0,.97.16,1.23.48.26.32.39.81.39,1.47v6.45c0,.27.14.4.41.4h1.21c.27,0,.41-.13.41-.4v-7.01c0-.96-.25-1.69-.76-2.21-.5-.52-1.23-.78-2.17-.78Z"
		/>
		<path
			:fill="fillComputed"
			d="m34.58,39.85h-1.2c-.27,0-.41.13-.41.4v7.45c-.99.64-1.96.96-2.94.96-.62,0-1.07-.17-1.36-.51-.28-.34-.42-.87-.42-1.58v-6.31c0-.27-.14-.4-.41-.4h-1.2c-.27,0-.41.13-.41.4v6.95c0,.97.26,1.72.77,2.25.51.53,1.25.8,2.21.8,1.32,0,2.61-.46,3.87-1.37l.14.7c.03.15.08.25.15.3.07.05.18.08.31.08h.87c.27,0,.41-.13.41-.4v-9.3c0-.27-.14-.4-.41-.4Z"
		/>
		<path
			:fill="fillComputed"
			d="m57.85,48.25c-.06,0-.16.02-.31.06-.67.19-1.29.29-1.88.29-1.13,0-1.95-.29-2.47-.87-.52-.58-.78-1.49-.78-2.73v-.27c0-1.27.26-2.19.77-2.77.51-.58,1.35-.87,2.52-.87.58,0,1.16.08,1.73.25.15.04.26.06.33.06.18,0,.27-.14.27-.41v-.55c0-.19-.03-.33-.09-.41-.06-.08-.17-.15-.32-.2-.72-.26-1.46-.39-2.21-.39-1.64,0-2.91.47-3.8,1.42-.9.95-1.34,2.29-1.34,4.02s.43,3.08,1.3,4c.87.92,2.12,1.38,3.76,1.38.9,0,1.69-.14,2.38-.43.17-.07.28-.14.33-.23.06-.08.08-.21.08-.39v-.55c0-.27-.09-.41-.27-.41Z"
		/>
		<path
			:fill="fillComputed"
			d="m16.49,28.54h1.56c.17,0,.3-.13.3-.29v-4.21c0-.92-.04-2.19,1.07-2.19s.96,1.3.96,2.19v4.21c0,.15.12.28.28.29h1.55c.17,0,.3-.13.3-.29v-4.21c0-.45-.01-1.12.15-1.52.16-.4.55-.65.93-.65.45,0,.8.15.91.69.07.32.04,1.15.04,1.49v4.21c0,.15.12.28.28.29h1.55c.17,0,.3-.13.3-.29v-5.02c0-.85.1-1.82-.39-2.49-.44-.6-1.15-.87-1.8-.87-.91,0-1.77.47-2.15,1.47-.44-1-1.05-1.47-2.02-1.47s-1.67.47-2.05,1.47h-.03v-1.04c0-.15-.13-.26-.28-.27h-1.45c-.17,0-.3.13-.3.29v7.95c.01.14.13.25.28.26Z"
		/>
		<path
			:fill="fillComputed"
			d="m32.6,23.18c-.89.1-2.06.16-2.89.52-.96.41-1.64,1.24-1.64,2.46,0,1.56,1.01,2.34,2.3,2.34,1.09,0,1.69-.25,2.54-1.09.28.39.37.59.88,1,.12.06.26.05.36-.03h0c.31-.26.87-.74,1.18-.99.13-.1.1-.26,0-.4-.28-.38-.58-.69-.58-1.39v-2.34c0-.99.07-1.9-.68-2.59-.59-.55-1.57-.75-2.32-.75-1.46,0-3.1.53-3.44,2.3-.04.19.1.29.23.31l1.49.16c.14,0,.24-.14.27-.28.13-.61.65-.9,1.24-.9.32,0,.68.11.86.39.22.31.19.73.19,1.09v.19Zm0,1.53c0,.59.01,1.07-.29,1.59-.24.42-.63.68-1.06.68-.59,0-.93-.44-.93-1.09,0-1.28,1.17-1.51,2.29-1.51v.33Z"
		/>
		<path
			:fill="fillComputed"
			d="m15.02,29.83c3.44,2.98,7.98,4.77,13.02,4.77,3.6,0,7.78-1.09,10.66-3.13.48-.34.07-.85-.42-.65-3.23,1.32-6.74,1.95-9.94,1.95-4.74,0-9.32-1.25-13.03-3.32-.32-.18-.57.14-.3.37Z"
		/>
		<path :fill="fillComputed" d="m23.9,10.65h.4v2.75h1.32v.33h-1.71v-3.08Z" />
		<path
			:fill="fillComputed"
			d="m26.49,10.9c0,.14-.1.25-.26.25-.14,0-.24-.11-.24-.25s.1-.25.25-.25.25.11.25.25Zm-.45,2.83v-2.21h.4v2.21h-.4Z"
		/>
		<path
			:fill="fillComputed"
			d="m27.06,13.32c.12.08.33.16.53.16.29,0,.43-.15.43-.33s-.11-.3-.41-.41c-.4-.14-.58-.36-.58-.63,0-.36.29-.65.76-.65.22,0,.42.06.54.14l-.1.29c-.09-.05-.25-.13-.45-.13-.24,0-.37.14-.37.3,0,.18.13.27.42.37.38.15.58.34.58.67,0,.39-.3.66-.83.66-.24,0-.47-.06-.62-.15l.1-.31Z"
		/>
		<path
			:fill="fillComputed"
			d="m29.41,10.88v.64h.58v.31h-.58v1.19c0,.27.08.43.3.43.1,0,.18-.01.23-.03l.02.3c-.08.03-.2.05-.36.05-.19,0-.34-.06-.43-.17-.11-.12-.16-.32-.16-.58v-1.21h-.34v-.31h.34v-.53l.39-.1Z"
		/>
		<path
			:fill="fillComputed"
			d="m30.63,12.7c0,.54.36.77.76.77.29,0,.46-.05.61-.11l.07.29c-.14.06-.38.14-.74.14-.68,0-1.09-.45-1.09-1.11s.39-1.19,1.04-1.19c.72,0,.91.64.91,1.04,0,.08,0,.15-.01.19h-1.55Zm1.18-.29c0-.26-.1-.65-.56-.65-.41,0-.58.37-.62.65h1.17Z"
		/>
		<path
			:fill="fillComputed"
			d="m32.7,12.12c0-.23,0-.42-.02-.6h.36l.02.37h0c.11-.21.37-.42.73-.42.31,0,.78.18.78.94v1.32h-.4v-1.27c0-.36-.13-.65-.51-.65-.27,0-.47.19-.54.41-.02.05-.03.12-.03.19v1.33h-.4v-1.61Z"
		/>
		<path
			:fill="fillComputed"
			d="m38.21,12.6c0,.82-.57,1.17-1.1,1.17-.6,0-1.06-.44-1.06-1.14,0-.74.48-1.17,1.1-1.17s1.06.46,1.06,1.14Zm-1.75.02c0,.48.28.85.67.85s.67-.36.67-.86c0-.37-.19-.85-.66-.85s-.68.44-.68.86Z"
		/>
		<path
			:fill="fillComputed"
			d="m38.71,12.12c0-.23,0-.42-.02-.6h.36l.02.37h0c.11-.21.37-.42.73-.42.31,0,.78.18.78.94v1.32h-.4v-1.27c0-.36-.13-.65-.51-.65-.27,0-.47.19-.54.41-.02.05-.03.12-.03.19v1.33h-.4v-1.61Z"
		/>
	</svg>
</template>
<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'AmazonMusic',
	mixins: [iconMixin],
}
</script>
