<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M54.45,25.81a4.47,4.47,0,0,0-3.15-3.16c-2.77-.75-13.89-.75-13.89-.75s-11.12,0-13.89.75a4.47,4.47,0,0,0-3.15,3.16c-.74,2.79-.74,8.61-.74,8.61s0,5.83.74,8.62a4.4,4.4,0,0,0,3.15,3.11c2.77.75,13.89.75,13.89.75s11.12,0,13.89-.75A4.37,4.37,0,0,0,54.45,43c.74-2.79.74-8.62.74-8.62s0-5.82-.74-8.61ZM33.77,39.71V29.14l9.3,5.28-9.3,5.29Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'YouTube',
	mixins: [iconMixin],
}
</script>
