<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g id="Group_363" data-name="Group 363">
			<path
				id="Path_179"
				data-name="Path 179"
				:fill="fillComputed"
				d="M21.35,60.8l-.11,0a4.38,4.38,0,0,1-2.91-3.34.88.88,0,0,0-.12-.21V19.69c.05-.06-.08-1.84,2.57-3.42a4,4,0,0,1,1.82-.44h1a5,5,0,0,0-.23,1.92c0,.2-.06.27-.25.25a2.48,2.48,0,0,0-1.78.49,2.4,2.4,0,0,0-.93,1.73c0,.13,0,.25,0,.38V56.41a2.48,2.48,0,0,0,2.42,2.45q13.61,0,27.19,0a2.33,2.33,0,0,0,2.34-2.33V20.35A2.39,2.39,0,0,0,50.47,18a4.52,4.52,0,0,0-.9,0c-.16,0-.22-.05-.21-.21a3.38,3.38,0,0,0,0-.45c0-.44-.07-.87-.13-1.31,0-.14,0-.21.15-.22a4.7,4.7,0,0,1,3.79,1.32,2.38,2.38,0,0,1,.8,1.24v40a1.13,1.13,0,0,1-.21.66,4.32,4.32,0,0,1-2.36,1.79"
			/>
			<path
				id="Path_180"
				data-name="Path 180"
				:fill="fillComputed"
				d="M36.34,22H26.77a1.13,1.13,0,0,1-1.18-.69,1.18,1.18,0,0,1-.08-.49c0-1.24,0-2.49,0-3.73a1.14,1.14,0,0,1,1.06-1.22h.16c1.57,0,3.15,0,4.72,0,.21,0,.3,0,.32-.29a4.62,4.62,0,0,1,4.72-4.35A4.53,4.53,0,0,1,40,12.88a4.47,4.47,0,0,1,1,2.71c0,.2.08.24.27.24h4.82a1,1,0,0,1,1.15.94.81.81,0,0,1,0,.22c0,1.24,0,2.48,0,3.72a1.14,1.14,0,0,1-1,1.24A.81.81,0,0,1,46,22Zm0-8.59a2.44,2.44,0,0,0-2.46,2.45v0a2.43,2.43,0,0,0,2.45,2.44h0a2.47,2.47,0,1,0,0-4.93Z"
			/>
			<path
				id="Path_183"
				data-name="Path 183"
				:fill="fillComputed"
				d="M36.36,55.23H48c.37,0,.59.19.6.51s-.21.54-.6.54H24.82a.54.54,0,0,1-.58-.32.49.49,0,0,1,.12-.61.69.69,0,0,1,.39-.12H36.36Z"
			/>
			<path
				id="Path_184"
				data-name="Path 184"
				:fill="fillComputed"
				d="M36.37,51.66H47.91a.56.56,0,0,1,.6.33.52.52,0,0,1-.26.69.48.48,0,0,1-.17,0H24.68a.49.49,0,0,1-.49-.49v0a.55.55,0,0,1,.51-.54H36.37Z"
			/>
		</g>
		<line :stroke="fillComputed" x1="23.56" y1="37.39" x2="27.37" y2="37.39" />
		<line :stroke="fillComputed" x1="27.04" y1="37.67" x2="29.01" y2="30.75" />
		<line :stroke="fillComputed" x1="29" y1="30.48" x2="33.15" y2="40.45" />
		<line :stroke="fillComputed" x1="34.83" y1="35.95" x2="33.15" y2="40.16" />
		<line :stroke="fillComputed" x1="37.87" y1="39.07" x2="34.92" y2="36.36" />
		<line :stroke="fillComputed" x1="39.72" y1="36.53" x2="37.5" y2="38.93" />
		<line :stroke="fillComputed" x1="41.62" y1="38.88" x2="39.2" y2="36.54" />
		<line :stroke="fillComputed" x1="41.22" y1="38.77" x2="43.56" y2="36.35" />
		<line :stroke="fillComputed" x1="44.08" y1="37.39" x2="47.67" y2="37.39" />
		<line :stroke="fillComputed" x1="44.34" y1="37.49" x2="43.07" y2="36.31" />
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'ClinicalTrials',
	mixins: [iconMixin],
}
</script>
