<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" :open="open">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="ease-in duration-200"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div
						class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
					>
						<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
							<button
								type="button"
								class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
								@click="closeModal"
							>
								<span class="sr-only">Close</span>
								<XIcon class="h-6 w-6" aria-hidden="true" />
							</button>
						</div>
						<div class="sm:flex sm:items-start">
							<div
								class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
								:class="[confirmationConfig.futureEvents ? 'animate-bounce' : '']"
							>
								<ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
							</div>
							<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
									{{ confirmationConfig.title }}
								</DialogTitle>
								<div class="mt-2">
									<p class="text text-gray-500">
										{{ confirmationConfig.content }}
									</p>
									<p
										v-if="confirmationConfig.futureEvents"
										class="mt-3 text-lg text-red-500 animate-pulse"
									>
										<b>WARNING:</b>
										This will cause the future events to also be deleted!!!
									</p>
									<p
										v-if="confirmationConfig.warning"
										class="mt-3 text-lg text-red-500 animate-pulse"
									>
										<b>WARNING:</b>
										{{ confirmationConfig.warning }}
									</p>
								</div>
							</div>
						</div>
						<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
							<button
								type="button"
								class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
								@click="confirm"
							>
								Confirm
							</button>
							<button
								type="button"
								class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
								@click="closeModal"
							>
								Cancel
							</button>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
// import { ref } from "vue";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon, XIcon } from '@heroicons/vue/outline'

export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		ExclamationIcon,
		XIcon,
	},
	props: {
		open: Boolean,
		confirmationConfig: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['closeModal', 'confirm'],
	methods: {
		closeModal() {
			this.$emit('closeModal')
		},
		confirm() {
			//   console.log(this.callback);
			this.$emit('confirm', this.confirmationConfig.callback)
		},
	},
}
</script>
