<template>
	<div class="bg-white shadow overflow-hidden sm:rounded-lg">
		<div class="border-t border-gray-200 px-4 py-5 flex justify-between flex-wrap gap-y-10 gap-x-3">
			<div v-for="day in hours" :key="day.day" class="flex-1">
				<div class="">
					<div class="bg-white text-lg font-medium text-gray-900 text-center">
						{{ day.day }}
					</div>

					<div
						class="flex flex-col gap-y-2 mt-1 border rounded-t-md p-2"
						:class="[!day.closed ? 'bg-green-50 border-green-200' : 'bg-red-50 border-red-200']"
					>
						<div>
							<div v-if="day.override_enabled && !day.closed" class="mb-4">
								<SwitchGroup as="div" class="sm:pt-3 flex items-center justify-between">
									<SwitchLabel as="span" class="flex-grow flex flex-col" passive>
										<span class="block text-sm font-bold text-gray-700 pb-1">24 Hours</span>
									</SwitchLabel>
									<Switch
										v-model="day.allDay"
										:class="[
											day.allDay ? 'bg-green-600' : 'bg-gray-200',
											'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
										]"
									>
										<span class="sr-only">Use setting</span>
										<span
											aria-hidden="true"
											:class="[
												day.allDay ? 'translate-x-5' : 'translate-x-0',
												'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
											]"
										/>
									</Switch>
								</SwitchGroup>
							</div>

							<div v-if="day.override_enabled && !day.allDay" class="mb-4">
								<SwitchGroup as="div" class="flex items-center justify-between">
									<SwitchLabel as="span" class="flex-grow flex flex-col" passive>
										<span class="block text-sm font-bold text-gray-700 pb-1">Closed</span>
									</SwitchLabel>
									<Switch
										v-model="day.closed"
										:class="[
											day.closed ? 'bg-green-600' : 'bg-gray-200',
											'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
										]"
									>
										<span class="sr-only">Use setting</span>
										<span
											aria-hidden="true"
											:class="[
												day.closed ? 'translate-x-5' : 'translate-x-0',
												'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
											]"
										/>
									</Switch>
								</SwitchGroup>
							</div>

							<div>
								<div
									v-if="!day.override_enabled && day.start != null && day.end != null && !day.closed"
									class="h-8 text-center pt-2"
								>
									{{ day.start_formatted }} - {{ day.end_formatted }}
								</div>
								<div v-else-if="!day.override_enabled" class="h-8 text-center pt-2">CLOSED</div>
							</div>

							<div v-if="!day.allDay && !day.closed">
								<div v-if="day.override_enabled">
									<div>
										<label class="block text-sm font-bold text-gray-700">
											Open
											<span v-if="day.start_formatted" class="text-xs text-gray-400 font-normal">
												({{ day.start_formatted }})
											</span>
										</label>
										<div class="my-2">
											<div class="">
												<input
													v-model="day.start_override"
													type="time"
													name="time"
													class="py-2 px-3 block w-full rounded-md focus:border-gray-300 focus:border-gray-300 focus:outline-none min-w-0 sm:text-sm border-gray-300 border disabled:opacity-80"
													@input="updateTime(day.day, 'start')"
												/>
											</div>
										</div>
									</div>
									<div>
										<label class="block text-sm font-bold text-gray-700">
											Close
											<span v-if="day.end_formatted" class="text-xs text-gray-400 font-normal">
												({{ day.end_formatted }})
											</span>
										</label>
										<div class="my-2">
											<div class="">
												<input
													v-model="day.end_override"
													type="time"
													name="time"
													class="py-2 px-3 block w-full rounded-md focus:border-gray-300 focus:border-gray-300 focus:outline-none min-w-0 sm:text-sm border-gray-300 border disabled:opacity-80"
													@input="updateTime(day.day, 'end')"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="flex items-center">
						<button
							v-if="day.override_enabled"
							type="button"
							class="-my-px w-full items-center px-2 py-1 border border-red-200 shadow-xs text-sm font-medium rounded-b-md text-red-600 bg-white hover:font-bold focus:outline-none text-center disabled:opacity-80"
							@click="toggleOverride(day)"
						>
							Disable Override
						</button>

						<button
							v-if="!day.override_enabled"
							type="button"
							class="-my-px w-full items-center px-2 py-1 border border-green-200 shadow-xs text-sm font-medium rounded-b-md text-green-600 bg-whote hover:font-bold focus:outline-none text-center disabled:opacity-80"
							@click="toggleOverride(day)"
						>
							Enable Override
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-white px-4 py-5 border-t border-gray-200 sm:px-6">
			<div class="-ml-4 -mt-2 flex items-center justify-end flex-wrap sm:flex-nowrap">
				<div class="ml-4 mt-2 flex-shrink-0">
					<button
						type="button"
						class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
						@click.prevent="cancel"
					>
						<XCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Reset
					</button>
					<button
						type="button"
						class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
						@click.prevent="update"
					>
						<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { SaveIcon, XCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		Switch,
		SwitchGroup,
		SwitchLabel,
		SaveIcon,
		XCircleIcon,
	},
	props: {},
	emits: ['cancelEditing'],
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		const location = inject('location')

		return {
			api,
			toast,
			route,
			location,
		}
	},
	data() {
		return {
			hours: [],
			allDay: false,
		}
	},
	computed: {
		department: function() {
			return typeof this.data.department === 'undefined' || this.data.department === null
				? ''
				: this.data.department.name
		},

		accepting_new_patients: function() {
			return this.data.accepting_new_patients === 0 ? 'No' : 'Yes'
		},
	},
	watch: {
		hours: {
			deep: true,
			handler(items) {
				items.forEach(item => {
					if (item.allDay) {
						item.closed = false
						item.start_override = null
						item.end_override = null
					} else {
						if (item.start_override == '00:00:00' && item.end_override == '23:59:59') {
							item.start_override = null
							item.end_override = null
						}
					}
					if (item.closed) {
						item.allDay = false
						item.start_override = null
						item.end_override = null
					}
				})
				// console.log('here', items)
			},
		},
	},
	beforeMount() {
		this.fetchHours()
	},
	methods: {
		fetchHours() {
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.hours.fetch()
				.then(hours => {
					if (hours.length !== 0) {
						hours.forEach(element => {
							let start = element.start_override || element.start
							let end = element.end_override || element.end

							element.allDay = start == '00:00:00' && end == '23:59:59'
							element.closed =
								(element.override_enabled &&
									element.start_override === null &&
									element.end_override === null) ||
								(element.start === null && element.end === null)
						})

						this.hours = hours
					}
				})
				.then(() => {
					this.loaderHide()
				})
		},

		showCloseDay(day) {
			// console.log(day.start_override, day.end_override)
			return (
				(day.start_override !== null && day.end_override !== null) || (day.start !== null && day.end !== null)
			)
		},

		showOpenDay(day) {
			return (
				(day.start_override === null && day.end_override === null && day.override_enabled === true) ||
				(day.start === null && day.end === null && day.override_enabled === false)
			)
		},

		toggleOverride(day) {
			if (day.override_enabled) {
				day.override_enabled = false

				day.allDay = day.start == '00:00:00' && day.end == '23:59:59'
				day.closed = day.start === null && day.end === null
			} else {
				day.override_enabled = true
			}

			// this.allDay = !this.allDay
			// console.log(this.allDay)
		},

		cancel() {
			this.fetchHours()
			// this.$emit('cancelEditing')
		},

		updateTime(day, when) {
			let index = this.hours.findIndex(x => x.day === day)
			if (this.hours[index][when].split(':').length == 2) {
				this.hours[index][when] += ':00'
			}
		},

		toggleClose(day) {
			console.log('test')
			if (day.closed) {
				day.closed = false
			} else {
				day.allDay = false
				day.closed = true
			}
		},

		// open(day) {
		// 	// let index = this.hours.findIndex(x => x.day === day)
		// 	day.closed = false
		// },

		update() {
			console.log(this.hours)
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.hours.update(this.hours)
				.then(hours => {
					if (hours.length !== 0) {
						hours.forEach(element => {
							let start = element.start_override || element.start
							let end = element.end_override || element.end

							element.allDay = start == '00:00:00' && end == '23:59:59'
							element.closed =
								(element.override_enabled &&
									element.start_override === null &&
									element.end_override === null) ||
								(element.start === null && element.end === null)
						})

						this.hours = hours
					}

					this.toast.success('Successfully updated Location Hours')
				})
				.catch(error => {
					this.toast.error('Error updating Location Hours:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>

<style>
.vc-time-date {
	display: none !important;
}
</style>
