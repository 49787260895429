<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<rect :stroke="fillComputed" class="cls-1" x="15.33" y="20.26" width="41.33" height="32.02" rx="2.92" />
		<rect :stroke="fillComputed" class="cls-1" x="29.77" y="15.08" width="12.46" height="5.18" />
		<polyline :stroke="fillComputed" class="cls-1" points="44.14 33.95 56.67 33.95 56.67 38.59 44.14 38.59" />
		<polygon
			:stroke="fillComputed"
			class="cls-1"
			points="40.46 34.62 37.65 34.62 37.65 31.81 34.35 31.81 34.35 34.62 31.54 34.62 31.54 37.92 34.35 37.92 34.35 40.73 37.65 40.73 37.65 37.92 40.46 37.92 40.46 34.62"
		/>
		<polyline :stroke="fillComputed" class="cls-1" points="27.86 33.95 15.33 33.95 15.33 38.59 27.86 38.59" />
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M27.86,36.27A8.14,8.14,0,1,1,36,44.41,8.15,8.15,0,0,1,27.86,36.27Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Locums',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1 {
	fill: none;
	stroke-linejoin: round;
	stroke-width: 2px;
}
</style>
