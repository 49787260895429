import { Node, mergeAttributes } from '@tiptap/core'

const Indent = Node.create({
	name: 'indent',

	priority: 1000,

	addOptions: {
		HTMLAttributes: {},
	},

	content: 'text*',

	marks: '',

	group: 'block',

	addAttributes() {
		return {
			class: {
				default: 'pl-4',
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: 'p',
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
	},

	addCommands() {
		return {
			setIndent: attributes => ({ commands }) => {
				return commands.setNode('indent', attributes)
			},
			toggleIndent: attributes => ({ commands }) => {
				return commands.toggleNode('indent', 'paragraph', attributes)
			},
			unsetIndent: () => ({ commands }) => {
				return commands.unsetNode('indent')
			},
		}
	},

	addKeyboardShortcuts() {
		return {
			'Mod-Enter': () => this.editor.commands.setHardBreak(),
			'Shift-Enter': () => this.editor.commands.setHardBreak(),
		}
	},
})

export default Indent
