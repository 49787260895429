<template>
	<div v-if="department && page" class="bg-white shadow sm:rounded-lg">
		<div class="border-t border-gray-200 px-4 py-5 sm:p-0">
			<dl class="sm:divide-y sm:divide-gray-200">
				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">MD-Staff ID</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
						{{ department.id }}
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">
						Publish Date
					</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
						<!-- <FieldsDate
							v-model="draft.publish_date"
							:field="{
								label: '',
								model: 'publish_date',
								colSpan: 'col-span-6',
							}"
						/> -->

						<ContentBuilderPublish
							:class="{ 'pointer-events-none opacity-50': dirtyData }"
							:draft="draft"
							:page="page"
							:dirty-data="dirtyData"
							:include-publish="includePublish"
							:show-heading="false"
							:disabled="errors.length != 0"
							@publish="publish"
							@unpublish="unpublish"
							@schedule="schedule"
							@unschedule="unschedule"
						/>
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">Name</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
						<input
							v-model="draft.title"
							type="text"
							name="name"
							:placeholder="department.name"
							class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50"
						/>
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">URL</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
						<FieldsInput
							v-model="draft.slug"
							:field="{
								type: 'input',
								inputType: 'text',
								label: '',
								model: 'url',
								prefix: '/medical-specialties/',
								placeholder: department.slug,
								enableCopy: true,
								copyWithPrefix: true,
								kebabCase: true,
								colSpan: 'col-span-6',
							}"
						/>
					</dd>
				</div>
				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">
						Header Image
					</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
						<FieldsImage
							v-model="draft.header_image"
							:field="{
								type: 'input',
								inputType: 'text',
								label: '',
								model: 'image',
								required: false,
								colSpan: 'col-span-6',
							}"
							:media-types="['image']"
							:media-categories="['Department']"
							:media-upload-category="'page'"
							:media-upload-accept="['image/png', 'image/jpeg']"
							:media-upload-extensions="['jpg', 'jpeg', 'png']"
						/>
					</dd>
				</div>
				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">About</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
						<FieldsTipTapEditor
							v-model="draft.body"
							:field="{
								label: '',
								model: 'text',
								required: false,
								colSpan: 'col-span-6',
								rows: 10,
							}"
						/>
					</dd>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">
						Show Locations
					</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
						<Switch
							v-model="department.show_locations"
							:class="[department.show_locations ? 'bg-green-600' : 'bg-gray-200']"
							class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none disabled:opacity-50"
						>
							<span class="sr-only">Use setting</span>
							<span
								aria-hidden="true"
								:class="[department.show_locations ? 'translate-x-5' : 'translate-x-0']"
								class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
							/>
						</Switch>
					</dd>
					<label v-show="department.show_locations" class="ml-3">ENABLED</label>
					<label v-show="!department.show_locations" class="ml-3">DISABLED</label>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">
						Show Providers
					</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
						<Switch
							v-model="department.show_providers"
							:class="[department.show_providers ? 'bg-green-600' : 'bg-gray-200']"
							class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none disabled:opacity-50"
						>
							<span class="sr-only">Use setting</span>
							<span
								aria-hidden="true"
								:class="[department.show_providers ? 'translate-x-5' : 'translate-x-0']"
								class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
							/>
						</Switch>
					</dd>
					<label v-show="department.show_providers" class="ml-3">ENABLED</label>
					<label v-show="!department.show_providers" class="ml-3">DISABLED</label>
				</div>

				<div class="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
					<dt class="text-sm font-bold text-gray-500">SEO</dt>
					<dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
						<ContentBuilderSeo
							:seo="draft.seo"
							:show-heading="false"
							@invalidField="invalidField"
							@validField="validField"
						/>
					</dd>
				</div>

				<div class="px-4 py-5 sm:p-6">
					<ContentBuilder
						ref="builder"
						parent-endpoint="departments"
						url-prefix="/medical-specialties/"
						:only-section-builder="true"
						:sections="draft"
						@updatedSection="updatedSection"
					/>
				</div>
			</dl>
		</div>

		<div
			class="px-4 py-5 shadow-sm border border-gray-300 rounded-l-md flex justify-end fixed bottom-5 right-4 bg-white bg-opacity-50 z-10"
		>
			<div class="-ml-4 -mt-2 flex items-center justify-end flex-wrap sm:flex-nowrap flex flex-col gap-x-2">
				<div v-if="errors.length != 0" class="mb-2 text-center text-red-500 font-semibold px-3 w-60">
					Your form has errors, please correct them.
				</div>
				<div class="ml-4 mt-2 flex-shrink-0">
					<button
						type="button"
						class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
						@click.prevent="cancel"
					>
						<XCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Reset
					</button>
					<button
						type="button"
						class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50"
						:disabled="errors.length != 0"
						@click.prevent="update"
					>
						<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

import { Switch } from '@headlessui/vue'
import { SaveIcon, XCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		Switch,
		SaveIcon,
		XCircleIcon,
	},
	props: {},
	emits: ['updatedPage', 'updated'],
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		const department = inject('department')

		const page = ref(null)
		const draft = ref(null)
		const original = ref(null)
		const dirtyData = ref(false)

		return {
			page,
			draft,
			original,
			dirtyData,
			api,
			toast,
			route,
			department,
		}
	},
	data() {
		return {
			// page: {
			// 	title: null,
			// 	body: null,
			// 	url_prefix: '/medical-specialties/',
			// 	slug: null,
			// 	header_image: null,
			// 	publish_date: null,
			// },
			includePublish: true,
			errors: [],
		}
	},
	computed: {},
	watch: {
		draft: {
			deep: true,
			handler(val) {
				// set all empty strings to null
				console.log(val)
				if (val !== null)
					Object.keys(val).forEach(key => {
						if (typeof val[key] == 'string' && val[key].length == 0) {
							val[key] = null
						}
					})

				this.dirtyData = JSON.stringify(val) != this.original
				// console.log('dirtyData', this.dirtyData)
			},
		},
	},
	mounted() {
		this.fetchPage()

		// setTimeout(() => {
		// 	console.log('this.department.show_locations', this.department.show_locations)
		// 	this.department.show_locations = this.department.show_locations
		// }, 2500)
	},
	methods: {
		setPage(page) {
			this.draft = null
			this.page = null
			this.original = null
			this.dirtyData = false

			setTimeout(() => {
				if (page.draft_page) {
					if (this.cloneId) {
						delete page.draft_page.id
						page.draft_page.publish_date = null
						page.draft_page.publish_date_display = null
					}

					this.draft = page.draft_page
					this.original = JSON.stringify(this.draft)
					this.page = JSON.parse(JSON.stringify(page))
					delete this.page.page_draft

					this.$emit('updated', page.slug)
				} else {
					if (this.cloneId) {
						delete page.id
						page.publish_date = null
						page.publish_date_display = null
					}

					if (page.menu && page.menu.length > 0) {
						page.menu_enabled = true
					}

					this.draft = page
					this.original = JSON.stringify(this.draft)
					this.page = JSON.parse(JSON.stringify(page))

					this.$emit('updated', page.slug)
				}

				// console.log(this.draft)
			}, 100)
		},

		fetchPage() {
			this.loaderShow()

			this.api.departments
				.slug(this.route.params.id)
				.pages.fetch()
				.then(page => {
					this.setPage(page)
					// if (Object.keys(page).length !== 0) this.page = page
				})
				.then(() => {
					this.loaderHide()
				})
		},

		cancel() {
			this.fetchPage()
		},

		processData() {
			let data = JSON.parse(JSON.stringify(this.draft))

			console.log('processData1', data)

			data.header_image_id = null
			if (data.header_image !== null) {
				data.header_image_id = data.header_image.id
			}
			delete data.header_image

			data.show_locations = this.department.show_locations
			data.show_providers = this.department.show_providers

			if (data.title === '') {
				data.title = null
			}

			if (Object.keys(data.sections).length > 0) {
				data.sections.forEach(section => {
					if (typeof section === 'object') {
						section.columns.forEach((column, index) => {
							if (
								column.video &&
								typeof column.video == 'object' &&
								typeof column.video.id !== 'undefined'
							) {
								column.video_id = column.video.id
								delete column.video
							} else {
								column.video_id = null
								delete column.video
							}

							if (column.text === '' && column.video_id === null) {
								delete section.columns.splice(index, 1)
							}
						})
					}

					if (section.anchor === '') {
						section.anchor = null
					}

					if (!section.component || Object.keys(section.component).length === 0) {
						section.component = null
					}
				})
			}

			return data
		},

		update() {
			// return false
			this.loaderShow()

			this.api.departments
				.slug(this.route.params.id)
				.pages.update(this.processData())
				.then(page => {
					this.setPage(page)
					this.$emit('updatedPage')
					this.toast.success('Successfully updated Department General Information')
				})
				.catch(error => {
					this.toast.error('Error updating Department General Information:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		publish() {
			this.loaderShow()

			this.api.departments
				.slug(this.route.params.id)
				.pages.publish()
				.then(page => {
					this.setPage(page)
					this.$emit('updatedPage')
					this.toast.success('Successfully published!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
		unpublish() {
			this.loaderShow()

			this.api.departments
				.slug(this.route.params.id)
				.pages.archive()
				.then(page => {
					this.setPage(page)
					this.$emit('updatedPage')
					this.toast.success('Successfully unpublished!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
		schedule(when) {
			this.loaderShow()

			this.api.departments
				.slug(this.route.params.id)
				.pages.schedule({
					publish_date: when,
				})
				.then(page => {
					this.setPage(page)
					this.$emit('updatedPage')
					this.toast.success('Successfully scheduled!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
		unschedule() {
			this.loaderShow()

			this.api.departments
				.slug(this.route.params.id)
				.pages.unschedule()
				.then(page => {
					this.setPage(page)
					this.$emit('updatedPage')
					this.toast.success('Successfully scheduled!')
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					console.log('Error saving page. Errors: ', validationErrors)
					console.log('Error Data: ', error.response.data.data)
					this.toast.error('Error saving page!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		invalidField(field) {
			if (!this.errors.includes(field)) this.errors.push(field)

			// console.log('invalidField', field, !this.errors.includes(field), this.errors)
		},
		validField(field) {
			if (this.errors.includes(field)) {
				var index = this.errors.indexOf(field)
				if (index !== -1) {
					this.errors.splice(index, 1)
				}
			}

			// console.log('validField', field, this.errors)
		},

		updatedSection(sections) {
			this.draft.sections = sections
			// console.log('this.draft.sections', this.draft.sections)
		},
	},
}
</script>
