<template>
	<div class="sm:pt-3" :class="[hasColSpan ? field.colSpan : '']">
		<label :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0 flex relative flex rounded-md">
			<div v-if="hasErrors" class="absolute inset-y-0 right-36 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>
			<input
				v-model="inputVal"
				:type="field.inputType"
				class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 sm:text-sm border-gray-300 focus:outline-none rounded-l-md"
				:class="[hasErrors ? 'border-red-300 text-red-900' : '']"
				:name="field.model"
				:autocomplete="field.model"
				read-only
				:disabled="true"
			/>
			<button
				class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none disabled:opacity-50"
				:disabled="inputVal == null"
				@click="removeIcon"
			>
				<XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
			</button>
			<button
				class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none"
				@click.prevent="openSlide"
			>
				<PhotographIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				<span>Select Icon</span>
			</button>
		</div>

		<div v-if="hasErrors" class="mt-2 text-red-500 font-semibold">
			{{ errors.$errors[0].$message }}
		</div>

		<SlidePanel
			slide-title="Select Section Icon"
			slide-component="MediaIconSelect"
			:open="slideOpen"
			@closeSlide="closeSlide"
			@selectIcon="selectIcon"
		/>
	</div>
</template>

<script>
import { useToast } from 'vue-toastification'
import { ExclamationCircleIcon, PhotographIcon, XCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		ExclamationCircleIcon,
		PhotographIcon,
		XCircleIcon,
	},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue'],
	setup() {
		const toast = useToast()

		return {
			toast,
		}
	},
	data() {
		return {
			slideOpen: false,
		}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasPrefix: function() {
			return typeof this.field.prefix !== 'undefined'
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				this.$emit('update:modelValue', val)
			},
		},
	},
	methods: {
		openSlide() {
			this.slideOpen = true
		},

		closeSlide() {
			this.slideOpen = false
		},

		selectIcon(icon) {
			this.inputVal = icon
		},

		removeIcon() {
			if (this.field.menu_item_enabled) {
				this.toast.error('You must disable the anchor link before removing the icon')
			} else {
				this.inputVal = null
			}
		},
	},
}
</script>
