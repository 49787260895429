<template>
	<div>
		<div class="bg-white px-4 pt-5 rounded-t-md border-gray-200 sm:px-6">
			<div class="-ml-4 -mt-2 flex items-center justify-end flex-wrap sm:flex-nowrap">
				<div class="ml-4 mt-2 flex-shrink-0">
					<div class="mt-4 flex md:mt-0 md:ml-4">
						<RefreshTableButton @refreshTable="refreshTable" />
					</div>
				</div>
			</div>
		</div>

		<div class="bg-white px-4 py-5 rounded-b-md border-gray-200 sm:px-6">
			<div v-if="rowData">
				<ag-grid-vue
					style="height: 725px"
					class="ag-theme-alpine"
					:grid-options="gridOptions"
					:column-defs="columnDefs"
					:default-col-def="defaultColDef"
					:row-data="rowData"
					:modules="modules"
					:pagination="true"
					:pagination-page-size="50"
					:enable-cell-text-selection="true"
					:ensure-dom-order="true"
				></ag-grid-vue>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

import { AgGridVue } from '@ag-grid-community/vue3'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import TableActionsById from '@/components/Events/TableActionsById.vue'

export default {
	components: {
		AgGridVue,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		return {
			api,
			toast,
			route,
		}
	},
	data() {
		return {
			rowData: null,
			columnDefs: [
				{ headerName: 'Event Name', field: 'title', flex: 1 },
				{ headerName: 'Venue', field: 'venue', flex: 1 },
				{ headerName: 'Starts', field: 'starts', width: 150 },
				{ headerName: 'Ends', field: 'ends', width: 150 },
				{ headerName: 'Registrations', field: 'registration_count', width: 150 },
				{ headerName: 'Max Registrations', field: 'max_registrations', width: 170 },
				{
					headerName: 'Actions',
					width: 200,
					filter: false,
					cellRendererFramework: TableActionsById,
					cellRendererParams: {
						appApi: this.api,
						parent: this,
					},
				},
			],
			defaultColDef: {
				resizable: true,
				sortable: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
			},
			modules: [ClientSideRowModelModule],
		}
	},
	beforeMount() {
		this.gridOptions = {
			context: {
				componentParent: this,
			},
		}
	},
	mounted() {
		this.gridApi = this.gridOptions.api

		this.fetchEventInstances()
	},
	methods: {
		fetchEventInstances() {
			this.loaderShow()

			this.api.reports.events.eventInstances
				.fetchRestricted()
				.then(eventInstances => {
					this.rowData = eventInstances
					console.log('params.data', this.rowData)
				})
				.catch(error => {
					this.toast.error('Error fetching events:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		refreshTable() {
			this.fetchEventInstances()
		},

		booleanFormatter(params) {
			return params.value ? 'Yes' : 'No'
		},
	},
}
</script>
