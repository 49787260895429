<template>
	<div :class="[hasColSpan ? field.colSpan : '', field.label.length > 0 ? 'sm:pt-3' : '']">
		<label :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>

		<div class="mt-1 sm:mt-0 flex relative flex rounded-md">
			<div v-if="!meta.valid" class="absolute inset-y-0 right-36 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>
			<input
				:value="value ? value.url : ''"
				:type="field.inputType"
				class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 sm:text-sm border-gray-300 focus:outline-none rounded-l-md disabled:opacity-50"
				:class="[!meta.valid ? 'border-red-300 text-red-900' : '']"
				:name="field.model"
				:autocomplete="field.model"
				readonly
			/>
			<button
				class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none disabled:opacity-50"
				:disabled="disabled || value == null"
				@click="value = null"
			>
				<XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
			</button>
			<button
				class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none disabled:opacity-50"
				:disabled="disabled || value == null"
				@click="openVideoPreview"
			>
				<EyeIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
			</button>
			<button
				class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none disabled:opacity-50"
				:disabled="disabled"
				@click.prevent="openSlide"
			>
				<PhotographIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				<span>Select Video</span>
			</button>
		</div>

		<div class="mt-2 text-red-500 font-semibold">{{ errorMessage }}</div>

		<ModalsVideoPreview
			v-if="videoPreviewOpen"
			ref="videoPreview"
			:open="videoPreviewOpen"
			:video-preview-config="videoPreviewConfig"
			@closeModal="videoPreviewOpen = false"
		/>

		<SlidePanel
			slide-title="Select Video"
			slide-component="MediaVideoSelect"
			:open="slideOpen"
			:media-types="mediaTypes"
			:media-categories="mediaCategories"
			:media-upload-category="mediaUploadCategory"
			:media-upload-accept="mediaUploadAccept"
			:media-upload-extensions="mediaUploadExtensions"
			@closeSlide="closeSlide"
			@selectMedia="selectMedia"
		/>
	</div>
</template>

<script>
import { computed, ref } from 'vue'
import { useForm, useField } from 'vee-validate'
import * as yup from 'yup'

import { PhotographIcon, ExclamationCircleIcon, EyeIcon, XCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		PhotographIcon,
		ExclamationCircleIcon,
		EyeIcon,
		XCircleIcon,
	},
	props: {
		modelValue: {
			type: Object,
			default: function() {
				return {}
			},
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
		disabled: Boolean,

		mediaTypes: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaCategories: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaUploadCategory: {
			type: String,
			default: '',
		},
		mediaUploadAccept: {
			type: Array,
			default: function() {
				return ['video/mp4']
			},
		},
		mediaUploadExtensions: {
			type: Array,
			default: function() {
				return ['mp4']
			},
		},
	},
	emits: ['update:modelValue', 'invalidField', 'validField'],
	setup(props, { emit }) {
		const required = ref(props.field.required)

		// Define a validation schema
		let schemaData = {
			inputVal: yup
				.object()
				.nullable(true)
				.label(props.field.label),
		}
		if (required.value) {
			// console.log('required')
			schemaData = {
				inputVal: yup
					.object()
					.required()
					.nullable(true)
					.label(props.field.label),
			}
		}

		const schema = computed(() => {
			return yup.object(schemaData)
		})

		// Create a form context with the validation schema
		useForm({
			validationSchema: schema,
		})

		const { errorMessage, value, meta, validate } = useField('inputVal', undefined, {
			initialValue: props.modelValue,
		})

		// trigger validation
		validate()
		setTimeout(() => {
			if (!meta.valid) {
				emit('invalidField', props.field.model)
			}
		}, 50)

		return {
			errorMessage,
			value,
			meta,
		}
	},
	data() {
		return {
			videoPreviewOpen: false,
			videoPreviewConfig: {},
			slideOpen: false,
		}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasPrefix: function() {
			return typeof this.field.prefix !== 'undefined'
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log('VIDEO INPUT:', val)
				this.$emit('update:modelValue', val)
			},
		},
	},
	watch: {
		value: function(val) {
			this.inputVal = this.value
			setTimeout(() => {
				// console.log('ERROR:', this.meta.valid)
				if (!this.meta.valid) this.$emit('invalidField', this.field.model)
				else this.$emit('validField', this.field.model)
			}, 50)
		},
	},
	mounted() {
		// console.log("INPUT:", this.modelValue);
	},
	methods: {
		openSlide() {
			this.slideOpen = true
		},

		closeSlide() {
			this.slideOpen = false
		},

		openVideoPreview(video) {
			if (this.value !== null) {
				this.videoPreviewConfig = {
					title: 'Image Preview',
					video: this.value,
				}
				this.videoPreviewOpen = true
			}
		},

		selectMedia(file) {
			// console.log(typeof file);
			this.value = file
		},
	},
}
</script>
