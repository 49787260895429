<template>
	<div :id="props.field.name">
		<div class="pointer-events-none">
			<label :for="props.field.name" class="block font-medium text-gray-700">
				{{ props.field.label }}
				<span v-show="isRequired" class="text-red-500">*</span>
			</label>
			<div class="mt-1 flex flex-col gap-y-2">
				<div v-for="choice in props.field.field_choices" :key="choice.choice_value" class="flex items-center">
					<input
						:name="props.field.name"
						type="checkbox"
						class="focus:ring-transparent h-4 w-4 text-blue-600 border-gray-300"
					/>
					<label :for="props.field.name" class="ml-3 block text-sm font-medium text-gray-700">
						{{ choice.choice_value }}
					</label>
				</div>
			</div>
			<div v-if="props.field.help" class="text-sm text-gray-400">
				{{ props.field.help }}
			</div>

			<!-- <div class="text-sm text-blue-400 mt-2 whitespace-pre">
				{{ props.field }}
			</div> -->
		</div>
	</div>
</template>

<script>
import { reactive, computed, watchEffect } from 'vue'

export default {
	props: {
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		index: {
			type: Number,
			default: 0,
		},
		selected: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const isRequired = computed(() => {
			let requiredIndex = props.field.field_validations.findIndex(
				x => x.field_validation_rule.rule === 'required'
			)

			return props.field.field_validations[requiredIndex].rule_value === 'true'
		})

		return {
			props,
			isRequired,
		}
	},
}
</script>
