import { createRouter, createWebHistory } from 'vue-router'
import routes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'
import { useToast } from 'vue-toastification'

//import { }
//import { meta } from 'eslint/lib/rules/*'

const toast = useToast()

const RouterLayout = createRouterLayout(layout => {
	return import('@/layouts/' + layout + '.vue')
})

const router = createRouter({
	// history: createWebHistory(process.env.BASE_URL),
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			component: RouterLayout,
			children: routes,
		},
		{
			path: '/:catchAll(.*)',
			name: 'NotFound',
			component: () => import('@/pages/404.vue'),
		},
	],
})

let redirectTo = null
router.beforeEach((to, from, next) => {
	//console.log('Router From: ', from)
	//console.log('Router beforeEach: ', to, ' - Loaded: ', store.state.user.loaded)
	//console.log('Store State: ', store.state.user.loaded)
	if (to.meta.guestAccess) {
		//console.log('Page has guest access')
		redirectTo = from.path
		next()
	} else if (store.state.user.loaded) {
		//console.debug('User is authenticated, proceeding on route.')
		next()
	} else {
		console.warn('Not Authorized! Redirecting to Login!')
		toast.error('Session has expired. Please login.')
		next('login')
	}
})

export default router
