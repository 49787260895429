<template>
	<svg
		version="1.1"
		:width="size"
		:height="size"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 72 72"
		style="enable-background: new 0 0 72 72"
		xml:space="preserve"
	>
		<path
			id="Path_189"
			:fill="fillComputed"
			d="M25.5,26.8c-0.2-5.7,4.3-10.4,10-10.6c5.7-0.2,10.4,4.3,10.6,10c0,0.2,0,0.4,0,0.6l0,0
	c-0.2,5.7-4.9,10.2-10.6,10C30,36.6,25.6,32.2,25.5,26.8z"
		/>
		<path
			:fill="fillComputed"
			d="M45.8,53.2c0-3.8,2.7-7,6.2-7.7c-1.5-3-3.7-5.6-6.5-7.6c-0.7-0.6-1.7-0.7-2.4-0.1c-4.4,3.1-10.4,3.1-14.8,0
	c-0.6-0.6-1.6-0.6-2.2,0c-4.5,3.2-7.5,8-8.4,13.5c-0.6,3.5-0.4,4.2,3.2,4.8c4.9,0.8,9.9,1.2,14.9,1.7l0-0.4c1.5,0.1,2.9,0.1,4.4,0
	c2.1-0.2,4.2-0.5,6.3-0.8C46,55.5,45.8,54.3,45.8,53.2z"
		/>
		<g>
			<path
				:fill="fillComputed"
				d="M53.6,60.1c-3.3,0-6.3-2.4-6.8-5.8c-0.6-3.8,1.9-7.3,5.7-7.9l0,0c3.8-0.6,7.3,2,7.9,5.7s-1.9,7.3-5.7,7.9
		C54.4,60,54,60.1,53.6,60.1z M52.6,46.9c-1.7,0.3-3.2,1.2-4.2,2.6c-1,1.4-1.4,3.1-1.1,4.8c0.6,3.5,3.9,5.9,7.3,5.3
		c1.7-0.3,3.2-1.2,4.2-2.6c1-1.4,1.4-3.1,1.1-4.8C59.4,48.7,56.1,46.3,52.6,46.9L52.6,46.9z"
			/>
		</g>
		<g id="Group_33">
			<g id="Group_31">
				<g id="Path_31">
					<path
						:fill="fillComputed"
						d="M51.8,54.6c0.6-0.5,1.2-1,1.8-1.5c1.1-0.8,2.2-1.7,3.4-2.5c0.3-0.2,0.6-0.4,0.9-0.5c0.1,0,0.2-0.1,0.3-0.1
				c0.2,0,0.3,0.1,0.2,0.3c-0.1,0.3-0.2,0.5-0.4,0.7c-1.2,1.3-2.3,2.7-3.5,4c-0.6,0.6-1.2,1.2-1.8,1.8c-0.4,0.5-1.2,0.6-1.7,0.2
				c-0.1-0.1-0.2-0.2-0.3-0.4c-0.6-0.9-1.2-1.9-1.6-2.9c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.4,0.2-0.6,0.5-0.4c0.3,0.1,0.6,0.3,0.9,0.5
				C50.8,53.8,51.2,54.2,51.8,54.6"
					/>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PatientRights',
	mixins: [iconMixin],
}
</script>
