<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g id="Group_66" data-name="Group 66">
			<g id="Group_63" data-name="Group 63">
				<path
					id="Path_57"
					data-name="Path 57"
					:fill="fillComputed"
					d="M36.53,53.07a1.23,1.23,0,0,1-.89-.38C35,52,20.56,36.56,20.56,22.71a16,16,0,0,1,32,0c0,13.85-14.47,29.32-15.08,30a1.23,1.23,0,0,1-.9.39m0-43.88A13.54,13.54,0,0,0,23,22.71c0,10.35,9.13,22.16,13.06,26.76a.57.57,0,0,0,.46.21.57.57,0,0,0,.45-.21c3.93-4.6,13.07-16.41,13.07-26.76A13.53,13.53,0,0,0,36.53,9.2"
				/>
			</g>
			<g id="Group_64" data-name="Group 64">
				<path
					id="Path_58"
					data-name="Path 58"
					:fill="fillComputed"
					d="M36.53,58.56c-6.8,0-14-2.35-14-6.71,0-1.14.57-3.29,4.39-5a1.34,1.34,0,0,1,.49-.1,1.21,1.21,0,0,1,1.12.74,1.23,1.23,0,0,1-.63,1.62C26.05,49.94,25,51,25,51.85c0,2,4.95,4.26,11.57,4.26S48.1,53.85,48.1,51.84c0-.9-1.1-1.91-2.93-2.71a1.21,1.21,0,0,1-.65-.68,1.22,1.22,0,0,1,1.14-1.68,1.16,1.16,0,0,1,.49.1c3.83,1.67,4.4,3.83,4.4,5,0,4.36-7.22,6.71-14,6.71"
				/>
			</g>
			<g id="Group_65" data-name="Group 65">
				<path
					id="Path_59"
					data-name="Path 59"
					:fill="fillComputed"
					d="M36.53,64c-13.21,0-23.17-4.85-23.17-11.28,0-3.89,3.71-7.31,10.18-9.4a1.19,1.19,0,0,1,.37-.06,1.22,1.22,0,0,1,.38,2.39C19,47.41,15.82,50,15.82,52.75c0,4.26,8.32,8.83,20.71,8.83S57.24,57,57.24,52.75c0-2.67-3.1-5.29-8.3-7a1.23,1.23,0,0,1-.78-1.55,1.22,1.22,0,0,1,1.16-.84,1.32,1.32,0,0,1,.39.06c6.34,2.09,10,5.5,10,9.34,0,6.43-10,11.28-23.17,11.28"
				/>
			</g>
		</g>
		<g id="Group_1015" data-name="Group 1015">
			<path
				id="Path_1374"
				data-name="Path 1374"
				:fill="fillComputed"
				d="M36.25,32.91a16.74,16.74,0,0,1,5.61-8.26,10.15,10.15,0,0,0-4,2.32,10.5,10.5,0,0,0-2.6,4v-.11c0-.61,0-1.22.08-1.83a9.17,9.17,0,0,1,.86-3.17,8,8,0,0,1,4-3.85,11.9,11.9,0,0,1,3.51-1c1-.12,1.94-.15,2.9-.22h.14c0,.26,0,.5,0,.75a12.15,12.15,0,0,1-1.91,7.23,8.81,8.81,0,0,1-4.54,3.42,13.52,13.52,0,0,1-3.57.69l-.55,0"
			/>
			<path
				id="Path_1375"
				data-name="Path 1375"
				:fill="fillComputed"
				d="M32.19,13.52c-.33.51-.67,1-1,1.52a9.23,9.23,0,0,0-1.18,3.12,5.89,5.89,0,0,0,1.67,5.27,8.75,8.75,0,0,0,2.64,1.87l.22.09c1.35-1.91,2.26-3.94,1.83-6.34a7.1,7.1,0,0,0-1.66-3.36,10.27,10.27,0,0,0-2.35-2.06l-.21-.11"
			/>
			<path
				id="Path_1376"
				data-name="Path 1376"
				:fill="fillComputed"
				d="M33.24,31.72a7.42,7.42,0,0,0-3.43-5.26A6.3,6.3,0,0,1,32.31,28a6.4,6.4,0,0,1,1.59,2.5v0c0-.38,0-.77,0-1.15a5.79,5.79,0,0,0-.51-2,4.91,4.91,0,0,0-2.48-2.46,7.16,7.16,0,0,0-2.2-.66c-.6-.08-1.21-.11-1.82-.16h-.09c0,.16,0,.32,0,.47A7.61,7.61,0,0,0,27.85,29a5.51,5.51,0,0,0,2.81,2.2,8.54,8.54,0,0,0,2.23.48l.35,0"
			/>
		</g>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'InOfficeServices',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke: #fff;
	stroke-miterlimit: 10;
}
</style>
