<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M30.08,33.37a7,7,0,0,0-5.84-3.06c-3.76,0-4.94,2.36-4.94,4.18a3,3,0,0,0,1.13,2.41,12.19,12.19,0,0,0,4.29,2A20.89,20.89,0,0,1,32,42.53,9.72,9.72,0,0,1,35,50c0,7-5,11.74-12.44,11.74A14.71,14.71,0,0,1,10.24,55l4.55-4.35a8.4,8.4,0,0,0,7.51,4.78A5,5,0,0,0,27.79,51a3.4,3.4,0,0,0,0-.45,4.16,4.16,0,0,0-1.38-3.21,16,16,0,0,0-5.53-2.63A17,17,0,0,1,15,41.56a8.26,8.26,0,0,1-2.85-6.64c0-5.25,3.54-10.51,11.47-10.51a13.74,13.74,0,0,1,10.13,4.18Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M57,42.82a8.49,8.49,0,0,0-6-2.28,7,7,0,0,0-7.34,6.65q0,.35,0,.69a7.26,7.26,0,0,0,7.1,7.41h.48A8.53,8.53,0,0,0,57,53v7.14a14.91,14.91,0,0,1-6.74,1.62A14.69,14.69,0,0,1,40.2,58a13.36,13.36,0,0,1-4.15-10A13.41,13.41,0,0,1,49.94,34a15.58,15.58,0,0,1,7,1.56Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M46.58,31.81a24.22,24.22,0,0,1,8.14-12,14.67,14.67,0,0,0-5.85,3.39,15.06,15.06,0,0,0-3.77,5.79v-.16c0-.89,0-1.78.11-2.67a13.33,13.33,0,0,1,1.25-4.63,11.61,11.61,0,0,1,5.86-5.61,17.22,17.22,0,0,1,5.12-1.44c1.41-.16,2.82-.21,4.23-.32h.2c0,.38.06.73.07,1.09a17.76,17.76,0,0,1-2.75,10.54,12.83,12.83,0,0,1-6.6,5,19.26,19.26,0,0,1-5.2,1l-.8.06"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M40.59,3.57c-.47.74-1,1.46-1.39,2.21a13.75,13.75,0,0,0-1.71,4.56A8.58,8.58,0,0,0,40,18a12.85,12.85,0,0,0,3.85,2.71l.32.13a12,12,0,0,0,2.66-9.25,10.5,10.5,0,0,0-2.44-4.88,15.11,15.11,0,0,0-3.44-3l-.31-.16"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M42.19,30.08a10.74,10.74,0,0,0-5-7.64,9.2,9.2,0,0,1,3.65,2.18,9.42,9.42,0,0,1,2.32,3.63v-.06c0-.57,0-1.12,0-1.68a8.44,8.44,0,0,0-.74-2.92A7.27,7.27,0,0,0,38.72,20a10.84,10.84,0,0,0-3.2-.95c-.88-.11-1.76-.16-2.65-.23h-.13c0,.23-.05.45-.05.68a11,11,0,0,0,1.64,6.64,8,8,0,0,0,4.1,3.19,11.69,11.69,0,0,0,3.26.68l.5,0"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'WhySC',
	mixins: [iconMixin],
}
</script>
