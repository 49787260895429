<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M16.33,49.21a12.67,12.67,0,0,1,5.25-8.16,20.41,20.41,0,0,1,9.25-3.68,25.14,25.14,0,0,1,9.7.36,6.38,6.38,0,0,1,.86.22,1.3,1.3,0,0,1,.9,1.61v0a1.27,1.27,0,0,1-1.47,1l-.17,0a23.12,23.12,0,0,0-8-.64,16.42,16.42,0,0,0-11.89,5.77,7.38,7.38,0,0,0-1.64,4.94c0,.89,0,1.79,0,2.67,0,1.41.42,1.83,1.81,1.83H47.1q.34,0,.69,0a1.37,1.37,0,0,1,.11,2.74,4.43,4.43,0,0,1-.89,0H21.5c-3.08,0-4-.6-5.18-3.36Z"
		/>
		<path
			:fill="fillComputed"
			d="M35.9,13.43A11.91,11.91,0,0,1,41,15.74a9.74,9.74,0,1,1-8.14-2.12,6.55,6.55,0,0,0,.63-.19Zm5.8,9.63a7,7,0,1,0-6.86,7.07,7,7,0,0,0,6.86-7.07h0Z"
		/>
		<path
			:fill="fillComputed"
			d="M50.39,46.05H44.54c-1.24,0-1.74-.4-1.74-1.37s.57-1.37,1.77-1.37h5.82c0-2,0-4,0-5.89A2.13,2.13,0,0,1,51,36a1.47,1.47,0,0,1,1.35-.1,1.61,1.61,0,0,1,.7,1.16c.08,1.58,0,3.16,0,4.74v1.52h5.46a6.61,6.61,0,0,1,.89,0,1.39,1.39,0,0,1,1.33,1.44,1.33,1.33,0,0,1-1.35,1.3H54c-.26,0-.51,0-.89.06v6.14c0,.73-.3,1.29-1,1.39a2,2,0,0,1-1.38-.44A1.74,1.74,0,0,1,50.41,52c0-1.58,0-3.16,0-4.74Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'AcceptingNewPatients',
	mixins: [iconMixin],
}
</script>
