<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" :width="size" :height="size">
		<path
			:fill="fillComputed"
			d="M36,53.2A17.2,17.2,0,1,1,53.2,36,17.21,17.21,0,0,1,36,53.2Zm0-31.53A14.33,14.33,0,1,0,50.33,36,14.35,14.35,0,0,0,36,21.67Z"
		/>
		<polygon
			:fill="fillComputed"
			points="41.66 40.44 34.56 36.89 34.56 26.54 37.44 26.54 37.44 35.11 42.95 37.87 41.66 40.44"
		/>
	</svg>
</template>
<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Hours',
	mixins: [iconMixin],
}
</script>
