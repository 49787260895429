<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" :open="open">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="ease-in duration-200"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div
						class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6"
					>
						<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
							<button
								type="button"
								class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
								@click="closeModal"
							>
								<span class="sr-only">Close</span>
								<XIcon class="h-6 w-6" aria-hidden="true" />
							</button>
						</div>
						<div class="">
							<div class="text-center sm:text-left">
								<DialogTitle
									v-if="!instance.page_id"
									as="h3"
									class="text-lg leading-6 font-medium text-gray-900"
								>
									Create Event Instance
								</DialogTitle>
								<DialogTitle
									v-if="instance.page_id"
									as="h3"
									class="text-lg leading-6 font-medium text-gray-900"
								>
									Edit Event Instance
								</DialogTitle>
								<div class="mt-4">
									<div>
										<label for="event_id" class="block text-sm font-bold text-gray-700 pb-1">
											Event Template
										</label>
										<select
											id="event_id"
											v-model="event_id"
											name="event_id"
											class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
											@change="selectEventTemplate"
										>
											<option value="" selected disabled></option>
											<option
												v-for="event in events"
												:key="event.event_id"
												:value="event.event_id"
											>
												{{ event.title }}
											</option>
										</select>
									</div>

									<FieldsInput
										v-show="!instance.location_id && !instance.location"
										v-model="instance.virtual_link"
										:field="{
											type: 'input',
											inputType: 'text',
											label: 'Virtual Link',
											model: 'virtual_link',
											required: true,
											colSpan: 'col-span-6',
										}"
									/>

									<FieldsLocation
										v-show="!instance.virtual_link && !instance.location"
										v-model="instance.location_id"
										:field="{
											type: 'select',
											label: 'Springfield Clinic Location',
											model: 'location_id',
											required: true,
											multiSelect: false,
											showMap: true,
											clearButton: true,
											colSpan: 'col-span-6',
										}"
									/>

									<FieldsPlacesAutoComplete
										v-show="!instance.virtual_link && !instance.location_id"
										v-model="instance.location"
										:field="{
											label: 'Non-Springfield Clinic Location',
											model: 'location_virtual',
											colSpan: 'col-span-6',
											required: true,
											showMap: true,
											clearButton: true,
										}"
									/>

									<div v-if="instance.registration_enabled" class="grid grid-cols-12 gap-x-5">
										<FieldsUser
											v-model="instance.user_id"
											:field="{
												label: 'Send Reporting Emails To',
												model: 'user_id',
												required: true,
												colSpan: 'col-span-6',
											}"
										/>

										<FieldsInputNumber
											v-model="instance.max_registrations"
											:field="{
												type: 'input',
												inputType: 'number',
												label: 'Max Submissions',
												model: 'max_registrations',
												required: true,
												min: 0,
												max: 10000,
												step: 1,
												colSpan: 'col-span-6',
											}"
										/>

										<FieldsDatetime
											v-model="instance.opens"
											:field="{
												label: 'Registration Opens',
												model: 'opens',
												required: true,
												colSpan: 'col-span-6',
											}"
										/>

										<FieldsDatetime
											v-model="instance.cutoff"
											:field="{
												label: 'Registration Cutoff',
												model: 'cutoff',
												required: true,
												colSpan: 'col-span-6',
											}"
										/>
									</div>

									<div class="grid grid-cols-12 gap-x-5">
										<FieldsDatetime
											v-model="instance.starts"
											:field="{
												label: 'Event Starts',
												model: 'starts',
												required: true,
												colSpan: 'col-span-6',
											}"
										/>

										<FieldsDatetime
											v-model="instance.ends"
											:field="{
												label: 'Event ends',
												model: 'ends',
												required: true,
												colSpan: 'col-span-6',
											}"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
							<button
								v-if="!instance.id"
								type="button"
								class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
								@click="store"
							>
								<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
								Save
							</button>

							<button
								v-if="instance.id"
								type="button"
								class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
								@click="update"
							>
								<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
								Update
							</button>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { SaveIcon, XIcon } from '@heroicons/vue/outline'

export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		SaveIcon,
		XIcon,
	},
	props: {
		open: Boolean,
		eventInstance: {
			type: Object,
			default: function() {
				return {}
			},
		},
		eventInstanceDate: {
			type: String,
			default: null,
		},
	},
	emits: ['closeModal', 'eventInstanceCreated', 'eventInstanceUpdated'],
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			event_id: null,
			events: [],
			instance: {},
		}
	},
	mounted() {
		if (Object.keys(this.eventInstance).length) {
			let instance = JSON.parse(JSON.stringify(this.eventInstance))

			if (instance.opens)
				instance.opens = instance.opens.replace(' ', 'T').substring(0, instance.opens.length - 3)
			if (instance.cutoff)
				instance.cutoff = instance.cutoff.replace(' ', 'T').substring(0, instance.cutoff.length - 3)
			instance.starts = instance.starts.replace(' ', 'T').substring(0, instance.starts.length - 3)
			instance.ends = instance.ends.replace(' ', 'T').substring(0, instance.ends.length - 3)

			this.instance = instance
			console.log('this.instance', this.instance)
		}

		if (!this.instance.opens) this.instance.opens = this.processDateString(this.eventInstanceDate)
		if (!this.instance.starts) this.instance.starts = this.processDateString(this.eventInstanceDate)
		if (!this.instance.ends) this.instance.ends = this.processDateString(this.eventInstanceDate)
		if (!this.instance.cutoff) this.instance.cutoff = this.processDateString(this.eventInstanceDate)

		if (this.instance.event_id) {
			this.event_id = this.instance.event_id
		}

		this.fetchEvents()
	},
	methods: {
		closeModal() {
			this.$emit('closeModal')
		},

		fetchEvents() {
			this.loaderShow()

			this.api.events
				.selectList()
				.then(events => {
					this.events = events
				})
				.catch(error => {
					this.toast.error('Error fetching events:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		selectEventTemplate(evt) {
			let eventIndex = this.events.findIndex(x => x.event_id === evt.target.value)

			let instance = JSON.parse(JSON.stringify(this.events[eventIndex]))

			instance.opens = this.processDateString(this.eventInstanceDate)
			if (this.instance.opens && this.instance.registration_enabled) instance.opens = this.instance.opens

			instance.cutoff = this.processDateString(this.eventInstanceDate)
			if (this.instance.cutoff && this.instance.registration_enabled) instance.cutoff = this.instance.cutoff

			instance.starts = this.processDateString(this.eventInstanceDate)
			if (this.instance.starts) instance.starts = this.instance.starts

			instance.ends = this.processDateString(this.eventInstanceDate)
			if (this.instance.ends) instance.ends = this.instance.ends

			this.instance = instance

			this.instance.event_id = this.event_id

			// console.log('this.instance', this.instance)
		},

		processDateString(date) {
			return date + 'T12:00'
		},

		store() {
			this.loaderShow()

			// Fix to ensure these values are null if
			// registration is not enabled
			if (!this.instance.registration_enabled) {
				this.instance.opens = null
				this.instance.cutoff = null
			}

			this.api.events.eventInstances
				.store(this.instance)
				.then(eventInstances => {
					console.log('eventInstances', eventInstances)
					this.$emit('eventInstanceCreated', eventInstances)
					// this.events = events
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					this.toast.error('Error creating Event Instance!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		update() {
			this.loaderShow()

			// Fix to ensure these values are null if
			// registration is not enabled
			if (!this.instance.registration_enabled) {
				this.instance.opens = null
				this.instance.cutoff = null
			}

			this.api.events.eventInstances
				.slug(this.instance.id)
				.update(this.instance)
				.then(eventInstances => {
					console.log('eventInstances', eventInstances)
					this.$emit('eventInstanceUpdated', eventInstances)
					// this.events = events
				})
				.catch(error => {
					let validationErrors = error.response.data.message + '\n'
					if (!!error.response.data.errors) {
						// If there ARE errors
						Object.values(error.response.data.errors).forEach(err => (validationErrors += err[0] + '\n'))
					} else {
						//console.log('There are no errors.')
					}

					this.toast.error('Error creating Event Instance!\n' + validationErrors)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
