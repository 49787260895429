<template>
	<div class="bg-white shadow overflow-hidden sm:rounded-lg">
		<div class="border-t border-gray-200 px-4 py-5">
			<FieldsMultiSelect
				v-model="locationAmenities"
				:field="{
					type: 'select',
					label: 'Amenities',
					model: 'amenities',
					options: amenities,
					required: true,
					mode: 'tags',
					colSpan: 'col-span-6',
				}"
			/>
		</div>

		<div class="bg-white px-4 py-5 border-t border-gray-200 sm:px-6">
			<div class="-ml-4 -mt-2 flex items-center justify-end flex-wrap sm:flex-nowrap">
				<div class="ml-4 mt-2 flex-shrink-0">
					<button
						type="button"
						class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
						@click.prevent="cancel"
					>
						<XCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Reset
					</button>
					<button
						type="button"
						class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
						@click.prevent="update"
					>
						<SaveIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

import { SaveIcon, XCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		SaveIcon,
		XCircleIcon,
	},
	props: {},
	emits: ['cancelEditing'],
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		const location = inject('location')

		return {
			api,
			toast,
			route,
			location,
		}
	},
	data() {
		return {
			amenities: null,
			locationAmenities: [],
		}
	},
	computed: {},
	beforeMount() {
		this.fetchAmenities()
	},
	methods: {
		fetchAmenities() {
			this.loaderShow()

			this.api.amenities
				.fetch()
				.then(amenities => {
					console.log(amenities)

					amenities.forEach(element => {
						if (!this.amenities) this.amenities = []

						this.amenities.push({
							label: element.name,
							value: element.id,
						})
					})

					this.fetchLocationAmenities()
				})
				.then(() => {
					// this.loaderHide()
				})
		},

		fetchLocationAmenities() {
			// this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.amenities.fetch()
				.then(locationAmenities => {
					console.log(locationAmenities)

					locationAmenities.forEach(element => {
						this.locationAmenities.push({
							label: element.name,
							value: element.id,
						})
					})
				})
				.then(() => {
					this.loaderHide()
				})
		},

		update() {
			console.log(this.hours)
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.amenities.update(this.locationAmenities)
				.then(amenities => {
					amenities.forEach(element => {
						this.amenities.push({
							label: element.name,
							value: element.id,
						})
					})

					this.toast.success('Successfully updated Location Amenities')
				})
				.catch(error => {
					this.toast.error('Error updating Location Amenities:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>

<style>
.vc-time-date {
	display: none !important;
}
</style>
