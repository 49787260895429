<template>
	<draggable
		:list="props.modelValue"
		class="grid gap-y-2 gap-x-2"
		:class="[props.columnCount == 2 && props.modelValue.length > 1 ? 'grid-cols-' + props.columnCount : '']"
		handle=".handle"
		item-key="name"
		@start="drag = true"
		@end="drag = false"
	>
		<template #item="{ element, index }">
			<div class="flex-1 h-full flex gap-x-2 p-2 rounded-md bg-blue-50 shadow">
				<component
					:is="componentName(element.component)"
					:key="element.name"
					:field="element"
					:index="index"
					class="flex-1"
				/>

				<div class="flex-none flex flex-col gap-y-4 justify-start items-center">
					<SwitchVerticalIcon
						class="h-5 w-5 text-gray-400 hover:text-gray-500 handle"
						title="Move Field"
						aria-hidden="true"
					/>
					<!-- <div class="flex flex-col gap-x-4"> -->
					<button
						type="button"
						class="inline-flex text-red-400 hover:text-red-500 focus:outline-none"
						@click="false"
					>
						<TrashIcon class="h-5 w-5" aria-hidden="true" @click="confirmDelete(index)" />
					</button>
					<button
						type="button"
						class="inline-flex text-yellow-500 hover:text-yellow-600 focus:outline-none"
						@click="false"
					>
						<PencilAltIcon class="h-5 w-5" aria-hidden="true" @click="editField(element)" />
					</button>
					<!-- </div> -->
				</div>
			</div>
		</template>
	</draggable>

	<div v-if="props.modelValue.length == 1 && props.columnCount == 2" class="flex-1">
		<FormBuilderFieldSelector :show-field-groups="false" @addField="addField" />
	</div>

	<ModalsConfirmation
		ref="confirmation"
		:open="confirmationOpen"
		:confirmation-config="confirmationConfig"
		@closeModal="closeModal"
		@confirm="confirm"
	/>
</template>

<script>
import { ref, inject, onMounted, computed } from 'vue'

import draggable from 'vuedraggable'

import { SwitchVerticalIcon, TrashIcon, PencilAltIcon } from '@heroicons/vue/solid'

export default {
	components: {
		draggable,
		SwitchVerticalIcon,
		TrashIcon,
		PencilAltIcon,
	},
	props: {
		modelValue: {
			type: Object,
			default: function() {
				return {}
			},
		},
		subGroupIndex: {
			type: Number,
			default: 0,
		},
		columnCount: {
			type: [Number, String],
			default: 0,
		},
	},
	emits: ['update:modelValue', 'editField'],
	setup(props, { emit }) {
		const drag = ref(false)
		const fieldIndex = ref(null)

		const availableFields = inject('availableFields').value

		const confirmation = ref(null)
		const confirmationOpen = ref(false)
		const confirmationConfig = ref({})

		const isFieldSelected = index => {
			return false
		}

		const selectField = index => {
			// console.log('index2', index)
			fieldIndex.value = index
		}

		const componentName = suffix => {
			return 'FormBuilder' + suffix.charAt(0).toUpperCase() + suffix.slice(1)
		}

		function addField(index) {
			let fields = []
			fields = JSON.parse(JSON.stringify(props.modelValue))

			let field = {}
			field = JSON.parse(JSON.stringify(availableFields.fields[index].sub_groups[0].fields[0]))

			field.name = uuidv4()

			fields.push(field)

			console.log(fields)

			emit('update:modelValue', fields)
		}

		const uuidv4 = () => {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = (Math.random() * 16) | 0,
					v = c == 'x' ? r : (r & 0x3) | 0x8
				return v.toString(16)
			})
		}

		const closeModal = () => {
			confirmationOpen.value = false
			confirmationConfig.value = {}
			confirmation.value = null
		}

		const confirm = callback => {
			deleteItem()
		}

		const confirmDelete = index => {
			confirmationOpen.value = true
			confirmationConfig.value = {
				callback: 'deleteItem',
				title: 'Delete Field',
				content: 'Are you sure you want to delete this field?',
			}
			confirmation.value = index
		}

		const deleteItem = () => {
			let fields = []
			fields = JSON.parse(JSON.stringify(props.modelValue))

			fields.splice(confirmation.value, 1)

			emit('update:modelValue', fields)

			closeModal()
		}

		const editField = field => {
			emit('editField', field)
		}

		return {
			props,
			drag,
			fieldIndex,
			componentName,
			isFieldSelected,
			selectField,
			addField,
			confirmationOpen,
			closeModal,
			confirm,
			confirmationConfig,
			confirmDelete,
			editField,
		}
	},
}
</script>
