<template>
	<div :class="[hasColSpan ? field.colSpan : '', field.label ? 'pt-3' : '']">
		<label v-if="field.label" :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0 relative">
			<div v-if="!meta.valid" class="absolute inset-y-0 right-5 pr-3 pt-2.5 flex items-top pointer-events-none">
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>
			<select
				v-model="value"
				class="block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-blue-500 sm:text-sm rounded-md disabled:opacity-50"
				:class="[
					!meta.valid
						? 'border-red-300 focus:border-red-500 text-red-900'
						: 'border-gray-300 focus:border-blue-500',
				]"
				:name="field.model"
				:multiple="field.multiSelect"
				:disabled="disabled"
				@blur="$emit('blur')"
			>
				<option
					v-for="option in field.options"
					:key="option.name"
					:value="option.value"
					:selected="field.options.length == 1"
				>
					{{ option.name }}
				</option>
			</select>

			<div class="mt-2 text-red-500 font-semibold">{{ errorMessage }}</div>
		</div>
	</div>
</template>

<script>
import { computed, ref, reactive } from 'vue'
import { useForm, useField } from 'vee-validate'
import * as yup from 'yup'

import { ExclamationCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		ExclamationCircleIcon,
	},
	props: {
		modelValue: {
			type: [Object, String],
			default: null,
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue', 'blur', 'invalidField', 'validField'],
	setup(props, { emit }) {
		const required = ref(props.field.required)

		// Define a validation schema
		let schemaData = reactive({
			inputVal: yup
				.string()
				.nullable(true)
				.label(props.field.label),
		})
		if (required.value) {
			// console.log('required')
			schemaData = {
				inputVal: yup
					.string()
					.required()
					.nullable(true)
					.label(props.field.label),
			}
		}

		const schema = computed(() => {
			return yup.object(schemaData)
		})

		// Create a form context with the validation schema
		useForm({
			validationSchema: schema,
		})

		const defaultValue = props.field.options && props.field.options.length ? props.field.options[0].value : null
		// console.log('props.field.options', props.field.options)
		// console.log('defaultValue', defaultValue)

		const { errorMessage, value, meta, validate } = useField('inputVal', undefined, {
			initialValue: props.modelValue,
		})

		// trigger validation
		validate()
		setTimeout(() => {
			if (!meta.valid) {
				emit('invalidField', props.field.model)
			}
		}, 50)

		return {
			errorMessage,
			value,
			meta,
			defaultValue,
		}
	},
	data() {
		return {}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasValue: function() {
			return this.modelValue !== ''
		},
		hasDefaultValue: function() {
			return typeof this.field.defaultValue !== 'undefined'
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log("INPUT:", val);
				this.$emit('update:modelValue', val)
			},
		},
	},
	watch: {
		value: function(val) {
			this.inputVal = this.value
			// console.log(val)
			setTimeout(() => {
				// console.log('ERROR:', this.meta.valid)
				if (!this.meta.valid) this.$emit('invalidField', this.field.model)
				else this.$emit('validField', this.field.model)
			}, 50)
		},
		'field.options': {
			deep: true,
			handler(val) {
				// console.log('field.options', val)
				if (!this.defaultValue) {
					this.defaultValue = val && val.length ? val[0].value : null
				}

				if (!this.modelValue) this.value = this.defaultValue
			},
		},
	},
	mounted() {
		// if (this.hasDefaultValue) {
		// 	this.inputVal = this.field.defaultValue
		// }
		if (!this.modelValue) this.value = this.defaultValue

		// console.log('INPUT:', this.modelValue)
	},
	methods: {
		isSelected(defaultValue, value) {
			// console.log(
			// 	typeof value,
			// 	value,
			// 	defaultValue,
			// 	(!this.hasValue && this.hasDefaultValue && defaultValue === value) || (this.hasValue && this.modelValue === value)
			// )
			return (
				(!this.hasValue && this.hasDefaultValue && defaultValue === value) ||
				(this.hasValue && this.modelValue === value)
			)
		},
	},
}
</script>
