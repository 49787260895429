<template>
	<svg xmlns="http://www.w3.org/2000/svg" :height="size" :width="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M33.05,10.65a3.05,3.05,0,0,1,2.21,1.06l9.55,9.55q7.57,7.56,15.13,15.13a4.52,4.52,0,0,1,.88,1.21,2.47,2.47,0,0,1-.35,2.59,6.42,6.42,0,0,1-.55.62L35.26,65.45c-1.17,1.17-2.42,1.39-3.62.63a4.29,4.29,0,0,1-.74-.61L6.17,40.76a2.74,2.74,0,0,1,0-4.35L30.89,11.7A3,3,0,0,1,33.05,10.65Zm5.63,28v6.77l9.57-9.55-9.61-9.63V33h-.57C33.7,33,29.33,33,25,33a4,4,0,0,0-1.51.25A2.61,2.61,0,0,0,21.88,36c0,3.52,0,7.05,0,10.57,0,.13,0,.26,0,.41h5.57V38.6Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'GetDirections',
	mixins: [iconMixin],
}
</script>
