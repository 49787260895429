<template>
	<div v-if="eventAttributes" class="section">
		<v-calendar
			ref="calendar"
			class="custom-calendar max-w-full"
			:masks="masks"
			:attributes="eventAttributes"
			:timezone="'UTC'"
			disable-page-swipe
			is-expanded
			@update:to-page="fetchEventInstances"
		>
			<template #day-content="{ day, attributes }">
				<div
					class="flex flex-col gap-y-2 justify-between min-h-[150px] h-full z-10 overflow-hidden border border-gray-300 p-1"
				>
					<span class="day-label font-semibold text-right text-gray-900">{{ day.day }}</span>
					<div class="flex-grow flex flex-col gap-y-1 h-full">
						<div
							v-for="attr in attributes"
							:key="attr.key"
							class="bg-gray-100 border text-sm leading-tight rounded-lg p-1 shadow"
							:style="'border-color: ' + attr.customData.color + ';'"
						>
							<div>
								<div class="font-semibold">
									{{ attr.customData.title }}
								</div>
								<div class="text-xs">
									{{ attr.customData.starts_time }} - {{ attr.customData.ends_time }}
								</div>
								<!-- <div class="text-xs font-semibold">
									Registration:
									<span v-if="attr.customData.registration_enabled" class="text-green-600">
										Enabled
									</span>
									<span v-if="!attr.customData.registration_enabled" class="text-yellow-600">
										Disabled
									</span>
								</div> -->
								<div class="text-xs font-semibold">
									Registrations:
									<span v-if="attr.customData.registration_enabled" class="text-green-600">
										{{ attr.customData.registration_count }}
									</span>
									<span v-if="!attr.customData.registration_enabled" class="text-yellow-600">
										Disabled
									</span>
								</div>

								<div class="flex gap-x-2 text-gray-400 justify-between mt-2">
									<button
										title="Delete Event"
										class="hover:text-red-500"
										@click="confirmDelete(attr.customData)"
									>
										<TrashIcon class="h-5 w-5" aria-hidden="true" />
									</button>
									<div v-if="attr.customData.registration_count">
										<!-- empty for flex space -->
									</div>
									<div class="flex gap-x-2">
										<button
											v-if="
												attr.customData.registration_enabled &&
													attr.customData.registration_count
											"
											title="Download Registrations"
											class="hover:text-green-500"
											@click="downloadRegistrations(attr.customData)"
										>
											<DownloadIcon class="h-5 w-5" aria-hidden="true" />
										</button>
										<button
											v-if="
												attr.customData.registration_enabled &&
													attr.customData.registration_count
											"
											title="View Registrations"
											class="hover:text-blue-500"
											@click="viewRegistrations(attr.customData)"
										>
											<ClipboardListIcon class="h-5 w-5" aria-hidden="true" />
										</button>
										<a
											:href="previewUrl + attr.customData.path"
											target="_blank"
											title="View Event Page"
											class="hover:text-indigo-500"
										>
											<ExternalLinkIcon class="h-5 w-5" aria-hidden="true" />
										</a>
										<button
											title="Edit Event"
											class="hover:text-yellow-400"
											@click="editEventInstance(attr.customData)"
										>
											<PencilAltIcon class="h-5 w-5" aria-hidden="true" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div>
						<button
							title="Add Event"
							class="flex justify-center p-1 w-full text-sm text-gray-300 font-semibold border rounded border-white hover:text-gray-600 hover:border-gray-300 hover:bg-gray-50"
							@click="addEventInstance(day)"
						>
							<PlusIcon class="h-5 w-5" aria-hidden="true" />
							Event
						</button>
					</div>
				</div>
			</template>
		</v-calendar>

		<EventsModalsEventInstance
			v-if="eventInstanceOpen"
			ref="eventInstance"
			:open="eventInstanceOpen"
			:event-instance="eventInstance"
			:event-instance-date="eventInstanceDate"
			@closeModal="closeModal"
			@eventInstanceCreated="eventInstanceCreated"
			@eventInstanceUpdated="eventInstanceUpdated"
		/>

		<EventsModalsRegistrations
			v-if="eventRegistrationsOpen"
			ref="eventInstance"
			:open="eventRegistrationsOpen"
			:event-instance="eventInstance"
			@closeModal="closeModal"
		/>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import getEnv from '@/extensions/env'

import {
	PlusIcon,
	PencilAltIcon,
	// DuplicateIcon,
	ClipboardListIcon,
	ExternalLinkIcon,
	DownloadIcon,
	TrashIcon,
} from '@heroicons/vue/solid'

export default {
	components: {
		PlusIcon,
		PencilAltIcon,
		// DuplicateIcon,
		ClipboardListIcon,
		ExternalLinkIcon,
		DownloadIcon,
		TrashIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		const month = new Date().getMonth()
		const year = new Date().getFullYear()
		return {
			previewUrl: getEnv('VUE_APP_FRONTEND_URL'),
			calendar: null,
			eventInstance: {},
			eventInstanceOpen: false,
			eventRegistrationsOpen: false,
			eventInstanceDate: null,
			masks: {
				weekdays: 'WWW',
			},
			eventAttributes: null,
			currentPage: null,
			year: year,
			month: month,

			confirmationOpen: false,
			confirmationConfig: {},
		}
	},
	mounted() {
		// console.log('calendar', this.$refs.calendar)

		this.fetchEventInstances({
			year: this.year,
			month: this.month,
		})
	},
	methods: {
		closeModal() {
			this.eventInstanceOpen = false
			this.eventRegistrationsOpen = false
			this.eventInstance = {}

			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.slug
		},

		addEventInstance(day) {
			// console.log('day', day)
			this.eventInstanceOpen = true
			this.eventInstanceDate = day.id
		},

		refreshCalendar() {
			this.fetchEventInstances(this.currentPage)
		},

		fetchEventInstances(page) {
			this.currentPage = page
			// console.log('fetchEventInstances', page)
			// this.eventAttributes = null
			this.loaderShow()

			this.api.events.eventInstances
				.showByMonth(page)
				.then(eventInstances => {
					console.log('eventInstances', eventInstances)
					this.eventAttributes = eventInstances
				})
				.catch(error => {
					this.toast.error('Error fetching Event Instances:\n' + error)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		viewRegistrations(data) {
			return false
			this.eventInstance = JSON.parse(JSON.stringify(data))
			// console.log('eventInstance', this.eventInstance)
			this.eventRegistrationsOpen = true
		},

		downloadRegistrations(eventInstance) {
			this.loaderShow()

			this.api.events.eventInstances
				.downloadRegistrations(eventInstance.id)
				.then(data => {
					// console.log(data)

					const blob = new Blob([data], { type: 'text/csv' })
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = eventInstance.fileName
					link.click()
					URL.revokeObjectURL(link.href)
				})
				.catch(error => {
					this.toast.error('Error downloading event registrations:\n' + error)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		editEventInstance(data) {
			this.eventInstance = JSON.parse(JSON.stringify(data))
			// console.log('eventInstance', this.eventInstance)
			this.eventInstanceOpen = true
		},

		eventInstanceCreated(eventInstances) {
			this.closeModal()
			this.eventAttributes = null
			this.eventAttributes = eventInstances
		},

		eventInstanceUpdated(eventInstances) {
			this.closeModal()
			this.eventAttributes = null
			this.eventAttributes = eventInstances
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete(customData) {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'deleteItem',
				title: 'Delete Event',
				content: 'Are you sure you want to delete this event?',
			}

			if (customData.registration_count && customData.future_event) {
				this.confirmationConfig.warning = 'This will send out cancellation notifications to registrants!'
			}

			this.$refs.confirmation.slug = customData.id
		},

		deleteItem() {
			let id = this.$refs.confirmation.slug

			this.loaderShow()

			this.api.events.eventInstances
				.destroy(id)
				.then(data => {
					delete this.$refs.confirmation.slug

					this.closeModal()

					this.refreshCalendar()
				})
				.catch(error => {
					// this.toast.error('Error deleting clinical research:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>

<style lang="postcss" scoped>
::-webkit-scrollbar {
	width: 0px;
}
::-webkit-scrollbar-track {
	display: none;
}
:deep() .custom-calendar.vc-container {
	--day-border: 1px solid #b8c2cc;
	--day-border-highlight: 1px solid #b8c2cc;
	--day-width: 90px;
	--day-height: 90px;
	--weekday-bg: #f8fafc;
	--weekday-border: 1px solid #eaeaea;
	border-radius: 0;
	width: 100%;
	& .vc-header {
		background-color: #f1f5f8;
		padding: 10px 0;
	}
	& .vc-weeks {
		padding: 0;
	}
	& .vc-weekday {
		background-color: var(--weekday-bg);
		border-bottom: var(--weekday-border);
		border-top: var(--weekday-border);
		padding: 5px 0;
	}
	& .vc-day {
		padding: 0 5px 3px 5px;
		text-align: left;
		height: var(--day-height);
		min-width: var(--day-width);
		background-color: white;
		&.weekday-1,
		&.weekday-7 {
			background-color: #eff8ff;
		}
		&:not(.on-bottom) {
			border-bottom: var(--day-border);
			&.weekday-1 {
				border-bottom: var(--day-border-highlight);
			}
		}
		&:not(.on-right) {
			border-right: var(--day-border);
		}
	}
	& .vc-day-dots {
		margin-bottom: 5px;
	}
}
</style>
