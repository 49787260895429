<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:stroke="fillComputed"
			class="cls-1"
			d="M47.55,55.61,46.49,44.55S54,38.91,56.81,26.38c.49-1.74.57-3-.82-3.73A2.65,2.65,0,0,0,52.12,24s-4.64,13.78-15,15.33S18,29.08,18,29.08c-1.67-1.66-3.21-1-4.1-.16-1.11,1.13-.67,2.29.3,3.81C21.07,44,29.29,47.08,29.29,47.08l1.05,8.58"
		/>
		<path
			:stroke="fillComputed"
			class="cls-2"
			d="M61.36,23.88a2.56,2.56,0,0,0-3.3,1.49l-.76,2a2.55,2.55,0,0,0,4.78,1.8l.76-2A2.55,2.55,0,0,0,61.36,23.88Z"
		/>
		<path
			:stroke="fillComputed"
			class="cls-2"
			d="M47.72,20.94l-.67,1.77a2.24,2.24,0,0,0,4.19,1.57l.67-1.76a2.24,2.24,0,1,0-4.19-1.58Z"
		/>
		<path
			:stroke="fillComputed"
			class="cls-2"
			d="M18.85,25.22a2.24,2.24,0,0,0-.41,3.14l1.15,1.49a2.24,2.24,0,0,0,3.55-2.73L22,25.63A2.23,2.23,0,0,0,18.85,25.22Z"
		/>
		<path
			:stroke="fillComputed"
			class="cls-2"
			d="M13,32.18a2.24,2.24,0,0,0-3.54,2.73l1.15,1.5a2.24,2.24,0,1,0,3.54-2.73Z"
		/>
		<path
			:stroke="fillComputed"
			class="cls-2"
			d="M35.17,36.12a7.3,7.3,0,1,0-7.29-7.29A7.3,7.3,0,0,0,35.17,36.12Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PhysicalTherapy',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1,
.cls-2 {
	fill: none;
	stroke-width: 2px;
}
.cls-1 {
	stroke-linejoin: round;
}
.cls-2 {
	stroke-miterlimit: 10;
}
</style>
