<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="section" static class="fixed inset-0 overflow-hidden slide-panel" :open="open">
			<div class="absolute inset-0 overflow-hidden">
				<TransitionChild
					as="template"
					enter="ease-in-out duration-500"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="ease-in-out duration-500"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<DialogOverlay class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>
				<div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
					<TransitionChild
						as="template"
						enter="transform transition ease-in-out duration-500 sm:duration-700"
						enter-from="translate-x-full"
						enter-to="translate-x-0"
						leave="transform transition ease-in-out duration-500 sm:duration-700"
						leave-from="translate-x-0"
						leave-to="translate-x-full"
					>
						<div class="relative w-screen">
							<TransitionChild
								as="template"
								enter="ease-in-out duration-500"
								enter-from="opacity-0"
								enter-to="opacity-100"
								leave="ease-in-out duration-500"
								leave-from="opacity-100"
								leave-to="opacity-0"
							>
								<div
									class="absolute bg-white top-0 left-0 -ml-8 pt-4 pr-4 pl-3 pb-4 rounded-bl-md flex sm:-ml-10"
								>
									<button
										class="rounded-md text-gray-300 hover:text-black focus:outline-none"
										@click="closeSlide"
									>
										<span class="sr-only">Close panel</span>
										<XIcon class="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
							</TransitionChild>
							<div
								class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll divide-y divide-gray-200"
							>
								<div class="px-4 sm:px-6">
									<DialogTitle class="text-lg font-bold text-gray-900">
										{{ slideTitle }}
									</DialogTitle>
								</div>
								<div class="mt-6 relative flex-1 px-4 sm:px-6 bg-gray-50">
									<!-- Replace with your content -->
									<div class=" inset-0 py-4 sm:py-6">
										<MdStaffProviderMediaUpload
											v-if="slideComponent === 'MdStaffProviderMediaUpload'"
											:media-upload-accept="mediaUploadAccept"
											:media-upload-extensions="mediaUploadExtensions"
										/>

										<FormBuilderForm v-if="slideComponent === 'FormBuilderForm'" />

										<MediaImage
											v-if="slideComponent === 'MediaImage'"
											:edit="edit"
											:media-types="mediaTypes"
											:media-categories="mediaCategories"
											:media-upload-category="mediaUploadCategory"
											:media-upload-accept="mediaUploadAccept"
											:media-upload-extensions="mediaUploadExtensions"
											@mediaCreated="mediaCreated"
										/>

										<MediaVideo
											v-if="slideComponent === 'MediaVideo'"
											:edit="edit"
											:media-types="mediaTypes"
											:media-categories="mediaCategories"
											:media-upload-category="mediaUploadCategory"
											:media-upload-accept="mediaUploadAccept"
											:media-upload-extensions="mediaUploadExtensions"
											@mediaCreated="mediaCreated"
										/>

										<MediaDocument
											v-if="slideComponent === 'MediaDocument'"
											:edit="edit"
											:media-types="mediaTypes"
											:media-categories="mediaCategories"
											:media-upload-category="mediaUploadCategory"
											:media-upload-accept="mediaUploadAccept"
											:media-upload-extensions="mediaUploadExtensions"
											@mediaCreated="mediaCreated"
										/>

										<MediaImageSelect
											v-if="slideComponent === 'MediaImageSelect'"
											:media-types="mediaTypes"
											:media-categories="mediaCategories"
											:media-upload-category="mediaUploadCategory"
											:media-upload-accept="mediaUploadAccept"
											:media-upload-extensions="mediaUploadExtensions"
											@selectMedia="selectMedia"
										/>

										<MediaVideoSelect
											v-if="slideComponent === 'MediaVideoSelect'"
											:media-types="mediaTypes"
											:media-categories="mediaCategories"
											:media-upload-category="mediaUploadCategory"
											:media-upload-accept="mediaUploadAccept"
											:media-upload-extensions="mediaUploadExtensions"
											@selectMedia="selectMedia"
										/>

										<MediaIconSelect
											v-if="slideComponent === 'MediaIconSelect'"
											@selectIcon="selectIcon"
										/>

										<MediaLinkSelect
											v-if="slideComponent === 'MediaLinkSelect'"
											:slide-extra="slideExtra"
											@selectLink="selectLink"
										/>

										<MediaButtonLinkSelect
											v-if="slideComponent === 'MediaButtonLinkSelect'"
											:menu-button="menuButton"
											@selectButtonLink="selectButtonLink"
										/>

										<MediaComponentSelect
											v-if="slideComponent === 'MediaComponentSelect'"
											@saveComponent="saveComponent"
										/>

										<MediaColorSelect
											v-if="slideComponent === 'MediaColorSelect'"
											@selectColor="selectColor"
										/>

										<MediaMainMenuItemSelect
											v-if="slideComponent === 'MediaMainMenuItemSelect'"
											@createMainMenuItem="createMainMenuItem"
											@updateMainMenuItem="updateMainMenuItem"
										/>

										<ContentDetourItemSelect
											v-if="slideComponent === 'ContentDetourItemSelect'"
											@createDetour="createDetour"
										/>
									</div>
									<!-- /End replace -->
								</div>
							</div>
						</div>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

export default {
	name: 'SlidePanel',
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		XIcon,
	},
	props: {
		open: Boolean,
		slideTitle: {
			type: String,
			default: '',
		},
		slideComponent: {
			type: String,
			default: '',
		},
		slideExtra: {
			type: String,
			default: null,
		},

		mediaTypes: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaCategories: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaUploadCategory: {
			type: String,
			default: 'services',
		},

		mediaUploadAccept: {
			type: Array,
			default: function() {
				return ['image/png', 'image/jpeg']
			},
		},
		mediaUploadExtensions: {
			type: Array,
			default: function() {
				return ['jpg', 'jpeg', 'png']
			},
		},

		menuButton: {
			type: [Object, null],
			default: null,
		},

		edit: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: [
		'closeSlide',
		'selectMedia',
		'selectIcon',
		'selectLink',
		'selectButtonLink',
		'saveComponent',
		'selectColor',
		'createMainMenuItem',
		'updateMainMenuItem',
		'mediaCreated',
		'createDetour',
	],
	beforeMount() {
		// console.log(this.mediaUploadAccept, this.mediaUploadExtensions);
	},
	methods: {
		closeSlide() {
			this.$emit('closeSlide')
		},

		selectMedia(file) {
			this.$emit('selectMedia', file)
			this.$emit('closeSlide')
		},

		selectIcon(icon) {
			this.$emit('selectIcon', icon)
			this.$emit('closeSlide')
		},

		selectLink(link) {
			this.$emit('selectLink', link)
			this.$emit('closeSlide')
		},

		selectButtonLink(buttonLink) {
			this.$emit('selectButtonLink', buttonLink)
			this.$emit('closeSlide')
		},

		saveComponent(module) {
			this.$emit('saveComponent', module)
			this.$emit('closeSlide')
		},

		selectColor(color) {
			this.$emit('selectColor', color)
			this.$emit('closeSlide')
		},

		createMainMenuItem(mainMenuItem) {
			this.$emit('createMainMenuItem', mainMenuItem)
			this.$emit('closeSlide')
		},

		updateMainMenuItem(mainMenuItem) {
			this.$emit('updateMainMenuItem', mainMenuItem)
			this.$emit('closeSlide')
		},
		createDetour(detour) {
			this.$emit('createDetour', detour)
			//this.$emit('closeSlide')
		},
		mediaCreated(media) {
			this.$emit('mediaCreated', media)
			this.$emit('closeSlide')
		},
	},
}
</script>
