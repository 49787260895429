<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M19.43,58.8l2.72-12.54a20,20,0,1,1,15.3,7.12,20.32,20.32,0,0,1-5.33-.71Zm18-42.15a16.68,16.68,0,0,0-12.27,28l.59.63-1.64,7.54,7.71-3.73.63.2a16.7,16.7,0,1,0,5-32.65Z"
		/>
		<text :stroke="fillComputed" class="cls-2" transform="translate(32.3 39.74)">?</text>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Questions',
	mixins: [iconMixin],
}
</script>

<style>
.cls-2 {
	font-size: 23px;
	font-family: ProximaNova-Bold, Proxima Nova;
	font-weight: 700;
}
</style>
