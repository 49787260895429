<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M39,8.73a22.5,22.5,0,0,1,12.2,6.58,24.17,24.17,0,0,1,6.94,16,35.64,35.64,0,0,1-.52,8.12,32.53,32.53,0,0,1-3.78,10.68,35.93,35.93,0,0,1-5.44,7A35.92,35.92,0,0,1,37,65.28c-1.07.47-2.18.86-3.33,1.31V57.22c0-.62,0-.63-.63-.65a23,23,0,0,1-7-1.38,23.62,23.62,0,0,1-12.25-9.61c-3.41-5.34-4.14-11.22-3.16-17.34a23,23,0,0,1,6.47-12.66A23.19,23.19,0,0,1,31.54,8.5,41.23,41.23,0,0,1,39,8.73Zm-12.58,25c-.09.25-.13.4-.19.55a7.9,7.9,0,0,1-2,2.9c-.37.35-.35.6,0,.91l1.69,1.37a.61.61,0,0,0,.83.05,17.31,17.31,0,0,0,3.74-3.3,8.33,8.33,0,0,0,2-5.29c0-1.55,0-3.1,0-4.65,0-.52-.1-.63-.61-.63H23.22c-.5,0-.62.13-.64.63V33c0,.57.11.68.68.68h3.13Zm13.31,0a.54.54,0,0,1,0,.16,1.66,1.66,0,0,1-.06.21,8,8,0,0,1-2.05,3.11c-.38.38-.36.56.06.9s1.11.88,1.65,1.34a.65.65,0,0,0,.88,0,18.08,18.08,0,0,0,3.35-2.89,8.08,8.08,0,0,0,2.26-5c.07-1.8,0-3.6,0-5.41,0-.38-.25-.51-.62-.51H36.56c-.47,0-.6.13-.6.61V33c0,.53.09.61.62.63H39.7Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'FAQs',
	mixins: [iconMixin],
}
</script>
