<template>
	<div class="mb-[100px]">
		<div v-if="!selectedComponent" class="flex flex-wrap gap-x-5">
			<div v-for="item in components" :key="item.name" class="mb-4 min-w-[23%]">
				<div class="bg-white border shadow rounded-lg px-4 py-5">
					<div class="mt-2 flex flex-col items-start justify-between">
						<div class="flex-1 text-sm text-gray-500">
							<h3 class="text-lg leading-6 font-medium text-gray-900">
								{{ item.name }}
							</h3>
							<p>{{ item.description }}</p>
						</div>

						<div class="flex-1 mt-4 flex items-center justify-center">
							<button
								type="button"
								class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
								@click="selectComponent(item)"
							>
								Select
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="selectedComponent">
			<div class="flex">
				<div class="flex-1 text-xl font-semibold">{{ selectedComponent.name }} Component</div>
				<div>
					<button
						type="button"
						class="w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none"
						@click="chooseAnotherComponent"
					>
						Choose Another Component
					</button>
				</div>
			</div>

			<div>
				<div>
					<FieldsSelect
						v-model="selectedComponent.display_option"
						:field="{
							type: 'select',
							label: 'Display Option',
							model: 'display_option',
							options: selectedComponent.options,
							required: true,
							colSpan: 'col-span-6',
						}"
					/>
				</div>

				<div
					v-if="
						selectedComponent.name != 'Accordions' &&
							selectedComponent.name != 'Search Providers' &&
							selectedComponent.name != 'Search Locations' &&
							selectedComponentOptions.length > 0
					"
				>
					<FieldsMultiSelect
						v-model="selectedComponent.items"
						:field="{
							type: 'select',
							label: selectedComponent.name,
							model: selectedComponent.name,
							options: selectedComponentOptions,
							mode: 'tags',
							maxItems: selectedComponent.mode == 'single' ? 1 : -1,
							colSpan: 'col-span-6',
						}"
					/>
					<div v-if="selectedComponent.mode == 'tags'" class="text-sm text-gray-400">
						If no {{ selectedComponent.name }} are selected then all {{ selectedComponent.name }} will be
						displayed.
					</div>
				</div>

				<div v-if="selectedComponent.name == 'Accordions'">
					<div>
						<div class="mt-5">
							<draggable
								v-model="static_data"
								handle=".handle"
								item-key="index"
								class="flex-1 flex flex-col gap-y-2"
								@start="drag = true"
								@end="drag = false"
								@change="itemMoved"
							>
								<template #item="{ element, index }">
									<div class="border border-gray-200 rounded-md bg-gray-50 shadow mb-2">
										<div class="flex gap-x-8 gap-y-2 items-center px-4 pb-4 sm:px-6">
											<div class="min-w-0 flex-1">
												<FieldsInput
													v-model="element.term"
													:field="{
														type: 'input',
														inputType: 'text',
														label: 'Term',
														model: 'term',
														required: false,
														colSpan: 'col-span-6',
													}"
												/>

												<FieldsTipTapEditor
													v-model="element.description"
													:field="{
														label: 'Description',
														model: 'description',
														required: false,
														colSpan: 'col-span-6',
														rows: 5,
													}"
												/>
											</div>

											<div
												class="py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center gap-x-8"
											>
												<button
													type="button"
													class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-red-100 text-red-600 hover:bg-red-200 focus:outline-none"
													@click="confirmDelete(index)"
												>
													<XIcon class="h-5 w-5" aria-hidden="true" />
												</button>
												<div class="flex-none flex justify-end items-center">
													<SwitchVerticalIcon
														class="h-5 w-5 text-gray-400 handle"
														aria-hidden="true"
													/>
												</div>
											</div>
										</div>
									</div>
								</template>
							</draggable>
						</div>

						<div class="mt-5">
							<button
								type="button"
								class="relative flex w-full items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
								@click="addItem"
							>
								<PlusIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
								Add Accordion Item
							</button>
						</div>
					</div>
				</div>

				<div class="border-t border-gray-200 mt-12 flex justify-end">
					<button
						type="button"
						class="mt-4 items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
						@click="saveComponent"
					>
						Save Component
					</button>
				</div>
			</div>
		</div>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>
	</div>
</template>

<script>
import { inject, ref } from 'vue'
import { useToast } from 'vue-toastification'

import draggable from 'vuedraggable'
import { PlusIcon, XIcon, SwitchVerticalIcon } from '@heroicons/vue/solid'

// import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default {
	components: {
		draggable,
		PlusIcon,
		XIcon,
		SwitchVerticalIcon,
	},
	props: {
		// component: {
		// 	type: Object,
		// 	default: function() {
		// 		return {}
		// 	},
		// },
		// test: {
		// 	type: Object,
		// 	default: function() {
		// 		return {}
		// 	},
		// },
	},

	emits: ['saveComponent'],

	setup() {
		const api = inject('api')
		const toast = useToast()

		const component = ref(inject('component').value)

		return {
			api,
			toast,
			component,
		}
	},
	data() {
		return {
			showServices: false,
			services: [],
			showDepartments: false,
			departments: [],
			showLocations: false,
			locations: [],

			selectedComponent: null,
			selectedComponentOptions: [],
			components: [],

			drag: false,
			static_data: [],
			index: null,
			name: null,
			term: null,
			description: null,
			editing: false,
			confirmationOpen: false,
			confirmationConfig: {},
		}
	},
	computed: {
		hasComponent: function() {
			return Object.keys(this.component).length !== 0
		},

		accordionItemFormValid() {
			return this.term && this.description
		},
		formValid() {
			return this.name && this.static_data.length
		},
	},
	watch: {
		// term: function(val) {
		// 	if (this.term) {
		// 		this.term = this.term.replaceAll('<p class="mb-2"></p>', '')
		// 		setTimeout(() => {
		// 			if (this.term == '') {
		// 				this.term = null
		// 			}
		// 		}, 50)
		// 	}
		// },
		// description: function(val) {
		// 	if (this.description) {
		// 		this.description = this.description.replaceAll('<p class="mb-2"></p>', '')
		// 		setTimeout(() => {
		// 			if (this.description == '') {
		// 				this.description = null
		// 			}
		// 		}, 50)
		// 	}
		// },
	},
	mounted() {
		this.fetchComponents()
	},
	beforeUnmount() {
		this.selectedComponent = null
		this.selectedComponentOptions = []
	},
	methods: {
		fetchComponents() {
			this.loaderShow()

			this.api.components
				.all()
				.then(components => {
					components.forEach(component => {
						let options = []
						component.options.forEach(componentOption => {
							options.push({
								value: componentOption.display_tag,
								name: componentOption.name,
							})
						})

						this.components.push({
							id: component.id,
							name: component.name,
							description: component.description,
							options: options,
							display_option: null,
							static_data: null,
							items: [],
						})
						// this.components.push()
					})

					setTimeout(() => {
						if (this.hasComponent) {
							let index = this.components.findIndex(x => x.name === this.component.name)
							this.selectComponent(Object.assign(this.components[index], this.component))

							// If there is static data then parse it
							if (this.component.static_data) {
								this.static_data = JSON.parse(this.component.static_data)
							}

							console.log(this.component)
						}
					}, 200)
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching keywords:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		hasServices(item) {
			return typeof item.services !== 'undefined'
		},
		hasDepartments(item) {
			return typeof item.departments !== 'undefined'
		},
		hasLocations(item) {
			return typeof item.locations !== 'undefined'
		},
		hasDisplayOptions(item) {
			return typeof item.displayOptions !== 'undefined'
		},

		toggleAllServices(item) {
			if (!item.allServices) {
				this.fetchServices()
			} else {
				this.showServices = false
				this.services = []
			}
		},
		toggleAllDepartments(item) {
			if (!item.allDepartments) {
				this.fetchDepartments()
			} else {
				this.showDepartments = false
				this.departments = []
			}
		},
		toggleAllLocations(item) {
			if (!item.allLocations) {
				this.fetchLocations()
			} else {
				this.showLocations = false
				item.locations = []
				this.locations = []
			}
		},

		chooseAnotherComponent() {
			this.selectedComponent = null
			this.static_data = []
			this.selectedComponentOptions = []
		},

		selectComponent(item) {
			this.selectedComponent = {}
			this.selectedComponent.display_option = ''
			this.selectedComponent.static_data = null
			this.selectedComponent.items = []
			this.selectedComponent = Object.assign(this.selectedComponent, JSON.parse(JSON.stringify(item)))

			let endpoint = this.selectedComponent.name.toLowerCase()

			this.selectedComponent.mode = 'tags'
			if (endpoint == 'forms') {
				this.selectedComponent.mode = 'single'
			}
			if (endpoint == 'clinical research') {
				endpoint = 'clinicalResearch'
			}
			if (endpoint == 'accordions' || endpoint == 'search providers' || endpoint == 'search locations') {
				return true
			}

			// console.log('endpoint', this.api)

			this.loaderShow()
			this.api[endpoint]
				.component_list()
				.then(data => {
					data.forEach(element => {
						this.selectedComponentOptions.push({
							label: element.name || element.title,
							value: element.id,
						})
					})
				})
				.catch(error => {
					this.toast.error('Error fetching component options:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		saveComponent() {
			if (!this.selectedComponent.display_option) {
				this.toast.error('You must select a display option.')
			} else {
				// clean up what is returned to the model
				if (typeof this.selectedComponent.options !== 'undefined') {
					delete this.selectedComponent.options
				}

				// Stringify the static_data if there is any
				if (Object.keys(this.static_data).length > 0) {
					if (this.selectedComponent.name == 'Forms') {
						this.selectedComponent.static_data = Object.assign({}, this.static_data)
					} else {
						this.selectedComponent.static_data = JSON.stringify(this.static_data)
					}
				}

				// console.log('this.selectedComponent', this.selectedComponent)
				this.$emit('saveComponent', this.selectedComponent)
			}
		},

		addItem() {
			this.static_data.push({
				term: this.term,
				description: this.description,
			})

			setTimeout(() => {
				this.term = null
				this.description = null
			}, 50)
		},

		editAccordionItem(index) {
			this.editing = true
			let term = JSON.parse(JSON.stringify(this.static_data[index]))
			this.index = index
			this.term = term.term
			this.description = term.description
		},

		updateAccordionItem() {
			this.static_data[this.index].term = this.term
			this.static_data[this.index].description = this.description

			this.cancelEdit()
		},

		cancelEdit() {
			this.index = null
			this.term = null
			this.description = null
			this.editing = false
		},

		closeModal() {
			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.category
			delete this.$refs.confirmation.id
		},

		itemMoved(evt) {
			//
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete(index) {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'deleteAccordionItem',
				title: 'Delete Accordion Item',
				content: 'Are you sure you want to delete this Accordion item?',
			}
			this.$refs.confirmation.index = index
		},

		deleteAccordionItem() {
			let index = this.$refs.confirmation.index
			delete this.$refs.confirmation.index

			this.static_data.splice(index, 1)

			this.closeModal()
		},
	},
}
</script>

<style>
.icon {
	min-width: 72px;
}
</style>
