<template>
	<button
		type="button"
		class="relative tooltip inline-flex items-center px-2 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none"
		@click.prevent="refreshTable"
	>
		<span class="tooltiptext">Refresh Table</span>
		<RefreshIcon class="h-5 w-5 text-white" aria-hidden="true" />
	</button>
</template>

<script>
import { defineComponent } from 'vue'
import { RefreshIcon } from '@heroicons/vue/solid'

export default defineComponent({
	name: 'RefreshTableButton',
	components: {
		RefreshIcon,
	},
	emits: ['refreshTable'],
	setup() {
		// DO SOMETHING HERE
	},
	methods: {
		refreshTable() {
			this.$emit('refreshTable')
		},
	},
})
</script>
