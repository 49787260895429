<template>
	<div :id="props.field.name">
		<div class="">
			<label :for="props.field.name" class="block font-medium text-gray-700">
				{{ props.field.label }}
				<span v-show="isRequired" class="text-red-500">*</span>
			</label>
			<div class="mt-1">
				<input
					type="text"
					:name="props.field.name"
					:placeholder="props.field.placeholder"
					class="pointer-events-none shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
				/>
			</div>
			<div v-if="props.field.help" class="text-sm text-gray-400">
				{{ props.field.help }}
			</div>

			<!-- <div class="text-sm text-blue-400 mt-2 whitespace-pre">
				{{ props.field }}
			</div> -->
		</div>
	</div>
</template>

<script>
import { reactive, computed, watchEffect } from 'vue'

export default {
	props: {
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		index: {
			type: Number,
			default: 0,
		},
		selected: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const isRequired = computed(() => {
			let requiredIndex = props.field.field_validations.findIndex(
				x => x.field_validation_rule.rule === 'required'
			)

			return props.field.field_validations[requiredIndex].rule_value === 'true'
		})

		return {
			props,
			isRequired,
		}
	},
}
</script>
