<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M44.08,43.62a9.85,9.85,0,0,0-1.63.11,4,4,0,0,0-1.3.56c-.57.35-1.09.78-1.64,1.18a3.35,3.35,0,0,1-4.09,0l-1.54-1.12a3.36,3.36,0,0,0-2.05-.69,19.55,19.55,0,0,1-2.54-.06A3.12,3.12,0,0,1,26.6,41.4c-.24-.64-.44-1.29-.64-1.95a3.51,3.51,0,0,0-1.35-1.86c-.53-.36-1.05-.74-1.57-1.12a3.37,3.37,0,0,1-1.24-3.81c.2-.63.41-1.26.62-1.89a3.51,3.51,0,0,0,0-2.22,19.75,19.75,0,0,1-.74-2.49,3.06,3.06,0,0,1,1.19-3c.56-.45,1.16-.86,1.75-1.28a3.48,3.48,0,0,0,1.32-1.81c.19-.61.39-1.21.58-1.82a3.36,3.36,0,0,1,3.3-2.42c.8,0,1.6,0,2.4,0a2.84,2.84,0,0,0,1.55-.58l1.66-1.2a3.35,3.35,0,0,1,4.09,0c.51.38,1,.75,1.53,1.13a3.52,3.52,0,0,0,2.14.7h1.93A3.35,3.35,0,0,1,48.39,18c.21.64.42,1.28.62,1.92a3.34,3.34,0,0,0,1.25,1.73c.54.39,1.1.78,1.64,1.18a3.39,3.39,0,0,1,1.25,3.83c-.21.64-.43,1.28-.63,1.92a3.47,3.47,0,0,0,0,2.16,19,19,0,0,1,.73,2.41,3.09,3.09,0,0,1-1.2,3.16c-.58.46-1.18.87-1.78,1.3A3.4,3.4,0,0,0,49,39.38c-.21.64-.41,1.28-.62,1.92a3.37,3.37,0,0,1-3.14,2.36c-.39,0-.78,0-1.17,0ZM37.27,18.26A11.41,11.41,0,1,0,48.89,29.44,11.44,11.44,0,0,0,37.27,18.26Z"
		/>
		<path
			:fill="fillComputed"
			d="M47.67,44.26c.38.88.75,1.74,1.11,2.6l3.53,8.34c.66,1.57,1.33,3.15,2,4.72a.55.55,0,0,1-.75.78L46.47,58a.44.44,0,0,0-.48.06l-4,2.63c-.56.37-.92.2-1-.47q-.47-4.22-.95-8.45c-.17-1.5-.34-3-.52-4.51a.4.4,0,0,1,.22-.44c.47-.3.92-.63,1.36-1a3.73,3.73,0,0,1,2.59-.91c.26,0,.53,0,.79,0A5.19,5.19,0,0,0,47.67,44.26Z"
		/>
		<path
			:fill="fillComputed"
			d="M27.26,44.26a7,7,0,0,0,4,.66,3.3,3.3,0,0,1,2.17.67c.61.43,1.23.87,1.83,1.31a.29.29,0,0,1,.09.24l-.48,4.2c-.25,2.15-.49,4.31-.74,6.46-.09.85-.18,1.69-.28,2.54a.53.53,0,0,1-.89.4c-1.34-.89-2.69-1.78-4-2.68a.51.51,0,0,0-.54-.06c-2.31.9-4.63,1.78-6.94,2.67l-.11,0a.59.59,0,0,1-.71-.07.62.62,0,0,1-.05-.74c.31-.7.61-1.4.91-2.1Q24,52.07,26.39,46.34C26.68,45.65,27,45,27.26,44.26Z"
		/>
		<path
			:fill="fillComputed"
			d="M31.78,38.19a.66.66,0,0,1-.62-.82c.31-1.33.63-2.66.94-4,.16-.65.31-1.3.47-1.94,0-.18,0-.26-.16-.36l-4.34-3.72-.23-.19a.63.63,0,0,1-.2-.69.61.61,0,0,1,.57-.44L31,25.82l3.21-.26a.43.43,0,0,0,.42-.32c.74-1.79,1.49-3.57,2.23-5.35.13-.32.31-.56.67-.55s.52.25.65.55c.73,1.77,1.47,3.53,2.19,5.31a.54.54,0,0,0,.55.38c1.92.15,3.84.31,5.76.45a.68.68,0,0,1,.67.43.71.71,0,0,1-.29.8l-4.46,3.81a.4.4,0,0,0-.15.47q.69,2.85,1.36,5.7a.66.66,0,0,1-.21.79.69.69,0,0,1-.84,0c-1.65-1-3.31-2-4.95-3a.48.48,0,0,0-.6,0c-1.63,1-3.28,2-4.92,3A4,4,0,0,1,31.78,38.19Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'AwardsAchievements',
	mixins: [iconMixin],
}
</script>
