<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" :width="size" :height="size">
		<path
			:fill="fillComputed"
			d="M47.28,53.43l-.4,0a29.11,29.11,0,0,1-12.61-4.48,28.68,28.68,0,0,1-8.74-8.77,29.13,29.13,0,0,1-4.47-12.64,4.26,4.26,0,0,1,1-3.14,4.22,4.22,0,0,1,2.89-1.52l.38,0h4.19a4.28,4.28,0,0,1,4.21,3.68,16.23,16.23,0,0,0,.89,3.57,4.3,4.3,0,0,1-1,4.5l-.93.93a20.55,20.55,0,0,0,6.17,6.18l.92-.92a4.3,4.3,0,0,1,4.51-1,16.06,16.06,0,0,0,3.55.89A4.26,4.26,0,0,1,51.53,45v4.14a4.27,4.27,0,0,1-4.25,4.28ZM29.48,25.84H25.2a1.27,1.27,0,0,0-1.15,1.37,25.86,25.86,0,0,0,4,11.32A25.46,25.46,0,0,0,35.9,46.4a26,26,0,0,0,11.28,4h.1a1.27,1.27,0,0,0,1.25-1.27V45a1.26,1.26,0,0,0-1.09-1.29,19.28,19.28,0,0,1-4.2-1.05,1.26,1.26,0,0,0-1.33.29l-1.75,1.75a1.49,1.49,0,0,1-1.8.24A23.58,23.58,0,0,1,29.5,36a1.51,1.51,0,0,1,.24-1.8l1.76-1.75a1.3,1.3,0,0,0,.28-1.34,19.17,19.17,0,0,1-1.05-4.22A1.27,1.27,0,0,0,29.48,25.84Zm16.4,9.62a1.51,1.51,0,0,1-1.47-1.21A5.37,5.37,0,0,0,40.14,30,1.5,1.5,0,1,1,40.71,27a8.37,8.37,0,0,1,6.65,6.64,1.49,1.49,0,0,1-1.19,1.76A1.43,1.43,0,0,1,45.88,35.46Zm5.53,0a1.51,1.51,0,0,1-1.49-1.33,10.88,10.88,0,0,0-9.66-9.67,1.5,1.5,0,0,1,.33-3A13.86,13.86,0,0,1,52.9,33.79a1.49,1.49,0,0,1-1.32,1.65Z"
		/>
	</svg>
</template>
<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Phone',
	mixins: [iconMixin],
}
</script>
