<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M50.72,29.31c-5.7.42-6.79.64-10.82,3.83a28.38,28.38,0,0,0-3.52-.42H36a28.61,28.61,0,0,0-3.52.42c-4-3.19-5.12-3.38-10.81-3.81C16.39,28.94,7,31.11,7,31.11v3.22c1.13.05,2.7,2.24,3.33,4.37s2.51,6,5.61,7.22,8,.9,11.17-.3,5.31-5.72,6.16-8.31A2.8,2.8,0,0,1,36,35.14h.41a2.8,2.8,0,0,1,2.74,2.17c.85,2.59,2.93,7.12,6.16,8.32s8.07,1.53,11.17.29,5-5.1,5.61-7.22,2.12-4.32,3.33-4.37V31.11S56,28.92,50.72,29.31ZM24.61,45.11c-3.06.6-8.07,1.11-11.09-2.8a9.32,9.32,0,0,1-1.91-4.66,6,6,0,0,1,5.09-6.54,28.88,28.88,0,0,1,10.2.37c1.7.42,4.55,1.4,4.38,5.65A8.72,8.72,0,0,1,24.61,45.11Zm36.13-7.46a9.32,9.32,0,0,1-1.91,4.66c-3,3.91-8,3.4-11.09,2.8a8.72,8.72,0,0,1-6.67-8c-.17-4.25,2.68-5.23,4.38-5.65a28.89,28.89,0,0,1,10.2-.37A6,6,0,0,1,60.74,37.65Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'OpticalCentre',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-2 {
	fill: #fff;
	stroke-miterlimit: 10;
}
</style>
