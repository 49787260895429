<template>
	<div class="h-full">
		<div v-if="!editing" class="mt-8">
			<div class="pb-5 border-b border-gray-200">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Upload Image
				</h3>
			</div>

			<FieldsFile
				v-model="upload_file"
				:field="{
					type: 'input',
					inputType: 'file',
					label: 'File',
					model: 'file',
					required: true,
					colSpan: 'col-span-6',
				}"
				:percent-complete="progress"
				:show-progress="showProgress"
				:media-upload-accept="mediaUploadAccept"
			/>

			<FieldsSelect v-model="upload_category" :field="categories" />

			<FieldsInput
				v-model="upload_alt"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Alt Text',
					model: 'alt',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<div class="mt-4 flex justify-end">
				<button
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
					:disabled="!canSubmit"
					@click="uploadImage"
				>
					<UploadIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Upload
				</button>
			</div>
		</div>

		<div v-if="editing" class="mt-8">
			<div class="pb-5 border-b border-gray-200">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Editing Image:
					<span class="text-blue-600">{{ media.original_filename }}</span>
				</h3>
			</div>

			<FieldsSelect v-model="edit.image_category_id" :field="categories" />

			<FieldsInput
				v-model="edit.alt_text"
				:field="{
					type: 'input',
					inputType: 'text',
					label: 'Alt Text',
					model: 'alt',
					required: true,
					wordcount: true,
					colSpan: 'col-span-6',
				}"
			/>

			<div class="mt-4 flex justify-end">
				<button
					type="button"
					class="relative mr-5 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none"
					@click="cancel"
				>
					<XCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Cancel
				</button>

				<button
					type="button"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none disabled:opacity-50"
					:disabled="!canUpdate"
					@click="updateImage"
				>
					<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
					Update
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { UploadIcon, SaveIcon, XCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		UploadIcon,
		SaveIcon,
		XCircleIcon,
	},
	props: {
		mediaTypes: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaCategories: {
			type: Array,
			default: function() {
				return []
			},
		},
		mediaUploadCategory: {
			type: String,
			default: '',
		},
		mediaUploadAccept: {
			type: Array,
			default: function() {
				return ['image/png', 'image/jpeg']
			},
		},
		mediaUploadExtensions: {
			type: Array,
			default: function() {
				return ['jpg', 'jpeg', 'png']
			},
		},
		editing: {
			type: Boolean,
			default: false,
		},
		media: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['mediaCreated', 'mediaUpdated', 'editCancel'],
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			categories: {
				type: 'select',
				label: 'Image Category',
				model: 'upload_category',
				options: [],
				required: true,
				multiSelect: false,
				colSpan: 'col-span-6',
			},
			files: [],
			upload_file: {},
			upload_category: null,
			upload_alt: null,

			edit: {},

			label: 'Choose File',
			file: null,
			progress: 0,
			showProgress: false,
			result: null,
			errors: [],
		}
	},
	computed: {
		fileSelected: function() {
			return typeof this.file.id !== 'undefined'
		},

		canSubmit: function() {
			return this.upload_file && this.upload_category && this.upload_alt
		},

		canUpdate: function() {
			return this.edit.image_category_id && this.edit.alt_text
		},
	},
	watch: {
		editing: function(val) {
			console.log('editing', val)
			if (val) {
				this.edit = {
					image_category_id: this.media.category.id,
					alt_text: this.media.alt_text,
				}

				console.log('edit', this.edit)
			}
		},
	},
	mounted() {
		this.fetchCategories()
	},
	methods: {
		fetchCategories(reload = false) {
			// this.loaderShow()

			this.api.images.categories
				.all()
				.then(data => {
					let categories = []

					data.forEach(element => {
						categories.push({
							value: element.id,
							name: element.name,
						})
					})

					this.categories = {
						type: 'select',
						label: 'Image Category',
						model: 'upload_category',
						options: categories,
						required: true,
						multiSelect: false,
						colSpan: 'col-span-6',
					}
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching image categories:\n' + error.response.data.message)
				})
				.then(() => {
					// this.loaderHide()
				})
		},

		mediaCreated(file) {
			this.$emit('mediaCreated', file)
		},

		uploadImage() {
			if (this.formValid()) {
				this.showProgress = true
				this.loaderShow()

				this.chunk(
					this.upload_file,
					// onProgress
					percent => {
						this.progress = percent
					},
					// onError
					err => {
						console.log(err)
						this.showProgress = false
						this.loaderHide()
					},
					// onSuccess
					res => {
						this.progress = 0
						console.log('data', res)

						this.showProgress = false

						this.api.images
							.store({
								original_filename: res.filename,
								image_category_id: this.upload_category,
								alt_text: this.upload_alt,
								mime_type: res.mime_type,
							})
							.then(data => {
								this.upload_file = null
								// this.upload_category = null
								this.upload_alt = null

								this.$emit('mediaCreated')
								this.toast.success('Successfully uploaded an image')
							})
							.catch(error => {
								this.toast.error('Error uploading image:\n' + error.response.data.message)
							})
							.then(() => {
								this.loaderHide()
							})
					}
				)
			} else {
				this.toast.error(this.errors.join('\n'))
			}
		},

		formValid() {
			this.errors = []

			if (!this.upload_file) {
				this.errors.push('No file selected.')
			}

			if (!this.upload_category) {
				this.errors.push('No category selected.')
			}

			if (!this.upload_alt) {
				this.errors.push('No title specified.')
			} else if (this.upload_alt.length < 5) {
				this.errors.push('Title must be more than 5 characters.')
			}

			return this.errors.length === 0
		},

		cancel() {
			this.$emit('editCancel')
		},

		updateImage() {
			this.loaderShow()

			this.api.images
				.slug(this.media.id)
				.update(this.edit)
				.then(data => {
					this.edit = {}

					this.$emit('mediaUpdated')

					this.toast.success('Successfully updated image')
				})
				.catch(error => {
					this.toast.error('Error updating image:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
