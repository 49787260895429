<template>
	<div class="sm:pt-3" :class="[hasColSpan ? field.colSpan : '']">
		<label :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0 relative">
			<div
				:id="dropAreaId"
				class="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
			>
				<div class="space-y-1 text-center">
					<svg
						v-if="!file"
						class="mx-auto h-12 w-12 text-gray-400"
						stroke="currentColor"
						fill="none"
						viewBox="0 0 48 48"
						aria-hidden="true"
					>
						<path
							d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>

					<div v-if="file" class="mb-5 text-left flex flex-col gap-y-1">
						<dl class="flex gap-x-3">
							<dt class="w-14 font-semibold">Name:</dt>
							<dd class="flex-1">{{ file.name }}</dd>
						</dl>
						<dl class="flex gap-x-3">
							<dt class="w-14 font-semibold">Size:</dt>
							<dd class="flex-1">{{ humanFileSize(file.size, true, 2) }}</dd>
						</dl>
						<dl class="flex gap-x-3">
							<dt class="w-14 font-semibold">Type:</dt>
							<dd class="flex-1">{{ file.type }}</dd>
						</dl>
					</div>

					<div v-show="percentComplete == 0" class="flex text-sm text-gray-600">
						<label
							:for="inputId"
							class="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none"
						>
							<span>Upload a file</span>
							<!-- <input id="file-upload" name="file-upload" type="file" class="sr-only" /> -->
							<input
								:id="inputId"
								ref="file"
								:type="field.inputType"
								class="sr-only"
								:name="inputId"
								@change="handleFileUpload"
							/>
						</label>
						<p class="pl-1">or drag and drop</p>
					</div>

					<div v-show="showProgress && hasFile" class="h-3 my-3 relative w-full rounded-full overflow-hidden">
						<div class="w-full h-full bg-gray-200 absolute"></div>
						<div
							id="bar"
							class="h-full bg-green-500 relative w-0"
							:style="'width: ' + percentComplete + '%'"
						></div>
					</div>
				</div>
			</div>

			<!-- <div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>
			<label for="file-upload" class="custom-file-upload flex-1 block w-full focus:outline-none min-w-0 sm:text-sm border-gray-300 rounded-md">
				Custom Upload
				<input
					ref="file"
					v-model="file"
					:type="field.inputType"
					class=" sr-only"
					:class="[hasErrors ? 'border-red-300 text-red-900' : '']"
					name="file-upload"
					:autocomplete="field.model"
					@change="handleFileUpload"
				/>
			</label> -->
		</div>

		<div v-if="hasErrors" class="mt-2 text-red-500 font-semibold">
			{{ errors.$errors[0].$message }}
		</div>
	</div>
</template>

<script>
import { useToast } from 'vue-toastification'
// import { ExclamationCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		// ExclamationCircleIcon,
	},
	props: {
		modelValue: {
			type: Object,
			default: function() {
				return {}
			},
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		mediaUploadAccept: {
			type: Array,
			default: function() {
				return []
			},
		},
		percentComplete: {
			type: Number,
			default: 0,
		},
		showProgress: {
			type: Boolean,
			default: false,
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue'],
	setup() {
		const toast = useToast()

		return {
			toast,
		}
	},
	data() {
		return {
			file: null,
			dropAreaId: null,
			inputId: null,
			dropArea: null,
		}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasPrefix: function() {
			return typeof this.field.prefix !== 'undefined'
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		hasFile: function() {
			return this.file
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// this.file = this.$refs.file.files[0];
				// console.log(this.$refs.file.files[0]);
				// this.$emit("update:modelValue", val);
			},
		},
	},
	watch: {
		inputVal: function(val) {
			// console.log(val)
			// console.log('inputVal', val)
			if (val === null) {
				this.file = null
			}
		},
	},
	mounted() {
		// console.log(this.mediaUploadAccept)
		this.dropAreaId = 'drop-area' + Math.floor(Math.random() * 100000)
		this.inputId = 'input' + Math.floor(Math.random() * 100000)

		setTimeout(() => {
			this.dropArea = document.getElementById(this.dropAreaId)
			// Null check
			if (this.dropArea == null) return
			;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
				this.dropArea.addEventListener(eventName, this.preventDefaults, false)
			})
			;['dragenter', 'dragover'].forEach(eventName => {
				this.dropArea.addEventListener(eventName, this.highlight, false)
			})
			;['dragleave', 'drop'].forEach(eventName => {
				this.dropArea.addEventListener(eventName, this.unhighlight, false)
			})

			this.dropArea.addEventListener('drop', this.handleDrop, false)
		}, 100)
	},
	beforeUnmount() {
		// this.dropArea = document.getElementById('drop-area')
		if (this.dropArea) {
			;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
				this.dropArea.removeEventListener(eventName, this.preventDefaults, false)
			})
			;['dragenter', 'dragover'].forEach(eventName => {
				this.dropArea.removeEventListener(eventName, this.highlight, false)
			})
			;['dragleave', 'drop'].forEach(eventName => {
				this.dropArea.removeEventListener(eventName, this.unhighlight, false)
			})

			this.dropArea.removeEventListener('drop', this.handleDrop, false)
		}
	},
	methods: {
		handleFileUpload() {
			let file = this.$refs.file.files[0]
			console.log(this.mediaUploadAccept, file)

			if (file.type === '') {
				this.toast.error('Folders are not allowed')
				return false
			} else if (!this.mediaUploadAccept.includes(file.type)) {
				this.toast.error('File type (' + file.type + ') is not allowed')
				return false
			}

			this.file = this.$refs.file.files[0]

			this.$emit('update:modelValue', this.file)
		},

		preventDefaults(e) {
			e.preventDefault()
			e.stopPropagation()
		},

		highlight(e) {
			this.dropArea.classList.add('border-green-500', 'bg-green-50')
		},

		unhighlight(e) {
			this.dropArea.classList.remove('border-green-500', 'bg-green-50')
		},

		handleDrop(e) {
			let dt = e.dataTransfer
			let file = dt.files[0]
			if (file.type === '') {
				this.toast.error('Folders are not allowed')
				return false
			} else if (!this.mediaUploadAccept.includes(file.type)) {
				this.toast.error('File type (' + file.type + ') is not allowed')
				return false
			}

			// console.log(file)
			this.file = file
			this.$emit('update:modelValue', file)

			// handleFiles(files)
		},

		humanFileSize(bytes, si = false, dp = 1) {
			const thresh = si ? 1000 : 1024

			if (Math.abs(bytes) < thresh) {
				return bytes + ' B'
			}

			const units = si
				? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
				: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
			let u = -1
			const r = 10 ** dp

			do {
				bytes /= thresh
				++u
			} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

			return bytes.toFixed(dp) + ' ' + units[u]
		},
	},
}
</script>
