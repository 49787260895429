<template>
	<div :class="[hasColSpan ? field.colSpan : '', field.label.length > 0 ? 'sm:pt-3' : '']">
		<label v-if="field.label.length > 0" :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0 relative" :class="{ 'flex rounded-md': hasPrefix }">
			<div v-if="!meta.valid" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
			</div>
			<span
				v-if="hasPrefix"
				class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm font-medium tracking-wide"
				:class="[disabled ? 'opacity-50' : '']"
			>
				{{ field.prefix }}
			</span>
			<input
				v-model="value"
				type="number"
				class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 sm:text-sm border-gray-300 disabled:opacity-50"
				:class="[
					hasPrefix && hasCopy ? 'rounded-none' : hasPrefix ? 'rounded-none rounded-r-md' : 'rounded-md',
					!meta.valid ? 'border-red-300 text-red-900' : '',
				]"
				:min="field.min"
				:max="field.max"
				:step="field.step"
				:name="field.model"
				:disabled="disabled"
				@blur="$emit('blur')"
			/>

			<button
				v-if="hasCopy"
				class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none disabled:opacity-50"
				:disabled="disabled"
				@click="copy"
			>
				<ClipboardCopyIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				<span>Copy</span>
			</button>
		</div>

		<div v-if="field.wordcount" class="flex justify-end text-sm text-gray-400">
			<div class="">
				<span class="font-semibold mr-1">Word Count:</span>
				{{ wordCount }}
			</div>
			<div class="ml-5">
				<span class="font-semibold mr-1">Character Count:</span>
				{{ characterCount }}
			</div>
		</div>

		<div class="mt-2 text-red-500 font-semibold">{{ errorMessage }}</div>
	</div>
</template>

<script>
import { computed, ref } from 'vue'
import { useToast } from 'vue-toastification'
import useClipboard from 'vue-clipboard3'
import { ExclamationCircleIcon, ClipboardCopyIcon } from '@heroicons/vue/solid'

import { useForm, useField } from 'vee-validate'
import * as yup from 'yup'

const { toClipboard } = useClipboard()

export default {
	components: {
		ExclamationCircleIcon,
		ClipboardCopyIcon,
	},
	props: {
		modelValue: {
			type: [String, Number],
			default: '',
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue', 'invalidField', 'validField', 'blur'],
	setup(props, { emit }) {
		const toast = useToast()

		const required = ref(props.field.required)
		const min = ref(props.field.min || 0)
		const max = ref(props.field.max || 255)

		// Define a validation schema
		let schemaData = {
			inputVal: yup
				.string()
				.min(min.value)
				.max(max.value)
				.nullable(true)
				.label(props.field.label),
		}
		if (required.value) {
			// console.log('required')
			schemaData = {
				inputVal: yup
					.string()
					.required()
					.min(min.value)
					.max(max.value)
					.nullable(true)
					.label(props.field.label),
			}
		}

		const schema = computed(() => {
			return yup.object(schemaData)
		})

		// Create a form context with the validation schema
		useForm({
			validationSchema: schema,
		})

		const { errorMessage, value, meta, validate } = useField('inputVal', undefined, {
			initialValue: props.modelValue,
		})

		// trigger validation
		validate()
		setTimeout(() => {
			if (!meta.valid) {
				emit('invalidField', props.field.model)
			}
		}, 50)

		return {
			toast,
			errorMessage,
			value,
			meta,
		}
	},
	data() {
		return {}
	},
	computed: {
		wordCount: function() {
			if (this.inputVal === null || this.inputVal.length === 0) {
				return 0
			}
			return this.inputVal.split(' ').length
		},
		characterCount: function() {
			if (this.inputVal === null || this.inputVal.length === 0) {
				return 0
			}
			return this.inputVal.length
		},
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasPrefix: function() {
			return typeof this.field.prefix !== 'undefined'
		},
		hasCopy: function() {
			return typeof this.field.enableCopy !== 'undefined' && this.field.enableCopy
		},
		copyWithPrefix: function() {
			return typeof this.field.copyWithPrefix !== 'undefined' && this.field.copyWithPrefix
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log('INPUT:', val)
				this.$emit('update:modelValue', this.processField(val))
			},
		},
	},
	watch: {
		modelValue: function(val) {
			this.value = val
		},
		value: function(val) {
			// this.inputVal = this.value
			this.$emit('update:modelValue', this.value)
			setTimeout(() => {
				// console.log('ERROR:', this.meta.valid)
				if (!this.meta.valid) this.$emit('invalidField', this.field.model)
				else this.$emit('validField', this.field.model)
			}, 50)
		},
	},
	mounted() {
		// if (this.modelValue) this.value = this.modelValue
		// console.log("INPUT:", this.modelValue);
	},
	methods: {
		async copy() {
			try {
				let fullUrl = (this.copyWithPrefix ? this.field.prefix : '') + this.inputVal
				console.log(fullUrl)
				await toClipboard(fullUrl)
				this.toast.success('Copied "' + fullUrl + '" to clipboard')
			} catch (e) {
				console.error(e)
			}
		},

		processField(val) {
			if (typeof this.field.kebabCase !== 'undefined' && this.field.kebabCase) {
				let processed = val
					.toLowerCase()
					.replaceAll(' ', '-')
					.replace(/[^-0-9a-z]/gi, '')

				while (processed.charAt(0) === '-') {
					processed = processed.substring(1)
				}
				while (processed.charAt(processed.length - 1) === '-') {
					processed = processed.slice(0, -1)
				}

				return processed
			}

			return val
		},
	},
}
</script>
