<template>
	<svg
		version="1.1"
		:width="size"
		:height="size"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 72 72"
		style="enable-background: new 0 0 72 72"
		xml:space="preserve"
	>
		<g id="Group_548">
			<g id="Path_128-2">
				<path
					:fill="fillComputed"
					d="M49.5,23.5v5.4c0,0.4-1.2,0.8-2.8,0.8H27.2c-1.5,0-2.8-0.3-2.8-0.8l0,0v-5.4c0-0.4,1.2-0.8,2.8-0.8h19.6
			C48.3,22.7,49.5,23.1,49.5,23.5z"
				/>
			</g>
		</g>
		<ellipse class="st1" cx="37" cy="40.1" rx="5.9" ry="5.8" />
		<g>
			<path
				:fill="fillComputed"
				d="M40.4,35.4c-1-0.7-2.2-1.1-3.4-1.1c-0.3,0-0.6,0-1,0.1c-1.5,0.3-2.9,1.1-3.8,2.4c-0.9,1.3-1.3,2.8-1,4.4
		c0.5,3.2,3.5,5.4,6.7,4.8c3.2-0.5,5.4-3.5,4.8-6.7C42.5,37.6,41.7,36.3,40.4,35.4z"
			/>
			<path
				:fill="fillComputed"
				d="M53.2,25.8H20.8c-2.6,0-4.7,1.4-4.7,3.2v22.1c0,1.8,2.1,3.2,4.7,3.2h32.5c2.6,0,4.7-1.4,4.7-3.2V29
		C57.9,27.3,55.8,25.8,53.2,25.8z M44.6,45.6c-1.5,2-3.6,3.4-6.1,3.8c-0.5,0.1-1,0.1-1.5,0.1c-4.5,0-8.5-3.3-9.3-7.9
		c-0.4-2.5,0.2-5,1.7-7c1.5-2,3.6-3.4,6.1-3.8c2.5-0.4,5,0.2,7,1.7s3.4,3.6,3.8,6.1C46.7,41,46.1,43.5,44.6,45.6z M55.2,30.6
		c0,0.4-0.4,0.7-1,0.7h-3.7c-0.6,0-1-0.3-1-0.7v-1.6c0-0.4,0.4-0.7,1-0.7h3.7c0.6,0,1,0.3,1,0.7V30.6z"
			/>
		</g>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'AdditionalPhotos',
	mixins: [iconMixin],
}
</script>
