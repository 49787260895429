<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M44.86,13.69a4.85,4.85,0,0,0-2.7-2.12A9.88,9.88,0,0,0,39.91,11a13.6,13.6,0,0,0-3,0,10,10,0,0,0-2.15.38,5.27,5.27,0,0,0-3.3,2.33c-.49,1.17-1,2.41-1.45,3.7-.59,1.61-1.14,3.12-1.55,4.66,1.43-2.64,4.35-5.08,9.7-5,5.06.08,8.4,2.73,9.7,5-.4-1.55-1-3.09-1.58-4.72C45.82,16.06,45.34,14.85,44.86,13.69Z"
		/>
		<path
			:fill="fillComputed"
			d="M26.76,59s14.58-20.9,19-28.12c1.19-1.95,3-5.37,2.1-8.79-.53-2.15-3-8.36-3-8.36a2.9,2.9,0,0,1,.16.72,5,5,0,0,1-.34,2.32,30.55,30.55,0,0,1-4.43,7.2c-5,6.46-17.4,23.93-17.4,23.93A46.11,46.11,0,0,0,26.76,59Z"
		/>
		<path
			:fill="fillComputed"
			d="M22.85,47.86l1-1.45c.66-.93,1.58-2.25,2.71-3.82,2.25-3.15,5.25-7.35,8.29-11.53C36.39,29,37.93,26.9,39.39,25c.73-1,1.45-1.88,2.11-2.78a30.59,30.59,0,0,0,1.72-2.6,21.31,21.31,0,0,0,1.22-2.34,8.77,8.77,0,0,0,.39-1,4.81,4.81,0,0,0,.16-.92,5,5,0,0,0,0-.75A2,2,0,0,0,45,14a1.67,1.67,0,0,0-.1-.33l-.05-.11.05.11a2.55,2.55,0,0,1,.19.88c0,.22,0,.47,0,.75a4.78,4.78,0,0,1-.16.93,8.62,8.62,0,0,1-.37,1,18.81,18.81,0,0,1-1.2,2.36,30,30,0,0,1-1.72,2.62c-.65.9-1.37,1.82-2.09,2.79-1.45,1.93-3,4-4.49,6.11-3.06,4.16-6.08,8.34-8.35,11.48Z"
		/>
		<path
			:fill="fillComputed"
			d="M49.56,59S35,38.06,30.56,30.84c-1.19-1.95-2.95-5.37-2.1-8.79.53-2.15,3-8.36,3-8.36a2.9,2.9,0,0,0-.16.72,5,5,0,0,0,.34,2.32,30.55,30.55,0,0,0,4.43,7.2c5,6.46,17.4,23.93,17.4,23.93a35.69,35.69,0,0,1-1.38,5.39A35,35,0,0,1,49.56,59Z"
		/>
		<path
			:fill="fillComputed"
			d="M49.56,59,45.7,53.42c-2.31-3.33-5.38-7.77-8.41-12.25l-2.24-3.35c-.74-1.1-1.46-2.2-2.15-3.26s-1.35-2.09-2-3.07a22.51,22.51,0,0,1-1.59-2.87A13.37,13.37,0,0,1,28.47,26a9.81,9.81,0,0,1-.24-2.12c0-.29,0-.56.05-.79a5.58,5.58,0,0,1,.08-.57l.1-.48-.09.48a3.69,3.69,0,0,0-.08.58c0,.22,0,.49-.05.78A10.4,10.4,0,0,0,28.49,26a13.53,13.53,0,0,0,.9,2.61A23,23,0,0,0,31,31.46c.61,1,1.28,2,2,3.07s1.42,2.15,2.16,3.26l2.25,3.34c3,4.48,6.07,8.93,8.37,12.27Z"
		/>
		<path
			:fill="fillComputed"
			d="M53.47,47.86l-5.16-7.13L43,33.43c-1.94-2.66-4-5.5-6.14-8.31-1.05-1.41-2.15-2.78-3.1-4.24a24.94,24.94,0,0,1-1.32-2.22c-.1-.18-.19-.37-.28-.56a6.11,6.11,0,0,1-.27-.58c-.09-.19-.17-.38-.25-.57a4.69,4.69,0,0,1-.2-.6,5.1,5.1,0,0,1-.2-1.22,3.81,3.81,0,0,1,.13-1.21,2.51,2.51,0,0,1,.6-1,6.85,6.85,0,0,1,.9-.73,7.12,7.12,0,0,1,2-.9,10.63,10.63,0,0,1,2-.34,15.3,15.3,0,0,1,1.9,0,12.58,12.58,0,0,1,1.7.2,8.26,8.26,0,0,1,2.61.93,4.69,4.69,0,0,1,1.37,1.13,2.57,2.57,0,0,1,.26.39l.08.15-.08-.15a2.86,2.86,0,0,0-.27-.38A4.69,4.69,0,0,0,43.14,12a8.13,8.13,0,0,0-2.61-.89A13.4,13.4,0,0,0,37,11a9.84,9.84,0,0,0-2,.35,6.5,6.5,0,0,0-2,.89,5.53,5.53,0,0,0-.88.72,2.33,2.33,0,0,0-.58,1,3.89,3.89,0,0,0-.12,1.18,5.37,5.37,0,0,0,.19,1.19,5.71,5.71,0,0,0,.21.58c.08.19.16.39.25.58s.17.38.26.56.19.38.29.57c.4.75.84,1.48,1.32,2.2,1,1.44,2,2.81,3.11,4.22,2.11,2.82,4.18,5.67,6.11,8.34l5.27,7.31c1.54,2.15,2.8,4,3.69,5.2Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Cancer',
	mixins: [iconMixin],
}
</script>
