export default {
	props: {
		color: {
			type: String,
			default: null,
		},
		size: {
			type: String,
			default: '72',
		},
	},
	computed: {
		fillComputed() {
			// if (this.color == null) return this.$store.state.theme.blue
			return this.color || null
		},
	},
}
