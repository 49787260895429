import Image from '@tiptap/extension-image'

const CustomImage = Image.extend({
	addAttributes() {
		return {
			...this.parent?.(),
			'data-id': {
				default: null,
			},
			'data-radius': {
				default: '',
			},
			'data-position': {
				default: 'float-none',
			},
			width: {
				default: '100%',
			},
			height: {
				default: '100%',
			},
			class: {
				default: 'float-none',
			},
		}
	},
})

export default CustomImage
