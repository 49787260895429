<template>
	<div
		class="relative"
		:class="{ 'h-28 overflow-hidden bg-yellow-50': sectionTmp.minimized }"
		@mouseover="hover = true"
		@mouseleave="hover = false"
	>
		<div class="px-4 py-3">
			<div class="grid grid-cols-1 sm:grid-cols-8 gap-x-5 gap-y-2">
				<FieldsIconPicker
					v-if="!hideSectionIcon"
					v-model="sectionTmp.icon"
					:field="{
						type: 'input',
						inputType: 'text',
						label: 'Section Icon',
						model: 'icon',
						required: false,
						colSpan: 'col-span-2',
						menu_item_enabled: sectionTmp.menu_item_enabled,
					}"
				/>

				<FieldsInput
					v-if="!hideSectionTitle"
					v-model="sectionTmp.title"
					:field="{
						type: 'input',
						inputType: 'text',
						label: 'Section Title',
						model: 'title',
						required: false,
						colSpan: 'col-span-4',
					}"
				/>

				<div class="col-span-2">
					<SwitchGroup as="div" class="sm:pt-3 flex items-center justify-between">
						<SwitchLabel as="span" class="flex-grow flex flex-col" passive>
							<span class="block text-sm font-bold text-gray-700 pb-1">Anchor Link Enabled</span>
							<span class="text-sm text-gray-500">
								Display a button under the page header that links to this section.
							</span>
						</SwitchLabel>
						<Switch
							v-model="sectionTmp.menu_item_enabled"
							:class="[
								sectionTmp.menu_item_enabled ? 'bg-green-600' : 'bg-gray-200',
								'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
							]"
							@click="toggleSectionMenuItemEnabled(sectionTmp.ref_id)"
						>
							<span class="sr-only">Use setting</span>
							<span
								aria-hidden="true"
								:class="[
									sectionTmp.menu_item_enabled ? 'translate-x-5' : 'translate-x-0',
									'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
								]"
							/>
						</Switch>
					</SwitchGroup>
				</div>
			</div>
		</div>

		<div class="px-3">
			<div class="hidden sm:block">
				<div class="border-b border-blue-200">
					<nav class="-mb-px flex space-x-8" aria-label="Tabs">
						<a
							:class="[
								tab == 'columns'
									? 'border-blue-500 text-blue-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm hover:cursor-pointer',
							]"
							:aria-current="tab == 'columns' ? 'page' : undefined"
							@click="tab = 'columns'"
						>
							Columns
						</a>
						<a
							:class="[
								tab == 'component'
									? 'border-blue-500 text-blue-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm hover:cursor-pointer',
							]"
							:aria-current="tab == 'component' ? 'page' : undefined"
							@click="tab = 'component'"
						>
							Component
							<span v-if="sectionTmp.component" class="text-green-600">(configured)</span>
						</a>
					</nav>
				</div>
			</div>
		</div>

		<div v-show="tab == 'columns'" class="flex gap-5 p-3">
			<div
				v-for="(column, index) in sectionTmp.columns"
				:key="column.ref_id"
				class="relative flex-1 bg-purple-50 border-2 border-dashed border-purple-300 border-opacity-50 rounded"
			>
				<!-- <div
					class="absolute z-10 -top-4 left-1/2 transform -translate-x-1/2 text-lg font-medium px-3 bg-white rounded-md border-2 border-dashed border-purple-300 border-opacity-50"
				>
					Column
				</div> -->
				<ContentBuilderColumn
					v-model="sectionTmp.columns[index]"
					:column-index="index"
					:num-columns="sectionTmp.columns.length"
					:main-menu-enabled="mainMenuEnabled"
					:hide-column-icon="hideColumnIcon"
					:hide-column-title="hideColumnTitle"
					@removeColumn="removeColumn"
					@reorderColumnLower="reorderColumnLower"
					@reorderColumnHigher="reorderColumnHigher"
					@updateColumn="updateColumn"
				></ContentBuilderColumn>
			</div>
		</div>

		<div v-show="tab == 'component'" class="px-4 pb-3">
			<div class="pb-5">
				<FieldsComponent
					v-model="sectionTmp.component"
					:field="{
						type: 'input',
						inputType: 'text',
						label: 'Component',
						model: 'component',
						required: false,
						colSpan: 'col-span-6',
					}"
				/>
			</div>
		</div>

		<div
			v-show="hover"
			class="absolute top-0 left-0 bg-gray-50 transition duration-500 ease-in-out opacity-0"
			:class="{ 'opacity-100': hover }"
		>
			<span class="relative z-0 inline-flex shadow-sm rounded-md">
				<button
					v-show="!sectionTmp.minimized"
					type="button"
					class="relative inline-flex items-center p-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50"
					@click="sectionTmp.minimized = true"
				>
					<ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
					Minimize
				</button>

				<button
					v-show="sectionTmp.minimized"
					type="button"
					class="relative inline-flex items-center p-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50"
					@click="sectionTmp.minimized = false"
				>
					<ChevronUpIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
					Maximize
				</button>
			</span>
		</div>

		<!-- <div class="absolute bg-gray-50 right-0 w-5 h-5" v-show="hover"> -->
		<div
			v-show="hover"
			class="absolute top-0 right-0 bg-gray-50 transition duration-500 ease-in-out opacity-0"
			:class="{ 'opacity-100': hover }"
		>
			<span class="relative z-0 inline-flex shadow-sm rounded-md">
				<button
					:disabled="numSections <= 1 || sectionIndex === 0"
					type="button"
					class="relative inline-flex items-center p-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50"
					@click="reorderSection('lower', sectionTmp.ref_id)"
				>
					<ArrowSmUpIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				</button>

				<button
					:disabled="numSections <= 1 || sectionIndex + 1 === numSections"
					type="button"
					class="relative inline-flex items-center p-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50"
					@click="reorderSection('higher', sectionTmp.ref_id)"
				>
					<ArrowSmDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				</button>

				<button
					:disabled="sectionTmp.columns.length >= 3"
					type="button"
					class="relative inline-flex items-center p-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50"
					@click="addColumn"
				>
					<PlusCircleIcon class="h-5 w-5 mr-2 text-gray-400" aria-hidden="true" />
					Add Column
				</button>

				<button
					type="button"
					class="relative inline-flex items-center p-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50"
					@click="removeSection(sectionTmp.ref_id)"
				>
					<MinusCircleIcon class="h-5 w-5 mr-2 text-gray-400" aria-hidden="true" />
					Remove Section
				</button>
			</span>
		</div>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="confirmationOpen = false"
			@confirm="confirm"
		/>
	</div>
</template>

<script>
import { useToast } from 'vue-toastification'
import {
	PlusCircleIcon,
	MinusCircleIcon,
	ArrowSmUpIcon,
	ArrowSmDownIcon,
	ChevronDownIcon,
	ChevronUpIcon,
} from '@heroicons/vue/solid'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default {
	components: {
		Switch,
		SwitchGroup,
		SwitchLabel,
		PlusCircleIcon,
		MinusCircleIcon,
		ArrowSmUpIcon,
		ArrowSmDownIcon,
		ChevronDownIcon,
		ChevronUpIcon,
	},
	props: {
		modelValue: {
			type: Object,
			default: function() {
				return {}
			},
		},
		pageMenu: {
			type: Array,
			default: function() {
				return []
			},
		},
		sectionIndex: {
			type: Number,
			default: 0,
		},
		mainMenuEnabled: Boolean,
		numSections: {
			type: Number,
			default: 0,
		},
		hideSectionIcon: {
			type: Boolean,
			default: false,
		},
		hideSectionTitle: {
			type: Boolean,
			default: false,
		},
		hideColumnIcon: {
			type: Boolean,
			default: false,
		},
		hideColumnTitle: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'update:modelValue',
		'updateSection',
		'removeSection',
		'reorderSectionLower',
		'reorderSectionHigher',
		'addSectionMenuItemEnabled',
		'removeSectionMenuItemEnabled',
		'updateSectionTitle',
		'updateSectionIcon',
	],
	setup() {
		const toast = useToast()

		return {
			toast,
		}
	},
	data() {
		return {
			hover: false,
			confirmationOpen: false,
			confirmationConfig: {},
			tab: 'columns',
		}
	},
	computed: {
		sectionTmp: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log('SECTION INPUT:', val)
				this.$emit('update:modelValue', val)
				// this.$emit('updateSection', val)
			},
		},
	},
	watch: {
		'modelValue.title': {
			deep: true,
			handler(val) {
				let anchor = this.sectionTmp.title
					.toLowerCase()
					.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\\\/]/gi, '')
					.replace(/ +/g, '-')

				this.sectionTmp.anchor = anchor

				this.$emit('updateSectionTitle', {
					ref_id: this.sectionTmp.ref_id,
					title: val,
					anchor: anchor,
				})
			},
		},
		'modelValue.icon': {
			deep: true,
			handler(val) {
				this.$emit('updateSectionIcon', {
					ref_id: this.sectionTmp.ref_id,
					icon: val,
				})
			},
		},
		'sectionTmp.menu_item_enabled': {
			deep: true,
			handler(val) {
				if (this.sectionTmp.icon && this.sectionTmp.title) {
					if (this.sectionTmp.menu_item_enabled) {
						this.$emit('addSectionMenuItemEnabled', this.sectionTmp.ref_id)
					} else if (!this.sectionTmp.menu_item_enabled) {
						this.$emit('removeSectionMenuItemEnabled', this.sectionTmp.ref_id)
					}
				} else {
					this.toast.error(
						'You must specify both an icon and a section title when adding a section anchor link'
					)
					this.sectionTmp.menu_item_enabled = false
				}
			},
		},
		modelValue: {
			deep: true,
			handler(val) {
				// console.log('val', val)
				this.sectionTmp = val
			},
		},
	},
	mounted() {
		// console.log(this.sectionIndex)

		// Add default column to section if none exist
		if (!this.modelValue || !this.modelValue.columns || this.modelValue.columns.length === 0) {
			this.addColumn()
		}

		if (this.modelValue.title) {
			// compute the anchor id based on the section title
			let attr_id = this.modelValue.title
				.toLowerCase()
				.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\\\/]/gi, '')
				.replace(/ +/g, '-')

			// check if the anchor id exists in menu and
			// enable the section anchor link if it does
			// console.log('pageMenu', this.pageMenu)
			// this.pageMenu.forEach(pageMenuItem => {
			// 	// console.log(pageMenuItem, pageMenuItem.anchor, attr_id)
			// 	if (pageMenuItem.anchor === attr_id) {
			// 		this.sectionTmp.menu_item_enabled = true
			// 	}
			// })
		}
	},
	methods: {
		addColumn() {
			let newColumn = {
				ref_id: this.uuidv4(),
				text: '',

				video_position: '',
				video: null,
				// {
				// 	id: '',
				// 	source: '',
				// 	name: '',
				// 	category: '',
				// 	title: '',
				// 	description: '',
				// 	width: 0,
				// 	height: 0,
				// 	size: 0,
				// 	thumbnail: {
				// 		id: '',
				// 		source: '',
				// 		name: '',
				// 		category: '',
				// 		alt: '',
				// 		width: 0,
				// 		height: 0,
				// 		size: 0,
				// 	},
				// },
			}

			this.sectionTmp.columns.push(newColumn)
		},

		confirm(callback) {
			this[callback]()
		},

		removeColumn(ref_id) {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'confirmRemoveColumn',
				title: 'Remove Column',
				content: 'Are you sure you want to remove this column?',
			}
			this.$refs.confirmation.ref_id = ref_id
		},

		confirmRemoveColumn() {
			let ref_id = this.$refs.confirmation.ref_id
			let index = this.sectionTmp.columns.findIndex(x => x.ref_id === ref_id)
			this.sectionTmp.columns.splice(index, 1)

			delete this.$refs.confirmation.ref_id
			this.confirmationConfig = {}
			this.confirmationOpen = false
		},

		removeSection(ref_id) {
			this.$emit('removeSection', ref_id)
		},

		uuidv4() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = (Math.random() * 16) | 0,
					v = c == 'x' ? r : (r & 0x3) | 0x8
				return v.toString(16)
			})
		},

		reorderSection(dir, ref_id) {
			if (dir === 'lower') {
				this.$emit('reorderSectionLower', ref_id)
			} else {
				this.$emit('reorderSectionHigher', ref_id)
			}
		},

		array_move(arr, old_index, new_index) {
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1
				while (k--) {
					arr.push(undefined)
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
			return arr // for testing
		},

		reorderColumnLower(ref_id) {
			let index = this.sectionTmp.columns.findIndex(x => x.ref_id === ref_id)
			this.array_move(this.sectionTmp.columns, index, index - 1)
		},

		reorderColumnHigher(ref_id) {
			let index = this.sectionTmp.columns.findIndex(x => x.ref_id === ref_id)
			this.array_move(this.sectionTmp.columns, index, index + 1)
		},

		toggleSectionMenuItemEnabled(ref_id) {
			// Disabled because @click seems to no longer work on switches...
			// Instead a watcher was setup to do the same logic
			return false

			// if (this.sectionTmp.icon && this.sectionTmp.title) {
			// 	if (this.sectionTmp.menu_item_enabled) {
			// 		this.$emit('addSectionMenuItemEnabled', ref_id)
			// 	} else {
			// 		if (!this.sectionTmp.menu_item_enabled) {
			// 			this.$emit('removeSectionMenuItemEnabled', ref_id)
			// 		}
			// 	}
			// } else {
			// 	this.toast.error('You must specify both an icon and a section title when adding a section anchor link')
			// 	this.sectionTmp.menu_item_enabled = false
			// }
		},

		updateColumn(val) {
			console.log('updateColumn', val)
			this.sectionTmp.columns[val.columnIndex] = val.column
		},
	},
}
</script>
