import { Mark, mergeAttributes } from '@tiptap/core'
// import '@tiptap/extension-text-style'

const TextStyle = Mark.create({
	name: 'textStyle',

	priority: 1000,

	addOptions: {
		color: [
			'text-black',
			'text-gray-500',
			'text-sc-blue',
			'text-sc-green',
			'text-sc-gold',
			'text-sc-light-blue',
			'text-sc-orange',
			'text-sc-purple',
		],
		size: ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl'],
		HTMLAttributes: {},
	},

	addAttributes() {
		return {
			color: {
				default: 'text-black',
				parseHTML: element => {
					return {
						color: element.getAttribute('data-color'),
					}
				},
				renderHTML: attributes => {
					if (!attributes.color) {
						return {}
					}

					let color = attributes.color
					if (typeof attributes.color === 'object' && attributes.color !== null) {
						color = attributes.color.color
					}

					let size = attributes.size
					if (typeof attributes.size === 'object' && attributes.size !== null) {
						size = attributes.size.size
					}

					// console.log('data-color', color, size, [color, size].join(' '))

					return {
						'data-color': color,
						// class: [color, size].join(' '),
						class: color,
					}
				},
			},
			size: {
				default: 'text-base',
				parseHTML: element => {
					return {
						size: element.getAttribute('data-size'),
					}
				},
				renderHTML: attributes => {
					if (!attributes.size) {
						return {}
					}

					let color = attributes.color
					if (typeof attributes.color === 'object' && attributes.color !== null) {
						color = attributes.color.color
					}

					let size = attributes.size
					if (typeof attributes.size === 'object' && attributes.size !== null) {
						size = attributes.size.size
					}

					return {
						'data-size': size,
						// class: [color, size].join(' '),
						class: size,
					}
				},
			},
		}
	},

	parseHTML() {
		return [
			{
				tag: 'span',
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
	},

	addCommands() {
		return {
			setTextStyle: attributes => ({ commands }) => {
				return commands.setMark('textStyle', attributes)
			},
			toggleTextStyle: attributes => ({ commands }) => {
				return commands.toggleMark('textStyle', attributes)
			},
			unsetTextStyle: () => ({ commands }) => {
				return commands.unsetMark('textStyle')
			},
		}
	},
})

export default TextStyle
