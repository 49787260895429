<template>
	<div :class="[hasColSpan ? field.colSpan : '', field.label.length > 0 ? 'sm:pt-3' : '']">
		<label v-if="field.label.length > 0" :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0">
			<div class="flex items-center relative">
				<div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
					<ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
				</div>
				<input
					v-model="inputVal"
					type="date"
					:name="field.model"
					class="flex-1 py-2 px-3 block w-full rounded-l-md focus:border-gray-300 focus:border-gray-300 focus:outline-none min-w-0 sm:text-sm border-gray-300 border h-9 disabled:opacity-50"
					:disabled="disabled"
				/>
				<div class="flex items-center">
					<button
						type="button"
						class=" inline-flex items-center h-9 px-4 py-2 border-t border-b border-r border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none disabled:opacity-50"
						:disabled="disabled"
						@click="clear"
					>
						Clear
					</button>
				</div>
			</div>
		</div>

		<div v-if="hasErrors" class="mt-2 text-red-500 font-semibold">
			{{ errors.$errors[0].$message }}
		</div>
	</div>
</template>

<script>
// import LitepieDatepicker from "litepie-datepicker";
import { ExclamationCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		ExclamationCircleIcon,
		// LitepieDatepicker,
	},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			attrs: [
				{
					key: 'today',
					// highlight: true,
					content: 'blue',
					dates: new Date(),
				},
			],
			modelConfig: {
				type: 'string',
				mask: 'YYYY-MM-DD HH:MM:SS', // Uses 'iso' if missing
			},
		}
	},
	beforeMounted() {
		// this.inputVal =
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				if (typeof this.modelValue === 'string') {
					let res = this.modelValue.split(' ')
					console.log('INPUT:', res[0])
					return res[0]
				}

				return this.modelValue
			},
			set(val) {
				this.$emit('update:modelValue', val)
			},
		},
	},
	mounted() {
		// var d = new Date();
		// this.inputVal = d.toISOString();
	},
	methods: {
		clear() {
			this.inputVal = null
		},
	},
}
</script>
