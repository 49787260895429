<template>
	<FieldsInput v-if="field.type === 'input'" v-model="localValue" :field="field" :errors="errors" />
	<FieldsSelect v-if="field.type === 'select'" v-model="localValue" :field="field" :errors="errors" />
</template>

<script>
export default {
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {}
	},
	computed: {
		localValue: {
			get() {
				return this.modelValue
			},
			set(localValue) {
				this.$emit('update:modelValue', localValue)
			},
		},
	},
	mounted() {
		// console.log("FIELD:", this.modelValue);
	},
	methods: {},
}
</script>
