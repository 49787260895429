<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M32.81,37.89a4.24,4.24,0,0,0-.46-.1,4.44,4.44,0,0,0-1.7-.13c-.44.1-.9.13-1.34.25a6.09,6.09,0,0,0-3.17,2.26,6.86,6.86,0,0,0-.79,1.49A6.08,6.08,0,0,0,25,44.49a5.17,5.17,0,0,0,.46,1.62,6.3,6.3,0,0,0,3.06,3.07,5.52,5.52,0,0,0,2.91.55,6.1,6.1,0,0,0,4.71-2.86,7,7,0,0,0,.69-1.64A4.48,4.48,0,0,0,37,43.92q0-10.52.08-21,0-3.73,0-7.45c0-.32,0-.33.33-.33h2.94l3.69.11.13,0c0,.31.07.61.1.92a9.53,9.53,0,0,0,.53,2.35A8.94,8.94,0,0,0,46.13,21a11.63,11.63,0,0,0,1.18,1.25,9.78,9.78,0,0,0,2.76,1.92,12.61,12.61,0,0,0,2.54.84c.42.09.85.14,1.27.2l.29,0v7.08c-.41,0-.8-.07-1.2-.12-.72-.09-1.44-.17-2.15-.29a11.48,11.48,0,0,1-2.68-.8q-1.16-.5-2.28-1.05c-.45-.23-.89-.51-1.33-.76l-.26-.12v.28c0,5,0,10-.07,15a9,9,0,0,1-.42,2.67c-.22.67-.43,1.36-.71,2A10.32,10.32,0,0,1,41.29,52a20.94,20.94,0,0,1-1.8,1.85,11.24,11.24,0,0,1-2.76,1.78,15.2,15.2,0,0,1-2.66.92,13.27,13.27,0,0,1-4.16.29A10.14,10.14,0,0,1,26.28,56c-.63-.29-1.28-.56-1.88-.91a14.53,14.53,0,0,1-2.28-1.7,11.26,11.26,0,0,1-1.5-1.51,13.42,13.42,0,0,1-1.76-3,19.45,19.45,0,0,1-.65-2,13.05,13.05,0,0,1-.3-4.66,12.18,12.18,0,0,1,.63-2.81,13.19,13.19,0,0,1,2.16-3.9,13.72,13.72,0,0,1,3.32-3,13.33,13.33,0,0,1,2.77-1.33,13.17,13.17,0,0,1,3-.64,21.32,21.32,0,0,1,2.77.12c.12,0,.18,0,.19.19,0,2.29,0,4.59,0,6.88A1.25,1.25,0,0,1,32.81,37.89Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'TikTok',
	mixins: [iconMixin],
}
</script>
