<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.07 59.71" :width="size" :height="size">
		<path
			:fill="fillComputed"
			d="m30.54,11.92h0c-2.06,0-3.72-1.67-3.72-3.72V3.72c0-2.06,1.67-3.72,3.72-3.72s3.72,1.67,3.72,3.72v4.47c0,2.06-1.67,3.72-3.72,3.72Z"
		/>
		<path
			:fill="fillComputed"
			d="m30.54,43.2h0c-2.06,0-3.72-1.67-3.72-3.72v-19.36c0-2.06,1.67-3.72,3.72-3.72s3.72,1.67,3.72,3.72v19.36c0,2.06-1.67,3.72-3.72,3.72Z"
		/>
		<path
			:fill="fillComputed"
			d="m30.54,59.58h0c-2.06,0-3.72-1.67-3.72-3.72v-4.47c0-2.06,1.67-3.72,3.72-3.72s3.72,1.67,3.72,3.72v4.47c0,2.06-1.67,3.72-3.72,3.72Z"
		/>
		<path
			:fill="fillComputed"
			d="m17.13,47.67h0c-2.06,0-3.72-1.67-3.72-3.72v-4.47c0-2.06,1.67-3.72,3.72-3.72s3.72,1.67,3.72,3.72v4.47c0,2.06-1.67,3.72-3.72,3.72Z"
		/>
		<path
			:fill="fillComputed"
			d="m43.94,23.83h0c-2.06,0-3.72-1.67-3.72-3.72v-4.47c0-2.06,1.67-3.72,3.72-3.72s3.72,1.67,3.72,3.72v4.47c0,2.06-1.67,3.72-3.72,3.72Z"
		/>
		<path
			:fill="fillComputed"
			d="m17.13,31.28h0c-2.06,0-3.72-1.67-3.72-3.72v-13.41c0-2.06,1.67-3.72,3.72-3.72s3.72,1.67,3.72,3.72v13.41c0,2.06-1.67,3.72-3.72,3.72Z"
		/>
		<path
			:fill="fillComputed"
			d="m43.94,49.16h0c-2.06,0-3.72-1.67-3.72-3.72v-13.41c0-2.06,1.67-3.72,3.72-3.72s3.72,1.67,3.72,3.72v13.41c0,2.06-1.67,3.72-3.72,3.72Z"
		/>
		<path
			:fill="fillComputed"
			d="m3.72,35.75h0c-2.06,0-3.72-1.67-3.72-3.72v-4.47c0-2.06,1.67-3.72,3.72-3.72s3.72,1.67,3.72,3.72v4.47c0,2.06-1.67,3.72-3.72,3.72Z"
		/>
		<path
			:fill="fillComputed"
			d="m57.35,35.75h0c-2.06,0-3.72-1.67-3.72-3.72v-4.47c0-2.06,1.67-3.72,3.72-3.72s3.72,1.67,3.72,3.72v4.47c0,2.06-1.67,3.72-3.72,3.72Z"
		/>
	</svg>
</template>
<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'GoogleListening',
	mixins: [iconMixin],
}
</script>
