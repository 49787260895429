<template>
	<div class="overflow-hidden bg-white shadow sm:rounded-lg">
		<form @submit.prevent="update()">
			<div class="flex flex-wrap p-4 space-x-4">
				<div v-for="badge in badges" :key="badge.id" class="flex flex-col items-center gap-y-2">
					<img :src="badge.media.original_url" class="w-28 h-28" />
					<h4>{{ badge.name }}</h4>
					<input
						name="badge"
						v-model="form.badges"
						:value="badge.id"
						type="checkbox"
						class="w-4 h-4 border-gray-300 cursor-pointer text-sc-green focus:ring-sc-green"
					/>
				</div>
			</div>

			<div class="flex justify-end p-3">
				<Button text="Save" />
			</div>
		</form>
	</div>
</template>
<script>
import { computed, onMounted, ref, reactive, inject, watch } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

export default {
	setup() {
		const provider = inject('provider')
		const badges = inject('badges')
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		const form = reactive({
			badges: [],
		})

		return {
			provider,
			badges,
			api,
			toast,
			route,
			form,
		}
	},

	mounted() {
		if (this.provider.badges.length > 0) {
			this.provider.badges.forEach(badge => this.form.badges.push(badge.id))
		}
	},

	methods: {
		update() {
			this.loaderShow()

			this.api.providers
				.slug(this.route.params.id)
				.badges.update(this.form)
				.then(data => {
					this.provider.badges = data
					this.toast.success('Successfully updated Provider Badges')
				})
				.catch(error => {
					this.toast.error('Error updating Provider Badges:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
