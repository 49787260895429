<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M36,31.74a8.21,8.21,0,1,1,8.21-8.21A8.22,8.22,0,0,1,36,31.74m0-14a5.76,5.76,0,1,0,5.75,5.75A5.76,5.76,0,0,0,36,17.78"
		/>
		<path
			:fill="fillComputed"
			d="M36,53.68a1.23,1.23,0,0,1-.89-.38C34.49,52.64,20,37.17,20,23.32a16,16,0,0,1,32,0c0,13.85-14.47,29.32-15.08,30a1.24,1.24,0,0,1-.9.39M36,9.8A13.54,13.54,0,0,0,22.48,23.32c0,10.35,9.13,22.16,13.06,26.76a.57.57,0,0,0,.46.21.57.57,0,0,0,.45-.21c3.93-4.6,13.06-16.41,13.06-26.76A13.51,13.51,0,0,0,36,9.81"
		/>
		<path
			:fill="fillComputed"
			d="M36,59.17c-6.8,0-14-2.35-14-6.71,0-1.14.58-3.29,4.39-5a1.44,1.44,0,0,1,.5-.1,1.23,1.23,0,0,1,1.12.74,1.18,1.18,0,0,1,0,.94,1.21,1.21,0,0,1-.65.68c-1.83.8-2.92,1.81-2.92,2.71,0,2,4.95,4.26,11.57,4.26s11.57-2.26,11.57-4.27c0-.9-1.1-1.91-2.93-2.71a1.21,1.21,0,0,1-.65-.68,1.22,1.22,0,0,1,1.14-1.68,1.34,1.34,0,0,1,.49.1c3.83,1.67,4.4,3.83,4.4,5,0,4.36-7.22,6.71-14,6.71"
		/>
		<path
			:fill="fillComputed"
			d="M36,64.65c-13.21,0-23.17-4.85-23.17-11.28,0-3.89,3.71-7.31,10.18-9.4a1.19,1.19,0,0,1,.37-.06,1.22,1.22,0,0,1,.38,2.39c-5.3,1.72-8.47,4.35-8.47,7.06,0,4.26,8.32,8.83,20.71,8.83s20.71-4.57,20.71-8.83c0-2.67-3.1-5.29-8.3-7a1.23,1.23,0,0,1-.78-1.55A1.22,1.22,0,0,1,48.79,44a1.32,1.32,0,0,1,.39.06c6.34,2.09,10,5.5,10,9.34,0,6.43-9.95,11.28-23.16,11.28"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'FindLocation',
	mixins: [iconMixin],
}
</script>
