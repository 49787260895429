<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:style="'fill:' + fillComputed"
			d="M29.2,60.63c0,2.1,0,4.19,0,6.29,0,.91.35,2.25-1.16,2.24s-1-1.3-1-2.14c0-3.95-.1-7.9,0-11.84A6.29,6.29,0,0,0,24.76,50a10.71,10.71,0,0,1-3.68-8c0-3.34,2.31-4.74,5.13-3a5.51,5.51,0,0,0,7-.36,4,4,0,0,1,5.44,0,5.51,5.51,0,0,0,7,.33c2.75-1.68,5.1-.35,5.12,2.85a10.28,10.28,0,0,1-3.46,7.9,7.14,7.14,0,0,0-2.49,6c.16,3.88.09,7.77,0,11.65,0,.6-.67,1.19-1,1.79-.39-.58-1.09-1.16-1.1-1.75-.09-4.5,0-9,0-13.5a7.25,7.25,0,0,1,2.76-5.42,9,9,0,0,0,3.12-6.28,2.37,2.37,0,0,0-.54-1.62c-.17-.17-1,.14-1.45.4-3.09,1.84-6.08,1.79-8.93-.45a2.39,2.39,0,0,0-3.33,0c-2.86,2.23-5.85,2.27-8.93.44-.44-.26-1.3-.56-1.45-.39a2,2,0,0,0-.51,1.61c.68,1.87,1.09,4.15,2.42,5.41a10.51,10.51,0,0,1,3.47,9.27C29.1,58.15,29.2,59.4,29.2,60.63Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M29.2,11.1a13.14,13.14,0,0,0,0,1.66,9.44,9.44,0,0,1-3.8,9.37c-2.52,1.94-3,4.93-2.3,7.91,1.05,4.29,6.07,6,9,3.77a5.93,5.93,0,0,1,7.55,0c3.5,2.56,9-.37,9.2-5.11.11-2.83-.67-5.31-2.93-7a8.29,8.29,0,0,1-3.42-7.87c.22-2.69,0-5.42.1-8.12,0-.6.71-1.18,1.09-1.76.36.59,1,1.18,1,1.78.1,3,.16,6,0,9.05a5.29,5.29,0,0,0,2.16,4.79,10.66,10.66,0,0,1,4.19,9c-.1,6.53-7.56,10.68-12.53,7.18a4,4,0,0,0-5-.26,7.62,7.62,0,0,1-9.45-.17c-3.26-2.38-4-5.76-3.28-9.55a9.65,9.65,0,0,1,3.73-6A5.82,5.82,0,0,0,27,14.34c-.18-2.82-.05-5.66,0-8.49,0-.84-.16-2,1.11-1.82.45.06,1,1.21,1.06,1.9C29.3,7.64,29.2,9.37,29.2,11.1Z"
		/>
		<path
			:style="'fill:' + fillComputed"
			d="M40.65,62.11c0,1.79.1,3.58,0,5.36-.05.6-.73,1.16-1.12,1.73-.35-.54-1-1.08-1-1.63-.09-3.63-.09-7.27,0-10.9,0-.53.7-1.05,1.07-1.57.36.55,1,1.08,1,1.65C40.74,58.53,40.65,60.32,40.65,62.11Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'TotalJointProcedures',
	mixins: [iconMixin],
}
</script>
