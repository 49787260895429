<template>
	<div class="flex gap-x-2">
		<button
			v-if="params.showEdit"
			title="Edit Event"
			class="items-center p-1 border shadow-sm leading-4 font-medium rounded-md text-yellow-600 border-yellow-600 hover:text-yellow-700 hover:bg-yellow-200 focus:outline-none"
			@click="edit"
		>
			<PencilAltIcon class="h-5 w-5" aria-hidden="true" />
		</button>

		<a
			:href="previewUrl + params.data.path"
			target="_blank"
			title="View Event Page"
			class="items-center p-1 border shadow-sm leading-4 font-medium rounded-md text-indigo-600 border-indigo-600 hover:text-indigo-700 hover:bg-indigo-200 focus:outline-none"
		>
			<ExternalLinkIcon class="h-5 w-5" aria-hidden="true" />
		</a>

		<button
			v-if="params.data.registration_enabled && params.data.registration_count"
			title="Download Registrations"
			class="items-center p-1 border shadow-sm leading-4 font-medium rounded-md text-green-600 border-green-600 hover:text-green-700 hover:bg-green-200 focus:outline-none"
			@click="downloadRegistrations(params.data)"
		>
			<DownloadIcon class="h-5 w-5" aria-hidden="true" />
		</button>

		<!-- <button
			v-if="params.data.registration_enabled && params.data.registration_count"
			title="View Registrations"
			class="items-center p-1 border shadow-sm leading-4 font-medium rounded-md text-blue-600 border-blue-600 hover:text-blue-700 hover:bg-blue-200 focus:outline-none"
			@click="viewRegistrations(params.data)"
		>
			<ClipboardListIcon class="h-5 w-5" aria-hidden="true" />
		</button> -->

		<button
			v-if="params.showClone"
			class="items-center p-1 border shadow-sm leading-4 font-medium rounded-md text-gray-500 border-gray-500 hover:text-gray-600 hover:bg-gray-200 focus:outline-none"
			@click="clone"
		>
			<DuplicateIcon class="h-5 w-5" aria-hidden="true" />
		</button>

		<button
			v-if="params.showDelete"
			title="Delete Event"
			type="button"
			class="items-center p-1 border shadow-sm leading-4 font-medium rounded-md text-red-500 border-red-500 hover:text-red-600 hover:bg-red-200 focus:outline-none"
			@click="confirmDelete"
		>
			<TrashIcon class="h-5 w-5" aria-hidden="true" />
		</button>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>
	</div>
</template>

<script>
import { getCurrentInstance, defineComponent, inject } from 'vue'
// import { useToast } from 'vue-toastification'
import router from '@/router'
import getEnv from '@/extensions/env'

import {
	PencilAltIcon,
	DuplicateIcon,
	TrashIcon,
	DownloadIcon,
	// ClipboardListIcon,
	ExternalLinkIcon,
} from '@heroicons/vue/solid'

import ModalsConfirmation from '../Modals/Confirmation'

export default defineComponent({
	name: 'TableActionsById',
	components: {
		ModalsConfirmation,
		PencilAltIcon,
		DuplicateIcon,
		TrashIcon,
		DownloadIcon,
		// ClipboardListIcon,
		ExternalLinkIcon,
	},
	setup() {
		// const api = inject('api')
		// const toast = useToast()

		return {
			// api,
			// toast,
		}
	},
	data() {
		return {
			previewUrl: getEnv('VUE_APP_FRONTEND_URL'),
			confirmationOpen: false,
			confirmationConfig: {},
		}
	},
	mounted() {
		// console.log('params.data', this.params.data)
	},
	methods: {
		edit() {
			console.log(this.params.data)
			this.params.parent.loaderShow()

			this.params.appApi.events.eventInstances
				.fetch(this.params.data.id)
				.then(data => {
					this.params.parent.editEvent(data.customData)
				})
				.catch(error => {
					this.toast.error('Error fetching event:\n' + error)
				})
				.then(() => {
					this.params.parent.loaderHide()
				})
		},

		clone() {
			router.push({
				name: this.params.cloneRoute,
				query: { id: this.params.data.id },
			})
		},

		closeModal() {
			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.id
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete() {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'deleteItem',
				title: this.params.deleteTitle,
				content: this.params.deleteContent,
			}

			if (this.params.data.registration_count && this.params.data.future_event) {
				this.confirmationConfig.warning = 'This will send out cancellation notifications to registrants!'
			}

			this.$refs.confirmation.id = this.params.data.id
		},

		deleteItem() {
			let id = this.$refs.confirmation.id

			this.params.appApi.events.eventInstances
				.destroy(id)
				.then(data => {
					delete this.$refs.confirmation.id

					this.closeModal()

					this.params.parent.refreshTableEvents()
				})
				.catch(error => {
					// this.toast.error('Error deleting clinical research:\n' + error.response.data.message)
				})
				.then(() => {})
		},

		viewRegistrations(data) {
			return false
			this.eventInstance = JSON.parse(JSON.stringify(data))
			// console.log('eventInstance', this.eventInstance)
			this.eventRegistrationsOpen = true
		},

		downloadRegistrations(eventInstance) {
			this.params.parent.loaderShow()

			this.params.appApi.events.eventInstances
				.downloadRegistrations(eventInstance.id)
				.then(data => {
					// console.log(data)

					const blob = new Blob([data], { type: 'text/csv' })
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = eventInstance.fileName
					link.click()
					URL.revokeObjectURL(link.href)
				})
				.catch(error => {
					// this.toast.error('Error downloading event registrations:\n' + error)
				})
				.then(() => {
					this.params.parent.loaderHide()
				})
		},
	},
})
</script>
