<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			fill="none"
			:stroke="fillComputed"
			class="cls-1"
			d="M25,13.56H48.69a7,7,0,0,1,7,7V44.83a7,7,0,0,1-7,7H44.77V61.88L34.63,51.8H25a7,7,0,0,1-7-7V20.52A7,7,0,0,1,25,13.56Z"
		/>
		<g id="Group_56" data-name="Group 56">
			<g id="Group_51" data-name="Group 51">
				<path
					id="Path_47"
					data-name="Path 47"
					:fill="fillComputed"
					d="M34.26,31.68a3.34,3.34,0,0,0-2.79-1.46c-1.78,0-2.35,1.13-2.35,2a1.41,1.41,0,0,0,.54,1.15,5.57,5.57,0,0,0,2,1,10,10,0,0,1,3.45,1.72,4.63,4.63,0,0,1,1.46,3.57c0,3.32-2.4,5.6-5.93,5.6A7,7,0,0,1,24.8,42L27,39.93a4,4,0,0,0,3.58,2.27,2.39,2.39,0,0,0,2.62-2.13,1.62,1.62,0,0,0,0-.22,2,2,0,0,0-.66-1.53,7.59,7.59,0,0,0-2.63-1.25,8.16,8.16,0,0,1-2.83-1.48,3.93,3.93,0,0,1-1.36-3.17c0-2.5,1.68-5,5.47-5a6.49,6.49,0,0,1,4.82,2Z"
				/>
			</g>
			<g id="Group_52" data-name="Group 52">
				<path
					id="Path_48"
					data-name="Path 48"
					:fill="fillComputed"
					d="M47.08,36.19a4,4,0,0,0-2.87-1.09,3.34,3.34,0,0,0-3.5,3.17v.33a3.46,3.46,0,0,0,3.39,3.53h.23A4,4,0,0,0,47.08,41v3.4a7.15,7.15,0,0,1-8-1,6.39,6.39,0,0,1-2-4.76A6.41,6.41,0,0,1,43.72,32a7.4,7.4,0,0,1,3.36.75Z"
				/>
			</g>
			<g id="Group_53" data-name="Group 53">
				<path
					id="Path_49"
					data-name="Path 49"
					:fill="fillComputed"
					d="M42.12,30.94A11.55,11.55,0,0,1,46,25.2a6.93,6.93,0,0,0-2.79,1.61,7.2,7.2,0,0,0-1.79,2.76V29.5c0-.43,0-.85,0-1.28a6.25,6.25,0,0,1,.59-2.2,5.54,5.54,0,0,1,2.8-2.68,8.2,8.2,0,0,1,2.44-.68c.67-.08,1.34-.1,2-.15h.1c0,.18,0,.34,0,.51a8.51,8.51,0,0,1-1.31,5A6.15,6.15,0,0,1,45,30.43a9.16,9.16,0,0,1-2.48.48l-.38,0"
				/>
			</g>
			<g id="Group_54" data-name="Group 54">
				<path
					id="Path_50"
					data-name="Path 50"
					:fill="fillComputed"
					d="M39.27,17.48c-.23.36-.46.7-.66,1.06a6.41,6.41,0,0,0-.82,2.17A4.09,4.09,0,0,0,39,24.36a6.06,6.06,0,0,0,1.84,1.29,1.07,1.07,0,0,0,.15.07,5.73,5.73,0,0,0,1.27-4.41A5.08,5.08,0,0,0,41.06,19a7.65,7.65,0,0,0-1.64-1.42l-.15-.08"
				/>
			</g>
			<g id="Group_55" data-name="Group 55">
				<path
					id="Path_51"
					data-name="Path 51"
					:fill="fillComputed"
					d="M40,30.11a5.15,5.15,0,0,0-2.39-3.64,4.45,4.45,0,0,1,2.85,2.78v0c0-.27,0-.53,0-.8A4,4,0,0,0,40.11,27a3.49,3.49,0,0,0-1.73-1.7,5.09,5.09,0,0,0-1.53-.45c-.42-.06-.84-.08-1.26-.11h-.07c0,.11,0,.21,0,.32a5.36,5.36,0,0,0,.78,3.17,3.88,3.88,0,0,0,2,1.52,5.49,5.49,0,0,0,1.55.32l.24,0"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'VoiceOfThePatient',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1 {
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 2px;
}
</style>
