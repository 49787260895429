<template>
	<div>
		<div class="bg-white px-4 py-5 rounded-t-md border-gray-200 sm:px-6">
			<div class="-ml-4 -mt-2 flex items-center justify-end flex-wrap sm:flex-nowrap">
				<div class="ml-4 mt-2 flex-shrink-0">
					<div class="mt-4 flex md:mt-0 md:ml-4">
						<RefreshTableButton @refreshTable="refreshTable" />
					</div>
				</div>
			</div>
		</div>

		<ag-grid-vue
			style="height: 725px"
			class="ag-theme-alpine"
			:grid-options="gridOptions"
			:column-defs="columnDefs"
			:default-col-def="defaultColDef"
			:row-data="rowData"
			:modules="modules"
			:pagination="true"
			:pagination-page-size="50"
			:enable-cell-text-selection="true"
			:ensure-dom-order="true"
		></ag-grid-vue>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

import { AgGridVue } from '@ag-grid-community/vue3'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
// import TableActions from "@/components/TableActions.vue";

export default {
	components: {
		AgGridVue,
	},
	props: {
		data: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['fetchProvider'],
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		return {
			api,
			toast,
			route,
		}
	},
	data() {
		return {
			rowData: null,
			columnDefs: [
				// { field: "id", minWidth: 310 },
				{ field: 'name', flex: 1 },
				// { field: 'category', flex: 1 },
				// {
				//     headerName: "Actions",
				//     width: 200,
				//     cellRendererFramework: TableActions,
				//     cellRendererParams: {
				//         editRoute: "md-staff-residencies-id",
				//     },
				// },
			],
			defaultColDef: {
				resizable: true,
				sortable: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
			},
			modules: [ClientSideRowModelModule],
		}
	},
	beforeMount() {
		this.gridOptions = {
			context: {
				componentParent: this,
			},
		}
	},
	mounted() {
		this.gridApi = this.gridOptions.api
		this.fetchSocieties()
	},
	methods: {
		fetchSocieties() {
			this.loaderShow()

			this.api.providers
				.slug(this.route.params.id)
				.societies.fetch()
				.then(rowData => {
					if (Object.keys(rowData).length !== 0) this.rowData = rowData
				})
				.then(() => {
					this.loaderHide()
				})
		},

		refreshTable() {
			this.fetchSocieties()
		},
	},
}
</script>
