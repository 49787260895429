<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" :open="open">
			<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="ease-in duration-200"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div
						class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6"
					>
						<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
							<button
								type="button"
								class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
								@click="closeModal"
							>
								<span class="sr-only">Close</span>
								<XIcon class="h-6 w-6" aria-hidden="true" />
							</button>
						</div>
						<div class="">
							<div class="text-center sm:text-left">
								<DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
									Event Registrations
								</DialogTitle>
								<div class="mt-4">
									CONTENT
								</div>
							</div>
						</div>
						<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
							ACTIONS
							<!-- <button
								v-if="!instance.id"
								type="button"
								class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
								@click="store"
							>
								<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
								Save
							</button>

							<button
								v-if="instance.id"
								type="button"
								class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
								@click="update"
							>
								<SaveIcon class="h-5 w-5 mr-2" aria-hidden="true" />
								Update
							</button> -->
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
	// SaveIcon,
	XIcon,
} from '@heroicons/vue/outline'

export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		// SaveIcon,
		XIcon,
	},
	props: {
		open: Boolean,
		eventInstance: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['closeModal'],
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			event_id: null,
			events: [],
			instance: {},
		}
	},
	mounted() {
		this.fetchRegistrations()
	},
	methods: {
		closeModal() {
			this.$emit('closeModal')
		},

		fetchRegistrations() {
			this.loaderShow()

			this.api.events
				.selectList()
				.then(events => {
					this.events = events
				})
				.catch(error => {
					this.toast.error('Error fetching events:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
