<template>
	<div class="sm:pt-3" :class="[hasColSpan ? field.colSpan : '']">
		<label :for="field.model" class="block text-sm font-bold text-gray-700 pb-1">
			{{ field.label }}
			<span v-if="field.required">*</span>
		</label>
		<div class="mt-1 sm:mt-0 relative" :class="[field.clearButton ? 'flex' : '']">
			<input
				id="pac-input"
				v-model="place"
				:name="field.model"
				:autocomplete="field.model"
				type="text"
				class="col-span-3  focus:ring-blue-500 focus:border-blue-500 min-w-0 sm:text-sm border-gray-300"
				:class="[field.clearButton ? 'rounded-l-md flex-1' : 'block w-full rounded-md']"
			/>

			<button
				v-if="field.clearButton"
				class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none"
				@click="clearInput"
			>
				<span>Clear</span>
			</button>
		</div>

		<div v-show="field.showMap && show && mapUrl.length > 0" class="text-sm text-gray-400 rounded-md mt-2">
			<iframe
				ref="map"
				width="100%"
				height="450"
				style="border:0"
				loading="lazy"
				class="rounded-md"
				allowfullscreen
				src=""
			></iframe>
		</div>

		<div v-if="hasErrors" class="mt-2 text-red-500 font-semibold">
			{{ errors.$errors[0].$message }}
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		field: {
			type: Object,
			default: function() {
				return {}
			},
		},
		errors: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			autocomplete: {},
			place: '',
			mapUrl: '',
			show: false,
		}
	},
	computed: {
		hasErrors: function() {
			return typeof this.errors !== 'undefined' && typeof this.errors.length > 0 && this.errors.$errors.length > 0
		},
		hasColSpan: function() {
			return typeof this.field.colSpan !== 'undefined'
		},
		inputVal: {
			get() {
				return this.modelValue
			},
			set(val) {
				// console.log("INPUT:", val);
				this.$emit('update:modelValue', val)
			},
		},
	},
	beforeMount() {
		this.place = this.inputVal
	},
	mounted() {
		const center = { lat: 39.7911, lng: 89.6479 }
		// Create a bounding box with sides ~10km away from the center point
		const defaultBounds = {
			north: center.lat + 0.1,
			south: center.lat - 0.1,
			east: center.lng + 0.1,
			west: center.lng - 0.1,
		}
		const input = document.getElementById('pac-input')
		// console.log(input)
		const options = {
			bounds: defaultBounds,
			componentRestrictions: { country: 'us' },
			fields: ['address_components', 'geometry', 'icon', 'name'],
			origin: center,
			strictBounds: false,
		}
		this.autocomplete = new google.maps.places.Autocomplete(input, options)

		this.autocomplete.addListener('place_changed', () => {
			this.getPlace()
		})

		if (this.place && this.place.length > 0) {
			this.mapUrl =
				'https://www.google.com/maps/embed/v1/place?key=AIzaSyD_qu91e7EaTYzETIHN593DfQ2cDWBsiPk&q=' +
				encodeURIComponent(this.place)
			this.$refs.map.src =
				'https://www.google.com/maps/embed/v1/place?key=AIzaSyD_qu91e7EaTYzETIHN593DfQ2cDWBsiPk&q=' +
				encodeURIComponent(this.place)
		}
	},
	methods: {
		getPlace() {
			var place = this.autocomplete.getPlace()
			var placeParts = {}

			if (typeof place.address_components !== 'undefined' && place.address_components.length > 0) {
				place.address_components.forEach(element => {
					if (element.types.includes('street_number')) {
						placeParts.street_number = element.long_name
					} else if (element.types.includes('route')) {
						placeParts.route = element.long_name
					} else if (element.types.includes('locality')) {
						placeParts.city = element.long_name
					} else if (element.types.includes('administrative_area_level_1')) {
						placeParts.state = element.short_name
					} else if (element.types.includes('postal_code')) {
						placeParts.postal_code = element.long_name
					}
				})
				// console.log(placeParts, Object.keys(placeParts).length)
				// springfield clinic

				if (Object.keys(placeParts).length > 0) {
					this.inputVal =
						placeParts.street_number +
						' ' +
						placeParts.route +
						', ' +
						placeParts.city +
						', ' +
						placeParts.state +
						' ' +
						placeParts.postal_code

					this.place =
						placeParts.street_number +
						' ' +
						placeParts.route +
						', ' +
						placeParts.city +
						', ' +
						placeParts.state +
						' ' +
						placeParts.postal_code

					this.show = true

					this.mapUrl =
						'https://www.google.com/maps/embed/v1/place?key=AIzaSyD_qu91e7EaTYzETIHN593DfQ2cDWBsiPk&q=' +
						encodeURIComponent(
							placeParts.street_number +
								' ' +
								placeParts.route +
								', ' +
								placeParts.city +
								', ' +
								placeParts.state +
								' ' +
								placeParts.postal_code
						)
					// console.log(this.mapUrl)
					this.$refs.map.src = this.mapUrl
				}
			}
		},

		clearInput() {
			this.show = false
			this.$refs.map.src = ''
			this.inputVal = null
			this.place = null
		},
	},
}
</script>
