<template>
	<div class="flex-1 " :class="{ 'px-4 py-5 sm:p-6': showHeading }">
		<div class="space-y-8 sm:space-y-5">
			<div>
				<div v-show="showHeading" class="border-b pb-2">
					<h3 class="text-lg leading-6 font-semibold text-gray-700">
						SEO
					</h3>
					<p class="mt-1 max-w-2xl text-sm text-gray-500">
						This information will be used to describe the page content and set rules for the search engines.
					</p>
				</div>

				<div class=" grid grid-cols-1 sm:grid-cols-6 gap-x-5" :class="[showHeading ? 'mb-5 mt-1' : '']">
					<FieldsInput
						v-model="seoTmp.title"
						class="flex-1"
						:field="{
							type: 'input',
							inputType: 'text',
							label: 'Title',
							model: 'seo_title',
							required: true,
							min: 5,
							wordcount: true,
							colSpan: 'col-span-6',
						}"
						:disabled="disabled"
						@invalidField="invalidField"
						@validField="validField"
					/>
					<FieldsInput
						v-model="seoTmp.description"
						class="flex-1"
						:field="{
							type: 'input',
							inputType: 'text',
							label: 'Description',
							model: 'seo_description',
							required: true,
							min: 5,
							wordcount: true,
							colSpan: 'col-span-6',
						}"
						:disabled="disabled"
						@invalidField="invalidField"
						@validField="validField"
					/>

					<FieldsMultiSelect
						v-model="seoTmp.keywords"
						class="flex-1"
						:field="{
							type: 'select',
							label: 'Keywords',
							model: 'seo_keywords',
							options: keywordOptions,
							required: true,
							min: 1,
							max: 10,
							mode: 'tags',
							colSpan: 'col-span-6',
						}"
						:create-tag="true"
						:disabled="disabled"
						@storeTag="storeTag"
					/>

					<FieldsSelect
						v-model="seoTmp.robots"
						class="flex-1"
						:field="{
							type: 'select',
							label: 'Robots Directive',
							model: 'seo_robots',
							options: [
								{
									name: 'index, follow',
									value: 'index, follow',
								},
								{
									name: 'noindex, nofollow',
									value: 'noindex, nofollow',
								},
								{
									name: 'noindex, follow',
									value: 'noindex, follow',
								},
								{
									name: 'index, nofollow',
									value: 'index, nofollow',
								},
							],
							required: true,
							multiSelect: false,
							colSpan: 'col-span-6',
						}"
						:disabled="disabled"
					/>

					<FieldsInput
						v-model="seoTmp.canonical"
						:field="{
							type: 'input',
							inputType: 'text',
							label: 'Canonical Link',
							model: 'seo_canonical',
							prefix: 'www.springfieldclinic.com/',
							required: false,
							colSpan: 'col-span-6',
						}"
						:disabled="disabled"
						@invalidField="invalidField"
						@validField="validField"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

export default {
	props: {
		seo: {
			type: Object,
			default: function() {
				return {}
			},
		},
		showHeading: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue', 'invalidField', 'validField'],
	setup() {
		const api = inject('api')
		const toast = useToast()

		// const keywordOptions = inject('keywordOptions')

		return {
			api,
			toast,
			// keywordOptions,
		}
	},
	data() {
		return {
			keywordOptions: [],
		}
	},
	computed: {
		seoTmp: {
			get() {
				return (
					this.seo || {
						title: null,
						description: null,
						keywords: null,
						robots: null,
						canonical: null,
					}
				)
			},
			set(val) {
				console.log('INPUT:', val)
				this.$emit('update:modelValue', val)
			},
		},
	},
	mounted() {
		// this.keywordOptions = []
		this.fetchKeywords()
	},
	methods: {
		fetchKeywords() {
			this.loaderShow()

			this.api.keywords
				.all()
				.then(data => {
					this.keywordOptions = data
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching keywords:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		storeTag(tag) {
			// console.log('tag', tag)
			this.loaderShow()

			this.api.keywords
				.store({ name: tag })
				.then(data => {
					console.log('data', data)

					this.keywordOptions.push(data)
				})
				.catch(error => {
					if (error?.response?.data?.message ?? undefined)
						this.toast.error('Error fetching keywords:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		invalidField(field) {
			this.$emit('invalidField', field)
		},
		validField(field) {
			this.$emit('validField', field)
		},
	},
}
</script>
