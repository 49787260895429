<template>
	<div class="flex h-full w-full justify-center items-center">
		<Menu as="div" class="relative block h-full w-full text-left">
			<div class="h-full w-full">
				<MenuButton
					class="inline-flex justify-center items-center h-full w-full rounded-md shadow-sm px-4 py-1 bg-white border shadow text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none"
				>
					<PlusIcon class="h-5 w-5" aria-hidden="true" />
					Add Field
				</MenuButton>
			</div>

			<transition
				enter-active-class="transition ease-out duration-100"
				enter-from-class="transform opacity-0 scale-95"
				enter-to-class="transform opacity-100 scale-100"
				leave-active-class="transition ease-in duration-75"
				leave-from-class="transform opacity-100 scale-100"
				leave-to-class="transform opacity-0 scale-95"
			>
				<MenuItems
					class="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10"
				>
					<div class="py-2">
						<h1 class="text-center font-semibold">Fields</h1>
					</div>
					<div class="py-1 grid grid-flow-col grid-cols-2 grid-rows-8 gap-2">
						<MenuItem
							v-for="(field, index) in availableFields.fields"
							:key="field.name"
							@click="addField(index)"
						>
							<button class="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100">
								{{ field.name }}
							</button>
						</MenuItem>
					</div>

					<div v-if="showFieldGroups" class="py-2">
						<h1 class="text-center font-semibold">Field Groups</h1>
					</div>
					<div v-if="showFieldGroups" class="py-1 grid grid-flow-col grid-cols-2 grid-rows-2 gap-2">
						<MenuItem
							v-for="(fieldGroup, index) in availableFields.field_groups"
							:key="fieldGroup.name"
							@click="addFieldGroup(index)"
						>
							<button class="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100">
								{{ fieldGroup.name }}
							</button>
						</MenuItem>
					</div>
				</MenuItems>
			</transition>
		</Menu>
	</div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import { PlusIcon } from '@heroicons/vue/solid'

export default {
	components: {
		Menu,
		MenuButton,
		MenuItem,
		MenuItems,
		PlusIcon,
	},
	props: {
		showFieldGroups: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['add-field', 'add-field-group'],
	setup(props, { emit }) {
		const availableFields = inject('availableFields').value

		onMounted(() => {
			console.log('availableFields', availableFields)
		})

		function addField(index) {
			emit('add-field', index)
		}

		const addFieldGroup = index => {
			emit('add-field-group', index)
		}

		return {
			props,
			availableFields,
			addField,
			addFieldGroup,
		}
	},
}
</script>
