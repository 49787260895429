<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M36.75,60.75c-4,0-8,0-12,0A7.47,7.47,0,0,1,20,59.23a5.55,5.55,0,0,1-2.12-3.83,25.47,25.47,0,0,1-.13-5.09,18.59,18.59,0,0,1,3-9.07,6.93,6.93,0,0,1,5.68-3.05,3.44,3.44,0,0,1,2,.7c.94.57,1.87,1.15,2.84,1.67a11.8,11.8,0,0,0,6.47,1.33,12.88,12.88,0,0,0,5.91-2c.54-.34,1.08-.67,1.6-1a3.45,3.45,0,0,1,3-.55,8.15,8.15,0,0,1,5.6,4.6,16.65,16.65,0,0,1,1.57,5.26,36.1,36.1,0,0,1,.41,6.06,7,7,0,0,1-1.29,4,6.27,6.27,0,0,1-4.25,2.43,15.43,15.43,0,0,1-2.15.15Z"
		/>
		<path
			:fill="fillComputed"
			d="M36.79,19a10.45,10.45,0,1,1-10.4,10.49c0-.11,0-.21,0-.32A10.41,10.41,0,0,1,36.79,19Z"
		/>
		<path
			:fill="fillComputed"
			d="M26.46,36.25c-.82.18-1.66.31-2.47.55a9,9,0,0,0-4.13,2.64,1,1,0,0,1-.9.38c-1.63-.1-3.28-.12-4.9-.3a3.41,3.41,0,0,1-3.23-3,10.21,10.21,0,0,1,0-2.47c.14-1.61.36-3.21.53-4.82a4.94,4.94,0,0,1,1.2-2.67,1.46,1.46,0,0,1,1.67-.45c.86.28,1.7.65,2.54,1a12.62,12.62,0,0,0,3.52,1,24.13,24.13,0,0,0,2.82,0c.47,0,.94-.08,1.44-.12a11.56,11.56,0,0,0,.39,4.3C25.42,33.62,26,34.94,26.46,36.25Z"
		/>
		<path
			:fill="fillComputed"
			d="M46.92,36.28a14.17,14.17,0,0,0,1.77-4.07,12.46,12.46,0,0,0,.25-4.43l1.79.35a9.51,9.51,0,0,0,5.4-.6c.76-.3,1.49-.67,2.23-1a7.4,7.4,0,0,1,1-.39,1.55,1.55,0,0,1,2,.85.14.14,0,0,1,0,.06,14.31,14.31,0,0,1,.77,2.46,32.56,32.56,0,0,1,.65,3.68,14.71,14.71,0,0,1,0,3,3.71,3.71,0,0,1-2.87,3.24,11.75,11.75,0,0,1-3.64.42,9.77,9.77,0,0,1-1.62-.08,1.88,1.88,0,0,1-1-.46,9.94,9.94,0,0,0-6.57-3Z"
		/>
		<path :fill="fillComputed" d="M21.09,25.91a7,7,0,1,1,6.9-7V19A7,7,0,0,1,21.09,25.91Z" />
		<path :fill="fillComputed" d="M52.46,25.91a7,7,0,1,1,6.9-7V19A7,7,0,0,1,52.46,25.91Z" />
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'MeetOurTeam',
	mixins: [iconMixin],
}
</script>
