<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M16.68,55.83a2.11,2.11,0,0,1-2.12-2.11V28a2.12,2.12,0,0,1,2.12-2.12H54.43A2.11,2.11,0,0,1,56.54,28V38.41h.34a12.32,12.32,0,0,1,2.66.29V19.49a2.64,2.64,0,0,0-2.64-2.64H50.55V18.7c0,2.32-1.53,4.15-3.49,4.15h-.53c-2,0-3.48-1.83-3.48-4.15V16.85h-15V18.7c0,2.32-1.52,4.15-3.48,4.15H24c-1.95,0-3.48-1.83-3.48-4.15V16.85H14.21a2.65,2.65,0,0,0-2.65,2.64v36.7a2.65,2.65,0,0,0,2.65,2.64H47.36a12.38,12.38,0,0,1-1.87-3Z"
		/>
		<rect :fill="fillComputed" x="37.05" y="46.84" width="6" height="6" rx="1.06" />
		<rect :fill="fillComputed" x="28.05" y="46.84" width="6" height="6" rx="1.06" />
		<rect :fill="fillComputed" x="19.06" y="46.84" width="6" height="6" rx="1.06" />
		<path
			:fill="fillComputed"
			d="M52,39.37V38.9a1.05,1.05,0,0,0-1-1.06H47.1a1.05,1.05,0,0,0-1.05,1.06v3.88a1,1,0,0,0,.62,1A12.54,12.54,0,0,1,52,39.37Z"
		/>
		<rect :fill="fillComputed" x="37.05" y="37.84" width="6" height="6" rx="1.06" />
		<rect :fill="fillComputed" x="28.05" y="37.84" width="6" height="6" rx="1.06" />
		<rect :fill="fillComputed" x="19.06" y="37.84" width="6" height="6" rx="1.06" />
		<rect :fill="fillComputed" x="46.05" y="28.84" width="6" height="6" rx="1.06" />
		<rect :fill="fillComputed" x="37.05" y="28.84" width="6" height="6" rx="1.06" />
		<rect :fill="fillComputed" x="28.05" y="28.84" width="6" height="6" rx="1.06" />
		<path
			:fill="fillComputed"
			d="M26.56,13.5v5.2c0,1.46-.89,2.65-2,2.65H24c-1.1,0-2-1.19-2-2.65V13.5c0-1.47.88-2.65,2-2.65h.53C25.67,10.85,26.56,12,26.56,13.5Z"
		/>
		<path
			:fill="fillComputed"
			d="M49.05,13.5v5.2c0,1.46-.89,2.65-2,2.65h-.53c-1.09,0-2-1.19-2-2.65V13.5c0-1.47.89-2.65,2-2.65h.53C48.16,10.85,49.05,12,49.05,13.5Z"
		/>
		<path
			:fill="fillComputed"
			d="M56.88,40.17A10.67,10.67,0,1,0,67.54,50.83,10.67,10.67,0,0,0,56.88,40.17Zm6.45,6.71h0c-.25.32-6,8.4-7.43,9.86a1,1,0,0,1-1.44,0l-4-4a1.13,1.13,0,0,1-.32-.7.81.81,0,0,1,.23-.65l.62-.62a1,1,0,0,1,1.44,0h0l2.79,3.74,6.17-9.6a1,1,0,0,1,.72-.3,1,1,0,0,1,.72.3l.62.63A1,1,0,0,1,63.33,46.88Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Appointments',
	mixins: [iconMixin],
}
</script>
