<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<g id="Group_548" data-name="Group 548">
			<g id="Path_128-2" data-name="Path 128-2">
				<path
					:stroke="fillComputed"
					class="cls-1"
					d="M22.69,13.83h28c2.2,0,4,2.08,4,4.64h0V51c0,2.56-1.79,4.64-4,4.64h-28c-2.2,0-4-2.08-4-4.64h0V18.47c0-2.56,1.79-4.64,4-4.64Z"
				/>
			</g>
		</g>
		<path
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M27.72,26.11h1.81a1,1,0,0,0,1-1V23.36h1.77a1,1,0,0,0,1-1V20.58a1,1,0,0,0-1-1H30.51V17.83a1,1,0,0,0-1-1H27.72a1,1,0,0,0-1,1v1.78H25a1,1,0,0,0-1,1v1.81a1,1,0,0,0,1,1h1.78v1.78A1,1,0,0,0,27.72,26.11ZM25,22.84a.44.44,0,0,1-.44-.45V20.58a.43.43,0,0,1,.44-.44h2a.27.27,0,0,0,.27-.27v-2a.43.43,0,0,1,.44-.44h1.81a.44.44,0,0,1,.45.44v2a.26.26,0,0,0,.26.27h2a.44.44,0,0,1,.45.44v1.81a.45.45,0,0,1-.45.45h-2a.25.25,0,0,0-.26.26v2a.44.44,0,0,1-.45.44H27.72a.43.43,0,0,1-.44-.44v-2a.26.26,0,0,0-.27-.26Z"
		/>
		<path
			id="Path_184"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M43.6,20.06h5.14a.24.24,0,0,1,.26.16.27.27,0,0,1-.11.35l-.08,0H38.39a.23.23,0,0,1-.22-.24v0a.27.27,0,0,1,.23-.27h5.2Z"
		/>
		<path
			id="Path_184-2"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M43.46,23.74h5.15a.24.24,0,0,1,.26.16.27.27,0,0,1-.11.35l-.08,0H38.26A.23.23,0,0,1,38,24v0a.27.27,0,0,1,.23-.27h5.19Z"
		/>
		<path
			id="Path_184-3"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M28.79,40.68h5.14a.25.25,0,0,1,.27.17.27.27,0,0,1-.12.34s0,0-.07,0H23.58a.23.23,0,0,1-.21-.25h0a.26.26,0,0,1,.22-.27h5.2Z"
		/>
		<path
			id="Path_184-4"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M35,40.68h5.14a.24.24,0,0,1,.27.17.27.27,0,0,1-.11.34l-.08,0H29.75a.24.24,0,0,1-.22-.25h0a.26.26,0,0,1,.23-.27H35Z"
		/>
		<path
			id="Path_184-5"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M43.33,40.68h5.14a.24.24,0,0,1,.27.17.27.27,0,0,1-.11.34l-.08,0H38.13a.24.24,0,0,1-.22-.25h0a.26.26,0,0,1,.23-.27h5.19Z"
		/>
		<path
			id="Path_184-6"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M28.66,37.09H33.8a.25.25,0,0,1,.27.17.27.27,0,0,1-.12.34l-.07,0H23.45a.23.23,0,0,1-.21-.24v0a.27.27,0,0,1,.22-.27h5.2Z"
		/>
		<path
			id="Path_184-7"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M34.82,37.09H40a.24.24,0,0,1,.27.17.28.28,0,0,1-.11.34l-.08,0H29.62a.23.23,0,0,1-.22-.24v0a.27.27,0,0,1,.23-.27h5.19Z"
		/>
		<path
			id="Path_184-8"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M43.2,37.09h5.14a.24.24,0,0,1,.27.17.27.27,0,0,1-.11.34l-.08,0H38a.23.23,0,0,1-.22-.24v0a.27.27,0,0,1,.23-.27H43.2Z"
		/>
		<path
			id="Path_184-9"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M28.79,33.65h5.14a.25.25,0,0,1,.27.17.27.27,0,0,1-.12.34s0,0-.07,0H23.58a.23.23,0,0,1-.21-.25h0a.26.26,0,0,1,.22-.27h5.2Z"
		/>
		<path
			id="Path_184-10"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M35,33.65h5.14a.24.24,0,0,1,.27.17.27.27,0,0,1-.11.34l-.08,0H29.75a.24.24,0,0,1-.22-.25h0a.26.26,0,0,1,.23-.27H35Z"
		/>
		<path
			id="Path_184-11"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M43.33,33.65h5.14a.24.24,0,0,1,.27.17.27.27,0,0,1-.11.34l-.08,0H38.13a.24.24,0,0,1-.22-.25h0a.26.26,0,0,1,.23-.27h5.19Z"
		/>
		<path
			id="Path_184-12"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M28.79,29.6h5.14a.25.25,0,0,1,.27.16.28.28,0,0,1-.12.35l-.07,0H23.58a.23.23,0,0,1-.21-.25h0a.26.26,0,0,1,.22-.27h5.2Z"
		/>
		<path
			id="Path_184-13"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M35,29.6h5.14a.23.23,0,0,1,.27.16.28.28,0,0,1-.11.35l-.08,0H29.75a.24.24,0,0,1-.22-.25h0a.26.26,0,0,1,.23-.27H35Z"
		/>
		<path
			id="Path_184-14"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M43.33,29.6h5.14a.23.23,0,0,1,.27.16.28.28,0,0,1-.11.35l-.08,0H38.13a.24.24,0,0,1-.22-.25h0a.26.26,0,0,1,.23-.27h5.19Z"
		/>
		<path
			id="Path_184-15"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M28.69,45.25h5.14a.24.24,0,0,1,.27.17.29.29,0,0,1-.11.35l-.08,0H23.49a.24.24,0,0,1-.22-.25h0a.26.26,0,0,1,.23-.27h5.19Z"
		/>
		<path
			id="Path_184-16"
			data-name="Path 184"
			:style="'fill:' + fillComputed"
			class="cls-2"
			d="M28.62,48.69h5.14a.24.24,0,0,1,.27.16.29.29,0,0,1-.11.35l-.08,0H23.42A.23.23,0,0,1,23.2,49v0a.26.26,0,0,1,.23-.27h5.19Z"
		/>
		<circle
			:stroke="fillComputed"
			fill="#fff"
			class="cls-3"
			cx="44.36"
			cy="48.21"
			r="4.53"
			transform="matrix(0.99, -0.16, 0.16, 0.99, -7.15, 7.73)"
		/>
		<g id="Group_25" data-name="Group 25">
			<path
				id="Path_23"
				data-name="Path 23"
				:style="'fill:' + fillComputed"
				class="cls-2"
				d="M44.72,51.64h-.59l0,0c.07-.34-.12-.45-.43-.52a2.59,2.59,0,0,1-.77-.36,1.3,1.3,0,0,1-.57-1.31h1s.08.08.09.12c.07.72.65.87,1.25.75a.62.62,0,0,0,.5-.58A.63.63,0,0,0,44.9,49c-.34-.19-.71-.33-1.06-.5s-.43-.21-.63-.32a1.4,1.4,0,0,1-.69-1.38,1.45,1.45,0,0,1,.93-1.29,3.13,3.13,0,0,1,.52-.16c.16,0,.23-.12.21-.28a1,1,0,0,1,0-.24h.61a2.53,2.53,0,0,1,0,.28c0,.15,0,.23.21.26a1.77,1.77,0,0,1,.92.47,1.54,1.54,0,0,1,.43,1.22h-1s-.1-.12-.12-.19a.75.75,0,0,0-.79-.69l-.11,0a.6.6,0,0,0-.58.49.66.66,0,0,0,.28.8c.3.17.63.31.94.46l.63.29a1.44,1.44,0,0,1,.81,1.56,1.39,1.39,0,0,1-1,1.26l-.18.06c-.13,0-.32,0-.39.11s-.05.26-.06.41"
			/>
		</g>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'PatientAccounting',
	mixins: [iconMixin],
}
</script>

<style scoped>
.cls-1 {
	fill: none;
}
.cls-1,
.cls-3 {
	stroke-miterlimit: 10;
}
.cls-2 {
	stroke: none;
	/* fill: #003764; */
}
.cls-3 {
	/* fill: #fff; */
	stroke-width: 0.5px;
}
</style>
