<template>
	<svg :width="size" :height="size" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M53,23.2a3,3,0,0,1,3,3V45.58a3,3,0,0,1-3,3H33.13l-7,7A.91.91,0,0,1,24.59,55V48.6H20.36a3,3,0,0,1-3-3V26.23a3,3,0,0,1,3-3ZM50.38,28a.92.92,0,0,0-.73.27l-13,13-13-13a1,1,0,0,0-.55-.27.91.91,0,0,0-.73,1.55l6.31,6.31-6.31,6.32a.89.89,0,0,0,0,1.28.91.91,0,0,0,1.28,0L30,37.18l6,6a.91.91,0,0,0,1.28,0l6-6,6.31,6.32a.92.92,0,0,0,1.29,0,.91.91,0,0,0,0-1.28L44.62,35.9l6.32-6.31a.91.91,0,0,0,0-1.28,1,1,0,0,0-.56-.27Zm0,0"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Email',
	mixins: [iconMixin],
}
</script>
