<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M44.7,13.61c3.51,1.62,3.51,1.62,6.1-1,2-2,2.71-2.05,4.79-.16a44.91,44.91,0,0,1,3.52,3.65,2.22,2.22,0,0,1,.07,3.13l-.28.25-.57.55c-2.57,2.58-2.57,2.58-1.06,6.16h4.52c1.37,0,2.31.63,2.36,2q.13,3.33,0,6.65a1.9,1.9,0,0,1-1.94,1.88H62a12.87,12.87,0,0,1-2.06,0c-1.94-.3-3.11.27-3.68,2.33-.28,1-.32,1.6.49,2.29S58.24,42.85,59,43.6a2.1,2.1,0,0,1,.38,2.95l-.15.18c-1.45,1.61-3,3.13-4.6,4.59a2,2,0,0,1-2.85,0l-.14-.15c-.27-.26-.53-.53-.79-.79-2.56-2.55-2.56-2.55-6.14-1v3.89c0,2.38-.58,3-3.17,3.08,0-3,0-5.9,0-8.84,0-.31.47-.76.82-.9,2.09-.85,4.21-1.62,6.73-2.57l3.94,4.5,3.26-3.26-4.52-4L55,33.64h6.1V29.43H55l-3.12-7.54,4.57-4-3.17-3.05-4,4.32-7.77-3.22V9.84H37.33c0,1.72,0,3.44,0,5.17,0,.89-.26,1.23-1.14,1.51-2.05.66-4,1.53-6.3,2.42l-4-4.48-3.35,3.23,4.55,4.12c-1.43,3.62-1.6,3.72-5,3.18l1-2.5-3.23-3a2.09,2.09,0,0,1-.51-2.91,1.59,1.59,0,0,1,.26-.32c1.43-1.55,2.92-3.06,4.48-4.48a2.13,2.13,0,0,1,3,0l.14.15a13.32,13.32,0,0,1,1.45,1.46c1.13,1.48,2.28,2,4.1.92.94-.56,1.46-.94,1.37-2.07a28.46,28.46,0,0,1,0-3.33,1.86,1.86,0,0,1,1.65-2H36q3.39-.15,6.8,0c1.32.06,1.92,1,1.94,2.27C44.72,10.59,44.7,12.12,44.7,13.61Z"
		/>
		<path
			:fill="fillComputed"
			d="M23.07,64.07c-4.3-.49-8.63-.81-12.9-1.5-3.06-.5-3.24-1.06-2.74-4.12A17.64,17.64,0,0,1,14.7,46.77a1.44,1.44,0,0,1,1.95,0,11.06,11.06,0,0,0,12.81,0,1.53,1.53,0,0,1,2.08.06A18,18,0,0,1,39,59.8a2,2,0,0,1-1.45,2.41l-.39.06c-3.38.55-6.79,1-10.19,1.4a32.29,32.29,0,0,1-3.81,0Z"
		/>
		<path :fill="fillComputed" d="M14.16,37.29a8.93,8.93,0,1,1,17.85,0h0a8.93,8.93,0,0,1-17.85,0Z" />
		<path
			:fill="fillComputed"
			d="M34.66,41.12l.61-3c5.35,1.93,9.46.58,11.21-3.68a7.65,7.65,0,0,0-3.78-9.8c-4-1.92-8.09-.27-10.63,4.38l-2.3-1.9c1.28-4.79,7.89-7.67,13.22-5.8a10.84,10.84,0,0,1,6.63,13.81q-.12.35-.27.69a10.7,10.7,0,0,1-14.06,5.62C35.07,41.33,34.87,41.23,34.66,41.12Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'Resources',
	mixins: [iconMixin],
}
</script>
