<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M37.56,8A27.74,27.74,0,1,0,65.3,35.76,27.74,27.74,0,0,0,37.56,8ZM43.2,49.17c0,.73,0,1.47,0,2.2a1.6,1.6,0,0,1,0,.31,1.75,1.75,0,0,1-1.9,1.58c-2.45,0-4.91,0-7.36,0-1.34,0-1.94-.61-1.95-1.93,0-3.25,0-6.51,0-9.92H22.83a7.09,7.09,0,0,1-1.38-.13A1.55,1.55,0,0,1,20,39.58c0-2.44,0-4.88,0-7.32,0-1.61.54-2.15,2.19-2.19,2.17-.06,4.36,0,6.54,0h3.1c0-2.24,0-4.43,0-6.62,0-1.17.05-2.33.1-3.49a1.21,1.21,0,0,1,0-.19,1.61,1.61,0,0,1,1.68-1.52q3.91-.08,7.83,0c1.08,0,1.69.77,1.71,1.95,0,2.86,0,5.73,0,8.6V30h5c1.56,0,3.12,0,4.67,0s2.23.62,2.25,2.27c0,2.29,0,4.57,0,6.86,0,1.59-.62,2.18-2.32,2.2-2.8,0-5.6,0-8.4,0H43.2Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'APPJobs',
	mixins: [iconMixin],
}
</script>
