<template>
	<div>
		<div class="bg-white px-4 pt-5 rounded-t-md border-gray-200 sm:px-6">
			<div class="-ml-4 -mt-2 flex items-center justify-end flex-wrap sm:flex-nowrap">
				<div class="ml-4 mt-2 flex-shrink-0">
					<div v-if="rowData" class="mt-4 flex gap-x-5 md:mt-0 md:ml-4">
						<a
							:href="csvFile"
							target="_blank"
							class="relative inline-flex items-center px-2 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
						>
							<DownloadIcon class="h-5 w-5 mr-2 text-white" aria-hidden="true" />
							Download CSV
						</a>

						<RefreshTableButton @refreshTable="refreshTable" />
					</div>
				</div>
			</div>
		</div>

		<div class="bg-white px-4 py-5 rounded-b-md border-gray-200 sm:px-6">
			<div v-if="rowData">
				<ag-grid-vue
					style="height: 725px"
					class="ag-theme-alpine"
					:grid-options="gridOptions"
					:column-defs="columnDefs"
					:default-col-def="defaultColDef"
					:row-data="rowData"
					:modules="modules"
					:pagination="true"
					:pagination-page-size="50"
					:enable-cell-text-selection="true"
					:ensure-dom-order="true"
				></ag-grid-vue>
			</div>
		</div>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

import { AgGridVue } from '@ag-grid-community/vue3'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import TableActions from '@/components/TableActions.vue'
import getEnv from '@/extensions/env'
import { DownloadIcon } from '@heroicons/vue/solid'

export default {
	components: {
		AgGridVue,
		DownloadIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		let csvFile =
			getEnv('VUE_APP_API_URL').replace('/cms', '/storage') +
			'/provider-missing-images.csv?' +
			Math.floor(Date.now() / 1000)

		return {
			api,
			toast,
			route,
			csvFile,
		}
	},
	data() {
		return {
			rowData: null,
			columnDefs: [
				{ field: 'name', flex: 1 },
				{ field: 'department_name', flex: 1 },
				{
					headerName: 'Header Image',
					field: 'header_image',
					width: 300,
					filter: false,
					// valueFormatter: this.booleanFormatter,
					cellRenderer: params => {
						return !params.value
							? '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">Missing</span>'
							: '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Has</span>'
					},
				},
				{
					headerName: 'Card Image',
					field: 'card_image',
					width: 300,
					filter: false,
					valueFormatter: this.booleanFormatter,
					cellRenderer: params => {
						return !params.value
							? '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">Missing</span>'
							: '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Has</span>'
					},
				},
				{
					headerName: 'MTD Video',
					field: 'mtd_video',
					width: 300,
					filter: false,
					valueFormatter: this.booleanFormatter,
					cellRenderer: params => {
						return !params.value
							? '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">Missing</span>'
							: '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Has</span>'
					},
				},
				{
					headerName: 'Actions',
					width: 100,
					filter: false,
					cellRendererFramework: TableActions,
					cellRendererParams: {
						editRoute: 'md-staff-providers-id',
						showEdit: true,
						newTab: true,
					},
				},
			],
			defaultColDef: {
				resizable: true,
				sortable: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
			},
			modules: [ClientSideRowModelModule],
		}
	},
	beforeMount() {
		this.gridOptions = {
			context: {
				componentParent: this,
			},
		}
	},
	mounted() {
		this.gridApi = this.gridOptions.api

		this.fetchProviderMissingImages()
	},
	methods: {
		fetchProviderMissingImages() {
			this.loaderShow()
			this.rowData = null

			this.api.reports.providers.images
				.all()
				.then(providers => {
					this.rowData = providers
				})
				.catch(error => {
					this.toast.error('Error fetching provider missing images:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		refreshTable() {
			this.fetchProviderMissingImages()

			this.csvFile =
				getEnv('VUE_APP_API_URL').replace('/cms', '/storage') +
				'/provider-missing-images.csv?' +
				Math.floor(Date.now() / 1000)
		},

		booleanFormatter(params) {
			return params.value ? 'Yes' : 'No'
		},
	},
}
</script>
