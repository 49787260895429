<template>
	<div>
		<div class="bg-white px-4 py-5 rounded-t-md border-gray-200 sm:px-6">
			<div class="-ml-4 -mt-2 flex items-center justify-end flex-wrap sm:flex-nowrap">
				<div class="ml-4 mt-2 flex-shrink-0">
					<div class="mt-4 flex md:mt-0 md:ml-4">
						<RefreshTableButton @refreshTable="refreshTable" />
					</div>
				</div>
			</div>
		</div>

		<ag-grid-vue
			style="height: 725px"
			class="ag-theme-alpine"
			:grid-options="gridOptions"
			:column-defs="columnDefs"
			:default-col-def="defaultColDef"
			:row-data="rowData"
			:modules="modules"
			:pagination="true"
			:pagination-page-size="50"
			:enable-cell-text-selection="true"
			:ensure-dom-order="true"
		></ag-grid-vue>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

import { AgGridVue } from '@ag-grid-community/vue3'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import TableActions from '@/components/TableActions.vue'

export default {
	components: {
		AgGridVue,
	},
	props: {
		data: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['fetchLocation'],
	setup() {
		const api = inject('api')
		const toast = useToast()
		const route = useRoute()

		return {
			api,
			toast,
			route,
		}
	},
	data() {
		return {
			rowData: null,
			columnDefs: [
				// { field: "id", minWidth: 310 },
				{ field: 'name', flex: 1 },
				// { field: 'department.name', flex: 1 },
				{
					field: 'department.name',
					valueGetter: params => {
						return params.data.department_overrided
							? params.data.department_overrided.name
							: params.data.department.name
					},
					flex: 1,
				},
				{
					headerName: 'Accepting New Patients',
					field: 'accepting_new_patients',
					width: 200,
					valueFormatter: this.booleanFormatter,
					cellRenderer: params => {
						return params.value === 0
							? '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">No</span>'
							: '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Yes</span>'
					},
				},
				{
					headerName: 'Active',
					field: 'active',
					width: 100,
					valueFormatter: this.booleanFormatter,
					cellRenderer: () => {
						return !params.value
							? '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">No</span>'
							: '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Yes</span>'
					},
				},
				{
					headerName: 'Actions',
					width: 100,
					cellRendererFramework: TableActions,
					cellRendererParams: {
						editRoute: 'md-staff-providers-id',
						showEdit: true,
						newTab: true,
						newTab: true,
					},
				},
			],
			defaultColDef: {
				resizable: true,
				sortable: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
			},
			modules: [ClientSideRowModelModule],
		}
	},
	beforeMount() {
		this.gridOptions = {
			context: {
				componentParent: this,
			},
		}
	},
	mounted() {
		this.gridApi = this.gridOptions.api
		this.fetchProviders()
	},
	methods: {
		fetchProviders() {
			this.loaderShow()

			this.api.locations
				.slug(this.route.params.id)
				.providers.fetch()
				.then(rowData => {
					if (Object.keys(rowData).length !== 0) this.rowData = rowData
				})
				.then(() => {
					this.loaderHide()
				})
		},

		refreshTable() {
			this.fetchProviders()
		},
	},
}
</script>
