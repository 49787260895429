<template>
	<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 72 72">
		<path
			:fill="fillComputed"
			d="M60.13,43.59a1.26,1.26,0,0,0,1.8,0l4.53-4.92a1.26,1.26,0,0,0,0-1.73l-29-29a1.27,1.27,0,0,0-1.76,0l-29,29a1.26,1.26,0,0,0,0,1.73l4.53,4.92a1.21,1.21,0,0,0,.89.4h0a1.25,1.25,0,0,0,.88-.36l3.43-3.43V59.59H9.19a1.25,1.25,0,1,0,0,2.5H64a1.25,1.25,0,0,0,0-2.5H56.7V40.16ZM12.19,40.9,9.36,37.82,36.58,10.59,63.81,37.82,61,40.9,37.46,17.39a1.23,1.23,0,0,0-1.76,0Zm42-3.12V59.59H19V37.78s0-.07,0-.1L36.58,20,54.22,37.68S54.2,37.75,54.2,37.78Z"
		/>
		<path
			:fill="fillComputed"
			d="M32,34.9c-3.85,0-6.54,2.55-6.54,6.2a6.42,6.42,0,0,0,2,4.57l8.16,8.16a1.22,1.22,0,0,0,.48.36,1.36,1.36,0,0,0,.48.09h.19L37,54.2a1.33,1.33,0,0,0,.49-.35l8.18-8.18a6.42,6.42,0,0,0,2-4.57c0-3.65-2.69-6.2-6.54-6.2a6.41,6.41,0,0,0-4.6,1.91A6.45,6.45,0,0,0,32,34.9Zm5.86,4.79c0-.92,1.4-2.29,3.35-2.29,2.46,0,4,1.45,4,3.7a3.94,3.94,0,0,1-1.29,2.81l-7.35,7.35-7.36-7.35a3.94,3.94,0,0,1-1.29-2.81c0-2.25,1.58-3.7,4-3.7,2,0,3.36,1.37,3.36,2.29a1.25,1.25,0,0,0,2.5,0Z"
		/>
	</svg>
</template>

<script>
import iconMixin from '@/mixins/icon.js'
export default {
	name: 'CommunitySchoolSupport',
	mixins: [iconMixin],
}
</script>
