<template>
	<div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
		<div class="sm:col-span-2">
			<label for="fieldLabel" class="block text-sm font-medium text-gray-700">
				Field Label
			</label>
			<div class="mt-1 flex rounded-md shadow-sm">
				<input
					v-model="schemaLocal.label"
					type="text"
					name="fieldLabel"
					class="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
				/>
			</div>
		</div>

		<div class="sm:col-span-2">
			<label for="fieldName" class="block text-sm font-medium text-gray-700">
				Field Name
			</label>
			<div class="mt-1 flex rounded-md shadow-sm">
				<input
					v-model="schemaLocal.name"
					type="text"
					name="fieldName"
					class="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
				/>
			</div>
		</div>

		<div class="sm:col-span-2">
			<label for="fieldName" class="block text-sm font-medium text-gray-700">
				Field Type
			</label>
			<div class="flex rounded-md shadow-sm">
				<select
					id="location"
					v-model="schemaLocal.type"
					name="location"
					class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
					@input="changeFieldType"
				>
					<option>Text</option>
					<option>Select</option>
					<option>Radio</option>
					<option>Checkbox</option>
				</select>
			</div>
		</div>

		<div class="sm:col-span-6 flex justify-end">
			<button
				type="button"
				class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
				@click.prevent="submitField"
			>
				<PlusCircleIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
				Add Field
			</button>
		</div>
	</div>
</template>

<script>
import { PlusCircleIcon } from '@heroicons/vue/solid'

export default {
	components: {
		PlusCircleIcon,
	},
	props: {
		schema: {
			type: Object,
			default: function() {
				return {}
			},
		},
	},
	emits: ['submitField'],
	data() {
		return {
			schemaLocal: {},
			//   index: Number,
		}
	},
	beforeMount() {
		this.schemaLocal = this.schema
	},
	methods: {
		submitField() {
			this.$emit('submitField', this.schema)
		},
	},
}
</script>
